import { Icon } from '@chakra-ui/react'
import React from 'react'

const WeatherRain: React.FC = (props) => (
  <Icon boxSize="80px" viewBox="0 0 80 80" {...props}>
    <rect width="80" height="80" rx="10" fill="#2F80ED" />
    <path
      d="M42.4 42.4H64C64 29.9536 54.5272 19.72 42.3976 18.52L42.4 18.4C42.3982 18.0809 42.3328 17.7654 42.2076 17.4719C42.0824 17.1784 41.8999 16.9128 41.6709 16.6907C41.4418 16.4685 41.1708 16.2943 40.8736 16.1782C40.5764 16.062 40.259 16.0063 39.94 16.0143C39.621 16.0223 39.3069 16.0938 39.0158 16.2246C38.7248 16.3555 38.4628 16.5431 38.2451 16.7764C38.0275 17.0097 37.8585 17.2841 37.7482 17.5834C37.6378 17.8828 37.5882 18.2012 37.6024 18.52C25.4728 19.72 16 29.9512 16 42.4H37.6V56.8C37.6 57.4365 37.3471 58.047 36.8971 58.4971C36.447 58.9471 35.8365 59.2 35.2 59.2C34.5635 59.2 33.953 58.9471 33.5029 58.4971C33.0529 58.047 32.8 57.4365 32.8 56.8C32.8 56.1635 32.5471 55.553 32.0971 55.1029C31.647 54.6529 31.0365 54.4 30.4 54.4C29.7635 54.4 29.153 54.6529 28.7029 55.1029C28.2529 55.553 28 56.1635 28 56.8C28 58.7096 28.7586 60.5409 30.1088 61.8912C31.4591 63.2414 33.2904 64 35.2 64C37.1096 64 38.9409 63.2414 40.2912 61.8912C41.6414 60.5409 42.4 58.7096 42.4 56.8V42.4Z"
      fill="white"
    />
  </Icon>
)

export default WeatherRain
