import React from 'react'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppList, AppListItem } from '../../components/common/AppList'
import { AppLoading } from '../../components/common/AppLoading'
import AppSearchHeader from '../../components/common/AppSearchHeader'
import {
  useContentSearchContext,
  withContentSearch,
} from '../../context/ContentSearchContext'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { getDateFormat } from '../../utils/date'

const KairanList: React.FC = () => {
  useHeaderConfig({
    title: 'お知らせ',
  })

  const { query } = useContentSearchContext()
  const PAGE_SIZE = 20
  const params = {
    page: 1,
    pageSize: PAGE_SIZE,
    search: query.search,
    startDate: query?.startDate,
    endDate: query?.endDate,
  }
  const { isLoading, isError, error, data, fetchNextPage, hasNextPage } =
    useContentListQuery('kairan', params)

  if (isLoading) {
    return <AppLoading />
  }

  if (isError) {
    return <AppErrorResult error={error} />
  }

  return (
    <>
      <AppSearchHeader
        contentType="kairan"
        isShowTag
        defaultValues={{
          search: '',
          year: '',
          placeId: '',
          tag: '',
        }}
      />
      <AppList
        isMore={hasNextPage}
        onClickMore={() => {
          fetchNextPage()
        }}
      >
        {data.pages.map((page) =>
          page.contents.map((content) => (
            <AppListItem
              key={content.id}
              title={content.titleJa}
              date={getDateFormat(content.releaseAt)}
              isShowLike
              likeCount={content?.likeCount}
              isLiked={content?.isLiked}
              tags={content?.contentTags?.map((tag) => tag.label) || []}
              link={`/kairan/${content.id}`}
              contentId={content.id}
              isReaded={content.isRead}
            />
          )),
        )}
      </AppList>
    </>
  )
}

export default withContentSearch(KairanList)
