import { UseQueryOptions } from '@tanstack/react-query'
import { MeApiMeDetailRequest } from '../../openapi/api'
import appApiClient from './appApiClient'
import useBaseQuery from './useBaseQuery'

export const useMeQuery = (
  req: MeApiMeDetailRequest,
  opts?: Omit<UseQueryOptions, 'queryFn'>,
) =>
  useBaseQuery({
    queryKey: ['meDetail', ...Object.keys(req)],
    queryFn: (config) => appApiClient.meApi.meDetail(req, config),
    ...opts,
  })
