import { Box, Button, HStack, Spacer } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppLoading } from '../../components/common/AppLoading'
import { useMeQuery } from '../../hooks/coreApi/useMeQuery'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import useNativeApp from '../../hooks/useNativeApp'
import { getIdToken } from '../../utils/auth'
import jsonMockUsers from './mockUsers.json'

const VideoPrivateTest: React.FC = () => {
  useHeaderConfig({
    title: '友達一覧（デバッグ用）',
  })

  const navigate = useNavigate()

  const meQuery = useMeQuery({})
  const me = meQuery.data?.user

  const nativeApp = useNativeApp()

  useEffect(() => {
    if (!getIdToken()) {
      navigate('/login', { replace: false })
    }
  }, [])

  if (!me) {
    return <AppLoading />
  }

  const mockUsers = jsonMockUsers as {
    id: number
    name: string
    email: string
  }[]

  return (
    <Box>
      {mockUsers.map((user) => (
        <Box borderBottom="1px solid #ddd" py="4" px="4">
          <HStack>
            <Box>
              {user.name}&nbsp;({user.email})
            </Box>
            <Spacer />
            <Box>
              <Button
                onClick={() => {
                  nativeApp.openVideoCall({
                    targetUser: {
                      id: user.id,
                      name: user.name,
                    },
                    user: {
                      id: me.id,
                      name: me.nickname,
                    },
                  })
                }}
              >
                通話開始（発信ポップアップ表示）
              </Button>
            </Box>
          </HStack>
        </Box>
      ))}
    </Box>
  )
}

export default VideoPrivateTest
