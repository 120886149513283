import {
  FormControl,
  FormErrorMessage,
  Select,
  SelectProps,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import {
  useFormContext,
  FieldPath,
  FieldValues,
  useController,
} from 'react-hook-form'

export type AppFormSelectProps<T extends FieldValues> = SelectProps & {
  name: FieldPath<T>
  children: ReactNode
}

const AppFormSelect = <T extends FieldValues>({
  name,
  children,
  ...other
}: AppFormSelectProps<T>) => {
  const { control, getFieldState } = useFormContext()
  const { field } = useController({ name, control })
  const { error } = getFieldState(name)

  return (
    <FormControl isInvalid={!!error?.message}>
      <Select
        {...field}
        fullWidth
        {...other}
        onChange={(evt) => {
          const value = evt.target.value === '' ? null : evt.target.value
          field.onChange(value)
        }}
      >
        {children}
      </Select>
      <FormErrorMessage>{error?.message || ''}</FormErrorMessage>
    </FormControl>
  )
}

export default AppFormSelect
