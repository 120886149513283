import { Button, ButtonProps, Icon, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { MdRadioButtonUnchecked } from 'react-icons/md'
import { p2r } from '../../../utils/font'

type TaxiTimeOKButtonProps = {
  onClick: () => void
  isActive?: boolean
} & ButtonProps
const TaxiTimeOKButton: React.FC<TaxiTimeOKButtonProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { onClick, isActive = false, ...rest } = props
  return (
    <Button
      w={isMobile ? p2r(56) : p2r(100)}
      h={p2r(56)}
      borderRadius="8px"
      borderWidth="2px"
      borderColor="rgba(106, 164, 59, 0.8)"
      backgroundColor={isActive ? 'theme.primary' : 'grand.white'}
      onClick={onClick}
      {...rest}
    >
      <Icon
        as={MdRadioButtonUnchecked}
        boxSize={isMobile ? p2r(28) : p2r(32)}
        color={isActive ? 'grand.white' : 'theme.primary'}
      />
    </Button>
  )
}
export default TaxiTimeOKButton
