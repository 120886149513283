import {
  Flex,
  Text,
  Link,
  PinInput,
  PinInputField,
  Stack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthButton } from '../../components/auth/AuthButton'
import { FormHeading } from '../../components/auth/FormHeading'
import { useAuthContext } from '../../context/AuthContext'
import { useLoginCodeMutation } from '../../hooks/coreApi/useLoginCodeMutation'
import useFormatMessage from '../../hooks/useFormatMessage'
import useNativeApp from '../../hooks/useNativeApp'
import { LS_KEY } from '../../lib/constants'
import { setSession } from '../../utils/auth'
import { p2r } from '../../utils/font'
import { useLoginState } from './useLoginState'

type Props = {
  fromPath: string
}

const ConfirmTwoFA: React.FC<Props> = ({ fromPath }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const loginCodeMutation = useLoginCodeMutation()

  const { f } = useFormatMessage()
  const { loginState, setLoginState } = useLoginState()
  const [pinNumber, setPinNumber] = useState<string>('')
  const [redirectPath, setRedirectPath] = useState<string | null>(null)
  const authContext = useAuthContext()
  const isError = pinNumber === ''
  const toast = useToast()
  const navigate = useNavigate()
  const nativeApp = useNativeApp()

  const onError = () => {
    toast({
      title: 'ログインできませんでした。', // TODO サーバーエラーのメッセージ
      status: 'error',
    })
  }

  const handleNext = () => {
    if (pinNumber.length !== 6) {
      toast({
        title: 'ログインできませんでした。', // TODO サーバーエラーのメッセージ
        status: 'error',
      })
      return
    }

    loginCodeMutation.mutate(
      {
        userLoginCodeRequest: {
          emailOrPhone: loginState.email,
          password: loginState.password,
          authCode: pinNumber,
        },
      },
      {
        onSuccess(res) {
          const { token: idToken, refreshToken } = res.data
          if (!idToken) {
            onError()
            return
          }
          setLoginState({
            authCode: pinNumber,
            idToken,
          })
          setSession(idToken, refreshToken)
          authContext.refetchMe()

          // ネイティブ側にcookie同期
          nativeApp.didLogin()

          toast({
            title: 'ログインしました。',
          })
          navigate(redirectPath || fromPath, { replace: true })
          localStorage.removeItem(LS_KEY.redirectPath)
        },
        onError,
      },
    )
  }
  useEffect(() => {
    const path = localStorage.getItem(LS_KEY.redirectPath)
    setRedirectPath(path)
  }, [])

  return (
    <Flex
      direction="column"
      alignItems={{ base: 'unset', md: 'center' }}
      w="100%"
    >
      <Stack
        spacing={{ base: 'unset', md: 4 }}
        alignItems={{ base: 'unset', md: 'center' }}
      >
        <FormHeading label={f('コードを確認')} />
        <Text fontSize={{ base: p2r(14), md: p2r(16) }} color="text.body">
          {f('送信されたコードを入力してください。')}
        </Text>
      </Stack>
      <Flex
        columnGap="8px"
        mt={{ base: '193px', md: '40px' }}
        mb={{ base: '222px', md: '80px' }}
        justify={{ base: 'space-between', md: 'center' }}
      >
        <PinInput
          focusBorderColor="theme.primary"
          placeholder=""
          onComplete={(event) => {
            setPinNumber(event)
          }}
        >
          <PinInputField
            fontSize={p2r(20)}
            bgColor="grand.white"
            border="2px solid"
            borderColor="grand.grayLight"
            w={{ base: '45px', md: '64px' }}
            h="64px"
          />
          <PinInputField
            fontSize={p2r(20)}
            bgColor="grand.white"
            border="2px solid"
            borderColor="grand.grayLight"
            w={{ base: '45px', md: '64px' }}
            h="64px"
          />
          <PinInputField
            fontSize={p2r(20)}
            bgColor="grand.white"
            border="2px solid"
            borderColor="grand.grayLight"
            w={{ base: '45px', md: '64px' }}
            h="64px"
            mr={{ base: '40px', md: '32px' }}
          />
          <PinInputField
            fontSize={p2r(20)}
            bgColor="grand.white"
            border="2px solid"
            borderColor="grand.grayLight"
            w={{ base: '45px', md: '64px' }}
            h="64px"
          />
          <PinInputField
            fontSize={p2r(20)}
            bgColor="grand.white"
            border="2px solid"
            borderColor="grand.grayLight"
            w={{ base: '45px', md: '64px' }}
            h="64px"
          />
          <PinInputField
            fontSize={p2r(20)}
            bgColor="grand.white"
            border="2px solid"
            borderColor="grand.grayLight"
            w={{ base: '45px', md: '64px' }}
            h="64px"
          />
        </PinInput>
      </Flex>
      {isMobile && (
        <Text color="text.sub" fontSize={p2r(16)} textAlign="center" pb="16px">
          {f('コードが届かない方は')}
          <Link href="/" color="theme.primary" fontSize={p2r(16)}>
            <Text as="u" fontWeight="700">
              {f('こちら')}
            </Text>
          </Link>
        </Text>
      )}
      <AuthButton
        isLoading={loginCodeMutation.isLoading}
        isDisabled={isError}
        onClick={handleNext}
        bgColor={isError ? 'grand.white' : 'theme.primary'}
        color={isError ? 'grand.grayLight' : 'text.white'}
      >
        {f('ログイン')}
      </AuthButton>
      {!isMobile && (
        <Text color="text.sub" fontSize={p2r(16)} pt="16px">
          {f('コードが届かない方は?')}
          <Link onClick={handleNext} color="theme.primary" fontSize={p2r(16)}>
            <Text as="u" fontWeight="700">
              {f('コードを再送する')}
            </Text>
          </Link>
        </Text>
      )}
    </Flex>
  )
}

export default ConfirmTwoFA
