import { UseQueryOptions } from '@tanstack/react-query'
import { RoomMeetingApiRoomMeetingListRequest } from '../../openapi/api'
import appApiClient from './appApiClient'
import useBaseQuery from './useBaseQuery'

export const useRoomMeetingListQuery = (
  req: RoomMeetingApiRoomMeetingListRequest,
  opts?: Omit<UseQueryOptions, 'queryFn'>,
) =>
  useBaseQuery({
    queryKey: ['roomMeetingList', ...Object.keys(req)],
    queryFn: (config) =>
      appApiClient.roomMeetingApi.roomMeetingList(req, config),
    ...opts,
  })
