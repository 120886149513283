import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react'
import { ReactNode } from 'react'

export type AppModalProps = Omit<ModalProps, 'children'> & {
  isOpen: boolean
  onClose(): void
  title?: ReactNode
  footer?: ReactNode
  okText?: ReactNode
  onOk?: () => void
  isShowOk?: boolean
  cancelText?: ReactNode
  onCancel?: () => void
  isShowCancel?: boolean
  children?: ReactNode
  maxWidth?: string | number
}

const AppModal: React.FC<AppModalProps> = ({
  isOpen,
  onClose,
  title,
  footer,
  okText,
  isShowOk = true,
  cancelText,
  onCancel = () => {},
  isShowCancel = true,
  onOk,
  children = undefined,
  maxWidth = '448px',
  ...other
}: AppModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose} {...other}>
    <ModalOverlay />
    <ModalContent maxWidth={maxWidth}>
      {title && <ModalHeader>{title}</ModalHeader>}
      <ModalCloseButton />
      {children && <ModalBody>{children}</ModalBody>}
      <ModalFooter>
        {footer === undefined ? (
          <>
            {isShowCancel && (
              <Button variant="ghost" mr={3} onClick={onCancel}>
                {cancelText || 'キャンセル'}
              </Button>
            )}

            {isShowOk && (
              <Button
                colorScheme="primary"
                onClick={() => {
                  if (onOk) {
                    onOk()
                  }
                }}
              >
                {okText || 'OK'}
              </Button>
            )}
          </>
        ) : (
          footer
        )}
      </ModalFooter>
    </ModalContent>
  </Modal>
)

export default AppModal
