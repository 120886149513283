import { useEffect, useRef } from 'react'

const useInterval = (onUpdate: () => void) => {
  const savedCallback = useRef(onUpdate)

  useEffect(() => {
    const times = setInterval(() => savedCallback.current(), 10000)
    return () => clearInterval(times)
  }, [savedCallback])
}

export default useInterval
