import { Box, Flex, Text, VStack } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AppErrorResult from '../../../components/common/AppErrorResult'
import { AppLoading } from '../../../components/common/AppLoading'
import { TaxiBox } from '../../../components/taxi/TaxiBox'
import { TaxiButton } from '../../../components/taxi/TaxiButton'
import { TaxiTitle } from '../../../components/taxi/TaxiTitle'
import { useTaxiReservationListQuery } from '../../../hooks/coreApi/useTaxiReservationListQuery'
import useFormatMessage from '../../../hooks/useFormatMessage'
import useHeaderConfig from '../../../hooks/useHeaderConfig'
import { getFromLabel, getToLabel } from '../../../models/taxiReservation'
import { p2r } from '../../../utils/font'

// 仮予約確定
const TaxiReservationPreFix: React.FC = () => {
  useHeaderConfig({
    title: 'タクシー予約',
  })

  const [searchParams] = useSearchParams()
  const outBoundTaxiReservationId = searchParams.get(
    'outBoundTaxiReservationId',
  )

  const outBoundTaxiReservationListQuery = useTaxiReservationListQuery({
    id: Number(outBoundTaxiReservationId),
    withTaxiCompany: true,
    withStartTaxiLandmark: true,
    withEndTaxiLandmark: true,
  })
  const taxiReservation =
    outBoundTaxiReservationListQuery.data?.taxiReservations?.[0]

  const { f } = useFormatMessage()
  const navigate = useNavigate()

  if (outBoundTaxiReservationListQuery.isLoading) {
    return <AppLoading />
  }

  if (!taxiReservation) {
    return <AppErrorResult message="予約見つかりませんでした" />
  }

  return (
    <Flex
      bgColor={{ base: '#FFFDFC', md: 'theme.background' }}
      minH={{ base: 'unset', md: 'calc(100vh - 56px)' }}
      justify="center"
      alignItems="center"
      direction="column"
      pt={{ base: '16px', md: '0px' }}
    >
      <Flex
        justify="center"
        alignItems="center"
        direction="column"
        px={{ base: '16px', md: '0px' }}
      >
        <VStack spacing="8px">
          <TaxiTitle label={f('仮予約が確定しました')} />
          <Text
            color="grand.error"
            fontSize={{ base: p2r(16), md: p2r(20) }}
            fontWeight="700"
            lineHeight="150%"
          >
            ※{f('予約が確定次第ご連絡いたします。')}
          </Text>
          <Text
            color="text.sub"
            fontSize={{ base: p2r(16), md: p2r(18) }}
            lineHeight="150%"
            textAlign="center"
          >
            {f(
              '数日経過しても連絡がない場合はお問合せいただきますようお願いいたします。',
            )}
          </Text>
        </VStack>
        <Box
          my={{ base: '16px', md: '2.8vh' }}
          w={{ base: '100%', md: 'unset' }}
        >
          <TaxiBox
            to={getToLabel(taxiReservation)}
            from={getFromLabel(taxiReservation)}
            companies={[taxiReservation?.taxiCompany?.name || '']}
            fromTime={dayjs(taxiReservation.rideAt).format(
              'M月DD日(ddd) HH:mm',
            )}
            isGoback={!!taxiReservation.inBoundTaxiReservationId}
            toTime={dayjs(taxiReservation.rideAt).format('M月DD日(ddd) HH:mm')} // TODO 帰路情報
          />
        </Box>
      </Flex>

      <Flex
        mt={{ base: 'auto', md: 'unset' }}
        columnGap={{ base: '0px', md: '32px' }}
        rowGap={{ base: '8px', md: '0px' }}
        direction={{ base: 'column', md: 'row' }}
        w={{ base: '100%', md: 'auto' }}
        p={{ base: '16px', md: '0px' }}
        backgroundColor={{ base: 'grand.white', md: 'unset' }}
        boxShadow={{ base: '0px 0px 10px rgba(0, 0, 0, 0.1)', md: 'unset' }}
      >
        <TaxiButton
          onClick={() => {
            navigate('/')
          }}
          isLarge
        >
          {f('ホームに戻る')}
        </TaxiButton>
      </Flex>
    </Flex>
  )
}

export default TaxiReservationPreFix
