import { Icon } from '@chakra-ui/react'
import React from 'react'
/**
 * もっとみるアイコン
 */
const More: React.FC = (props) => (
  <Icon viewBox="0 0 48 48" {...props}>
    <path
      d="M12 28C10.9 28 9.95867 27.608 9.176 26.824C8.392 26.0413 8 25.1 8 24C8 22.9 8.392 21.958 9.176 21.174C9.95867 20.3913 10.9 20 12 20C13.1 20 14.0413 20.3913 14.824 21.174C15.608 21.958 16 22.9 16 24C16 25.1 15.608 26.0413 14.824 26.824C14.0413 27.608 13.1 28 12 28ZM24 28C22.9 28 21.9587 27.608 21.176 26.824C20.392 26.0413 20 25.1 20 24C20 22.9 20.392 21.958 21.176 21.174C21.9587 20.3913 22.9 20 24 20C25.1 20 26.042 20.3913 26.826 21.174C27.6087 21.958 28 22.9 28 24C28 25.1 27.6087 26.0413 26.826 26.824C26.042 27.608 25.1 28 24 28ZM36 28C34.9 28 33.958 27.608 33.174 26.824C32.3913 26.0413 32 25.1 32 24C32 22.9 32.3913 21.958 33.174 21.174C33.958 20.3913 34.9 20 36 20C37.1 20 38.042 20.3913 38.826 21.174C39.6087 21.958 40 22.9 40 24C40 25.1 39.6087 26.0413 38.826 26.824C38.042 27.608 37.1 28 36 28Z"
      fill="currentColor"
    />
  </Icon>
)

export default More
