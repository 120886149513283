import { useQuery } from '@tanstack/react-query'
import { weatherApiClient } from './weatherApiClient'

export type WeekDataType = {
  date: Date
  r_prob: number
  temp_max: number
  temp_min: number
  wthc: number
  wthw: string
}

export const useDailyForecastQuery = (weatherAreaCode: string) =>
  useQuery({
    queryKey: ['wetherApi', 'dailyForecast', weatherAreaCode],
    queryFn: () => weatherApiClient.getDailyForecasts(weatherAreaCode),
})

export const useWth10ForecastQuery = () =>
  useQuery({
    queryKey: ['wetherWth10Api', 'dailyWth10Forecast'],
    queryFn: () => weatherApiClient.getWth10Forecasts(),
})

export const usePointwfForecastQuery = () =>
useQuery({
  queryKey: ['wetherPointwfApi', 'dailyPointwfForecast'],
  queryFn: () => weatherApiClient.getPointwfForecasts(),
})