import { Box, Flex, FlexProps, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MypageButton } from '../../components/mypage/MypageButton'
import { useAuthContext } from '../../context/AuthContext'
import { usePlaceListQuery } from '../../hooks/coreApi/usePlaceListQuery'
import { useTrashCalendarListQuery } from '../../hooks/coreApi/useTrashCalendarListQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import { p2r } from '../../utils/font'

type ProfileItemProps =
  | {
      label: string
      value: string
    } & FlexProps
const ProfileItem: React.FC<ProfileItemProps> = (props) => {
  const { label, value, children, ...rest } = props
  return (
    <Flex
      pt="16px"
      pb={{ base: '16px', md: '16px' }}
      borderBottom="0.5px solid"
      borderBottomColor="grand.grayLight"
      flexDirection="column"
      rowGap={{ base: '8px', md: '16px' }}
      _last={{ borderBottomWidth: 0 }}
      {...rest}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Text
            fontSize={{ base: p2r(16), md: p2r(18) }}
            lineHeight="150%"
            fontWeight="400"
            color="text.sub"
          >
            {label}
          </Text>
          <Text
            fontSize={p2r(24)}
            lineHeight="150%"
            fontWeight="700"
            color="text.body"
          >
            {value}
          </Text>
        </Box>
        <Box>{children}</Box>
      </Flex>
    </Flex>
  )
}

const MypageProfile: React.FC = () => {
  const navigate = useNavigate()
  const { f } = useFormatMessage()
  const { me, refetchMe } = useAuthContext()

  const placeListQuery = usePlaceListQuery({})
  const places = placeListQuery?.data?.places

  const trashCalendarListQuery = useTrashCalendarListQuery({})
  const trashCalendars = trashCalendarListQuery?.data?.trashCalendars

  useEffect(() => {
    refetchMe()
  }, [])

  if (!me) {
    return null
  }

  return (
    <Box px={{ base: '16px', md: '32px' }} py={{ base: '0', md: '32px' }}>
      <Flex
        justifyContent={{ base: 'unset', md: 'space-between' }}
        align="center"
        direction={{ base: 'column', md: 'row' }}
        rowGap="8px"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          columnGap="16px"
          order={{ base: '2', md: '1' }}
          mr={{ base: 'auto', md: 'unset' }}
        >
          {/* <Image
            src="https://placehold.jp/150x150.png"
            borderRadius="50%"
            w={{ base: '56px', md: '60px' }}
            h={{ base: '56px', md: '60px' }}
            objectFit="cover"
          /> */}
          <Text color="text.body" fontSize={p2r(24)} fontWeight="700">
            {me.nickname}
          </Text>
        </Flex>
        <Box order={{ base: '1', md: '2' }} ml={{ base: 'auto', md: 'unset' }}>
          <MypageButton
            onClick={() => {
              navigate('/mypage/edit')
            }}
          >
            {f('プロフィールを変更する')}
          </MypageButton>
        </Box>
      </Flex>
      <Box mt={{ base: '0px', md: '32px' }}>
        <Flex direction="column">
          <ProfileItem label={f('お名前')} value={me.nickname} />

          {/* TODO https://www.notion.so/maglab/E-2d2e415e3a7b4a83aa08354bfaff60d4 */}
          {me?.userInfo?.email && (
            <ProfileItem
              w="100%"
              label={f('Eメールアドレス')}
              value={me.userInfo.email}
              borderBottom={{ base: '0.5px solid', md: 'none' }}
            >
              <MypageButton
                onClick={() => {
                  navigate('/mypage/updateEmail')
                }}
              >
                {f('変更する')}
              </MypageButton>
            </ProfileItem>
          )}
          {me?.userInfo?.phone && (
            <ProfileItem
              w="100%"
              label={f('電話番号')}
              value={me.userInfo.phone}
              borderBottom={{ base: '0.5px solid', md: 'none' }}
            >
              <MypageButton
                onClick={() => {
                  navigate('/mypage/updatePhone')
                }}
              >
                {f('変更する')}
              </MypageButton>
            </ProfileItem>
          )}

          <ProfileItem w="100%" label={f('パスワード')} value="●●●●●●●●●●">
            <MypageButton
              onClick={() => {
                navigate('/mypage/updatePassword')
              }}
            >
              {f('変更する')}
            </MypageButton>
          </ProfileItem>
          <Flex
            justify="space-between"
            borderBottom="0.5px solid"
            borderBottomColor="grand.grayLight"
            direction={{ base: 'column', md: 'row' }}
          >
            {me?.userInfo?.code && (
              <ProfileItem
                w={{ base: '100%', md: '50%' }}
                label={f('さあ・くるユーザーID')}
                value={me.userInfo.code}
              />
            )}
          </Flex>
          <Flex
            justify="space-between"
            borderBottom="0.5px solid"
            borderBottomColor="grand.grayLight"
            direction={{ base: 'column', md: 'row' }}
          >
            <ProfileItem
              w={{ base: '100%', md: '50%' }}
              label={f('エリア')}
              value={places?.find((p) => p.id === me.placeId)?.name || ''}
            />
            <ProfileItem
              w={{ base: '100%', md: '50%' }}
              label={f('ごみ出しカレンダーエリア')}
              value={
                trashCalendars?.find((c) => c.id === me.trashCalendarId)
                  ?.name || '未設定'
              }
            />
          </Flex>
          {/*
          <ProfileItem label={f('郵便番号')} value="771-3200" />
          <ProfileItem label={f('住居エリア')} value="上分地区" />
          <ProfileItem
            label={f('住所')}
            value="徳島県名西郡神山町神領本野間１００番地"
          />
           */}
        </Flex>
      </Box>
    </Box>
  )
}

export default MypageProfile
