import { Box, Flex, Grid, GridItem, Icon, Text } from '@chakra-ui/react'
import dayjs, { Dayjs } from 'dayjs'
import React, { useMemo, useState } from 'react'
import { MdPerson, MdPinDrop, MdSchedule } from 'react-icons/md'
import { useAdminTaxiReservationModalContext } from '../../../components/taxi/admin/AdminTaxiReservationModalContext'
import { useAuthContext } from '../../../context/AuthContext'
import { useTaxiReservationListQuery } from '../../../hooks/coreApi/useTaxiReservationListQuery'
import {
  getFromLabel,
  getStatusOption,
  getToLabel,
  getUserName,
} from '../../../models/taxiReservation'
import { TaxiReservationResponse } from '../../../openapi'
import { p2r } from '../../../utils/font'
import PagerButton from './PagerButton'

type Reserve = {
  name: string // 予約者
  from: string // 出発地
  to: string // 目的地
  month: number // 月
  day: number // 日
  dayOfWeek: string // 曜日
  time: string // 時間
  color: string | undefined
  reservation: TaxiReservationResponse
}

type ReserveCardProps = {
  onClick(): void
} & Omit<Reserve, 'reservation'>

const ReserveCard: React.FC<ReserveCardProps> = (props) => (
  <Box
    p="16px"
    borderWidth="2px"
    borderColor={props.color || 'theme.primary'}
    borderRadius="8px"
    onClick={() => {
      props.onClick()
    }}
  >
    <Flex direction="column" rowGap="8px">
      <Flex columnGap="8px" align="center">
        <Icon as={MdPerson} color="text.sub" boxSize="24px" />
        <Text flex="1" fontSize={p2r(16)} fontWeight="700">
          {props.name}
        </Text>
      </Flex>
      <Flex columnGap="8px" align="center">
        <Icon as={MdPinDrop} color="text.sub" boxSize="24px" />
        <Text flex="1" fontSize={p2r(16)}>
          {props.from}→
          <Box as="span" fontSize={p2r(20)} fontWeight="700">
            {props.to}
          </Box>
        </Text>
      </Flex>
      <Flex columnGap="8px" align="center">
        <Icon as={MdSchedule} color="text.sub" boxSize="24px" />
        <Text flex="1" fontSize={p2r(20)} fontWeight="700">
          {props.month}
          <Box as="span" fontSize={p2r(16)} fontWeight="400">
            月
          </Box>
          {props.day}
          <Box as="span" fontSize={p2r(16)} fontWeight="400">
            日({props.dayOfWeek})
          </Box>
          {props.time}-
        </Text>
      </Flex>
    </Flex>
  </Box>
)

type DayItemProps = {
  id: number
  month: number // 月
  day: number // 日
  dayOfWeek: string // 曜日
  reserves: Reserve[] // 予約一覧
}
const DayItem: React.FC<DayItemProps> = (props) => {
  const reservationDetailModalContext = useAdminTaxiReservationModalContext()
  const onClickCard = (reservation: TaxiReservationResponse) => {
    reservationDetailModalContext.showModal(reservation)
  }

  return (
    <GridItem
      px="16px"
      pb="16px"
      borderRightWidth="1px"
      _last={{ borderRightWidth: 0 }}
      borderBottomColor="grand.grayLight"
    >
      <Text fontSize={p2r(28)} fontWeight="700" letterSpacing="0.05em">
        {props.month}
        <Box as="span" fontSize={p2r(20)} fontWeight="400">
          月
        </Box>
        {props.day}
        <Box as="span" fontSize={p2r(20)} fontWeight="400">
          日({props.dayOfWeek})
        </Box>
      </Text>
      <Flex mt="16px" direction="column" rowGap="16px">
        {props.reserves.length === 0 && <Box>予約なし</Box>}
        {props.reserves.length > 0 &&
          props.reserves.map((reserve, index) => {
            const key = `${props.id}-${index}`
            return (
              <ReserveCard
                name={reserve.name}
                from={reserve.from}
                to={reserve.to}
                month={reserve.month}
                day={reserve.day}
                dayOfWeek={reserve.dayOfWeek}
                time={reserve.time}
                key={key}
                color={reserve.color}
                onClick={() => {
                  onClickCard(reserve.reservation)
                }}
              />
            )
          })}
      </Flex>
    </GridItem>
  )
}

const AdminTaxiCalendarThreedays: React.FC = () => {
  const taxiReservationListQuery = useTaxiReservationListQuery({
    withStartTaxiLandmark: true,
    withEndTaxiLandmark: true,
    withUser: true,
  })
  const { me } = useAuthContext()
  const allReservations = useMemo(
    () =>
      taxiReservationListQuery.data?.taxiReservations?.filter(
        (r) =>
          r.taxiCompanyId === me?.taxiCompanyId &&
          ['draft', 'fixed'].includes(r.status),
      ) || [],
    [me, taxiReservationListQuery],
  )

  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('day'))
  const onClickPrev = () => {
    setStartDate(startDate.subtract(1, 'days'))
  }
  const onClickNext = () => {
    setStartDate(startDate.add(1, 'days'))
  }

  const dayReservations = useMemo(() => {
    const newDayReservations = []
    for (let i = 0; i <= 2; i += 1) {
      const currentDate = startDate.add(i, 'day')
      const currentDayReservations = allReservations?.filter((r) =>
        dayjs(r.rideAt).isSame(currentDate, 'day'),
      )
      const row = {
        id: i,
        month: currentDate.month() + 1,
        day: currentDate.date(),
        dayOfWeek: currentDate.format('ddd'),
        reserves: currentDayReservations.map((r) => ({
          name: getUserName(r),
          from: getFromLabel(r),
          to: getToLabel(r),
          month: currentDate.month() + 1,
          day: currentDate.date(),
          dayOfWeek: currentDate.format('ddd'),
          time: dayjs(r.rideAt).format('HH:mm'),
          reservation: r,
          color: getStatusOption(r)?.color,
        })),
      }

      newDayReservations.push(row)
    }
    return newDayReservations
  }, [allReservations, startDate])

  return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <PagerButton
          isBack
          ml="4"
          onClick={onClickPrev}
          position="absolute"
          top="50vh"
          left="0"
          transformOrigin="-50%"
          zIndex={2}
        />
        <Grid
          position="relative"
          flex="1"
          templateColumns="repeat(3, 1fr)"
          px="85px"
          py="24px"
          minH="100%"
          zIndex={1}
        >
          {dayReservations.map((item) => (
            <DayItem
              id={item.id}
              month={item.month}
              day={item.day}
              dayOfWeek={item.dayOfWeek}
              reserves={item.reserves}
            />
          ))}
        </Grid>
        <PagerButton
          isBack={false}
          mr="4"
          onClick={onClickNext}
          position="absolute"
          top="50vh"
          right="0"
          transformOrigin="-50%"
          zIndex={2}
        />
      </Box>
  )
}

export default AdminTaxiCalendarThreedays
