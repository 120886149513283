import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

export type TaxiButtonProps = {
  isReverse?: boolean
  isLarge?: boolean
  isInvalid?: boolean
  children: React.ReactNode
} & ButtonProps
export const TaxiButton: React.FC<TaxiButtonProps> = (props) => {
  const {
    isReverse = false,
    isLarge = false,
    isInvalid = false,
    children,
    ...rest
  } = props

  let color = 'text.white'
  if (isReverse) {
    color = 'theme.primary'
  }
  if (isInvalid) {
    color = 'grand.grayLight'
  }

  return (
    <Button
      variant="outline"
      border="2px solid"
      borderColor={isInvalid ? 'grand.grayLight' : 'theme.primary'}
      px="24px"
      py={isLarge ? '24px' : '16px'}
      color={color}
      backgroundColor={isReverse || isInvalid ? 'grand.white' : 'theme.primary'}
      fontSize={{ base: p2r(20), md: isLarge ? p2r(28) : p2r(20) }}
      fontWeight="700"
      borderRadius={{ base: '4px', md: '10px' }}
      minW={{ base: 'unset', md: isLarge ? '300px' : '168px' }}
      w={{ base: '100%', md: 'fit-content' }}
      h={{ base: '48px', md: isLarge ? '76px' : '62px' }}
      isDisabled={isInvalid}
      {...rest}
    >
      {children}
    </Button>
  )
}
