import { Icon } from '@chakra-ui/react'
import React from 'react'

const weatherCloudy: React.FC = (props) => (
  <Icon boxSize="80px" viewBox="0 0 80 80" {...props}>
    <rect width="80" height="80" rx="10" fill="#BDBDBD" />
    <path
      d="M27.0608 23.2312C30.659 20.1285 35.2481 18.4149 39.9992 18.4C49.6832 18.4 57.722 25.6 58.5968 34.8844C64.328 35.6944 68.7992 40.4932 68.7992 46.3828C68.7992 52.8484 63.4064 58 56.8724 58H24.8108C17.348 58 11.1992 52.1176 11.1992 44.7448C11.1992 38.398 15.7568 33.142 21.7904 31.81C22.3052 28.7032 24.3032 25.6072 27.0608 23.2312Z"
      fill="white"
    />
  </Icon>
)

export default weatherCloudy
