import {
  Box,
  Heading,
  Input,
  Text,
  Flex,
  Button,
  useClipboard,
  useToast,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AppErrorAlert } from '../../components/common/AppErrorAlert'
import VideoHeading from '../../components/video/VideoHeading'
import VideoModal from '../../components/video/VideoModal'
import {
  VideoUserList,
  VideoUserListItem,
} from '../../components/video/VideoUserList'
import { useFriendCreateMutation } from '../../hooks/coreApi/useFriendCreateMutation'
import { useFriendDeleteMutation } from '../../hooks/coreApi/useFriendDeleteMutation'
import { useFriendUpdateMutation } from '../../hooks/coreApi/useFriendUpdateMutation'
import { useMeQuery } from '../../hooks/coreApi/useMeQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { FriendResponse } from '../../openapi'
import { getDateFormat } from '../../utils/date'
import { p2r } from '../../utils/font'

type FormValueType = {
  userCode: string
}

const getMaskedName = (name: string) => {
  if (!name) return name // 空文字列やundefined、nullの場合はそのまま返す
  return name.slice(0, -1).replace(/./g, '*') + name.slice(-1)
}

const VideoFriendAdd: React.FC = () => {
  useHeaderConfig({
    title: 'ビデオ通話',
  })
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { f } = useFormatMessage()
  // 登録用ID
  const [registerId, setRegisterId] = useState<string>('')
  const form = useForm<FormValueType>()
  const toast = useToast()

  const meQuery = useMeQuery({
    withFriends: true,
    withUserInfo: true,
  })
  const me = meQuery?.data?.user
  const allFriends = meQuery.data?.user?.friends

  const friendCreateMutation = useFriendCreateMutation()
  const friendUpdateMutation = useFriendUpdateMutation()
  const friendDeleteMutation = useFriendDeleteMutation()

  // 承認待ち(相手→自分)の友達一覧
  const inRequestFriends = useMemo(
    () => allFriends?.filter((friend) => friend.status === 'in_request'),
    [me],
  )

  // TODO 申請中(自分→相手)の友達
  const waitingFriends = useMemo(
    () => allFriends?.filter((friend) => friend.status === 'in_waiting'),
    [me],
  )

  // 友達登録
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegisterId(e.target.value)
  }

  const myId = useMemo(() => {
    if (!me || !me?.userInfo?.code) {
      return ''
    }
    return me.userInfo.code
  }, [me])

  // クリップボードに自分のIDをコピー
  const { onCopy } = useClipboard(myId)

  // エラーハンドリング
  const onError = () => {
    toast({
      title: 'エラーが発生しました',
      status: 'error',
    })
  }

  const onSubmit = useCallback((values: FormValueType) => {
    friendCreateMutation.mutate(
      {
        friendCreateRequest: {
          code: values.userCode,
        },
      },
      {
        onSuccess() {
          toast({
            title: '友達申請を行いました',
          })
          meQuery.refetch()
        },
        onError,
      },
    )
  }, [])

  const [currentModalFriend, setCurrentModalFriend] =
    useState<FriendResponse | null>(null)
  const [currentModalName, setCurrentModalName] = useState<string>('')

  /**
   * 承認する
   */
  const inRequestModalDisclosure = useDisclosure()
  const handleInWaitingMoreClick = (friend: FriendResponse) => {
    if (!friend) {
      throw new Error('handleInWaitingMoreClick')
    }
    setCurrentModalFriend(friend)
    setCurrentModalName(friend?.friendUser?.nickname || '')
    inRequestModalDisclosure.onOpen()
  }

  // 拒否する
  const handleReject = () => {
    if (!currentModalFriend) {
      throw new Error('handleReject')
    }
    friendUpdateMutation.mutate(
      {
        id: currentModalFriend.id,
        friendUpdateRequest: {
          status: 'is_blocked',
          isHidden: false,
          isBlocked: true,
        },
      },
      {
        onSuccess() {
          toast({
            title: '申請を拒否しました',
            status: 'success',
          })
          meQuery.refetch()
          inRequestModalDisclosure.onClose()
        },
        onError,
      },
    )
  }

  // 承認する
  const handleAccept = () => {
    if (!currentModalFriend) {
      throw new Error('handleAccept')
    }
    friendUpdateMutation.mutate(
      {
        id: currentModalFriend.id,
        friendUpdateRequest: {
          status: 'accepted',
          isHidden: false,
          isBlocked: false,
        },
      },
      {
        onSuccess() {
          toast({
            title: '友達申請を承認しました',
            status: 'success',
          })
          meQuery.refetch()
          inRequestModalDisclosure.onClose()
        },
        onError,
      },
    )
  }

  /**
   * 申請中
   */
  const inWaitingModalDisclosure = useDisclosure()
  // 申請中用のモーダル
  const handleInRequestMoreClick = (friend: FriendResponse) => {
    if (!friend) {
      throw new Error('handleInRequestMoreClick')
    }

    setCurrentModalFriend(friend)
    setCurrentModalName(friend?.friendUser?.nickname || '')
    inWaitingModalDisclosure.onOpen()
  }

  // 取り下げる
  const handleDelete = () => {
    if (!currentModalFriend) {
      throw new Error('handleDelete')
    }
    friendDeleteMutation.mutate(
      {
        id: currentModalFriend.id,
      },
      {
        onSuccess() {
          toast({
            title: '友達申請を取り下げました',
            status: 'success',
          })
          meQuery.refetch()
          inWaitingModalDisclosure.onClose()
        },
        onError,
      },
    )
  }

  return (
    <Box backgroundColor="theme.background">
      <Box w="100%" maxWidth="570px" p={isMobile ? '16px' : '32px'} mx="auto">
        <Box>
          <Heading
            as="h2"
            fontSize={isMobile ? p2r(24) : p2r(32)}
            fontWeight="700"
            color="text.body"
          >
            {f('友達登録')}
          </Heading>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Flex mt="16px" flexDirection="column" gap="16px">
              <Input
                {...form.register('userCode', { required: true })}
                type="text"
                w="full"
                placeholder="0123 4567 8901 2345"
                borderWidth="3px"
                borderColor="theme.primary"
                borderRadius="10px"
                backgroundColor="grand.white"
                px={isMobile ? '12px' : '32px'}
                py="12px"
                fontSize={isMobile ? p2r(24) : p2r(32)}
                textAlign="center"
                value={registerId}
                onChange={handleInputChange}
                maxLength={16}
                _placeholder={{ color: 'grand.grayLight' }}
                h={isMobile ? '68px' : '80px'}
              />
              {friendCreateMutation.isError && (
                <AppErrorAlert error={friendCreateMutation.error} />
              )}
              <Button type="submit" mt="8px">
                登録する
              </Button>
              <Text fontSize={p2r(14)} color="text.body" fontWeight="400">
                {f(
                  '友達登録をすることで、個別通話とグループ通話への招待ができます。',
                )}
              </Text>
            </Flex>
          </form>
        </Box>
        <Box
          mt={isMobile ? '16px' : '32px'}
          backgroundColor="grand.white"
          borderWidth="3px"
          borderColor="grand.grayLight"
          borderRadius="10px"
          px="12px"
          py="12px"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Text color="text.body" fontSize={p2r(16)} fontWeight="700">
              {f('あなたのID')}
            </Text>
            <Button
              color="theme.primary"
              fontSize={p2r(16)}
              fontWeight="400"
              variant="unstyled"
              onClick={() => {
                onCopy()
                toast({
                  title: 'IDをコピーしました',
                })
              }}
              height="fit-content"
            >
              {f('IDをコピー')}
            </Button>
          </Flex>
          <Text
            pt={isMobile ? '22px' : '16px'}
            color="theme.primary"
            fontSize={p2r(24)}
            lineHeight="150%"
            fontWeight="700"
            textAlign="center"
          >
            {myId}
          </Text>
        </Box>
      </Box>
      <Box>
        <VideoHeading
          label={`友達かも？ ${String(inRequestFriends?.length || 0)}人`}
        />
        <VideoUserList>
          {inRequestFriends?.map((friend) => (
            <VideoUserListItem
              name={friend.friendUser?.nickname || ''}
              nameKn={(friend.friendUser?.nicknameKana || '').charAt(0)}
              updatedAt={getDateFormat(friend.updatedAt)}
              status="in_request"
              onClickStatus={() => {
                handleInWaitingMoreClick(friend)
              }}
              onClickMore={() => {
                handleInWaitingMoreClick(friend)
              }}
              key={friend.id}
            />
          ))}
        </VideoUserList>
        <VideoHeading
          label={`申請中の友達 ${String(waitingFriends?.length || 0)}人`}
        />
        <VideoUserList>
          {waitingFriends?.map((friend) => (
            <VideoUserListItem
              name={getMaskedName(friend.friendUser?.nickname || '')}
              nameKn={(friend.friendUser?.nicknameKana || '').charAt(0)}
              updatedAt={getDateFormat(friend.updatedAt)}
              status="in_waiting"
              onClickStatus={() => {
                handleInRequestMoreClick(friend)
              }}
              onClickMore={() => {
                handleInRequestMoreClick(friend)
              }}
              key={friend.id}
            />
          ))}
        </VideoUserList>

        <VideoModal
          isOpen={inRequestModalDisclosure.isOpen}
          onClose={inRequestModalDisclosure.onClose}
        >
          <Flex direction="column">
            <Flex direction="column" alignItems="center" rowGap="8px">
              <Text
                fontSize={p2r(24)}
                fontWeight="700"
                lineHeight="150%"
                color="text.body"
              >
                {currentModalName}
              </Text>
              {/* <Text
                w={isMobile ? '70%' : '80%'}
                textAlign="center"
                fontSize={p2r(16)}
                lineHeight="150%"
                color="text.body"
              >
                ID: {currentModalFriend?.id}
              </Text> */}
              <Text
                pt="8px"
                color="text.sub"
                fontSize={isMobile ? p2r(14) : p2r(16)}
                lineHeight="150%"
                whiteSpace="pre-wrap"
              >
                {f(
                  `友達依頼が届いています。承認しますか？\n友達になると個別でビデオ通話が行えます。`,
                )}
              </Text>
            </Flex>
            <Flex
              mt="16px"
              direction={isMobile ? 'column' : 'row'}
              columnGap="48px"
              rowGap="8px"
              justify="center"
            >
              <Button
                order={isMobile ? 2 : 1}
                variant="ghost"
                borderWidth="4px"
                fontWeight="700"
                rounded="8px"
                minW="300px"
                h="68px"
                borderColor="transparent"
                color="#c84545"
                fontSize={p2r(24)}
                onClick={handleReject}
              >
                {f('承認しない')}
              </Button>
              <Button
                order={isMobile ? 1 : 2}
                variant="ghost"
                borderWidth="4px"
                borderColor="theme.primary"
                color="#FFFDFC"
                fontWeight="700"
                rounded="8px"
                bgColor="theme.primary"
                minW="300px"
                h="68px"
                fontSize={p2r(24)}
                onClick={handleAccept}
                display="flex"
              >
                {f('承認する')}
              </Button>
            </Flex>
          </Flex>
        </VideoModal>

        <VideoModal
          isOpen={inWaitingModalDisclosure.isOpen}
          onClose={() => {
            inWaitingModalDisclosure.onClose()
          }}
        >
          <Flex direction="column">
            <Flex direction="column" alignItems="center" rowGap="8px">
              <Text
                fontSize={p2r(24)}
                fontWeight="700"
                lineHeight="150%"
                color="text.body"
              >
                {getMaskedName(currentModalName)}
              </Text>
              {/* <Text
                w={isMobile ? '70%' : '80%'}
                textAlign="center"
                fontSize={p2r(16)}
                lineHeight="150%"
                color="text.body"
              >
                ID: {currentModalFriend?.id}
              </Text> */}
              <Text
                pt="8px"
                color="text.sub"
                fontSize={isMobile ? p2r(14) : p2r(16)}
                lineHeight="150%"
                whiteSpace="pre-wrap"
              >
                {f(`友達申請依頼中です。`)}
              </Text>
            </Flex>
            <Flex
              mt="16px"
              direction={isMobile ? 'column' : 'row'}
              columnGap="48px"
              rowGap="8px"
              justify="center"
            >
              <Button
                order={isMobile ? 2 : 1}
                variant="ghost"
                borderWidth="4px"
                fontWeight="700"
                rounded="8px"
                minW="300px"
                h="68px"
                borderColor="transparent"
                color="#c84545"
                fontSize={p2r(24)}
                onClick={handleDelete}
              >
                {f('申請を取り下げる')}
              </Button>
            </Flex>
          </Flex>
        </VideoModal>
      </Box>
    </Box>
  )
}

export default VideoFriendAdd
