export default {
  fonts: {
    heading: '"BIZ UDPGothic", sans-serif;',
    body: '"BIZ UDPGothic", sans-serif;',
  },
  colors: {
    theme: {
      primary: '#6AA43B',
      primaryDark: '#456D3E',
      primaryBright: '#A6DF78',
      background: '#FFFBF5',
      primaryLight: '#F4FBEE',
    },
    text: {
      headline: '#1C1F21',
      body: '#494C4F',
      sub: '#747678',
      white: '#FFFFFF',
    },
    grand: {
      grayDark: '#222624',
      gray: '#747678',
      grayLight: '#DFE1E4',
      graySuperLight: '#F7F8FA',
      white: '#FFFFFF',
      success: '#27AE60',
      error: '#EB5757',
      warning: '#F2C94C',
      pink: '#FFA397',
      blue: '#7FC7DE',
      blueLight: '#2F80ED',
      yellow: '#FFC774',
      orange: '#CB7224',
      orangeLight: '#fcf6f1',
      green: '#34C759',
      black: '#1C1F21',
      blackLight: '#0C000C',
      red: '#FF2800',
      purple: '#AA00AA',
      notification: '#B33636',
    },
    // colorScheme
    switch: {
      500: '#34C759',
    },
    checkbox: {
      500: '#6AA43B',
    },
    radio: {
      500: '#6AA43B',
    },
    primary: {
      500: '#6AA43B',
    },
    primaryDark: {
      500: '#456D3E',
    },
    red: {
      500: '#CA2323',
    },
    blue: {
      500: '#0B699D',
    },
  },
  components: {
    Radio: {
      sizes: {
        lg: {
          control: {
            w: '1.875rem',
            h: '1.875rem',
          },
          label: { fontSize: '1.5rem', fontWeight: '700' },
          icon: { fontSize: '1rem' },
        },
        md: {
          control: {
            w: '1.875rem',
            h: '1.875rem',
          },
        },
      },
    },
    PinInput: {
      baseStyle: {
        w: '64px',
        h: '64px',
        rounded: '10px',
        fontSize: '1.25rem',
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          w: '30px',
          h: '30px',
        },
        label: { fontSize: '1.5rem' },
        icon: { fontSize: '1.25rem' },
      },
      sizes: {
        lg: {
          control: {
            w: '1.875rem',
            h: '1.875rem',
          },
          label: { fontSize: '1.5rem' },
          icon: { fontSize: '1rem' },
        },
        xl: {
          control: {
            w: '2.25rem',
            h: '2.25rem',
          },
        },
      },
    },
    tabs: {
      600: '#6AA43B',
    },
  },
}
