import { Table, Thead, Tbody, Tr, Th, Td, TableProps } from '@chakra-ui/react'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table'
import * as React from 'react'

export type AppDataTableProps<Data extends object> = {
  data: Data[]
  columns: ColumnDef<Data, any>[]
} & TableProps

// NOTE: chakra-uiのTableとreact-tableを組み合わせ
// https://chakra-ui.com/getting-started/with-react-table
export function AppDataTable<Data extends object>({
  data,
  columns,
  ...rest
}: AppDataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  })

  return (
    <Table {...rest}>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              // const { meta } = cell.column.columnDef
              <Td
                key={cell.id}
                //  isNumeric={meta?.isNumeric}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default AppDataTable
