import { Box, Flex, FormLabel, Switch } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { p2r } from '../../../utils/font'

export const MypageSwitchList: React.FC<{ children: ReactNode }> = ({
  children,
}) => (
  <Box
    backgroundColor="grand.white"
    borderWidth="1px"
    borderColor="grand.grayLight"
    borderRadius={{ base: '8px', md: '14px' }}
    overflow="hidden"
  >
    {children}
  </Box>
)

export type MypageSwitchListItemProps = {
  label: string
  name: string
  value: boolean
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void
}
export const MypageSwitchListItem: React.FC<MypageSwitchListItemProps> = (
  props,
) => (
  <Box borderBottomWidth="1px" _last={{ borderBottomWidth: 0 }}>
    <Flex
      px="16px"
      py="12.5px"
      alignItems="center"
      justifyContent="space-between"
      columnGap="8px"
    >
      <FormLabel
        m="0"
        color="grand.black"
        fontSize={p2r(16)}
        lineHeight="150%"
        fontWeight="400"
        htmlFor={props.name}
      >
        {props.label}
      </FormLabel>
      <Switch
        colorScheme="switch"
        size="lg"
        id={props.name}
        name={props.name}
        isChecked={props.value}
        onChange={props.handleChange}
      />
    </Flex>
  </Box>
)
