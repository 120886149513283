import { Button, ButtonProps, Icon } from '@chakra-ui/react'
import React from 'react'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import theme from '../../../theme'

type Props = {
  isBack: boolean
} & ButtonProps

const PagerButton: React.FC<Props> = (props) => {
  const { isBack, ...rest } = props

  return (
    <Button
      bg={theme.colors.theme.primaryDark}
      color="white"
      w="52px"
      h="168px"
      display="flex"
      flexDir="column"
      _hover={{
        bg: theme.colors.theme.primary,
      }}
      _disabled={{
        color: '#747678',
        bg: theme.colors.grand.graySuperLight,
        _hover: {
          bg: theme.colors.grand.graySuperLight,
        },
      }}
      {...rest}
    >
      <Icon
        className="arrow-icon"
        as={isBack ? MdArrowBackIos : MdArrowForwardIos}
        boxSize="20px"
        pos="relative"
        left="3px"
        mb=".5rem"
      />
      {isBack ? '前へ' : '次へ'}
    </Button>
  )
}

export default PagerButton
