import { Flex, Text, Icon, Center, Box } from '@chakra-ui/react'
import React from 'react'
import { MdWarning } from 'react-icons/md'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { useWeatherWarningQuery } from '../../../hooks/weatherApi/useWeatherRadarQuery'
import { p2r } from '../../../utils/font'
import { AppLoading } from '../../common/AppLoading'

export type WeatherWarningProps = {
  isWarning?: boolean
  pt?: boolean
  pb?: boolean
}
export const WeatherWarning: React.FC<WeatherWarningProps> = (props) => {
  const { f } = useFormatMessage()

  const warningForecastQuery = useWeatherWarningQuery('360000')

  const headlineText = warningForecastQuery.data?.headlineText

  if (warningForecastQuery.isLoading) {
    return <AppLoading />
  }

  return (
    <>
      <Flex
        gap="10px"
        alignItems="center"
        pb="8px"
        pt={props.pt ? '32px' : '10px'}
      >
        <Icon as={MdWarning} boxSize="24px" color="grand.gray" />
        <Text color="text.body" fontWeight="700" fontSize={p2r(16)}>
          {f('注意報・警報')}
        </Text>
      </Flex>
      <Box pb={props.pb ? '24px' : ''}>
        {props.isWarning ? (
          <Center
            border="1px solid"
            borderColor="grand.grayLight"
            rounded="4px"
            p="16px"
            bgColor="grand.white"
          >
            <Text fontSize={p2r(12)} color="text.sub" lineHeight="150%">
              {headlineText || ''}
            </Text>
          </Center>
        ) : (
          <Center
            border="1px solid"
            borderColor="grand.grayLight"
            rounded="4px"
            p="16px"
            bgColor="grand.white"
          >
            <Text fontSize={p2r(12)} color="text.sub" lineHeight="150%">
              {headlineText || ''}
            </Text>
          </Center>
        )}
      </Box>
    </>
  )
}
