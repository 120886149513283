import * as holidayJp from '@holiday-jp/holiday_jp'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useAuthContext } from '../context/AuthContext'
import { useTaxiReservationListQuery } from './coreApi/useTaxiReservationListQuery'
import { useTrashCalendarListQuery } from './coreApi/useTrashCalendarListQuery'

export type CalendarType = 'trash' | 'taxiReservation' | 'holiday'

export const defaultShowCalendarTypes = atom<CalendarType[]>({
  key: 'showCalendarTypes',
  default: ['trash', 'taxiReservation', 'holiday'],
})

type CalendarConfig = {
  calendarType: CalendarType
  colorProp: string
  name: string
  colorScheme: string
}

type CalendarConfigMap = {
  [key in CalendarType]: CalendarConfig
}

export const calendarConfigMap: CalendarConfigMap = {
  holiday: {
    calendarType: 'holiday',
    colorProp: 'var(--chakra-colors-red-500)',
    name: '休日・祝日',
    colorScheme: 'red',
  },
  trash: {
    calendarType: 'trash',
    colorProp: 'var(--chakra-colors-blue-500)',
    name: 'ゴミ出し',
    colorScheme: 'blue',
  },
  taxiReservation: {
    calendarType: 'taxiReservation',
    colorProp: 'var(--chakra-colors-primaryDark-500)',
    name: 'タクシー予約',
    colorScheme: 'primaryDark',
  },
}

const useMySchedule = () => {
  const { me } = useAuthContext()

  // カレンダーの表示日時
  const [calendarDate, setCalendarDate] = useState<Date>(new Date())

  // フィルター（表示するカレンダー種類のチェック）
  const [showCalendarTypes, setShowCalendarTypes] = useRecoilState<
    CalendarType[]
  >(defaultShowCalendarTypes)

  // 取得範囲を動的に設定
  const fetchRange = useMemo(() => {
    const start = dayjs(calendarDate).startOf('month').subtract(1, 'week')
    const end = dayjs(calendarDate).endOf('month').add(1, 'week')
    return {
      fetchStartDate: start.format('YYYY-MM-DD'),
      fetchEndDate: end.format('YYYY-MM-DD'),
    }
  }, [calendarDate])

  // タクシー予約
  const taxiReservationListQuery = useTaxiReservationListQuery({})
  const myTaxiReservations = useMemo(() => {
    if (!taxiReservationListQuery.data || !me) {
      return []
    }
    // APIでフィルタ済みを取得
    return taxiReservationListQuery.data.taxiReservations.filter(
      (r) => r.userId === me.id && ['draft', 'fixed'].includes(r.status),
    )
  }, [taxiReservationListQuery, me])

  const myTaxiReservationEvents = useMemo(
    () =>
      myTaxiReservations.map((r) => ({
        title: calendarConfigMap.taxiReservation.name,
        start: dayjs(r.rideAt).toDate(),
        end: dayjs(r.dropAt).toDate(),
        color: calendarConfigMap.taxiReservation.colorProp,
      })),
    [myTaxiReservations],
  )

  // ゴミ出し
  const trashCalendarListQuery = useTrashCalendarListQuery({
    startDate: fetchRange.fetchStartDate,
    endDate: fetchRange.fetchEndDate,
    withTrashCalendarDates: true,
    id: me?.trashCalendarId || 1,
  })

  const myTrashCalendarDates = useMemo(() => {
    if (!trashCalendarListQuery.data || !me) {
      return []
    }

    return (
      trashCalendarListQuery.data.trashCalendars?.[0]?.trashCalendarDates || []
    )
  }, [trashCalendarListQuery, me])

  const myTrashEvents = useMemo(
    () =>
      myTrashCalendarDates.map((d) => ({
        title: d.trashType,
        allDay: true,
        start: dayjs(d.date).startOf('day').toDate(),
        end: dayjs(d.date).endOf('day').toDate(),
        color: calendarConfigMap.trash.colorProp,
      })),
    [myTrashCalendarDates, fetchRange],
  )

  // 休日
  const holidayEvents = useMemo(() => {
    const holidays = holidayJp.between(
      dayjs(fetchRange.fetchStartDate).toDate(),
      dayjs(fetchRange.fetchEndDate).toDate(),
    )
    return holidays.map((h) => ({
      title: h.name,
      allDay: true,
      start: dayjs(h.date).startOf('day').toDate(),
      end: dayjs(h.date).endOf('day').toDate(),
      color: calendarConfigMap.holiday.colorProp,
    }))
  }, [fetchRange])

  // 各予定をeventに変換
  const myEvents = useMemo(() => {
    let newMyEvents: any = []
    // タクシー予約
    if (showCalendarTypes.includes('taxiReservation')) {
      newMyEvents = newMyEvents.concat(myTaxiReservationEvents)
    }

    // ゴミ出し
    if (showCalendarTypes.includes('trash')) {
      newMyEvents = newMyEvents.concat(myTrashEvents)
    }

    // 休日
    if (showCalendarTypes.includes('holiday')) {
      newMyEvents = newMyEvents.concat(holidayEvents)
    }

    return newMyEvents
  }, [myTaxiReservationEvents, myTrashEvents, holidayEvents, showCalendarTypes])

  // 各予定のrefetch
  const refetchAll = () => {
    taxiReservationListQuery.refetch()
    trashCalendarListQuery.refetch()
  }

  // カレンダーの移動
  const handleNavigate = (newDate: Date) => {
    setCalendarDate(newDate)
    refetchAll()
  }

  return {
    myEvents,
    calendarDate,
    handleNavigate,
    refetchAll,
    showCalendarTypes,
    setShowCalendarTypes,
  }
}

export default useMySchedule
