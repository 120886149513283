export const isBrowser = () => typeof window !== 'undefined'

export const languageType = {
  Ja: 'ja',
  En: 'en',
} as const
export type LanguageTypes = typeof languageType[keyof typeof languageType]

export const isBrowserLanguage = (): LanguageTypes => {
  const language = window.navigator.language || languageType.Ja
  switch (language) {
    case 'en':
      return languageType.En
    default:
      return languageType.Ja
  }
}
