import { Button, ButtonProps, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { MdArrowForwardIos } from 'react-icons/md'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { p2r } from '../../../utils/font'

const AppListMoreButton: React.FC<ButtonProps> = (props) => {
  const { ...rest } = props
  const { f } = useFormatMessage()
  return (
    <Button
      mt={{ base: '16px', md: '0px' }}
      mx="auto"
      mb="24px"
      p="16px"
      w="fit-content"
      h="56px"
      display="flex"
      columnGap="4px"
      borderRadius="8px"
      variant="unstyled"
      backgroundColor="theme.primary"
      {...rest}
    >
      <Text
        fontSize={p2r(20)}
        lineHeight="120%"
        color="grand.white"
        fontWeight="700"
      >
        {f('もっと見る')}
      </Text>
      <Icon as={MdArrowForwardIos} boxSize="24px" color="grand.white" />
    </Button>
  )
}

export default AppListMoreButton
