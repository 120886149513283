import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { MdCall, MdPhonelinkRing } from 'react-icons/md'
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom'
import { AdminTaxiReservationModalProvider } from '../../../components/taxi/admin/AdminTaxiReservationModalContext'
import useFormatMessage from '../../../hooks/useFormatMessage'
import useHeaderConfig from '../../../hooks/useHeaderConfig'
import { p2r } from '../../../utils/font'
import useTaxiAdminReservationCreateState from '../useTaxiAdminReservationCreateState'

const useSetWeatherButtons = () => {
  const { f } = useFormatMessage()
  return [
    {
      id: 1,
      path: '/admin/taxi/calendar/today',
      title: f('今日'),
    },
    {
      id: 2,
      path: '/admin/taxi/calendar/threedays',
      title: f('3日間'),
    },
    {
      id: 3,
      path: '/admin/taxi/calendar/month',
      title: f('1ヶ月'),
    },
  ]
}

type WeatherLinkProps = {
  path: string
  title: string
  isActive: boolean
}
const WeatherLink: React.FC<WeatherLinkProps> = (props) => (
  <Link to={props.path}>
    <Text
      bgColor={props.isActive ? 'theme.primary' : 'grand.graySuperLight'}
      color={props.isActive ? 'text.white' : 'text.sub'}
      p="4px 60.5px"
      rounded="10"
      fontSize={p2r(20)}
      fontWeight="700"
    >
      {props.title}
    </Text>
  </Link>
)

const AdminTaxiCalendar: React.FC = () => {
  useHeaderConfig({
    title: 'タクシー管理',
  })
  const { f } = useFormatMessage()
  const WeatherButtonsMenu = useSetWeatherButtons()

  const { draftTaxiReservations } = useTaxiAdminReservationCreateState()

  const location = useLocation()
  const currentPath = location.pathname
  if (currentPath === '/admin/taxi' || currentPath === '/admin/taxi/calendar') {
    return <Navigate to="/admin/taxi/calendar/today" replace />
  }

  return (
    <AdminTaxiReservationModalProvider>
      <Flex direction="column" minH="calc(100vh - 58px)">
        <Flex
          p="14px 32px"
          boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
          borderBottomColor="grand.grayLight"
          borderBottomWidth="1px"
          justify="space-between"
          align="center"
        >
          <Flex backgroundColor="grand.graySuperLight" borderRadius="10px">
            {WeatherButtonsMenu.map((item) => (
              <WeatherLink
                key={item.id}
                path={item.path}
                title={item.title}
                isActive={currentPath === item.path}
              />
            ))}
          </Flex>

          <Flex alignItems="center" columnGap="24px">
            <Link to="/admin/taxi/reservations/create/select-input">
              <Flex
                p="16px"
                backgroundColor="theme.primary"
                gap="4px"
                alignItems="center"
                borderRadius="8px"
                h="56px"
              >
                <Icon as={MdCall} boxSize="24px" color="text.white" />
                <Text color="text.white" fontWeight="700" fontSize={p2r(20)}>
                  {f('電話予約')}
                </Text>
              </Flex>
            </Link>
            <Link to="/admin/taxi/reservations/online">
              <Flex
                p="16px"
                backgroundColor="theme.primary"
                gap="4px"
                alignItems="center"
                borderRadius="8px"
                h="56px"
                position="relative"
              >
                <Icon as={MdPhonelinkRing} boxSize="24px" color="text.white" />
                <Text color="text.white" fontWeight="700" fontSize={p2r(20)}>
                  {f('オンライン予約')}
                </Text>
                {draftTaxiReservations.length > 0 && (
                  <Box
                    position="absolute"
                    top="-4px"
                    right="-4px"
                    w="18px"
                    h="18px"
                    borderRadius="50%"
                    backgroundColor="grand.notification"
                  />
                )}
              </Flex>
            </Link>
          </Flex>
        </Flex>
        <Flex flex="1 0 auto" direction="column">
          <Outlet />
        </Flex>
      </Flex>
    </AdminTaxiReservationModalProvider>
  )
}

export default AdminTaxiCalendar
