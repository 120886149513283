import { Box } from '@chakra-ui/react'
import React from 'react'
import { AppErrorAlert } from '../../components/common/AppErrorAlert'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppList, AppListItem } from '../../components/common/AppList'
import { AppLoading } from '../../components/common/AppLoading'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { getDateFormat } from '../../utils/date'

const BousaiSeries: React.FC = () => {
  useHeaderConfig({
    title: '防災シリーズ',
  })

  const PAGE_SIZE = 100
  const { isLoading, isError, error, data } = useContentListQuery('bousai', {
    page: 1,
    pageSize: PAGE_SIZE,
    withFiles: true,
    tags: ['防災シリーズ'],
  })

  const bousaiSeriesContents = data?.pages?.[0]?.contents || []

  if (isLoading) {
    return <AppLoading />
  }

  if (isError) {
    return <AppErrorResult error={error} />
  }

  if (!bousaiSeriesContents) {
    return <AppErrorAlert error="コンテンツがありません" />
  }

  return (
    <Box>
      <AppList isMore={false} onClickMore={() => {}}>
        {bousaiSeriesContents.map((content) => (
          <AppListItem
            key={content.id}
            title={content.titleJa}
            date={getDateFormat(content.releaseAt)}
            isShowLike
            likeCount={content?.likeCount}
            isLiked={content?.isLiked}
            tags={content?.contentTags?.map((tag) => tag.label) || []}
            link={`/bousai/${content.id}`}
            contentId={content.id}
            isReaded={content.isRead}
          />
        ))}
      </AppList>
    </Box>
  )
}

export default BousaiSeries
