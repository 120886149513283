import { Box, Flex, Select, useBreakpointValue } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { AppCalendar } from '../../components/common/AppCalendar'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppLoading } from '../../components/common/AppLoading'
import { useAuthContext } from '../../context/AuthContext'
import { useTrashCalendarListQuery } from '../../hooks/coreApi/useTrashCalendarListQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import { p2r } from '../../utils/font'

const getColorByTrashType = (trashType: string) => {
  switch (trashType) {
    case 'リサイクルプラ':
      return '#9a7b96'
    case '燃やすゴミ':
      return '#c57930'
    case '紙資源':
      return '#91aa7a'
    case '粗大ごみ':
      return '#787878'
    case '生ごみ':
      return '#ae8b58'
    case '紙おむつ':
      return '#987165'
    case '空き瓶':
      return '#c34949'
    case '空き缶':
      return '#69b988'
    case '空きペットボトル':
      return '#d6b66e'
    default:
      return '#91aa7a'
  }
}

const TrashCalendar: React.FC = () => {
  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { me } = useAuthContext()

  // エリア選択
  const defaultCalendarId = 1
  const [areaId, setAreaId] = useState<number>(defaultCalendarId)

  useEffect(() => {
    if (!me || !me.trashCalendarId) {
      return
    }
    setAreaId(me.trashCalendarId || defaultCalendarId)
  }, [me])

  // カレンダーの表示日時
  const [calendarDate, setCalendarDate] = useState<Date>(new Date())

  // ゴミ出し情報取得範囲
  const fetchRange = useMemo(() => {
    const start = dayjs(calendarDate).startOf('month').subtract(1, 'week')
    const end = dayjs(calendarDate).endOf('month').add(1, 'week')
    return {
      fetchStartDate: start.format('YYYY-MM-DD'),
      fetchEndDate: end.format('YYYY-MM-DD'),
    }
  }, [calendarDate])

  // ゴミ出し情報取得
  const { data, isLoading, isError, error, refetch } =
    useTrashCalendarListQuery({
      startDate: fetchRange.fetchStartDate,
      endDate: fetchRange.fetchEndDate,
      withTrashCalendarDates: true,
    })

  // エリア切り替え
  const areaSelect: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    setAreaId(Number(event.target.value))
  }
  // 選択中のエリア名
  const areaName = useMemo(
    () => data?.trashCalendars.find((info) => info.id === areaId),
    [data, areaId],
  )?.name

  if (isLoading) {
    return <AppLoading />
  }
  if (isError) {
    return <AppErrorResult error={error} />
  }

  // カレンダーの移動
  const onNavigate = (newDate: Date) => {
    setCalendarDate(newDate)
    refetch()
  }

  return (
    <Box
      position="relative"
      maxW="850px"
      mx="auto"
      pt="6px"
      backgroundColor="theme.background"
    >
      <Box
        display="flex"
        columnGap="8px"
        position="absolute"
        top={{ base: '18px', md: '38px' }}
        right={{ base: '16px', md: '32px' }}
        zIndex="10"
      >
        {!isMobile && areaName && (
          <Flex
            bgColor="grand.white"
            color="theme.primary"
            justify={{ base: 'unset', md: 'center' }}
            alignItems="center"
            columnGap="8px"
            p={{ base: '5px 8px 5px 16px', md: '5px 16px 5px 16px' }}
            rounded="8px"
            borderWidth="1px"
            borderColor="theme.primary"
            fontSize={{ base: p2r(12), md: p2r(14) }}
            fontWeight="700"
            order={{ base: '2', md: 'unset' }}
          >
            {areaName}
          </Flex>
        )}
        <Select
          w={{ base: 'fit-content', md: '140px' }}
          h={{ base: '32px', md: '40px' }}
          placeholder={isMobile ? '' : f('エリア選択')}
          bg="theme.primary"
          color="text.white"
          fontSize={{ base: p2r(10), md: p2r(14) }}
          fontWeight="700"
          rounded="8px"
          value={areaId}
          onChange={areaSelect}
          order={{ base: '1', md: 'unset' }}
          size={isMobile ? 'xs' : 'lg'}
        >
          {data.trashCalendars.map((calendarArea) => (
            <option key={calendarArea.id} value={calendarArea.id}>
              {calendarArea.name}
            </option>
          ))}
        </Select>
      </Box>
      <AppCalendar
        hasArea
        events={
          data?.trashCalendars
            .filter((calendar) => calendar.id === areaId)[0]
            .trashCalendarDates?.map((trash) => ({
              allDay: true,
              title: trash.trashType,
              start: trash.date ? new Date(trash.date) : new Date(),
              end: trash.date ? new Date(trash.date) : new Date(),
              color: getColorByTrashType(trash.trashType || ''),
            })) || []
        }
        onNavigate={onNavigate}
        defaultViewDate={calendarDate}
      />
    </Box>
  )
}

export default TrashCalendar
