import { Text, Box } from '@chakra-ui/react'
import { AppSegmentedPicker } from '../../components/common/AppSegmentedPicker'
import { WeatherWarning } from '../../components/weather/Warning'
import useFormatMessage from '../../hooks/useFormatMessage'
import { p2r } from '../../utils/font'
import WeatherTime from '../WeatherTime'
import WeatherWeekly from '../WeatherWeekly'

const WeatherResponsive: React.FC = () => {
  // 注意報警報
  const warning = false

  const { f } = useFormatMessage()
  return (
    <Box pt="8px" pb="31px" bgColor="theme.background" w="100%">
      <Box px="16px">
        <AppSegmentedPicker
          info={[
            {
              label: '天気',
              link: '/weather/forecast',
              isActive: true,
            },
            {
              label: '雨雲レーダー',
              link: '/weather/radar',
              isActive: false,
            },
          ]}
        />
        {warning ? <WeatherWarning isWarning pb /> : ''}
        <Text
          pt="8px"
          fontSize={p2r(16)}
          lineHeight="150%"
          color="text.sub"
          fontWeight="700"
        >
          {f('今日明日の天気')}
        </Text>
      </Box>
      <Box pl="16px">
        <WeatherTime />
      </Box>
      <Box px="16px">
        <Text
          fontSize={p2r(16)}
          lineHeight="150%"
          color="text.sub"
          fontWeight="700"
        >
          {f('週間天気')}
        </Text>
        <WeatherWeekly />
        {!warning ? <WeatherWarning pt /> : ''}
      </Box>
    </Box>
  )
}

export default WeatherResponsive
