import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import 'react-big-calendar/lib/css/react-big-calendar.css'
// import './index.scss'

// import dayjs plugins
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import minMax from 'dayjs/plugin/minMax'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import React, { useCallback, useMemo } from 'react'
import {
  Calendar,
  Views,
  Event,
  SlotInfo,
  ViewsProps,
  View,
  NavigateAction,
} from 'react-big-calendar'
import withDragAndDrop, {
  withDragAndDropProps,
} from 'react-big-calendar/lib/addons/dragAndDrop'
import { TaxiReservationResponse } from '../../../openapi/api'
import style from './Style'
import dayjslocalizer from './dayjsLocalizer'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

// NOTE: Drag, Resizeに対応
// https://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/addons-drag-and-drop-props--on-event-drop
// https://github.com/jquense/react-big-calendar/blob/master/stories/demos/exampleCode/dndOutsideSource.js

const DragAndDropCalendar = withDragAndDrop(Calendar)

// load dayjs plugins
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(minMax)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.locale('ja')
const localizer = dayjslocalizer(dayjs)

export type AppCalendarCustomEvent = {
  color?: string
  taxiReservation?: TaxiReservationResponse
} & Event

type AppCalendarProps<TEvent extends object = AppCalendarCustomEvent> = {
  events: TEvent[]
  hasArea?: boolean
  defaultViewDate?: Date
  onSelectSlot?: ((slotInfo: SlotInfo) => void) | undefined
  selectable?: boolean | 'ignoreEvents' | undefined
  scrollToTime?: Date
  resizable?: boolean
  onSelectEvent?: (event: AppCalendarCustomEvent) => void
  onEventDrop?: (event: AppCalendarCustomEvent) => void
  onEventResize?: (event: AppCalendarCustomEvent) => void
  onNavigate?:
    | ((newDate: Date, view: View, action: NavigateAction) => void)
    | undefined
  views?: ViewsProps<AppCalendarCustomEvent>
} & withDragAndDropProps<AppCalendarCustomEvent>

export const AppCalendar: React.FC<AppCalendarProps> = ({
  events,
  hasArea,
  defaultViewDate = new Date(),
  onSelectSlot,
  selectable,
  scrollToTime,
  resizable,
  onSelectEvent,
  onEventDrop,
  onEventResize,
  onNavigate,
  views = [Views.MONTH, Views.WEEK, Views.DAY],
}) => {
  const lang = {
    ja: {
      week: '週',
      work_week: 'スケジュール',
      day: '日',
      month: '月',
      previous: '',
      next: '',
      today: '現在',
      agenda: 'スケジュール',
      showMore: (total: number) => `他${total}件`,
    },
  }

  const { defaultDate } = useMemo(
    () => ({
      defaultDate: defaultViewDate,
    }),
    [],
  )

  const formats = {
    dateFormat: 'D',
    dayFormat: 'D(ddd)',
    monthHeaderFormat: 'YYYY年M月',
    dayHeaderFormat: 'M月D日(ddd)',
  }

  const eventPropGetter = useCallback(
    (event: any) => ({
      ...{
        style: {
          backgroundColor: event.color || '#ffcaca',
          color: '#fff',
        },
      },
    }),
    [],
  )

  return (
    <div className={`${style} ${hasArea ? '--area' : ''}`}>
      <DragAndDropCalendar
        localizer={localizer}
        startAccessor={(event: any) => event.start}
        endAccessor={(event: any) => event.end}
        events={events}
        views={views}
        formats={formats}
        defaultDate={defaultDate}
        defaultView={Views.MONTH}
        messages={lang.ja}
        style={{ height: '70vh' }}
        eventPropGetter={eventPropGetter}
        step={15}
        onNavigate={onNavigate}
        onSelectSlot={onSelectSlot}
        selectable={selectable || false}
        scrollToTime={scrollToTime}
        resizable={resizable || false}
        longPressThreshold={0}
        onSelectEvent={onSelectEvent}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
      />
    </div>
  )
}
