import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { MdArrowForwardIos } from 'react-icons/md'
import nl2br from 'react-nl2br'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { p2r } from '../../utils/font'

type CardProps = {
  title: string
  body: string
  image: string
  imageWidth: number
}
const Card: React.FC<CardProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <Flex
      columnGap={{ base: '0px', md: '24px' }}
      rowGap={{ base: '16px', md: '0px' }}
      justify="space-between"
      align="center"
      direction={{ base: 'column', md: 'row' }}
    >
      <Box flex="1" maxWidth="740px">
        <Heading
          as="h2"
          fontSize={{ base: p2r(18), md: p2r(24) }}
          lineHeight="150%"
          color="theme.primary"
          fontWeight="700"
        >
          {isMobile ? props.title : `・${props.title}`}
        </Heading>
        <Text
          mt={{ base: '16px', md: '8px' }}
          fontSize={p2r(16)}
          lineHeight="150%"
        >
          {nl2br(props.body)}
        </Text>
      </Box>
      <Box px={{ base: '16px', md: props.imageWidth < 250 ? '40px' : '0px' }}>
        <Image src={props.image} w={`${props.imageWidth}px`} />
      </Box>
    </Flex>
  )
}

const BousaiSonae: React.FC = () => {
  useHeaderConfig({
    title: '安心・安全',
  })
  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const navigate = useNavigate()
  const { me } = useAuthContext()

  const myPlaceMapPath = useMemo(() => {
    let path = `/bousai/sonae-map`
    if (me?.placeId) {
      path += `?placeId=${me.placeId}`
    }
    return path
  }, [me])

  return (
    <Flex
      direction="column"
      px={{ base: '0px', md: '32px' }}
      pt={{ base: '0px', md: '24px' }}
      pb={{ base: '0px', md: '80px' }}
      minH="calc(100vh - 56px)"
    >
      <Flex
        mt={{ base: '40px', md: '0px' }}
        order={{ base: '2', md: '1' }}
        justify="flex-end"
        align="center"
      >
        <Flex
          p={{ base: '16px', md: '0px' }}
          direction={{ base: 'column', md: 'row' }}
          backgroundColor={{ base: 'grand.white', md: 'unset' }}
          boxShadow={{ base: '0px 0px 10px rgba(0, 0, 0, 0.1)', md: 'unset' }}
          columnGap={{ base: '0px', md: '24px' }}
          rowGap={{ base: '8px', md: '0px' }}
          w={{ base: '100%', md: 'fit-content' }}
        >
          <Button
            onClick={() => {
              navigate('/bousai/sonae-map')
            }}
            variant="unstyled"
            backgroundColor="grand.white"
            borderWidth="2px"
            borderColor="theme.primary"
            borderRadius="8px"
            w={{ base: '100%', md: 'fit-content' }}
            h={{ base: '48px', md: '56px' }}
          >
            <Flex columnGap="4px" justify="center" align="center" px="16px">
              <Text
                fontSize={{ base: p2r(18), md: p2r(20) }}
                color="theme.primary"
                fontWeight="700"
                lineHeight="1"
              >
                {isMobile
                  ? f('すべてのハザードマップを見る')
                  : f('すべてのハザードマップを確認する')}
              </Text>
              {!isMobile && (
                <Icon
                  as={MdArrowForwardIos}
                  color="theme.primary"
                  boxSize="24px"
                />
              )}
            </Flex>
          </Button>
          <Button
            onClick={() => {
              navigate(myPlaceMapPath)
            }}
            variant="unstyled"
            backgroundColor="theme.primary"
            borderWidth="2px"
            borderColor="theme.primary"
            borderRadius="8px"
            w={{ base: '100%', md: 'fit-content' }}
            h={{ base: '48px', md: '56px' }}
          >
            <Flex columnGap="4px" justify="center" align="center" px="16px">
              <Text
                fontSize={{ base: p2r(18), md: p2r(20) }}
                color="grand.white"
                fontWeight="700"
                lineHeight="1"
              >
                {isMobile
                  ? f('あなたの地域のハザードマップを見る')
                  : f('あなたの地域のハザードマップを確認する')}
              </Text>
              {!isMobile && (
                <Icon
                  as={MdArrowForwardIos}
                  color="grand.white"
                  boxSize="24px"
                />
              )}
            </Flex>
          </Button>
        </Flex>
      </Flex>
      <Flex
        order={{ base: '1', md: '2' }}
        mt={{ base: '0px', md: '24px' }}
        direction="column"
        rowGap={{ base: '16px', md: '24px' }}
        p={{ base: '16px', md: '0px' }}
      >
        <Card
          title="ハザードマップを確認しておきましょう！"
          image="/images/bousai/sonae_map.svg"
          imageWidth={250}
          body={`土砂災害ハザードマップを見ながら、あなたの身の回りで、どこが土砂災害の起きやすいところなのか確認しておきましょう。
          また、避難場所までのルートで、途中に危険なところがないかなどを確認しておきましょう。大雨などで、河川が増水した場合に、どの橋が安全にわたることができるのか、事前に確認しておくことも必要です。`}
        />
        <Card
          title="非常品の持ち出し袋を確認しましょう"
          image="/images/bousai/sonae_bag.svg"
          imageWidth={140}
          body="地震や、局地的で急な大雨によって、突然、避難情報が発表されることもあります。いざというときに備えて、非常品の持ち出し袋を準備しておきましょう。また、中身も定期的に点検して、食料品の消費期限が切れている場合は新品に交換しましょう。"
        />
        <Card
          title="家の周りの水路や溝を掃除しましょう"
          image="/images/bousai/sonae_cleaning.svg"
          imageWidth={172}
          body={`大雨になると、家の周りの水路や溝などには大量の水が流れ込みます。ゴミや落ち葉が詰まっていると、流れてきた水があふれ出す恐れがあります。
          水路や溝は、常に清掃しておきましょう。`}
        />
        <Card
          title="避難訓練に参加しましょう"
          image="/images/bousai/sonae_emergency.svg"
          imageWidth={290}
          body={`災害が起きたときに必要な助けや支援には、
          1.自助（自分の身は自分で守る）
          2.共助（自分達の地域は自分で守る）
          3.公助（自治体や消防・警察・自衛隊などが行う）
          の3つに分けられます。
          特に共助は、救助された人の全体のうち約7割の人が共助により助かった事例もあり、災害時の救助活動にとって重要な役割をはたしています。
          避難訓練に参加することで、災害の基礎知識を得たり、避難場所や避難経路の確認ができるだけでなく、地域住民が連携・協力して「共助」の力を養うことにも繋がります。`}
        />
      </Flex>
    </Flex>
  )
}

export default BousaiSonae
