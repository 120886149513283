import { atom } from 'recoil'

export type ResetPassword = {
  step: number
  sendValue: string
  authCode: string
  password: string
  passwordConfirmation: string
  isValidStep1: boolean
  isValidStep2: boolean
}
export const resetPasswordState = atom<ResetPassword>({
  key: 'resetPassword',
  default: {
    sendValue: '',
    authCode: '',
    password: '',
    passwordConfirmation: '',
    isValidStep1: true,
    isValidStep2: true,
    step: 1,
  },
})
