import {
  Text,
  Flex,
  Icon,
  Box,
  HStack,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import {
  MdFormatSize,
  MdHelp,
  MdLogout,
  MdVideocam,
  MdCreditCard,
  MdNotifications,
} from 'react-icons/md'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import useFormatMessage from '../../hooks/useFormatMessage'
import { removeSession } from '../../utils/auth'
import { p2r } from '../../utils/font'

const useSetMenu = () => {
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  const toast = useToast()
  const items = {
    basic: [
      {
        id: 1,
        path: '/mypage/font',
        icon: MdFormatSize,
        title: f('文字の大きさ'),
      },
      {
        id: 2,
        path: '/mypage/notification',
        icon: MdNotifications,
        title: f('各種通知設定'),
      },
      {
        id: 3,
        path: '/mypage/video',
        icon: MdVideocam,
        title: f('ビデオ通話'),
      },
    ],
    myNumber: [
      {
        id: 1,
        path: '/mypage/mynumber',
        icon: MdCreditCard,
        title: f('マイナンバーカード認証登録'),
      },
    ],
    rule: [
      // {
      //   id: 1,
      //   path: '/policy',
      //   icon: MdPrivacyTip,
      //   title: f('プライバシーポリシー'),
      // },
      {
        id: 1,
        path: '/policy',
        icon: MdFormatSize,
        title: f('利用規約'),
      },
    ],
    help: [
      {
        id: 1,
        url: 'https://www1.fastcloud.jp/cbakcs/PoC/web/index.html',
        icon: MdHelp,
        title: f('使い方がわからない'),
      },
    ],
    logout: [
      {
        id: 1,
        icon: MdLogout,
        title: f('ログアウト'),
        color: 'grand.error',
        onClick: () => {
          removeSession()
          toast({
            title: 'ログアウトしました',
          })
          navigate('/')
        },
      },
    ],
  }

  return items
}

type MenuProfileProps = {
  username: string
  currentPath: string
}
const MenuProfile: React.FC<MenuProfileProps> = (props) => {
  // トップと編集中は名前部分を選択中の色
  const profilePaths = ['/mypage/profile', '/mypage/edit']
  const isActive = profilePaths.includes(props.currentPath)
  return (
    <Link to="/mypage/profile">
      <Flex
        backgroundColor={isActive ? 'theme.primary' : 'grand.white'}
        px="16px"
        py="24px"
        borderRadius="10px"
        gap="16px"
        alignItems="center"
      >
        <Text
          color={isActive ? 'text.white' : 'text.body'}
          fontSize={p2r(18)}
          fontWeight="700"
        >
          {props.username}
        </Text>
      </Flex>
    </Link>
  )
}

type MenuLinkProps = {
  path?: string
  url?: string
  onClick?: () => void
  icon: React.FC
  title: string
  isCaution?: boolean
  isActive: boolean
}
const MenuLink: React.FC<MenuLinkProps> = (props) => {
  // 表示中の色などの切り替え
  let textColor = 'text.headline'
  let iconColor = 'grand.grayDark'
  if (props.isActive) {
    textColor = 'text.white'
    iconColor = 'text.white'
  } else if (props.isCaution) {
    textColor = 'grand.error'
  }

  const inner = useMemo(
    () => (
      <HStack
        px="16px"
        py={{ base: '8px', md: '11px' }}
        spacing="16px"
        backgroundColor={props.isActive ? 'theme.primary' : 'grand.white'}
      >
        <Icon
          as={props.icon}
          boxSize={{ base: p2r(24), md: p2r(24) }}
          color={iconColor}
        />
        <Text
          fontSize={{ base: p2r(18), md: p2r(18) }}
          fontWeight="700"
          color={textColor}
        >
          {props.title}
        </Text>
      </HStack>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props],
  )

  if (props.path) {
    return <Link to={props.path}>{inner}</Link>
  }

  if (props.url) {
    return (
      <a href={props.url} target="_blank" rel="noreferrer">
        {inner}
      </a>
    )
  }

  return (
    <Box
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
      }}
      cursor="pointer"
    >
      {inner}
    </Box>
  )
}

type MenuContainerProps = {
  list: {
    id: number
    path?: string
    url?: string
    onClick?: () => void
    icon: React.FC
    title: string
  }[]
  currentPath: string
  isCaution?: boolean
}
const MenuContainer: React.FC<MenuContainerProps> = (props) => (
  <Box borderRadius="14px" overflow="hidden">
    {props.list.map((item) => (
      <MenuLink
        key={item.id}
        path={item?.path}
        url={item?.url}
        icon={item.icon}
        title={item.title}
        isActive={props.currentPath === item.path}
        isCaution={props.isCaution}
        onClick={item?.onClick}
      />
    ))}
  </Box>
)

const MypageMenu: React.FC = () => {
  // メニュー情報
  const menu = useSetMenu()
  // 現在のパス情報取得
  const location = useLocation()
  const currentPath = location.pathname
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { me } = useAuthContext()

  return (
    <Flex
      flexDirection="column"
      gap="32px"
      w={{ base: '100%', md: '374px' }}
      p={{ base: '30px 16px', md: '48px 24px 48px 32px' }}
      backgroundColor="theme.background"
    >
      <MenuProfile username={me ? me.nickname : ''} currentPath={currentPath} />
      <MenuContainer list={menu.basic} currentPath={currentPath} />
      {isMobile && (
        <MenuContainer list={menu.myNumber} currentPath={currentPath} />
      )}
      <MenuContainer list={menu.rule} currentPath={currentPath} />
      <MenuContainer list={menu.help} currentPath={currentPath} />
      <MenuContainer list={menu.logout} currentPath={currentPath} isCaution />
    </Flex>
  )
}

export default MypageMenu
