import _ from 'lodash'
import { LS_KEY } from '../../lib/constants'
import { ContentApiContentReadRequest } from '../../openapi/api'
import appApiClient from './appApiClient'
import useBaseMutation from './useBaseMutation'

export const useContentReadMutation = () =>
  useBaseMutation({
    mutationFn: (req: ContentApiContentReadRequest, config) => {
      // 未ログイン状態でも既読状態をローカルストレージに保存
      const readedContentIds = JSON.parse(
        localStorage.getItem(LS_KEY.readedContentIds) || '[]',
      ) as number[]

      const newReadedContentIds = _.uniq([
        ...readedContentIds,
        ...req.contentReadRequest.contentIds,
      ])

      localStorage.setItem(
        LS_KEY.readedContentIds,
        JSON.stringify(newReadedContentIds),
      )
      return appApiClient.contentApi.contentRead(req, config)
    },
  })
