import {
  Button,
  Box,
  Text,
  Icon,
  Flex,
  Radio,
  RadioGroup,
  Image,
} from '@chakra-ui/react'
import React, { useState, ReactNode } from 'react'
import { MdArrowForwardIos, MdArrowBackIos } from 'react-icons/md'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import theme from '../../../theme'
import { p2r } from '../../../utils/font'
import './index.scss'
import IconBeside from './icon_beside.svg'
import IconVertical from './icon_vertical.svg'

type AppPDFViewProps = {
  fileUrl: string
}

const For = <T,>({
  v,
  children,
}: {
  v: [T, (v: T) => unknown, (v: T) => T]
  children: (v: T, index: number) => ReactNode
}) => {
  const index = 0
  const value: ReactNode[] = []
  for (let i = v[0]; v[1](i); i = v[2](i)) {
    value.push(children(i, index + 1))
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{value}</>
}

export const AppPDFView: React.FC<AppPDFViewProps> = ({ fileUrl }) => {
  const [pages, setPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [value, setValue] = useState('1')
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setPages(numPages)
  }
  return (
    <Flex
      className={`pdf ${value === '1' && 'pdf--vertical'}`}
      pos="relative"
      justify={{ base: 'flex-start', md: 'center' }}
      alignItems="center"
      minH="calc(100vh - 56px)"
      flexDirection={{ base: 'column', md: 'row' }}
      px="2rem"
      py={{ base: '1rem', md: '4rem' }}
    >
      <Box
        pos={{ base: 'relative', md: 'absolute' }}
        right={{ base: '0', md: '2rem' }}
        top={{ base: '0', md: '2rem' }}
        zIndex="2"
        w={{ base: 'full', md: 'auto' }}
        mb={{ base: '2rem', md: '0' }}
      >
        <Text fontSize={p2r(16)} mb={{ base: '.5rem', md: '1rem' }}>
          ページめくりの向き
        </Text>
        <Box
          bg="white"
          borderRadius="8px"
          border={{ base: 'none', md: '2px solid #DFE1E4' }}
          w={{ base: '100%', md: '160px' }}
          p={{ base: '0', md: '16px' }}
        >
          <RadioGroup
            onChange={setValue}
            value={value}
            display="flex"
            justifyContent="center"
            flexDir={{ base: 'row', md: 'column' }}
            w={{ base: '100%', md: 'auto' }}
          >
            <Radio
              size="lg"
              colorScheme="green"
              value="1"
              display="flex"
              mb={{ base: '0', md: '1rem' }}
              w={{ base: '50%', md: 'auto' }}
            >
              <Flex as="span" alignItems="center">
                <Image src={IconVertical} alt="縦" mr=".5rem" />縦
              </Flex>
            </Radio>
            <Radio
              size="lg"
              colorScheme="green"
              value="2"
              display="flex"
              w={{ base: '50%', md: 'auto' }}
            >
              <Flex as="span" alignItems="center">
                <Image src={IconBeside} alt="横" mr=".5rem" />横
              </Flex>
            </Radio>
          </RadioGroup>
        </Box>
      </Box>
      <Box pos="relative" className="pdf__wrapper">
        <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
          {value === '1' && pages ? (
            <For v={[1, (i) => i <= pages, (i) => i + 1]}>
              {(i) => <Page pageNumber={i} className="pdf__page" />}
            </For>
          ) : (
            <Page pageNumber={pageNumber} className="pdf__page" />
          )}
        </Document>

        {value === '2' && (
          <>
            <Text
              pos="absolute"
              top={p2r(14)}
              left={p2r(14)}
              fontSize={p2r(20)}
            >
              {pageNumber} / {pages}
            </Text>
            <Button
              className="pager-button"
              bg={theme.colors.theme.primaryDark}
              color="white"
              w="52px"
              h="168px"
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              left={{ base: '20px', md: '-72px' }}
              disabled={pageNumber === 1}
              onClick={() => {
                setPageNumber(pageNumber - 1)
              }}
              display="flex"
              flexDir="column"
              _hover={{
                bg: theme.colors.theme.primary,
              }}
              _disabled={{
                color: '#747678',
                bg: theme.colors.grand.graySuperLight,
                _hover: {
                  bg: theme.colors.grand.graySuperLight,
                },
              }}
            >
              <Icon
                className="arrow-icon"
                as={MdArrowBackIos}
                boxSize="20px"
                pos="relative"
                left="3px"
                mb=".5rem"
              />
              前へ
            </Button>
            <Button
              className="pager-button"
              bg={theme.colors.theme.primaryDark}
              color="white"
              w="52px"
              h="168px"
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              right={{ base: '20px', md: '-72px' }}
              disabled={pages === pageNumber}
              onClick={() => {
                setPageNumber(pageNumber + 1)
              }}
              display="flex"
              flexDir="column"
              _hover={{
                bg: theme.colors.theme.primary,
              }}
              _disabled={{
                color: '#747678',
                bg: theme.colors.grand.graySuperLight,
                _hover: {
                  bg: theme.colors.grand.graySuperLight,
                },
              }}
            >
              <Icon
                className="arrow-icon"
                as={MdArrowForwardIos}
                boxSize="20px"
                mb=".5rem"
              />
              次へ
            </Button>
          </>
        )}
      </Box>
    </Flex>
  )
}
