import { useQuery } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'
import appApiClient from '../../../hooks/coreApi/appApiClient'

// 仮 codeからユーザー検索 ダミーユーザーのみ返す
type UserSearchQueryRequest = {
  code?: string
}

export default function useUserSearchQuery(req: UserSearchQueryRequest) {
  return useQuery({
    queryKey: ['userSearch'],
    queryFn: async () => {
      if (!req.code) {
        return null
      }

      const config: AxiosRequestConfig = {
        headers: {},
      }

      const verifyRes = await appApiClient.userApi.userVerifyCodeForTaxi(
        {
          userVerifyCodeForTaxiRequest: {
            code: req.code,
          },
        },
        config,
      )

      const userId = verifyRes.data.id

      if (!userId) {
        return null
      }

      const userRes = await appApiClient.userApi.userList(
        {
          id: userId,
          withUserInfo: true,
        },
        config,
      )

      return userRes.data?.users?.[0] || null
    },
    cacheTime: 0,
  })
}

export const searchUserByCode = async (code: string) => {
  const config: AxiosRequestConfig = {
    headers: {},
  }

  const verifyRes = await appApiClient.userApi.userVerifyCodeForTaxi(
    {
      userVerifyCodeForTaxiRequest: {
        code,
      },
    },
    config,
  )

  const userId = verifyRes.data.id

  if (!userId) {
    return null
  }

  const userRes = await appApiClient.userApi.userList(
    {
      id: userId,
      withUserInfo: true,
    },
    config,
  )

  return userRes.data?.users?.[0] || null
}
