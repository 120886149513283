import { Flex, Text, Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { AuthButton } from '../../components/auth/AuthButton'
import { FormHeading } from '../../components/auth/FormHeading'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { p2r } from '../../utils/font'

const SignupCompleted: React.FC = () => {
  const { f } = useFormatMessage()
  useHeaderConfig({
    show: false,
  })
  const navigate = useNavigate()
  return (
    <Flex
      direction="column"
      justify="center"
      alignItems="center"
      bgColor="theme.background"
      minH="100vh"
      rowGap="64px"
      w="100%"
      px={{ base: '16px', md: 'unset' }}
      maxW={{ base: 'auto', md: '560px' }}
    >
      <Stack spacing="32px" alignItems="center">
        <FormHeading label={f('新規アカウントの登録が完了しました')} />
        <Text fontSize={p2r(16)} color="text.body">
          {f('以下のボタンからログインを行なってください')}
          <br />
          {f('(登録したEメールアドレスか電話番号による認証が必要です)')}
        </Text>
      </Stack>
      <Stack spacing="16px" w="100%">
        <AuthButton
          onClick={() => navigate('/login')}
          bgColor="theme.primary"
          color="text.white"
        >
          {f('ログイン画面へ')}
        </AuthButton>
      </Stack>
    </Flex>
  )
}

export default SignupCompleted
