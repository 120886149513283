// import { useBreakpointValue } from '@chakra-ui/react'
import { css } from '@emotion/css'
import theme from '../../../theme'
import { p2r } from '../../../utils/font'
import IconArrowLeft from './icon_arrow_left.svg'
import IconArrowRight from './icon_arrow_right.svg'

// const useIsMobile = () => useBreakpointValue({ base: true, md: false })

export default css`
  max-width: 850px;
  margin: 0 auto;
  position: relative;
  background-color: ${theme.colors.grand.white};
  padding: 32px;
  @media only screen and (max-width: 767px) {
    background-color: unset;
    padding: 10px 16px;
  }
  &.--area {
    .rbc-btn-group {
      &:last-child {
        display: none;
      }
    }
  }

  .rbc-today {
    background-color: ${theme.colors.grand.white};
  }

  .rbc-header {
    padding: 4px 0;
    span {
      font-weight: normal;
      font-size: ${p2r(16)};
    }

    &.rbc-today {
      background-color: ${theme.colors.theme.primary};

      .rbc-button-link {
        color: #fff;
      }
    }
  }

  .rbc-time-view {
    .rbc-row {
      min-height: inherit;
    }
  }

  .rbc-toolbar {
    position: relative;
    justify-content: space-between;
    margin-bottom: ${p2r(32)};
    @media only screen and (max-width: 767px) {
      margin-bottom: 10px;
      align-items: center;
    }

    .rbc-btn-group {
      button {
        height: 40px;
        background-color: ${theme.colors.theme.primary};
        color: #fff;
        font-size: ${p2r(14)};
        &.rbc-active {
          background-color: ${theme.colors.theme.primaryDark};
        }
        @media only screen and (max-width: 767px) {
          height: 36px;
        }
      }
      &:first-child {
        display: flex;
        button {
          background-color: ${theme.colors.theme.primaryDark};
          // 今日ボタン
          &:first-child {
            order: 3;
            border-radius: 4px;
            margin-left: ${p2r(8)};
            width: 60px;
            padding: 0;
            @media only screen and (max-width: 767px) {
              display: none;
            }
          }
          &:nth-child(2),
          &:nth-child(3) {
            font-weight: 700;
            display: flex;
            align-items: center;
            &::before {
              position: relative;
              top: 2px;
            }
          }

          &:nth-child(2) {
            border-radius: 4px 0 0 4px;
            &::before {
              content: url(${IconArrowLeft});
            }
          }

          &:nth-child(3) {
            &::before {
              content: url(${IconArrowRight});
            }
          }
        }
      }
      &:last-child {
        @media only screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }

  .rbc-toolbar-label {
    position: absolute;
    font-size: ${p2r(22)};
    font-weight: 700;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: 767px) {
      font-size: ${p2r(16)};
      left: 45%;
    }
  }

  // today style
  .rbc-date-cell {
    &.rbc-now {
      .rbc-button-link {
        color: #fff;
      }
    }

    &.rbc-off-range {
      .rbc-button-link {
        color: #dfe1e4;
      }
    }
  }

  .rbc-row-segment {
    .rbc-button-link {
      color: #1c1f21;
      background-color: transparent;
      width: 100%;
      display: block;
      font-weight: 700;
    }
  }

  .rbc-day-bg {
    @media only screen and (max-width: 767px) {
      background-color: ${theme.colors.grand.white};
    }
    &.rbc-today {
      background-color: ${theme.colors.theme.primary};
    }

    &.rbc-off-range-bg {
      background-color: #fff;
    }
  }

  .rbc-event {
    font-size: ${p2r(12)};
    color: #1c1f21;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    &.trash {
      background-color: #ffcaca;
    }
    &.holiday {
      background-color: ${theme.colors.theme.primary};
    }
    &.dekigoto {
      background-color: #aac8f3;
    }
    &.taxi {
      background-color: ${theme.colors.theme.primaryBright};
    }
  }

  &.calendar-resource {
    width: 100%;
    &--fullWidth {
      max-width: inherit;
    }
    .rbc-timeslot-group {
      min-height: 100px;
    }

    .rbc-event {
      padding: 0;
      border: 1px solid rgba(171, 90, 109, 0.4);
      &.type1 {
        border: 2px dashed ${theme.colors.theme.primaryDark};
        background-color: #fff;
        .rbc-event-content {
          background-color: rgba(81, 123, 121, 0.2);
        }
      }
      &.type2 {
        background-color: #ab5a6d;
        .rbc-event-label {
          color: #fff;
        }
      }
      &.type3 {
        background-color: ${theme.colors.theme.primaryDark};
        .rbc-event-label {
          color: #fff;
        }
      }
      .rbc-event-label {
        padding: 5px 8px;
        padding-top: 1.2rem;
        display: block;
        width: 100%;
        font-size: ${p2r(14)};
      }

      .rbc-event-content {
        background-color: #fff;
        padding: 8px 16px;
      }
    }
  }
`
