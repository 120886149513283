import { useMemo } from 'react'
import { useAuthContext } from '../../context/AuthContext'

const useDebugUtil = () => {
  const { me } = useAuthContext()
  const isDebugUser = useMemo(() => {
    const debugUserIds =
      process.env.REACT_APP_DEBUG_USER_IDS?.split(',')?.map((d) => Number(d)) ||
      []

    if (!debugUserIds) {
      return false
    }

    if (!me) {
      return false
    }

    return debugUserIds.includes(me.id)
  }, [me])

  return {
    isDebugUser,
  }
}

export default useDebugUtil
