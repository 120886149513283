import {
  Text,
  Flex,
  Box,
  FormControl,
  Input,
  FormLabel,
  Select,
  useToast,
} from '@chakra-ui/react'
import { css } from '@emotion/css'
import React, { useEffect } from 'react'
import { useForm, UseFormRegister, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AppErrorAlert } from '../../components/common/AppErrorAlert'
import { MypageButton } from '../../components/mypage/MypageButton'
import { useAuthContext } from '../../context/AuthContext'
import { useMeUpdateMutation } from '../../hooks/coreApi/useMeUpdateMutation'
import { usePlaceListQuery } from '../../hooks/coreApi/usePlaceListQuery'
import { useTrashCalendarListQuery } from '../../hooks/coreApi/useTrashCalendarListQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import { MeUpdateRequest } from '../../openapi/api'
import { p2r } from '../../utils/font'

type MypageEditFormInput = MeUpdateRequest

/**
 * テキスト入力
 */
type InputTextProps = {
  label: string
  bgColor?: string
}
const InputText = React.forwardRef<
  HTMLInputElement,
  InputTextProps & ReturnType<UseFormRegister<MypageEditFormInput>>
>((props, ref) => (
  <Flex
    py="16px"
    backgroundColor={props.bgColor ? props.bgColor : 'grand.white'}
    flexDirection="column"
    w="100%"
    minW="242px"
    rowGap="8px"
  >
    <FormLabel
      fontSize={p2r(16)}
      lineHeight="150%"
      fontWeight="400"
      color="text.sub"
      mb="0"
      htmlFor={props.label}
    >
      {props.label}
    </FormLabel>
    <Input
      ref={ref}
      name={props.name}
      onChange={props.onChange}
      onBlur={props.onBlur}
      type="text"
      p="16px"
      lineHeight="150%"
      fontSize={p2r(24)}
      fontWeight="400"
      color="text.body"
      variant="unstyled"
      border="1px solid"
      borderColor="grand.grayLight"
      borderRadius="10px"
    />
  </Flex>
))

/**
 * エリア選択
 */
type AreaSelectProps = {
  label: string
  bgColor?: string
}
const AreaSelectInnerStyle = css`
  padding: 16px !important;
`
const AreaSelect = React.forwardRef<
  HTMLSelectElement,
  AreaSelectProps & ReturnType<UseFormRegister<MypageEditFormInput>>
>((props, ref) => {
  const placeListQuery = usePlaceListQuery({})
  const places = placeListQuery?.data?.places

  return (
    <Flex
      py="16px"
      backgroundColor={props.bgColor ? props.bgColor : 'grand.white'}
      flexDirection="column"
      w="100%"
      minW="242px"
      rowGap="8px"
    >
      <FormLabel
        fontSize={p2r(16)}
        lineHeight="150%"
        fontWeight="400"
        color="text.sub"
        mb="0"
        htmlFor={props.label}
      >
        {props.label}
      </FormLabel>
      <Select
        className={AreaSelectInnerStyle}
        ref={ref}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        lineHeight="150%"
        fontSize={p2r(24)}
        fontWeight="400"
        color="text.body"
        variant="unstyled"
        border="1px solid"
        borderColor="grand.grayLight"
        borderRadius="10px"
      >
        {places &&
          places.map((place) => (
            <option key={place.id} value={place.id}>
              {place.name}
            </option>
          ))}
      </Select>
    </Flex>
  )
})

/**
 * エリア選択
 */
type TrashCalendarSelectProps = {
  label: string
  bgColor?: string
}
const TrashCalendarInnerStyle = css`
  padding: 16px !important;
`
const TrashCalendarSelect = React.forwardRef<
  HTMLSelectElement,
  TrashCalendarSelectProps & ReturnType<UseFormRegister<MypageEditFormInput>>
>((props, ref) => {
  const trashCalendarListQuery = useTrashCalendarListQuery({})
  const trashCalendars = trashCalendarListQuery?.data?.trashCalendars

  return (
    <Flex
      py="16px"
      backgroundColor={props.bgColor ? props.bgColor : 'grand.white'}
      flexDirection="column"
      w="100%"
      minW="242px"
      rowGap="8px"
    >
      <FormLabel
        fontSize={p2r(16)}
        lineHeight="150%"
        fontWeight="400"
        color="text.sub"
        mb="0"
        htmlFor={props.label}
      >
        {props.label}
      </FormLabel>
      <Select
        className={TrashCalendarInnerStyle}
        ref={ref}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        lineHeight="150%"
        fontSize={p2r(24)}
        fontWeight="400"
        color="text.body"
        variant="unstyled"
        border="1px solid"
        borderColor="grand.grayLight"
        borderRadius="10px"
      >
        {trashCalendars &&
          trashCalendars.map((c) => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
      </Select>
    </Flex>
  )
})

const MypageEdit: React.FC = () => {
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  const meUpdateMutation = useMeUpdateMutation()
  const toast = useToast()
  const { me, refetchMe } = useAuthContext()

  const form = useForm<MypageEditFormInput>()

  // フォームの初期値セット
  useEffect(() => {
    if (!me) {
      return
    }
    form.reset({
      nickname: me.nickname,
      nicknameKana: me.nicknameKana,
      placeId: me.placeId || undefined,
      trashCalendarId: me.trashCalendarId || undefined,
      cnfAllowInviteFromFriends: me.cnfAllowInviteFromFriends,
      cnfEnableMicAtStartOfMeeting: me.cnfEnableMicAtStartOfMeeting,
      cnfSendNotifTaxiReservationReminder:
        me.cnfSendNotifTaxiReservationReminder,
      cnfConfirmAtLeavingMeeting: me.cnfConfirmAtLeavingMeeting,
      cnfEnableVideoAtStartOfMeeting: me.cnfEnableVideoAtStartOfMeeting,
      cnfFontSize: me.cnfFontSize || 2, // TODO 作成時の初期値をAPI側で修正 https://www.notion.so/maglab/DB-Go-boil-infer-87a96876ddfb451b91d2813b282160f3?pvs=4
      cnfSendNotifTaxiReservationFixed: me.cnfSendNotifTaxiReservationFixed,
    })
  }, [me])

  const onSubmit: SubmitHandler<MypageEditFormInput> = (values) => {
    // プロフィール更新処理
    meUpdateMutation.mutate(
      {
        meUpdateRequest: {
          ...values,
          placeId: Number(values.placeId),
          trashCalendarId: Number(values.trashCalendarId),
        },
      },
      {
        onSuccess() {
          toast({
            title: '変更を保存しました',
          })

          // 設定情報のリロード
          refetchMe()

          // マイページトップへ
          navigate('/mypage')
        },
      },
    )
  }

  if (!me) {
    return null
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      {meUpdateMutation.isError && (
        <AppErrorAlert error={meUpdateMutation.error} />
      )}
      <FormControl>
        <Box px={{ base: '16px', md: '32px' }} py={{ base: '0', md: '32px' }}>
          <Flex
            justifyContent={{ base: 'unset', md: 'space-between' }}
            align="center"
            direction={{ base: 'column', md: 'row' }}
            rowGap="8px"
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              columnGap="16px"
              order={{ base: '2', md: '1' }}
              mr={{ base: 'auto', md: 'unset' }}
            >
              <Text color="text.body" fontSize={p2r(24)} fontWeight="700">
                プロフィールの変更
              </Text>
            </Flex>
            <Box
              order={{ base: '1', md: '2' }}
              ml={{ base: 'auto', md: 'unset' }}
            >
              <MypageButton type="submit">{f('保存する')}</MypageButton>
            </Box>
          </Flex>
          <Flex pt="16px" direction="column">
            <InputText label={f('お名前')} {...form.register('nickname')} />
            {/* <InputText label={f('住居エリア')} {...form.register('areaId')} /> */}
            <AreaSelect label={f('住居エリア')} {...form.register('placeId')} />
            <TrashCalendarSelect
              label={f('ごみ出しカレンダーエリア')}
              {...form.register('trashCalendarId')}
            />
            {/* <InputText label={f('メールアドレス')} {...form.register('email')} /> */}
            {/* <InputText label={f('電話番号')} {...form.register('phone')} /> */}
          </Flex>
        </Box>
      </FormControl>
    </form>
  )
}

export default MypageEdit
