import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React from 'react'
import { MdOutlineExpandMore } from 'react-icons/md'
import { AppCalendar } from '../../components/common/AppCalendar'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import useMySchedule, { calendarConfigMap } from '../../hooks/useMySchedule'
import { p2r } from '../../utils/font'

const Sidebar: React.FC = () => {
  const { f } = useFormatMessage()
  const typeConfigs = Object.values(calendarConfigMap)
  const { showCalendarTypes, setShowCalendarTypes } = useMySchedule()
  return (
    <Box
      backgroundColor="grand.white"
      w="298px"
      borderLeftWidth="1px"
      borderLeftColor="grand.grayLight"
      px="40px"
      py="4.8vh"
    >
      <Text fontSize={p2r(20)} color="grand.gray">
        {f('コンテンツ一覧')}
      </Text>
      <Flex mt="32px" direction="column" rowGap="32px">
        {typeConfigs.map((item) => {
          const checked = showCalendarTypes.includes(item.calendarType)
          return (
            <Checkbox
              colorScheme={item.colorScheme}
              size="lg"
              spacing="16px"
              value={item.calendarType}
              key={item.calendarType}
              defaultChecked={checked}
              sx={{
                '.chakra-checkbox__control': {
                  borderRadius: '5px',
                },
              }}
              onChange={(evt) => {
                if (evt.target.checked) {
                  setShowCalendarTypes([
                    ...showCalendarTypes,
                    item.calendarType,
                  ])
                } else {
                  setShowCalendarTypes(
                    showCalendarTypes.filter((t) => t !== item.calendarType),
                  )
                }
              }}
            >
              <Text fontWeight="700">{item.name}</Text>
            </Checkbox>
          )
        })}
      </Flex>
    </Box>
  )
}

const Calendar: React.FC = () => {
  useHeaderConfig({
    title: 'カレンダー',
  })

  const isMobile = useBreakpointValue({ base: true, md: false })

  const typeConfigs = Object.values(calendarConfigMap)
  const { myEvents, showCalendarTypes, setShowCalendarTypes, handleNavigate } =
    useMySchedule()

  return (
    <Flex minH={{ base: 'calc(100vh - 168px)', md: 'calc(100vh - 56px)' }}>
      <Box
        py={{ base: '0', md: '4.8vh' }}
        backgroundColor="theme.background"
        flex="1"
      >
        <Box position="relative">
          {isMobile && (
            <Box zIndex="100">
              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  position="absolute"
                  top="12px"
                  right="16px"
                  zIndex="1000"
                  w="105px"
                  h="32px"
                  bg="theme.primary"
                  borderRadius="8px"
                  variant="unstyled"
                  columnGap="4px"
                  color="#fff"
                  fontSize="12px"
                  sx={{
                    '> span': {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                  }}
                >
                  コンテンツ
                  <Icon as={MdOutlineExpandMore} boxSize="20px" />
                </MenuButton>
                <MenuList zIndex="9000" p="12px">
                  {typeConfigs.map((item) => {
                    const checked = showCalendarTypes.includes(
                      item.calendarType,
                    )
                    return (
                      <MenuItem key={item.calendarType}>
                        <Checkbox
                          colorScheme={item.colorScheme}
                          size="lg"
                          spacing="16px"
                          value={item.calendarType}
                          key={item.calendarType}
                          defaultChecked={checked}
                          sx={{
                            '.chakra-checkbox__control': {
                              borderRadius: '5px',
                            },
                          }}
                          onChange={(evt) => {
                            if (evt.target.checked) {
                              setShowCalendarTypes([
                                ...showCalendarTypes,
                                item.calendarType,
                              ])
                            } else {
                              setShowCalendarTypes(
                                showCalendarTypes.filter(
                                  (t) => t !== item.calendarType,
                                ),
                              )
                            }
                          }}
                        >
                          <Text fontWeight="700">{item.name}</Text>
                        </Checkbox>
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </Menu>
            </Box>
          )}
          <AppCalendar
            events={myEvents}
            onNavigate={(newDate) => {
              handleNavigate(newDate)
            }}
          />
        </Box>
      </Box>
      {!isMobile && <Sidebar />}
    </Flex>
  )
}

export default Calendar
