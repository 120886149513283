import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { getIdToken } from '../../utils/auth'

type State = {
  from: Location
}

const NotAuthGuard: React.FC = () => {
  const location = useLocation()
  const { pathname } = location
  const state = location?.state as State
  const idToken = getIdToken()
  // 認証済みはTOPへリダイレクト
  const isRedirect =
    idToken &&
    state?.from?.pathname &&
    (pathname === '/signup' ||
      pathname === '/signup/completed' ||
      pathname === '/login')

  return isRedirect ? <Navigate to="/" replace /> : <Outlet />
}

export default NotAuthGuard
