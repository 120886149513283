import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Spacer,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React from 'react'
import {
  MdArrowBackIosNew,
  MdCampaign,
  MdHomeFilled,
  MdOutlineWbSunny,
} from 'react-icons/md'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { useKinkyuBanner } from '../../../hooks/useKinkyuBanner'
import useUserTmpConfig from '../../../hooks/useUserTmpConfig'
import { UserConfigHomeTypeMobile } from '../../../models/user'
import { HeaderConfig, headerConfigState } from '../../../store/headerConfig'
import { p2r } from '../../../utils/font'
import Bousai from '../../svg/Bousai'
import Calendar from '../../svg/Calendar'
import Delete from '../../svg/Delete'
import Navigation from '../../svg/Navigation'
import Note from '../../svg/Note'
import Shikyo from '../../svg/Shikyo'
import SlideShow from '../../svg/SlideShow'
import { AppContentMenuModal } from '../AppContentMenuModal'
import { AppKinkyu } from '../AppKinkyu'

const useSetContents = () => {
  const { f } = useFormatMessage()
  return [
    {
      id: 1,
      path: '/',
      title: f('トップ'),
      icon: MdHomeFilled,
    },
    // {
    //   id: 2,
    //   path: '/authentications',
    //   title: f('認証'),
    //   icon: CreditCard,
    // },
    {
      id: 3,
      path: '/dekigoto',
      title: f('かみやまch'),
      icon: SlideShow,
    },
    {
      id: 4,
      path: '/kairan',
      title: f('お知らせ'),
      icon: Note,
    },
    {
      id: 5,
      path: '/musen',
      title: f('防災無線'),
      icon: MdCampaign,
    },
    {
      id: 6,
      path: '/shikyo',
      title: f('市況情報'),
      icon: Shikyo,
    },
    {
      id: 7,
      path: '/bousai',
      title: f('安心･安全'),
      icon: Bousai,
    },
    {
      id: 8,
      path: '/weather/forecast',
      title: f('天気'),
      icon: MdOutlineWbSunny,
    },
    {
      id: 9,
      path: '/trash/latest',
      title: f('ゴミ出し'),
      icon: Delete,
    },
    {
      id: 10,
      path: '/calendar',
      title: f('カレンダー'),
      icon: Calendar,
    },
  ]
}

type MenuCardProps = {
  link: string
  title: string
  icon: React.FC
  isActive: boolean
}
const MenuCard: React.FC<MenuCardProps> = (props) => (
  <Link to={props.link}>
    <Flex
      py="4px"
      px="16px"
      direction="column"
      align="center"
      borderBottomWidth="4px"
      borderBottomColor={props.isActive ? 'theme.primary' : 'grand.white'}
    >
      <Icon
        as={props.icon}
        boxSize="24px"
        color={props.isActive ? 'grand.grayDark' : 'grand.gray'}
      />
      <Text
        mt="4px"
        color={props.isActive ? 'text.headline' : 'grand.gray'}
        fontWeight="700"
        fontSize={p2r(12)}
        letterSpacing="0.05em"
      >
        {props.title}
      </Text>
    </Flex>
  </Link>
)

export const AppMenuHeader: React.FC = () => {
  const { f } = useFormatMessage()
  const navigationContents = useSetContents()
  const headerConfig = useRecoilValue<HeaderConfig>(headerConfigState)

  // モーダル
  const contentMenuDisclosure = useDisclosure()

  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const currentBasePath = currentPath.split('/')[1]

  // ヘッダーナビゲーションを非表示にするコンテンツ一覧
  const disabledItems = ['video', 'taxi', 'mypage']
  const isShowNavigation = !disabledItems.includes(currentBasePath)

  const { userConfig } = useUserTmpConfig()
  // スマホかつホームの表示タイプがBの場合のみヘッダーナビゲーション表示
  // ※ Aの場合は非表示
  const isMobile = useBreakpointValue({ base: true, md: false })
  const isSPHomeA =
    isMobile &&
    currentPath === '/' &&
    userConfig.homeTypeMobile === UserConfigHomeTypeMobile.TYPE_A

  // 緊急情報バナー
  const { kinkyuContent, isShowKinkyuBanner, onClickCloseKinkyuBanner } =
    useKinkyuBanner()

  if (!headerConfig.show) {
    return null
  }
  return (
    <>
      <Box pt={{ base: '106px', md: '0px' }}>
        <Box
          position="fixed"
          top="0"
          left="0"
          backgroundColor="grand.white"
          w="100%"
          zIndex="1000"
        >
          <Flex
            justify="space-between"
            align="center"
            px="16px"
            py="4px"
            position="relative"
          >
            {currentPath !== '/' ? (
              <Button
                display="flex"
                alignItems="center"
                columnGap="8px"
                variant="unstyled"
                onClick={() => navigate(-1)}
              >
                <Icon
                  as={MdArrowBackIosNew}
                  color="grand.grayDark"
                  boxSize={p2r(24)}
                />
                <Text fontSize={p2r(17)} color="grand.grayDark">
                  {f('戻る')}
                </Text>
              </Button>
            ) : (
              <Spacer />
            )}

            <Heading
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              zIndex="10"
              as="h1"
              fontSize={p2r(17)}
              lineHeight="129%"
              fontWeight="700"
              color="grand.black"
              textAlign="center"
            >
              {headerConfig.title}
            </Heading>
            <Button
              display="flex"
              alignItems="center"
              columnGap="8px"
              variant="unstyled"
              onClick={contentMenuDisclosure.onOpen}
            >
              <Icon as={Navigation} color="grand.grayDark" boxSize={p2r(32)} />
            </Button>
          </Flex>
          {isShowNavigation && !isSPHomeA && (
            <Flex justify="center">
              <Flex
                align="flex-end"
                overflowX="auto"
                whiteSpace="nowrap"
                borderBottomWidth="1px"
                borderBottomColor="grand.grayLight"
                sx={{
                  '::-webkit-scrollbar': {
                    display: 'none',
                    WebkitAppearance: 'none',
                  },
                }}
              >
                {navigationContents.map((content) => (
                  <MenuCard
                    key={content.id}
                    link={content.path}
                    title={content.title}
                    icon={content.icon}
                    isActive={currentBasePath === content.path.split('/')[1]}
                  />
                ))}
              </Flex>
            </Flex>
          )}
        </Box>
      </Box>
      {headerConfig.showKinkyuBanner === true &&
        isShowKinkyuBanner &&
        kinkyuContent && (
          <AppKinkyu
            contentId={kinkyuContent.id}
            level={kinkyuContent?.kinkyuLevel || 1}
            title={kinkyuContent.titleJa}
            date={dayjs(kinkyuContent.releaseAt).format('M月D日 HH:mm 発表')}
            onClose={onClickCloseKinkyuBanner}
          />
        )}
      <AppContentMenuModal
        isOpen={contentMenuDisclosure.isOpen}
        onClose={contentMenuDisclosure.onClose}
      />
    </>
  )
}
