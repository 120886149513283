import {
  Flex,
  Stack,
  Text,
  Input,
  RadioGroup,
  Radio,
  useToast,
  InputGroup,
  InputRightElement,
  Button,
  Icon,
  FormControl,
  FormHelperText,
  HStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import * as yup from 'yup'
import { AuthButton } from '../../components/auth/AuthButton'
import { FormHeading } from '../../components/auth/FormHeading'
import LoginEye from '../../components/svg/LoginEye'
import { parseErrorMessages } from '../../hooks/coreApi/appApiClient'
import { usePlaceListQuery } from '../../hooks/coreApi/usePlaceListQuery'
import { useSignupMutation } from '../../hooks/coreApi/useSignupMutation'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { UserSignupRequest } from '../../openapi/api'
import { p2r } from '../../utils/font'
import SignupCompleted from './completed'

type PlaceSelectOption = {
  value: number
  label: string
}

type SignupFormProps = {
  onFinish: () => void
}

type FormValueType = {
  nickname: string
  nicknameKana: string
  type: 'email' | 'phone'
  email: string | null
  phone: string | null
  password: string
  passwordConfirm: string
  placeId: number
}

const formSchema = yup.object().shape({
  nickname: yup
    .string()
    .required('入力必須項目です')
    .min(1, '1文字以上で入力してください'),
  nicknameKana: yup
    .string()
    .required('入力必須項目です')
    .min(1, '1文字以上で入力してください'),
  type: yup.string(),
  email: yup.string().when('type', {
    is: 'email',
    then: (schema) =>
      schema
        .required('入力必須項目です')
        .email('メールアドレスの形式で入力してください'),
  }),
  phone: yup.string().when('type', {
    is: 'phone',
    then: (schema) =>
      schema
        .required('入力必須項目です')
        .matches(
          /^0\d{1,4}\d{1,4}\d{4}$/,
          '電話番号(ハイフンなし)の形式で入力してください(例: 00011112222)',
        ),
  }),
  password: yup
    .string()
    .required('入力必須項目です')
    .min(8, '8文字以上で入力してください'),
  passwordConfirm: yup
    .string()
    .required('入力必須項目です')
    .min(8, '8文字以上で入力してください')
    .oneOf([yup.ref('password'), ''], '確認用パスワードが一致していません'),
})

const SignupForm: React.FC<SignupFormProps> = ({ onFinish }) => {
  const { f } = useFormatMessage()

  const form = useForm<FormValueType>({
    defaultValues: {
      nickname: '',
      nicknameKana: '',
      type: 'email',
      email: '',
      phone: '',
      password: '',
      passwordConfirm: '',
      placeId: 1,
    },
    resolver: yupResolver(formSchema),
  })

  const toast = useToast()

  const signupMutation = useSignupMutation()

  const onError = (err: any) => {
    toast({
      title: parseErrorMessages(err) || '登録できませんでした。',
      status: 'error',
    })
  }

  const onSubmit = (values: FormValueType) => {
    const params: UserSignupRequest = {
      nickname: values.nickname || '',
      nicknameKana: values.nicknameKana || '',
      manualPassword: values.password,
      placeId: values.placeId,
    }

    if (values.type === 'email') {
      params.email = values.email || ''
    }

    if (values.type === 'phone') {
      params.phone = values.phone || ''
    }

    signupMutation.mutate(
      {
        userSignupRequest: params,
      },
      {
        onSuccess() {
          onFinish()
        },
        onError,
      },
    )
  }

  const placeListQuery = usePlaceListQuery({})
  const places = placeListQuery.data?.places

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  const [isShowPasswordConfirm, setIsShowPasswordConfirm] =
    useState<boolean>(false)

  const placeSelectOptions = useMemo<PlaceSelectOption[]>(() => {
    if (!places) return []
    return places.map((place) => ({
      key: place.id || 0,
      label: place.name || '',
      value: place.id || 0,
    }))
  }, [places])

  const currentType = form.watch('type')

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Flex
        alignItems="center"
        py="48px"
        bgColor="theme.background"
        minH="100vh"
        direction="column"
        rowGap="80px"
        w="100%"
      >
        <Flex
          direction="column"
          rowGap="40px"
          minW={{ base: 'auto', md: '560px' }}
        >
          <Stack spacing="16px">
            <FormHeading label={f('新規登録')} />
            <Text
              fontSize={p2r(16)}
              lineHeight="150%"
              color="text.body"
              whiteSpace="pre-wrap"
            >
              {f(
                `Eメールアドレスか電話番号による認証を設定してください。\nこの設定は後から変更可能です。`,
              )}
            </Text>
          </Stack>
          <Stack spacing="32px">
            <Stack spacing="16px">
              <Flex columnGap="16px" alignItems="center">
                <FormHeading label={f('お名前')} />
                <Text color="text.body">
                  {f('さあくる内で表示される名前です。')}
                </Text>
              </Flex>
              <FormControl>
                <Input
                  {...form.register('nickname')}
                  placeholder={f('お名前')}
                  _placeholder={{ color: 'grand.grayLight' }}
                  bgColor="grand.white"
                  borderWidth="3px"
                  borderColor="grand.grayLight"
                  rounded="10px"
                  minH="64px"
                />
                {form.formState.errors.nickname && (
                  <FormHelperText color="red">
                    {form.formState.errors.nickname.message}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl>
                <Input
                  {...form.register('nicknameKana')}
                  placeholder={f('お名前のフリガナ')}
                  _placeholder={{ color: 'grand.grayLight' }}
                  bgColor="grand.white"
                  borderWidth="3px"
                  borderColor="grand.grayLight"
                  rounded="10px"
                  minH="64px"
                />
                {form.formState.errors.nicknameKana && (
                  <FormHelperText color="red">
                    {form.formState.errors.nicknameKana.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
            <Stack spacing="16px">
              <FormHeading label={f('メールアドレスもしくは電話番号')} />
              <Controller
                control={form.control}
                name="type"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup onChange={onChange} value={value}>
                    <HStack spacing={p2r(24)}>
                      <Radio value="email" colorScheme="radio">
                        <Text fontSize={p2r(16)} color="text.body">
                          メールアドレス
                        </Text>
                      </Radio>
                      <Radio value="phone" colorScheme="radio">
                        <Text fontSize={p2r(16)} color="text.body">
                          電話番号
                        </Text>
                      </Radio>
                    </HStack>
                  </RadioGroup>
                )}
              />
              {currentType === 'email' && (
                <FormControl>
                  <Input
                    {...form.register('email')}
                    placeholder={f('EXAMPL@EXAMPLE.COM')}
                    _placeholder={{ color: 'grand.grayLight' }}
                    bgColor="grand.white"
                    borderWidth="3px"
                    borderColor="grand.grayLight"
                    rounded="10px"
                    minH="64px"
                  />
                  {form.formState.errors.email && (
                    <FormHelperText color="red">
                      {form.formState.errors.email.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}

              {currentType === 'phone' && (
                <FormControl>
                  <Input
                    {...form.register('phone')}
                    placeholder={f('00011112222')}
                    _placeholder={{ color: 'grand.grayLight' }}
                    bgColor="grand.white"
                    borderWidth="3px"
                    borderColor="grand.grayLight"
                    rounded="10px"
                    minH="64px"
                  />
                  {form.formState.errors.phone && (
                    <FormHelperText color="red">
                      {form.formState.errors.phone.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            </Stack>

            <Stack spacing="16px">
              <FormHeading label={f('パスワード')} />
              <FormControl>
                <InputGroup>
                  <Input
                    {...form.register('password')}
                    placeholder="パスワード"
                    type={isShowPassword ? 'text' : 'password'}
                    fontSize={{ base: p2r(18), md: p2r(20) }}
                    color="#372B1F"
                    bgColor="grand.white"
                    minW={{ base: '100%' }}
                    w="fit-content"
                    h="64px"
                    border="3px solid"
                    borderColor="grand.grayLight"
                    rounded="10px"
                    focusBorderColor="theme.primary"
                  />
                  <InputRightElement>
                    <Button
                      onClick={() => {
                        setIsShowPassword(!isShowPassword)
                      }}
                      bgColor="grand.white"
                      position="relative"
                      top="12px"
                      right="10px"
                      outline="none"
                    >
                      <Icon
                        color="grand.gray"
                        as={isShowPassword ? MdOutlineRemoveRedEye : LoginEye}
                        boxSize="24px"
                      />
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {form.formState.errors.password && (
                  <FormHelperText color="red">
                    {form.formState.errors.password.message}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl>
                <InputGroup>
                  <Input
                    {...form.register('passwordConfirm', {
                      validate: (value) =>
                        value === form.getValues('password') ||
                        'パスワードが一致しません',
                    })}
                    placeholder="パスワード(確認用)"
                    type={isShowPasswordConfirm ? 'text' : 'password'}
                    fontSize={{ base: p2r(18), md: p2r(20) }}
                    color="#372B1F"
                    bgColor="grand.white"
                    minW={{ base: '100%' }}
                    w="fit-content"
                    h="64px"
                    border="3px solid"
                    borderColor="grand.grayLight"
                    rounded="10px"
                    focusBorderColor="theme.primary"
                  />
                  <InputRightElement>
                    <Button
                      onClick={() => {
                        setIsShowPasswordConfirm(!isShowPasswordConfirm)
                      }}
                      bgColor="grand.white"
                      position="relative"
                      top="12px"
                      right="10px"
                      outline="none"
                    >
                      <Icon
                        color="grand.gray"
                        as={
                          isShowPasswordConfirm
                            ? MdOutlineRemoveRedEye
                            : LoginEye
                        }
                        boxSize="24px"
                      />
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {form.formState.errors.passwordConfirm && (
                  <FormHelperText color="red">
                    {form.formState.errors.passwordConfirm.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            <Stack spacing="32px">
              <FormHeading label={f('住居地区')} />
              <Stack spacing="24px">
                <Stack spacing="16px">
                  <Text color="text.body" fontSize={p2r(16)} lineHeight="150%">
                    {f('町内の地区を選択してください')}
                  </Text>
                  <Controller
                    control={form.control}
                    name="placeId"
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup
                        onChange={(v) => {
                          onChange(Number(v))
                        }}
                        value={value}
                      >
                        <Stack
                          spacing="24px"
                          bgColor="grand.white"
                          p="16px"
                          border="2px"
                          borderColor="grand.grayLight"
                          rounded="8px"
                        >
                          {placeSelectOptions.map((option) => (
                            <Radio
                              value={option.value}
                              colorScheme="radio"
                              columnGap="16px"
                            >
                              <Text
                                fontSize={p2r(16)}
                                color="text.body"
                                fontWeight="700"
                              >
                                {f(option.label)}
                              </Text>
                            </Radio>
                          ))}
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Flex>
        <Flex w="100%" justifyContent="center">
          <AuthButton
            type="submit"
            isLoading={signupMutation.isLoading}
            bgColor="theme.primary"
            color="text.white"
            // bgColor={!form.formState.isValid ? 'grand.white' : 'theme.primary'}
            // color={!form.formState.isValid ? 'grand.grayLight' : 'text.white'}
          >
            {f('次へ')}
          </AuthButton>
        </Flex>
      </Flex>
    </form>
  )
}

const Signup: React.FC = () => {
  useHeaderConfig({
    show: false,
  })
  const [activeStepNumber, setActiveStepNumber] = useState<number>(0)

  useEffect(
    () => () => {
      setActiveStepNumber(0)
    },
    [],
  )

  return (
    <Flex
      alignItems="center"
      justify="center"
      direction="column"
      backgroundColor="theme.background"
      minH="100vh"
      px={{ base: '16px', md: 'unset' }}
    >
      {activeStepNumber === 0 && (
        <SignupForm
          onFinish={() => {
            setActiveStepNumber(1)
          }}
        />
      )}
      {activeStepNumber === 1 && <SignupCompleted />}
    </Flex>
  )
}

export default Signup
