import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { AppLoading } from '../../components/common/AppLoading'
import VideoHeading from '../../components/video/VideoHeading'
import VideoModal from '../../components/video/VideoModal'
import {
  VideoUserList,
  VideoUserListItem,
} from '../../components/video/VideoUserList'
import { useFriendUpdateMutation } from '../../hooks/coreApi/useFriendUpdateMutation'
import { useMeQuery } from '../../hooks/coreApi/useMeQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { FriendResponse } from '../../openapi'
import { p2r } from '../../utils/font'

const VideoFriendBlock: React.FC = () => {
  useHeaderConfig({
    title: 'ビデオ通話',
  })
  const { f } = useFormatMessage()
  const meQuery = useMeQuery({
    withFriends: true,
  })

  const toast = useToast()

  const allFriends = meQuery.data?.user?.friends

  const blockedFriends = useMemo(
    () => allFriends?.filter((friend) => friend.isBlocked),
    [meQuery.fetchStatus],
  )

  const friendUpdateMutation = useFriendUpdateMutation()

  const [currentModalFriend, setCurrentModalFriend] =
    useState<FriendResponse | null>(null)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleMoreClick = (friend: FriendResponse | null) => {
    if (!friend) {
      throw new Error('handleMoreClick')
    }
    setCurrentModalFriend(friend)
    onOpen()
  }

  // エラーハンドリング
  const onError = () => {
    toast({
      title: 'エラーが発生しました',
      status: 'error',
    })
  }

  // ブロック解除ボタンクリック
  const handleBlockReleaseClick = (friend: FriendResponse | null) => {
    if (!friend) {
      throw new Error('handleBlockReleaseClick')
    }

    friendUpdateMutation.mutate(
      {
        id: friend.id,
        friendUpdateRequest: {
          status: 'accepted',
          isHidden: false,
          isBlocked: false,
        },
      },
      {
        onSuccess() {
          toast({
            title: 'ブロックを解除しました',
            status: 'success',
          })
          meQuery.refetch()
          onClose()
        },
        onError,
      },
    )
  }

  if (meQuery.isLoading) {
    return <AppLoading />
  }

  return (
    <Box>
      <VideoHeading
        label={`${f('友達リスト')} ${String(blockedFriends?.length || 0)}人`}
      />

      <VideoUserList>
        {blockedFriends?.map((friend) => (
          <VideoUserListItem
            name={friend.friendUser?.nickname || ''}
            nameKn={(friend.friendUser?.nicknameKana || '').charAt(0)}
            status="blocked"
            onClickMore={() => {
              handleMoreClick(friend)
            }}
            onClickStatus={() => {
              handleMoreClick(friend)
            }}
            key={friend.id}
          />
        ))}
      </VideoUserList>

      <VideoModal isOpen={isOpen} onClose={onClose}>
        <Flex direction="column">
          <Flex pt="24px" direction="column" alignItems="center" rowGap="8px">
            <Text
              fontSize={p2r(24)}
              fontWeight="700"
              lineHeight="150%"
              color="text.body"
            >
              {currentModalFriend?.friendUser?.nickname}
            </Text>
            {/* <Text
              w={isMobile ? '70%' : '80%'}
              textAlign="center"
              fontSize={p2r(16)}
              lineHeight="150%"
              color="text.body"
            >
              ID: {currentModalFriend?.id}
            </Text> */}
          </Flex>
          <Flex mt="56px" columnGap="48px" justify="center">
            <Button
              variant="ghost"
              borderWidth="4px"
              fontWeight="700"
              rounded="8px"
              minW="300px"
              h="68px"
              borderColor="theme.primary"
              color="theme.primary"
              fontSize={p2r(24)}
              onClick={() => handleBlockReleaseClick(currentModalFriend)}
            >
              {f('ブロック解除')}
            </Button>
          </Flex>
        </Flex>
      </VideoModal>
    </Box>
  )
}

export default VideoFriendBlock
