import { RoomMeetingApiRoomMeetingCreateCompositionRequest } from '../../openapi/api'
import appApiClient from './appApiClient'
import useBaseMutation from './useBaseMutation'

export const useRoomMeetingCreateCompositionMutation = () =>
  useBaseMutation({
    mutationFn: (
      req: RoomMeetingApiRoomMeetingCreateCompositionRequest,
      config,
    ) => appApiClient.roomMeetingApi.roomMeetingCreateComposition(req, config),
  })
