import { Button, Icon, useBreakpointValue } from '@chakra-ui/react'
import { MdOutlineClear } from 'react-icons/md'
import { p2r } from '../../../utils/font'

const TaxiTimeNGButton: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <Button
      w={isMobile ? p2r(56) : p2r(100)}
      h={p2r(56)}
      borderRadius="8px"
      borderWidth="2px"
      borderColor="grand.grayLight"
      backgroundColor="grand.grayLight"
      pointerEvents="none"
      onClick={() => {}}
    >
      <Icon
        as={MdOutlineClear}
        boxSize={isMobile ? p2r(28) : p2r(32)}
        color="#C84545"
      />
    </Button>
  )
}

export default TaxiTimeNGButton
