import { Box } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AppContentBasic } from '../../components/common/AppContentBasic'
import { AppContentMovie } from '../../components/common/AppContentMovie'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppLoading } from '../../components/common/AppLoading'
import { AppPDFView } from '../../components/common/AppPDFView'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import { useContentReadMutation } from '../../hooks/coreApi/useContentReadMutation'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { getDateFormat } from '../../utils/date'

const BousaiDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  useHeaderConfig({
    title: '防災情報',
  })
  const contentReadMutation = useContentReadMutation()
  const contentQuery = useContentListQuery('bousai', {
    id: Number(id),
    withFiles: true,
  })
  const content = contentQuery?.data?.pages[0].contents[0]

  useEffect(() => {
    if (id) {
      contentReadMutation.mutate({
        contentReadRequest: {
          contentIds: [Number(id)],
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (contentQuery.isLoading) {
    return <AppLoading />
  }

  if (!content) {
    return <AppErrorResult message="コンテンツが見つかりませんでした" />
  }

  // TODO detailTypeで判定 PDFファイルの場合 PDFファイルの特定
  const pdfFile = content.files && content.files[0]

  // 動画の場合
  if (content.dekigotoVideoUrl) {
    return (
      <Box mt="4">
        <AppContentMovie
          moviePath={content?.dekigotoVideoUrl || ''}
          date={getDateFormat(content?.releaseAt)}
          title={content?.titleJa || ''}
          body={content?.bodyJa || ''}
          isShowLike
          isLiked={content?.isLiked}
          isReaded={content?.isRead}
          likeCount={content?.likeCount}
          playCount={content?.likeCount}
          tags={content?.contentTags?.map((tag) => tag.label) || []}
          contentId={content.id}
        />
      </Box>
    )
  }

  // PDFの場合
  if (pdfFile) {
    return <AppPDFView fileUrl={pdfFile.cloudFrontUrl} />
  }

  // 通常記事の場合
  return (
    <AppContentBasic
      date={getDateFormat(content.releaseAt)}
      title={content.titleJa || ''}
      body={content.bodyJa || ''}
      isShowLike
      isLiked={content?.isLiked || false}
      likeCount={content?.likeCount || 0}
      tags={['防災情報']}
      contentId={content.id}
    />
  )
}

export default BousaiDetail
