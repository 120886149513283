import {
  Box,
  Flex,
  TabPanels,
  Tabs,
  TabList,
  Text,
  Grid,
  Button,
  Icon,
  useDisclosure,
  keyframes,
  useBreakpointValue,
  BoxProps,
  Image,
  AspectRatio,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { ReactNode, useState } from 'react'
import {
  MdCall,
  MdCampaign,
  MdDelete,
  MdOpenInNew,
  MdOutlineArrowForwardIos,
  MdOutlineClose,
  MdOutlineSlideshow,
  MdOutlineWbSunny,
  MdArrowForwardIos,
} from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { AppContentMenuModal } from '../../components/common/AppContentMenuModal'
import AppLikeButton from '../../components/common/AppLikeButton'
import { AppLoading } from '../../components/common/AppLoading'
import { AppNavigationCard } from '../../components/common/AppNavigationList'
import AppSoundPlayButton from '../../components/common/AppSoundPlayButton'
import { HomePanel } from '../../components/home/HomePanel'

import HomePanelError from '../../components/home/HomePanelError'
import {
  HomePanelList,
  HomePanelListItem,
} from '../../components/home/HomePanelList'
import {
  HomePanelThumbnailList,
  HomePanelThumbnailListItem,
} from '../../components/home/HomePanelThumbnailList'
import { HomeTabListItem } from '../../components/home/HomeTabListItem'
import NavBousai from '../../components/svg/NavBousai'
import NavMypage from '../../components/svg/NavMypage'
import NavNotification from '../../components/svg/NavNotification'
import NavPractice from '../../components/svg/NavPractice'
import NavShikyo from '../../components/svg/NavShikyo'
import NavTaxi from '../../components/svg/NavTaxi'
import NavVideo from '../../components/svg/NavVideo'
import Navigation from '../../components/svg/Navigation'
import Note from '../../components/svg/Note'
import WeatherCloudySunny from '../../components/svg/WeatherCloudySunny'
import { TrashCalendarTypeIcon } from '../../components/trashCalendar/TrashCalendarTypeIcon'
import WeatherIconMap from '../../components/weather/WeatherIconMap'
import WeatherTitleMap from '../../components/weather/WeatherIconMap/weatherTitle'
import { useAuthContext } from '../../context/AuthContext'
import { useUserPersistConfigContext } from '../../context/UserPersistConfigContext'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import useInterval from '../../hooks/useInterval'
import { useKinkyuBanner } from '../../hooks/useKinkyuBanner'
import useUserTmpConfig from '../../hooks/useUserTmpConfig'
import { usePointwfForecastQuery } from '../../hooks/weatherApi/useDailyForecastQuery'
import { getYoutubeThumbnailUrl } from '../../models/content'
import { UserConfigFontSize, UserConfigHomeTypeMobile } from '../../models/user'
import { LEVEL_COLOR_INFO } from '../../utils/content'
import { getDateFormat } from '../../utils/date'
import { p2r } from '../../utils/font'
import { useTrashCalendars } from './useTrashCalendars'

const HomeCalendar = () => {
  const now = new Date()
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })

  type TimeObject = {
    hour: number
    minutes: number
  }

  const [intervalTime, setIntervalTime] = useState<TimeObject>({
    hour: now.getHours(),
    minutes: now.getMinutes(),
  })

  useInterval(() =>
    setIntervalTime({
      hour: new Date().getHours(),
      minutes: new Date().getMinutes(),
    }),
  )

  // 点滅
  const flashing = keyframes`
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  `
  const flashingAnimation = `${flashing} infinite 2s linear`
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      h="100%"
      minH={isMobile ? 'fit-content' : '83px'}
      w={isMobile ? 'fit-content' : 'auto'}
      ml={isMobile ? 'auto' : 'unset'}
      mr={isMobile ? 'auto' : 'unset'}
      py={isMobile ? '8px' : '0px'}
    >
      <Text
        color="text.body"
        textAlign={isMobile ? 'left' : 'unset'}
        w={isMobile ? '100%' : 'auto'}
        fontSize={isMobile ? '14px' : '16px'}
        fontWeight="400"
      >
        <Text as="span" fontSize={isMobile ? '16px' : '20px'} fontWeight="700">
          {now.getMonth() + 1}
        </Text>
        月
        <Text as="span" fontSize={isMobile ? '16px' : '20px'} fontWeight="700">
          {now.getDate()}
        </Text>
        日 ({['日', '月', '火', '水', '木', '金', '土'][now.getDay()]})
      </Text>
      <Text
        fontSize={isMobile ? '48px' : '44px'}
        fontWeight="700"
        lineHeight="100%"
        color="text.headline"
      >
        <Box as="span">{`${intervalTime.hour
          .toString()
          .padStart(2, '0')}`}</Box>
        <Box as="span" animation={flashingAnimation}>
          :
        </Box>
        <Box as="span">{`${intervalTime.minutes
          .toString()
          .padStart(2, '0')}`}</Box>
      </Text>
    </Flex>
  )
}

type HomeWeatherProps = {
  title: string
  max: number
  min: number
  icon: React.FC
}
const HomeWeather: React.FC<HomeWeatherProps> = (props) => {
  const { f } = useFormatMessage()

  const { userPersistConfig } = useUserPersistConfigContext()

  // フォントサイズによって余白を変える
  const textRowSpaces = ['16px', '8px', '4px']
  const textColumnSpaces = ['16px', '16px', '8px']

  return (
    <Flex
      py="8px"
      gap="16px"
      justify="center"
      align="flex-start"
      background="linear-gradient(180deg, rgba(57, 151, 234, 0.8) 0%, rgba(56, 144, 222, 0.8) 100%)"
      h="100%"
      minH="83px"
      px={
        userPersistConfig.cnfFontSize === UserConfigFontSize.LARGE
          ? '8px'
          : '16px'
      }
    >
      <Flex
        gap={textRowSpaces[userPersistConfig.cnfFontSize - 1]}
        direction="column"
      >
        <Text
          fontSize={p2r(14)}
          lineHeight="150%"
          fontWeight="400"
          textShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
          color="text.white"
        >
          {f('今日の天気')}
        </Text>
        <Flex
          align="flex-end"
          gap={textColumnSpaces[userPersistConfig.cnfFontSize - 1]}
        >
          <Text
            fontSize={p2r(20)}
            lineHeight="150%"
            fontWeight="700"
            textShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
            color="text.white"
            noOfLines={1}
          >
            {f(props.title)}
          </Text>
          <Text
            textShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
            color="text.white"
            noOfLines={1}
          >
            <Text as="span" fontSize="18px" fontWeight="700" lineHeight="1">
              {props.max}℃
            </Text>
          </Text>
          <Text textShadow="0px 0px 4px rgba(0, 0, 0, 0.25)" color="text.white">
            <Text as="span" fontSize="18px" fontWeight="700" lineHeight="1">
              {props.min}℃
            </Text>
          </Text>
        </Flex>
      </Flex>

      <Icon my="8px" as={props.icon} boxSize="50px" borderRadius="10px" />
    </Flex>
  )
}

const HomeTrash: React.FC = () => {
  // 直近のゴミ出しカレンダー
  const trashCalendarDates = useTrashCalendars()

  const { userPersistConfig } = useUserPersistConfigContext()

  // フォントサイズによって余白を変える
  const textRowSpaces = ['16px', '16px', '8px']
  const wrapColumnSpaces = ['40px', '16px', '8px']

  if (!trashCalendarDates) {
    return null
  }

  return (
    <Flex
      px="24px"
      pl="16px"
      py="8px"
      gap={wrapColumnSpaces[userPersistConfig.cnfFontSize - 1]}
      justify="center"
      backgroundColor="rgba(240, 240, 240, 0.8)"
      h="100%"
      minH="83px"
    >
      {trashCalendarDates?.[0] && (
        <Flex
          w="50%"
          gap={textRowSpaces[userPersistConfig.cnfFontSize - 1]}
          justify="center"
          align="flex-start"
        >
          <Box>
            <Text
              fontSize={p2r(14)}
              lineHeight="150%"
              fontWeight="400"
              color="text.headline"
              noOfLines={1}
            >
              {dayjs(trashCalendarDates[0].date).format('M月DD日(ddd)')}
            </Text>
            <Text
              mt="12px"
              fontSize={p2r(14)}
              lineHeight="150%"
              fontWeight="700"
              color="text.headline"
              noOfLines={1}
            >
              {trashCalendarDates[0].trashType}
            </Text>
          </Box>
          <TrashCalendarTypeIcon
            mt="12px"
            boxSize="48px"
            borderRadius="10px"
            trashType={trashCalendarDates[0].trashType || ''}
          />
        </Flex>
      )}

      {trashCalendarDates?.[1] && (
        <Flex
          w="50%"
          gap={textRowSpaces[userPersistConfig.cnfFontSize - 1]}
          justify="center"
          align="flex-start"
        >
          <Box>
            <Text
              fontSize={p2r(14)}
              lineHeight="150%"
              fontWeight="400"
              color="text.headline"
              noOfLines={1}
            >
              {dayjs(trashCalendarDates[1].date).format('M月DD日(ddd)')}
            </Text>
            <Text
              mt="12px"
              fontSize={p2r(14)}
              lineHeight="150%"
              fontWeight="700"
              color="text.headline"
              noOfLines={1}
            >
              {trashCalendarDates[1].trashType}
            </Text>
          </Box>
          <TrashCalendarTypeIcon
            mt="12px"
            boxSize="48px"
            borderRadius="10px"
            trashType={trashCalendarDates[1].trashType || ''}
          />
        </Flex>
      )}
    </Flex>
  )
}

/**
 * 上部の3種基準コンポーネント
 */
type HomeHeadItemProps = {
  label: string
  path: string
  contentBg: string
  children: ReactNode
} & BoxProps
const HomeHeadItem: React.FC<HomeHeadItemProps> = (props) => {
  const { label, path, children, contentBg, ...rest } = props
  return (
    <Box {...rest} padding="4px">
      <Link to={path}>
        <Box
          borderRadius="10px 10px 0 0"
          overflow="hidden"
          maxH={{ base: 'unset', md: '120px' }}
          border={{ base: '2px', md: 'none' }}
          borderColor={{ base: 'grand.grayLight', md: 'none' }}
        >
          <Box h="calc(100% - 38px)" background={contentBg}>
            {children}
          </Box>
          <Text
            borderRadius="0 0 8px 8px "
            h={{ base: '32px', md: '38px' }}
            py="3.5px"
            color="text.headline"
            fontSize={{ base: '16px', md: p2r(20) }}
            lineHeight="150%"
            fontWeight="700"
            textAlign="center"
            borderTopWidth="2px"
            borderTopColor="grand.grayLight"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor="grand.white"
          >
            {label}
          </Text>
        </Box>
      </Link>
    </Box>
  )
}

const HOMETAB_DEFAULT_PAGESIZE = 5

const HomeTabs: React.FC = () => {
  const { f } = useFormatMessage()
  const { userPersistConfig } = useUserPersistConfigContext()

  // 最大表示件数
  const maxCount =
    userPersistConfig.cnfFontSize === UserConfigFontSize.LARGE ? 2 : 3

  const { me } = useAuthContext()

  const dekigotoQuery = useContentListQuery('dekigoto', {
    pageSize: HOMETAB_DEFAULT_PAGESIZE,
    sort: 'releaseAtDesc',
  })

  const kairanQuery = useContentListQuery('kairan', {
    pageSize: HOMETAB_DEFAULT_PAGESIZE,
    sort: 'releaseAtDesc',
  })

  const musenQuery = useContentListQuery('musen', {
    pageSize: HOMETAB_DEFAULT_PAGESIZE,
    withFiles: true,
    sort: 'releaseAtDesc',
    placeIds: me?.placeId ? [me.placeId] : [],
  })

  const bgColors = ['#F7FFF1', '#F8FBFF', '#FFF4F4']
  const borderColors = ['#ABDA87', '#86BDEF', '#E48888']
  const [tabIndex, setTabIndex] = useState<number>(0)

  return (
    <Tabs
      w="70.5vh"
      h="70vh"
      overflow="hidden"
      borderRadius="8px"
      colorScheme="tabs"
      variant="enclosed"
      onChange={(index) => setTabIndex(index)}
    >
      <TabList px="16px" columnGap="16px" alignItems="flex-end">
        <HomeTabListItem
          label={f('かみやまch')}
          backgroundColor={bgColors[0]}
          borderColor={borderColors[0]}
          px="16px"
        />
        <HomeTabListItem
          label={f('お知らせ')}
          backgroundColor={bgColors[1]}
          borderColor={borderColors[1]}
          px="30px"
        />
        <HomeTabListItem
          label={f('防災無線')}
          backgroundColor={bgColors[2]}
          borderColor={borderColors[2]}
          px="20px"
        />
      </TabList>
      <TabPanels
        h="calc(100% - 70px)"
        borderWidth="4px"
        borderColor={borderColors[tabIndex]}
        borderRadius="10px"
        backgroundColor={bgColors[tabIndex]}
      >
        <HomePanel path="/dekigoto">
          <HomePanelThumbnailList>
            {(() => {
              if (dekigotoQuery.isLoading) {
                return <>Loading...{/* TODO タブ内のローディング */}</>
              }

              if (dekigotoQuery.isError) {
                return <HomePanelError />
              }

              if (dekigotoQuery.data.pages[0].count === 0) {
                return <>コンテンツがありません{/* TODO タブ内0件表示 */}</>
              }

              return dekigotoQuery.data.pages[0].contents
                .slice(0, maxCount)
                .map((content) => (
                  <HomePanelThumbnailListItem
                    key={content.id}
                    title={content.titleJa}
                    image={getYoutubeThumbnailUrl(content.dekigotoVideoUrl)}
                    link={`/dekigoto/${content.id}`}
                    date={getDateFormat(content.releaseAt)}
                    isReaded={content?.isRead}
                    isLiked={content?.isLiked}
                    contentId={content.id}
                  />
                ))
            })()}
          </HomePanelThumbnailList>
        </HomePanel>
        <HomePanel path="/kairan">
          <HomePanelList py={p2r(12)}>
            {(() => {
              if (kairanQuery.isLoading) {
                return <>Loading...{/* TODO タブ内のローディング */}</>
              }

              if (dekigotoQuery.isError) {
                return <HomePanelError />
              }

              if (kairanQuery.data?.pages[0].count === 0) {
                return <>コンテンツがありません{/* TODO タブ内0件表示 */}</>
              }

              return kairanQuery.data?.pages[0].contents
                .slice(0, maxCount)
                .map((content) => (
                  <HomePanelListItem
                    key={content.id}
                    date={getDateFormat(content.releaseAt)}
                    title={content.titleJa}
                    isShowLike
                    isLiked={content?.isLiked}
                    likeCount={content?.likeCount}
                    tags={[]}
                    contentId={content.id}
                    link={`/kairan/${content.id}`}
                    isReaded={content.isRead || false}
                  />
                ))
            })()}
          </HomePanelList>
        </HomePanel>

        <HomePanel path="/musen">
          <HomePanelList py={p2r(12)}>
            {(() => {
              if (musenQuery.isLoading) {
                return <>Loading...{/* TODO タブ内のローディング */}</>
              }

              if (musenQuery.isError) {
                return <HomePanelError />
              }

              if (musenQuery.data?.pages[0].count === 0) {
                return <>コンテンツがありません{/* TODO タブ内0件表示 */}</>
              }

              return musenQuery.data?.pages[0].contents
                .slice(0, maxCount)
                .map((content) => (
                  <HomePanelListItem
                    key={content.id}
                    title={content.titleJa}
                    date={getDateFormat(content.releaseAt)}
                    isShowLike
                    isLiked={content?.isLiked}
                    likeCount={content?.likeCount}
                    tags={[]}
                    soundPath={content.files?.[0].cloudFrontUrl || ''}
                    contentId={content.id}
                    isReaded={content.isRead || false}
                  />
                ))
            })()}
          </HomePanelList>
        </HomePanel>
      </TabPanels>
    </Tabs>
  )
}

/**
 * 右下のナビゲーション
 */

// ホーム表示用
const useSetContents = () => {
  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })
  return [
    {
      id: 1,
      path: '/taxi',
      title: f('予約'),
      imgPath: NavTaxi,
      bg: 'linear-gradient(360deg, #216542 0%, #144F35 100%)',
      border: 'rgba(33, 101, 66, 0.8)',
      iconColor: 'grand.white',
    },
    {
      id: 2,
      path: isMobile ? '/video/private' : '/video',
      title: f('ビデオ通話'),
      imgPath: NavVideo,
      bg: 'linear-gradient(360deg, #FF912B 0%, #FF7A00 100%)',
      border: 'rgba(255, 145, 43, 0.8)',
    },
    {
      id: 3,
      path: '/bousai',
      title: f('安心・安全'),
      imgPath: NavBousai,
      bg: 'linear-gradient(360deg, #1A82DD 0%, #0B74CF 100%)',
      border: 'rgba(26, 130, 221, 0.8)',
    },
    {
      id: 4,
      path: '/shikyo',
      title: f('市況情報'),
      imgPath: NavShikyo,
      bg: 'linear-gradient(0deg, #54CF51 0%, #46C21A 100%)',
      border: 'rgba(106, 223, 103, 0.8)',
    },
    {
      id: 5,
      path: '/notification',
      title: f('通知'),
      imgPath: NavNotification,
      bg: '#ffffff',
      border: 'rgba(245, 179, 80, 0.8)',
    },
    {
      id: 6,
      path: isMobile ? '/mypage' : '/mypage/profile',
      title: f('マイページ'),
      imgPath: NavMypage,
      bg: '#ffffff',
      border: 'none',
    },
    {
      id: 7,
      path: 'https://game.cir-cle.app/3x3.html',
      title: f('あそぶ・まなぶ'),
      imgPath: NavPractice,
      bg: '#ffffff',
      border: 'none',
    },
  ]
}

type HomeNavigationProps = {
  clickOpenModal?: () => void
}
const HomeNavigation: React.FC<HomeNavigationProps> = (props) => {
  const { clickOpenModal } = props
  const { userPersistConfig } = useUserPersistConfigContext()
  const navigationContents = useSetContents()

  const { f } = useFormatMessage()

  // フォントサイズ（大中小）ごとのカラム情報
  const COLUMNS_INFO: { [key in number]: any } = {
    // 小
    [UserConfigFontSize.SMALL]: {
      displayCount: 7,
      basic: {
        columns: 3,
        rowGap: '24px',
        columnGap: '16px',
      },
      imgW: '20vh',
      h: '15.4vh',
    },
    // 中
    [UserConfigFontSize.MEDIUM]: {
      displayCount: 5,
      basic: {
        columns: 2,
        rowGap: '2.9vh',
        columnGap: '27px',
      },
      imgW: '30vh',
      h: '21.1vh',
    },
    // 大
    [UserConfigFontSize.LARGE]: {
      displayCount: 2,
      basic: {
        columns: 1,
        rowGap: '24px',
        columnGap: '16px',
      },
      imgW: '65.7vh',
      h: '21.1vh',
    },
  }

  // 現在のフォントサイズから対応するカラム情報取得
  const columnsInfo = COLUMNS_INFO[userPersistConfig.cnfFontSize]

  const isMobile = useBreakpointValue({ base: true, md: false })

  // PCのみ表示個数でフィルター
  const formatNavigationContents = isMobile
    ? navigationContents
    : navigationContents.slice(0, columnsInfo.displayCount)

  return (
    <Grid
      gridTemplateColumns={{
        base: 'repeat(2, 1fr)',
        md: `repeat(${columnsInfo.basic.columns}, 1fr)`,
      }}
      columnGap={{
        base: '24px',
        md: `${columnsInfo.basic.columnGap}`,
      }}
      rowGap={{
        base: '8px',
        md: `${columnsInfo.basic.rowGap}`,
      }}
      h="fit-content"
    >
      {formatNavigationContents.map((content) => (
        <AppNavigationCard
          key={content.id}
          path={content.path}
          title={content.title}
          imgPath={content.imgPath}
          imgW={columnsInfo.imgW}
          h={columnsInfo.h}
          bg={content.bg}
          border={content.border}
          iconColor={content.iconColor || ''}
          isFontLarge={
            userPersistConfig.cnfFontSize === UserConfigFontSize.LARGE
          }
        />
      ))}
      {!isMobile && (
        <AppNavigationCard
          path="/"
          title={f('すべて')}
          imgPath={Navigation}
          imgW={columnsInfo.imgW}
          h={columnsInfo.h}
          bg="#ffffff"
          border="none"
          key="settings"
          onClick={clickOpenModal}
          isFontLarge={
            userPersistConfig.cnfFontSize === UserConfigFontSize.LARGE
          }
        />
      )}
    </Grid>
  )
}

/**
 * 緊急時TOPバナー
 */
type KinkyuBannerProps = {
  level: number
  onClose(): void
  title?: string
  date?: string
  contentId: number
}
const KinkyuBanner: React.FC<KinkyuBannerProps> = (props) => {
  const { f } = useFormatMessage()
  const navigate = useNavigate()

  // レベルをもとに色情報を取得
  const selectedColorInfo = LEVEL_COLOR_INFO?.[props.level - 1]

  return (
    <Flex
      px="32px"
      py="8px"
      justify="space-between"
      align="center"
      background={selectedColorInfo.background}
      boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
      backdropFilter="blur(20px)"
      borderRadius="16px"
      position="absolute"
      top="30px"
      left="50%"
      transform="translateX(-50%)"
      zIndex="9999"
      pos="fixed"
      flexDirection="row"
      w="calc(100% - 64px)"
      minH="140px"
    >
      <Flex direction="column">
        {/* <Text
          mt="0px"
          fontSize={p2r(24)}
          fontWeight="700"
          lineHeight="150%"
          color={selectedColorInfo.mainText}
        >
          警戒レベル{props.level}
        </Text> */}
        <Text
          fontSize={p2r(32)}
          fontWeight="700"
          lineHeight="150%"
          color={selectedColorInfo.mainText}
        >
          {props.title}
        </Text>
        <Text
          mt="4px"
          color={selectedColorInfo.subText}
          fontSize={p2r(16)}
          lineHeight="150%"
          fontWeight="400"
        >
          {props.date}
        </Text>
      </Flex>
      <Flex columnGap="32px" align="center">
        <Button
          p="16px"
          h="56px"
          display="flex"
          columnGap="4px"
          borderWidth={props.level === 1 ? '2px' : '0px'}
          borderColor="grand.grayLight"
          borderRadius="8px"
          variant="unstyled"
          backgroundColor="grand.white"
          onClick={() => {
            navigate(`/kinkyu/${props.contentId}`)
          }}
        >
          <Text fontSize={p2r(20)} color="text.headline" fontWeight="700">
            {f('詳細を確認する')}
          </Text>
          <Icon as={MdArrowForwardIos} boxSize="24px" color="text.headline" />
        </Button>
        <Button
          backgroundColor="grand.grayLight"
          w={p2r(44)}
          h={p2r(44)}
          borderRadius="50%"
          onClick={props.onClose}
        >
          <Icon as={MdOutlineClose} boxSize="24px" color="text.body" />
        </Button>
      </Flex>
    </Flex>
  )
}

/**
 * 新着通知
 */
const NotifyCard = () => {
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  return (
    <Box
      px="16px"
      py="8px"
      borderWidth="2px"
      borderColor="grand.grayLight"
      borderRadius="10px"
      backgroundColor="grand.white"
    >
      <Flex justify="space-between" align="center">
        <Flex columnGap="10px" align="center">
          <Text fontSize={p2r(16)} fontWeight="700">
            {f('新着通知')}
          </Text>
          <Text
            h={p2r(22)}
            px="8px"
            fontSize={p2r(14)}
            fontWeight="700"
            backgroundColor="theme.primary"
            color="text.white"
            borderRadius="20px"
            display="flex"
            alignItems="center"
          >
            他3件
          </Text>
        </Flex>
        <Button
          variant="ghost"
          h={p2r(24)}
          pr="0"
          onClick={() => navigate('/')}
        >
          <Flex align="center">
            <Text fontSize={p2r(16)} color="text.body">
              {f('すべて見る')}
            </Text>
            <Icon
              as={MdOutlineArrowForwardIos}
              boxSize={p2r(16)}
              color="text.body"
            />
          </Flex>
        </Button>
      </Flex>
      <Box mt="16px">
        <Flex mb="8px" columnGap="8px" align="center">
          <Icon as={MdCall} boxSize={p2r(24)} color="grand.gray" />
          <Text fontSize={p2r(16)} fontWeight="700">
            {f('不在着信')}
          </Text>
        </Flex>

        <Link to="/">
          <Flex align="center">
            <Text fontSize={p2r(16)} color="#757575">
              山田花子さんからの不在着信があります
            </Text>
            <Icon
              as={MdOutlineArrowForwardIos}
              boxSize={p2r(16)}
              color="#757575"
            />
          </Flex>
        </Link>
      </Box>
    </Box>
  )
}

type HomeSPContentListItemProps = {
  icon: React.FC
  title: string
  link: string
  children: ReactNode
}
const HomeSPContentListItem: React.FC<HomeSPContentListItemProps> = (props) => {
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  return (
    <Flex
      backgroundColor="grand.white"
      p="16px"
      direction="column"
      rowGap="16px"
      borderBottom="0.5px solid #e0e0e0"
    >
      <Flex justify="space-between" align="center">
        <Flex columnGap="8px" align="center">
          <Icon as={props.icon} boxSize={p2r(24)} color="text.body" />
          <Text fontSize={p2r(16)} color="text.body" fontWeight="700">
            {props.title}
          </Text>
        </Flex>
        <Button
          variant="ghost"
          h="24px"
          pr="0"
          onClick={() => navigate(props.link)}
        >
          <Flex align="center">
            <Text color="text.body" fontSize={p2r(16)}>
              {f('すべて見る')}
            </Text>
            <Icon
              as={MdOutlineArrowForwardIos}
              boxSize={p2r(16)}
              color="text.body"
            />
          </Flex>
        </Button>
      </Flex>
      <Box>{props.children}</Box>
    </Flex>
  )
}

/**
 * スマホ用コンテンツ一覧
 */
const HomeSPContentList: React.FC = () => {
  const { f } = useFormatMessage()

  const { me } = useAuthContext()

  type WeatherDataType = {
    date: Date
    wth: number
    temp: number
    rain_1h: number
    wind_d: number
    wind_s: number
    hum: number
  }

  const pointwfForecastQuery = usePointwfForecastQuery()

  const weatherDataList: Array<WeatherDataType> =
    pointwfForecastQuery.data?.data

  const weatherCords: number[] = []
  const temps: number[] = []

  const todayTempsQuantity = 22
  weatherDataList.slice(0, todayTempsQuantity).map((weatherData) => {
    weatherCords.push(weatherData.wth)
    temps.push(Math.round(weatherData.temp))
    return undefined
  })

  const dekigotoQuery = useContentListQuery('dekigoto', {
    pageSize: 1,
    page: 1,
    sort: 'releaseAtDesc',
  })

  const musenQuery = useContentListQuery('musen', {
    pageSize: 1,
    page: 1,
    withFiles: true,
    sort: 'releaseAtDesc',
    placeIds: me?.placeId ? [me.placeId] : [],
  })

  const kairanQuery = useContentListQuery('kairan', {
    pageSize: 1,
    page: 1,
    sort: 'releaseAtDesc',
  })

  const trashCalendars = useTrashCalendars()

  if (pointwfForecastQuery.isLoading) {
    return <AppLoading />
  }

  return (
    <Flex direction="column" rowGap="8px">
      <HomeSPContentListItem
        title={f('かみやまch')}
        icon={MdOutlineSlideshow}
        link="/dekigoto"
      >
        {(() => {
          if (dekigotoQuery.isLoading) {
            return <>Loading...</>
          }

          // if (dekigotoQuery.isError) {
          //   return <HomePanelError />
          // }

          if (!dekigotoQuery.data?.pages.length) {
            return <>コンテンツがありません</>
          }

          return dekigotoQuery.data?.pages[0].contents.map((content) => (
            <>
              <Link to={`/dekigoto/${content.id}`}>
                <AspectRatio ratio={12 / 7} flex="1 1 auto" h="190px">
                  <Box position="relative" w="100%">
                    {/* <Box
                      background="linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))"
                      backgroundSize="cover"
                      backgroundPosition="center"
                      backgroundRepeat="no-repeat"
                      position="absolute"
                      w="100%"
                      h="100%"
                      zIndex="10"
                    /> */}

                    <Image
                      src={getYoutubeThumbnailUrl(content.dekigotoVideoUrl)}
                      fallbackSrc="/noimage.png"
                      w="100%"
                      h="100%"
                      objectFit="cover"
                      background="linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))"
                      backgroundSize="cover"
                      backgroundPosition="center"
                      backgroundRepeat="no-repeat"
                    />

                    {/* <Icon
                      as={MdPlayArrow}
                      boxSize={p2r(64)}
                      color="grand.white"
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      zIndex="15"
                    /> */}
                  </Box>
                </AspectRatio>
                <Box mt="8px">
                  <Text color="text.sub">
                    {getDateFormat(content.updatedAt)}
                  </Text>
                  <Text
                    fontWeight="700"
                    fontSize={p2r(24)}
                    lineHeight="150%"
                    noOfLines={2}
                  >
                    {content.titleJa}
                  </Text>
                </Box>
              </Link>
              <AppLikeButton
                mt="4px"
                isLiked={content?.isLiked}
                contentId={content.id}
              />
            </>
          ))
        })()}
      </HomeSPContentListItem>
      <HomeSPContentListItem
        title={f('防災無線')}
        icon={MdCampaign}
        link="/musen"
      >
        {(() => {
          if (musenQuery.isLoading) {
            return <>Loading...</>
          }

          // if (musenQuery.isError) {
          //   return <HomePanelError />
          // }

          if (!musenQuery.data?.pages.length) {
            return <>コンテンツがありません</>
          }

          return musenQuery.data?.pages[0].contents.map((content) => (
            <Flex align="center" columnGap="16px">
              <Box flex="1">
                <Text fontSize={p2r(16)} color="#9e9e9e" lineHeight="1">
                  {getDateFormat(content.updatedAt)}
                </Text>
                <Text
                  fontSize={p2r(24)}
                  lineHeight="150%"
                  fontWeight="700"
                  noOfLines={3}
                  color="text.body"
                >
                  {content.titleJa}
                </Text>
                <AppLikeButton
                  mt="8px"
                  isLiked={content?.isLiked}
                  contentId={content.id}
                />
              </Box>
              <AppSoundPlayButton
                soundPath={content.files?.[0].cloudFrontUrl || ''}
                height="52px"
                px="16px"
                py="14px"
                fontSize={p2r(16)}
              />
            </Flex>
          ))
        })()}
      </HomeSPContentListItem>
      <HomeSPContentListItem
        title={f('まちのお知らせ')}
        icon={Note}
        link="/kairan"
      >
        {(() => {
          if (kairanQuery.isLoading) {
            return <>Loading...</>
          }

          // if (dekigotoQuery.isError) {
          //   return <HomePanelError />
          // }

          if (!kairanQuery.data?.pages.length) {
            return <>コンテンツがありません</>
          }

          return kairanQuery.data?.pages[0].contents.map((content) => (
            <>
              <Link to={`/kairan/${content.id}`}>
                <Text fontSize={p2r(16)} color="#9e9e9e" lineHeight="1">
                  {getDateFormat(content.updatedAt)}
                </Text>
                <Text
                  fontSize={p2r(24)}
                  lineHeight="150%"
                  fontWeight="700"
                  noOfLines={3}
                  color="text.body"
                >
                  {content.titleJa}
                </Text>
              </Link>
              <AppLikeButton
                isLiked={content?.isLiked}
                contentId={content.id}
              />
            </>
          ))
        })()}
      </HomeSPContentListItem>
      <HomeSPContentListItem
        title={f('天気')}
        icon={MdOutlineWbSunny}
        link="/weather/forecast"
      >
        <Flex align="center" columnGap="16px">
          <Box flex="1">
            <Text
              fontSize={p2r(16)}
              color="text.body"
              lineHeight="150%"
              fontWeight="bold"
            >
              {WeatherTitleMap[weatherCords[0]]}
            </Text>
            <Flex mt="4px" columnGap="16px">
              <Text fontSize={p2r(14)} color="text.body">
                {Math.max(...temps)}℃
              </Text>
              <Text fontSize={p2r(14)} color="text.body">
                {Math.min(...temps)}℃
              </Text>
            </Flex>
          </Box>
          <Icon as={WeatherCloudySunny} boxSize="64px" />
        </Flex>
      </HomeSPContentListItem>
      {/* TODO 正式リリースまで本番で非表示 */}
      <HomeSPContentListItem
        title={f('ゴミ出し')}
        icon={MdDelete}
        link="/trash/latest"
      >
        <Flex align="center" columnGap="51px">
          {trashCalendars?.[0] && (
            <Flex align="center" columnGap="16px">
              <Box flex="1">
                <Text fontSize={p2r(12)} color="text.sub">
                  {dayjs(trashCalendars[0].date).format('M月DD日(ddd)')}
                </Text>
                <Text fontSize={p2r(14)} color="text.body" fontWeight="700">
                  {trashCalendars[0].trashType}
                </Text>
              </Box>
              <TrashCalendarTypeIcon
                trashType={trashCalendars[0].trashType || ''}
                boxSize="48px"
                borderRadius="10px"
              />
            </Flex>
          )}

          {trashCalendars?.[1] && (
            <Flex align="center" columnGap="16px">
              <Box flex="1">
                <Text fontSize={p2r(12)} color="text.sub">
                  {dayjs(trashCalendars[1].date).format('MM月DD日(ddd)')}
                </Text>
                <Text fontSize={p2r(14)} color="text.body" fontWeight="700">
                  {trashCalendars[1].trashType}
                </Text>
              </Box>
              <TrashCalendarTypeIcon
                trashType={trashCalendars[1].trashType || ''}
                boxSize="48px"
                borderRadius="10px"
              />
            </Flex>
          )}
        </Flex>
      </HomeSPContentListItem>
      <HomeSPContentListItem
        title={f('安心・安全')}
        icon={NavBousai}
        link="/bousai"
      >
        <Flex columnGap="16px" direction="column">
          <Text mb="8px" fontSize={p2r(12)} color="#9d9d9d" lineHeight="1">
            {f('災害時のために確認しておきましょう')}
          </Text>
          <Link to="/">
            <Flex align="center" columnGap="10px">
              <Text
                fontSize={p2r(24)}
                color="#3a3a3a"
                letterSpacing="0.05em"
                fontWeight="700"
                display="inline-block"
              >
                {f('広野地区土砂災害ハザードマップ')}
                <Icon
                  ml="4px"
                  as={MdOpenInNew}
                  boxSize={p2r(24)}
                  lineHeight="150%"
                  color="theme.primaryDark"
                  verticalAlign="middle"
                />
              </Text>
            </Flex>
          </Link>
        </Flex>
      </HomeSPContentListItem>
    </Flex>
  )
}

const Home: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  const hasHeader = !!isMobile
  useHeaderConfig({
    title: 'ホーム',
    show: hasHeader,
    showKinkyuBanner: false,
  })

  const { f } = useFormatMessage()

  // 警戒レベルの表示有無
  const { userConfig } = useUserTmpConfig()

  // モーダル
  const contentMenuDisclosure = useDisclosure()

  // 天気情報取得
  type WeatherDataType = {
    date: Date
    wth: number
    temp: number
    rain_1h: number
    wind_d: number
    wind_s: number
    hum: number
  }

  const { isShowKinkyuBanner, kinkyuContent, onClickCloseKinkyuBanner } =
    useKinkyuBanner()

  const pointwfForecastQuery = usePointwfForecastQuery()

  const weatherDataList: Array<WeatherDataType> =
    pointwfForecastQuery.data?.data

  if (pointwfForecastQuery.isLoading) {
    return <AppLoading />
  }

  const weatherCords: number[] = []
  const temps: number[] = []

  const todayTempsQuantity = 22
  weatherDataList.slice(0, todayTempsQuantity).map((weatherData) => {
    weatherCords.push(weatherData.wth)
    temps.push(Math.round(weatherData.temp))
    return undefined
  })

  return (
    <>
      <Box
        minH={{ base: 'unset', md: '100vh' }}
        backgroundColor="theme.background"
      >
        {isShowKinkyuBanner && kinkyuContent && !isMobile && (
          <KinkyuBanner
            contentId={kinkyuContent.id}
            level={kinkyuContent?.kinkyuLevel || 1}
            title={kinkyuContent.titleJa}
            date={dayjs(kinkyuContent.releaseAt).format('M月D日 HH:mm 発表')}
            onClose={onClickCloseKinkyuBanner}
          />
        )}
        {isMobile && (
          <Box backgroundColor="theme.background" pb="16px">
            {/* 表示タイプA */}
            {userConfig.homeTypeMobile === UserConfigHomeTypeMobile.TYPE_A && (
              <Box px="16px" py="8px">
                <HomeHeadItem
                  label={f('カレンダー')}
                  path="/calendar"
                  w="100%"
                  contentBg="grand.white"
                >
                  <HomeCalendar />
                </HomeHeadItem>
                <Box p="8px">
                  <HomeNavigation />
                </Box>
              </Box>
            )}
            {/* 表示タイプB */}
            {userConfig.homeTypeMobile === UserConfigHomeTypeMobile.TYPE_B && (
              <>
                {/* TODO 新着通知 */}
                {false && (
                  <Box p="16px">
                    <NotifyCard />
                  </Box>
                )}
                <HomeSPContentList />
              </>
            )}
          </Box>
        )}
        {!isMobile && (
          <Box minH="100vh" backgroundColor="theme.background">
            <Box px="40px" py="4.8vh" position="relative">
              {/* Head */}
              <Flex gap="24px" justify="center">
                {/* カレンダー */}
                <HomeHeadItem
                  label={f('カレンダー')}
                  path="/calendar"
                  w="37.7vh"
                  backdropFilter="blur(4px)"
                  position="relative"
                  contentBg="grand.white"
                  _after={{
                    content: '""',
                    position: 'absolute',
                    zIndex: '-1',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%',
                    background: '#DFE1E4',
                    borderRadius: '12px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <HomeCalendar />
                </HomeHeadItem>
                {/* 天気 */}
                <HomeHeadItem
                  label={f('天気')}
                  path="/weather/time"
                  w="59.8vh"
                  backdropFilter="blur(4px)"
                  position="relative"
                  contentBg="linear-gradient(180deg, #3087D5 0%, #62AAEA 100%)"
                  _after={{
                    content: '""',
                    position: 'absolute',
                    zIndex: '-1',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%',
                    background:
                      'linear-gradient(180deg, rgba(96, 167, 230, 0.8) 0%, rgba(97, 170, 235, 0.8) 100%)',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <HomeWeather
                    title={WeatherTitleMap[weatherCords[0]]}
                    max={Math.max(...temps)}
                    min={Math.min(...temps)}
                    icon={WeatherIconMap[weatherCords[0]]}
                  />
                </HomeHeadItem>
                {/* ゴミ出し */}
                <HomeHeadItem
                  label={f('ゴミ出し')}
                  path="/trash/latest"
                  w="58.7vh"
                  backdropFilter="blur(4px)"
                  position="relative"
                  contentBg="linear-gradient(180deg, #E0E0E0 0%, #F0F0F0 100%)"
                  _after={{
                    content: '""',
                    position: 'absolute',
                    zIndex: '-1',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%',
                    background: 'rgba(223, 225, 228, 0.8)',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <HomeTrash />
                </HomeHeadItem>
              </Flex>

              {/* Main */}
              <Flex mt="3.2vh" gap="24px" justify="center">
                <HomeTabs />
                <HomeNavigation clickOpenModal={contentMenuDisclosure.onOpen} />
              </Flex>
            </Box>
          </Box>
        )}
      </Box>
      {!isMobile && (
        <AppContentMenuModal
          isOpen={contentMenuDisclosure.isOpen}
          onClose={contentMenuDisclosure.onClose}
        />
      )}
    </>
  )
}

export default Home
