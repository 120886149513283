import { Text } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

export type TaxiPointHeadingProps = {
  label: string
}
export const TaxiPointHeading: React.FC<TaxiPointHeadingProps> = ({
  label,
}) => (
  <Text
    px="16px"
    py="8px"
    backgroundColor="grand.grayLight"
    fontSize={p2r(16)}
    fontWeight="700"
    lineHeight="150%"
    color="text.sub"
  >
    {label}
  </Text>
)
