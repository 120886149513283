import { Flex, Icon, Text, Grid } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React from 'react'
import { AppLoading } from '../../components/common/AppLoading'
import WeatherIconMap from '../../components/weather/WeatherIconMap'
import useFormatMessage from '../../hooks/useFormatMessage'
import {
  useWth10ForecastQuery,
  WeekDataType,
} from '../../hooks/weatherApi/useDailyForecastQuery'
import { p2r } from '../../utils/font'

// 前日の気温との差分
const diff = (temperature: string) =>
  Number(temperature) >= 1 ? `+${temperature}` : String(temperature)
export type WeeklyCardProps = {
  date: string | number
  day: string
  icon: React.FC
  max: number
  min: number
  maxDiff: string
  minDiff: string
  rainyPercents: number
}
const WeeklyCard: React.FC<WeeklyCardProps> = (props) => {
  const maxDiff = diff(props.maxDiff)
  const minDiff = diff(props.minDiff)

  return (
    <Flex
      borderColor="grand.grayLight"
      borderWidth="1px"
      borderRadius="10px"
      direction={{ base: 'row', md: 'column' }}
      py={{ base: '8px', md: '2.8vh' }}
      px={{ base: 'unset', md: '18px' }}
      rowGap={{ base: 'unset', md: '2.8vh' }}
      rounded={{ base: '4px', md: '10px' }}
      bgColor={{ base: 'grand.white', md: 'unset' }}
    >
      <Flex
        h={{ base: 'unset', md: '12vh' }}
        w={{ base: '69.3px', md: 'unset' }}
        color="text.headline"
        fontWeight="700"
        alignItems="center"
        direction={{ base: 'row', md: 'column' }}
        justify="center"
        lineHeight="150%"
      >
        <Text fontSize={{ base: p2r(14), md: p2r(24) }} lineHeight="150%">
          {props.date}
        </Text>
        <Text fontSize={{ base: p2r(14), md: p2r(24) }} lineHeight="150%">
          ({props.day})
        </Text>
      </Flex>
      <Flex
        h={{ base: 'unset', md: '12vh' }}
        w={{ base: '69.3px', md: 'unset' }}
        justify="center"
        alignItems="center"
      >
        <Icon
          as={props.icon}
          boxSize={{ base: '40px', md: '9.6vh' }}
          rounded="10px"
        />
      </Flex>
      <Flex
        h={{ base: 'unset', md: '12vh' }}
        w={{ base: '135px', md: 'unset' }}
        alignItems="center"
        direction={{ base: 'row', md: 'column' }}
        justify={{ base: 'space-around', md: 'center' }}
        rowGap={{ base: 'unset', md: '8px' }}
      >
        <Flex alignItems={{ base: 'flex-start', md: 'baseline' }}>
          <Text
            fontSize={{ base: p2r(12), md: p2r(24) }}
            color="grand.error"
            fontWeight="700"
            lineHeight="100%"
          >
            {props.max}
          </Text>
          <Text
            fontSize={{ base: p2r(10), md: p2r(18) }}
            color="grand.error"
            fontWeight="400"
            lineHeight="100%"
          >
            ℃[{maxDiff}]
          </Text>
        </Flex>
        <Flex alignItems={{ base: 'flex-start', md: 'baseline' }}>
          <Text
            fontSize={{ base: p2r(12), md: p2r(24) }}
            color="grand.blueLight"
            fontWeight="700"
            lineHeight="100%"
          >
            {props.min}
          </Text>
          <Text
            fontSize={{ base: p2r(10), md: p2r(18) }}
            color="grand.blueLight"
            fontWeight="400"
            lineHeight="100%"
          >
            ℃[{minDiff}]
          </Text>
        </Flex>
      </Flex>
      <Flex
        h={{ base: 'unset', md: '12vh' }}
        w={{ base: '69.3px', md: 'unset' }}
        alignItems="center"
        justify="center"
      >
        <Text
          color="text.headline"
          fontWeight="700"
          fontSize={{ base: p2r(14), md: p2r(24) }}
        >
          {props.rainyPercents}
        </Text>
        <Text
          color="text.headline"
          fontWeight="400"
          fontSize={{ base: p2r(14), md: p2r(20) }}
        >
          %
        </Text>
      </Flex>
    </Flex>
  )
}

const WeatherWeekly: React.FC = () => {
  const { f } = useFormatMessage()
  const digit = 0

  const wth10ForecastQuery = useWth10ForecastQuery()

  const weekDataList: Array<WeekDataType> = wth10ForecastQuery.data?.daily

  if (wth10ForecastQuery.isLoading) {
    return <AppLoading />
  }

  const dates: Date[] = []
  const rainyPercents: number[] = []
  const maxTemps: number[] = []
  const minTemps: number[] = []
  const weatherCords: number[] = []
  const wthw: string[] = []

  weekDataList.map((weekData) => {
    dates.push(weekData.date)
    rainyPercents.push(weekData.r_prob)
    maxTemps.push(Math.round(weekData.temp_max))
    minTemps.push(Math.round(weekData.temp_min))
    weatherCords.push(weekData.wthc)
    wthw.push(weekData.wthw)
    return undefined
  })

  return (
    <Flex>
      <Flex
        justify="center"
        py={{ base: 'unset', md: '8.6vh' }}
        columnGap={{ base: 'unset', md: '16px' }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Flex
          direction={{ base: 'row', md: 'column' }}
          justify="center"
          rowGap={{ base: 'unset', md: '2.8vh' }}
          py={{ base: '8px', md: 'unset' }}
        >
          <Flex
            h={{ base: 'unset', md: '12vh' }}
            w={{ base: '68.6px', md: 'unset' }}
            alignItems="center"
            justify={{ base: 'center', md: 'unset' }}
          >
            <Text fontSize={{ base: p2r(12), md: p2r(22) }} color="text.sub">
              {f('日')}
            </Text>
          </Flex>

          <Flex
            h={{ base: 'unset', md: '12vh' }}
            w={{ base: '68.6px', md: 'unset' }}
            alignItems="center"
            justify={{ base: 'center', md: 'unset' }}
          >
            <Text fontSize={{ base: p2r(12), md: p2r(22) }} color="text.sub">
              {f('天気')}
            </Text>
          </Flex>

          <Flex
            h={{ base: 'unset', md: '12vh' }}
            w={{ base: '135px', md: 'unset' }}
            alignItems="center"
            direction={{ base: 'row', md: 'column' }}
            justify="center"
            rowGap={{ base: 'unset', md: '8px' }}
            columnGap={{ base: '16px', md: 'unset' }}
          >
            <Text fontSize={{ base: p2r(12), md: p2r(16) }} color="text.sub">
              {f('最高気温')}
            </Text>
            <Text fontSize={{ base: p2r(12), md: p2r(16) }} color="text.sub">
              {f('最低気温')}
            </Text>
          </Flex>

          <Flex
            h={{ base: 'unset', md: '12vh' }}
            w={{ base: '68.6px', md: 'unset' }}
            alignItems="center"
            justify={{ base: 'center', md: 'unset' }}
          >
            <Text fontSize={{ base: p2r(12), md: p2r(22) }} color="text.sub">
              {f('降水')}
            </Text>
          </Flex>
        </Flex>

        <Grid
          gap="8px"
          templateColumns={{ base: 'unset', md: 'repeat(7, 1fr)' }}
          flex="1"
        >
          <WeeklyCard
            date={dayjs(dates[0]).format('D')}
            day={f(dayjs(dates[0]).format('ddd'))}
            icon={WeatherIconMap[weatherCords[0]]}
            max={maxTemps[0]}
            maxDiff="-"
            min={minTemps[0]}
            minDiff="-"
            rainyPercents={rainyPercents[0]}
          />
          <WeeklyCard
            date={dayjs(dates[1]).format('D')}
            day={f(dayjs(dates[1]).format('ddd'))}
            icon={WeatherIconMap[weatherCords[1]]}
            max={maxTemps[1]}
            maxDiff={(maxTemps[1] - maxTemps[0]).toFixed(digit)}
            min={minTemps[1]}
            minDiff={(minTemps[1] - minTemps[0]).toFixed(digit)}
            rainyPercents={rainyPercents[1]}
          />
          <WeeklyCard
            date={dayjs(dates[2]).format('D')}
            day={f(dayjs(dates[2]).format('ddd'))}
            icon={WeatherIconMap[weatherCords[2]]}
            max={maxTemps[2]}
            maxDiff={(maxTemps[2] - maxTemps[1]).toFixed(digit)}
            min={minTemps[2]}
            minDiff={(minTemps[2] - minTemps[1]).toFixed(digit)}
            rainyPercents={rainyPercents[2]}
          />
          <WeeklyCard
            date={dayjs(dates[3]).format('D')}
            day={f(dayjs(dates[3]).format('ddd'))}
            icon={WeatherIconMap[weatherCords[3]]}
            max={maxTemps[3]}
            maxDiff={(maxTemps[3] - maxTemps[2]).toFixed(digit)}
            min={minTemps[3]}
            minDiff={(minTemps[3] - minTemps[2]).toFixed(digit)}
            rainyPercents={rainyPercents[3]}
          />
          <WeeklyCard
            date={dayjs(dates[4]).format('D')}
            day={f(dayjs(dates[4]).format('ddd'))}
            icon={WeatherIconMap[weatherCords[4]]}
            max={maxTemps[4]}
            maxDiff={(maxTemps[4] - maxTemps[3]).toFixed(digit)}
            min={minTemps[4]}
            minDiff={(minTemps[4] - minTemps[3]).toFixed(digit)}
            rainyPercents={rainyPercents[4]}
          />
          <WeeklyCard
            date={dayjs(dates[5]).format('D')}
            day={f(dayjs(dates[5]).format('ddd'))}
            icon={WeatherIconMap[weatherCords[5]]}
            max={maxTemps[5]}
            maxDiff={(maxTemps[5] - maxTemps[4]).toFixed(digit)}
            min={minTemps[5]}
            minDiff={(minTemps[5] - minTemps[4]).toFixed(digit)}
            rainyPercents={rainyPercents[5]}
          />
          <WeeklyCard
            date={dayjs(dates[6]).format('D')}
            day={f(dayjs(dates[6]).format('ddd'))}
            icon={WeatherIconMap[weatherCords[6]]}
            max={maxTemps[6]}
            maxDiff={(maxTemps[6] - maxTemps[5]).toFixed(digit)}
            min={minTemps[6]}
            minDiff={(minTemps[6] - minTemps[5]).toFixed(digit)}
            rainyPercents={rainyPercents[6]}
          />
        </Grid>
      </Flex>
      <Flex
        display="block"
        alignItems="left"
        gap={p2r(4)}
        fontSize={p2r(16)}
        fontWeight="700"
        margin="10px"
        position="absolute"
        left="0px"
        bottom="0px"
      >
        情報提供元 : 気象庁 / 株式会社フランクリン・ジャパン
      </Flex>
    </Flex>
  )
}

export default WeatherWeekly
