import { Text, Flex, Icon, Box, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { MdSettings, MdSearch } from 'react-icons/md'
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom'
import { AppSegmentedPicker } from '../../components/common/AppSegmentedPicker'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { p2r } from '../../utils/font'

const useSetTrashButtons = () => {
  const { f } = useFormatMessage()
  return [
    {
      id: 1,
      path: '/trash/latest',
      title: f('直近のゴミ出し'),
    },
    {
      id: 2,
      path: '/trash/calendar',
      title: f('1ヶ月カレンダー'),
    },
  ]
}

// buttonのコンポーネント
type TrashLinkProps = {
  path: string
  label: string
  isActive: boolean
}
const TrashLink: React.FC<TrashLinkProps> = (props) => (
  <Link to={props.path}>
    <Text
      fontSize={p2r(24)}
      fontWeight="700"
      color={props.isActive ? 'text.white' : 'text.sub'}
      bgColor={props.isActive ? 'theme.primary' : 'grand.graySuperLight'}
      p="3px 67px"
      rounded="10"
    >
      {props.label}
    </Text>
  </Link>
)

// Icon付きLinkのコンポーネント
type IconLinkProps = {
  path: string
  icon: React.FC
  label: string
}
const IconLink: React.FC<IconLinkProps> = (props) => (
  <Link to={props.path}>
    <Flex alignItems="center" gap="8px" color="grand.gray">
      <Icon as={props.icon} boxSize="32px" />
      <Text fontSize={p2r(24)} fontWeight="700" color="text.body">
        {props.label}
      </Text>
    </Flex>
  </Link>
)

// Icon付きLinkのコンポーネント
type IconLinkWithHrefProps = {
  href: string
  icon: React.FC
  label: string
  target?: string
}
const IconLinkWithHref: React.FC<IconLinkWithHrefProps> = (props) => (
  <a href={props.href} target={props.target}>
    <Flex alignItems="center" gap="8px" color="grand.gray">
      <Icon as={props.icon} boxSize="32px" />
      <Text fontSize={p2r(24)} fontWeight="700" color="text.body">
        {props.label}
      </Text>
    </Flex>
  </a>
)

// 通知設定とゴミ品目詳細表示のコンポーネント
const Trash: React.FC = () => {
  useHeaderConfig({
    title: 'ゴミ出し',
  })
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { f } = useFormatMessage()
  const TrashButtonMenu = useSetTrashButtons()
  const location = useLocation()
  const currentPath = location.pathname
  if (currentPath === '/trash') {
    return <Navigate to="/trash/latest" replace />
  }

  return (
    <>
      {isMobile ? (
        <Box
          pb="8px"
          px="16px"
          borderBottom="solid 1px"
          borderColor="grand.grayLight"
        >
          <AppSegmentedPicker
            info={[
              {
                label: '直近のゴミ出し',
                link: '/trash/latest',
                isActive: currentPath === '/trash/latest',
              },
              {
                label: '1ヶ月カレンダー',
                link: '/trash/calendar',
                isActive: currentPath === '/trash/calendar',
              },
            ]}
          />
        </Box>
      ) : (
        <Flex p="14px 32px" justify="space-between" alignItems="center">
          <Flex>
            {TrashButtonMenu.map((item) => (
              <TrashLink
                key={item.id}
                path={item.path}
                label={item.title}
                isActive={currentPath === item.path}
              />
            ))}
          </Flex>
          <Flex gap="40px">
            <IconLink
              path="/mypage/notification"
              icon={MdSettings}
              label={f('通知設定')}
            />
            <IconLinkWithHref
              href="https://www.town.kamiyama.lg.jp/office/juumin/environment/trash.html"
              icon={MdSearch}
              label={f('ゴミ品目詳細表示')}
            />
          </Flex>
        </Flex>
      )}
      <Box minH="calc(100vh - 130px)" bgColor="theme.background">
        <Outlet />
      </Box>
    </>
  )
}

export default Trash
