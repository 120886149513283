import { useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { Navigate } from 'react-router-dom'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import MypageMenu from '../MypageMenu'

const Mypage: React.FC = () => {
  useHeaderConfig({
    title: 'マイページ',
  })
  const isMobile = useBreakpointValue({ base: true, md: false })

  // PCで/アクセスの場合はプロフィールページへ遷移
  if (!isMobile) {
    return <Navigate to="/mypage/profile" replace />
  }
  return <MypageMenu />
}

export default Mypage
