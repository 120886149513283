import { SearchIcon } from '@chakra-ui/icons'
import {
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  Select,
  Box,
} from '@chakra-ui/react'
import _ from 'lodash'
import queryString from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom'
import { ContentType } from '../../../hooks/coreApi/useContentListQuery'
import { useContentTagListQuery } from '../../../hooks/coreApi/useContentTagListQuery'
import { usePlaceListQuery } from '../../../hooks/coreApi/usePlaceListQuery'
import { p2r } from '../../../utils/font'

type SearchParamsType = {
  year: string
  placeId: string
  tag: string
  search: string
}

type Props = {
  contentType: ContentType
  isShowArea?: boolean
  isShowTag?: boolean
  isShowYear?: boolean
  defaultValues: SearchParamsType
}

const inputLabel = (contentType: ContentType) => {
  // TASK contentTypeで判定をしてplaceholderのテキストを変更する
  switch (contentType) {
    case 'dekigoto':
      return 'かみやまchを探す'
    case 'musen':
      return '防災無線を探す'
    case 'kairan':
      return 'お知らせを探す'
    case 'bousai':
      return '防災情報を探す'
    default:
      return '探す'
  }
}

const AppSearchHeader: React.FC<Props> = ({
  contentType,
  isShowArea = false,
  isShowTag = false,
  isShowYear = false,
  defaultValues,
}) => {
  const { search: queryStringSearch, pathname } = useLocation()
  const navigate = useNavigate()
  const [path, setPath] = useState<string>(pathname)
  const [searchParams, setSearchParams] =
    useState<SearchParamsType>(defaultValues)

  const placeQuery = usePlaceListQuery({})

  const resetParams = () => {
    setSearchParams(defaultValues)
  }

  useEffect(() => {
    const query = queryString.parse(queryStringSearch)
    if (!_.isEmpty(query)) {
      setSearchParams({
        ...searchParams,
        year: query.year ? query.year.toString() : '',
        placeId: query.placeId ? query.placeId.toString() : '',
        search: query.search ? query.search.toString() : '',
        tag: query.tag ? (query.tag as string) : '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (path === '' || path === pathname) {
      if (_.isEmpty(searchParams)) return
      navigate(
        {
          pathname,
          search: `?${createSearchParams(searchParams)}`,
        },
        {
          replace: true,
        },
      )
    } else {
      resetParams()
    }
    setPath(pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, navigate, searchParams])

  const contentTags = useContentTagListQuery(contentType, {})
  const tagOptions: { label: string; value: string }[] = useMemo(() => {
    if (!contentTags.data) return []
    return contentTags.data.contentTags.map((contentTag) => ({
      label: contentTag.label,
      value: contentTag.label,
    }))
  }, [contentTags])

  return (
    <Box
      m="auto"
      bg={{ base: 'grand.white', md: 'theme.background' }}
      p={{ base: '8px 16px', md: '15px' }}
    >
      <Flex
        as="header"
        justifyContent="space-between"
        maxW="1194px"
        m="auto"
        direction={{ base: 'column', md: 'row' }}
        rowGap={{ base: '8px', md: '0px' }}
      >
        <Flex columnGap={{ base: 'unset', md: '16px' }}>
          <InputGroup maxW={{ base: '100%', md: '314px' }}>
            <InputLeftElement cursor="pointer">
              <SearchIcon color="#DFE1E4" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder={inputLabel(contentType)}
              _placeholder={{ color: '#DFE1E4' }}
              bg="#fff"
              borderRadius="8px"
              borderColor="grand.grayLight"
              defaultValue={searchParams.search}
              onBlur={(e) => {
                setSearchParams({
                  ...searchParams,
                  search: e.target.value,
                })
              }}
            />
          </InputGroup>
        </Flex>
        <Flex columnGap={{ base: '8px', md: '16px' }}>
          {isShowTag && (
            <Select
              w={{ base: '140px', md: '160px' }}
              h={{ base: '32px', md: '40px' }}
              fontWeight="700"
              bg="theme.primary"
              color="#fff"
              rounded="8px"
              fontSize={{ base: p2r(12), md: p2r(14) }}
              value={searchParams.tag}
              onChange={(e) =>
                setSearchParams({ ...searchParams, tag: e.target.value })
              }
            >
              <option value="">カテゴリを選択</option>
              {tagOptions.map((tag) => (
                <option key={tag.value} value={tag.value}>
                  {tag.label}
                </option>
              ))}
            </Select>
          )}
          {isShowArea && (
            <Select
              w={{ base: '120px', md: '140px' }}
              h={{ base: '32px', md: '40px' }}
              placeholder="エリアを選択"
              fontWeight="700"
              bg="theme.primary"
              color="#fff"
              rounded="8px"
              fontSize={{ base: p2r(12), md: p2r(14) }}
              value={searchParams.placeId}
              onChange={(e) =>
                setSearchParams({ ...searchParams, placeId: e.target.value })
              }
            >
              {placeQuery?.data?.places.map((place) => (
                <option key={place.id} value={place.id}>
                  {place.name}
                </option>
              ))}
            </Select>
          )}

          {isShowYear && (
            <Select
              maxW="110px"
              h={{ base: '32px', md: '40px' }}
              fontWeight="700"
              bg="theme.primary"
              color="#fff"
              fontSize={{ base: p2r(12), md: p2r(14) }}
              order={{ base: '-1', md: 'unset' }}
              rounded="8px"
              value={searchParams.year}
              onChange={(e) =>
                setSearchParams({ ...searchParams, year: e.target.value })
              }
            >
              {/* 2022 〜 現在年 */}
              <option value="">年を選択</option>
              {_.range(2022, new Date().getFullYear() + 1)
                .reverse()
                .map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
            </Select>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default AppSearchHeader
