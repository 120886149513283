import {
  Button,
  HStack,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react'
import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import useNativeApp from '../../hooks/useNativeApp'
import useUserTmpConfig from '../../hooks/useUserTmpConfig'
import {
  UserConfigHomeTypeMobile,
  UserConfigHomeTypeMobileType,
} from '../../models/user'
import { getIdToken, getRefreshToken } from '../../utils/auth'
import { isAndroidNative, isIOSNative } from '../../utils/nativeApp'

const Debug: React.FC = () => {
  useHeaderConfig({
    title: 'デバッグ',
  })

  const nativeApp = useNativeApp()

  const { userConfig, setUserConfig } = useUserTmpConfig()
  const [roomId, setRoomId] = useState(1)

  return (
    <Table variant="simple">
      <Tbody>
        <Tr>
          <Td>環境変数</Td>
          <Td>{JSON.stringify(process.env)}</Td>
        </Tr>
        <Tr>
          <Td>User Agent</Td>
          <Td>
            {window.navigator.userAgent}
            (iOS: {isIOSNative() ? 'true' : 'false'}, Android:
            {isAndroidNative() ? 'true' : 'false'})
          </Td>
        </Tr>
        <Tr>
          <Td>認証用 TOKEN</Td>
          <Td>{getIdToken()}</Td>
        </Tr>
        <Tr>
          <Td>リフレッシュトークン</Td>
          <Td>{getRefreshToken()}</Td>
        </Tr>
        <Tr>
          <Td>スマホ：ホーム表示タイプ</Td>
          <Td>
            <HStack>
              <Select
                width="20"
                defaultValue={userConfig.homeTypeMobile}
                onChange={(evt) => {
                  setUserConfig({
                    homeTypeMobile: Number(
                      evt.target.value,
                    ) as UserConfigHomeTypeMobileType,
                  })
                }}
              >
                <option value={UserConfigHomeTypeMobile.TYPE_A}>A</option>
                <option value={UserConfigHomeTypeMobile.TYPE_B}>B</option>
              </Select>
            </HStack>
          </Td>
        </Tr>
        <Tr>
          <Td>userConfig</Td>
          <Td>
            <>userConfig(state): {JSON.stringify(userConfig)}</>
            <br />
            <>userConfig(cookie): {Cookies.get('userConfig')}</>
          </Td>
        </Tr>
        <Tr>
          <Td>userConfig同期</Td>
          <Td>
            <Button
              onClick={() => {
                nativeApp.didLogin()
              }}
            >
              同期する
            </Button>
          </Td>
        </Tr>
        <Tr>
          <Td>ビデオ通話画面遷移</Td>
          <Td>
            <HStack>
              RoomID:{' '}
              <Input
                width="20"
                type="number"
                onChange={(evt) => {
                  setRoomId(Number(evt.target.value))
                }}
              />
              <Button
                onClick={() => {
                  nativeApp.openVideoRoom(roomId)
                }}
              >
                遷移する
              </Button>
            </HStack>
          </Td>
        </Tr>
        <Tr>
          <Td>ビデオ通話画面（1to1発進テスト)</Td>
          <Td>
            <HStack>
              <Link to="/debug/video-private-test">テストページ</Link>
            </HStack>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  )
}

export default Debug
