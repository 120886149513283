import { Button, Flex, Icon, Text, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { MdArrowForwardIos, MdOutlineClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { LEVEL_COLOR_INFO } from '../../../utils/content'
import { p2r } from '../../../utils/font'

export type AppKinkyuProps = {
  level: number
  onClose(): void
  isTop?: boolean
  title?: string
  date?: string
  contentId: number
}
export const AppKinkyu: React.FC<AppKinkyuProps> = (props) => {
  const navigate = useNavigate()
  const { f } = useFormatMessage()
  const isTop = props.isTop || false
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })

  // レベルをもとに色情報を取得
  const selectedColorInfo = LEVEL_COLOR_INFO[props.level - 1]

  return (
    <Flex
      px="32px"
      py="8px"
      justify="space-between"
      align="center"
      background={selectedColorInfo.background}
      boxShadow={isTop ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : 'unset'}
      backdropFilter="blur(40px)"
      borderRadius="16px"
      position="absolute"
      top="30px"
      left="50%"
      transform="translateX(-50%)"
      zIndex="9999"
      pos="fixed"
      flexDirection={isMobile ? 'column' : 'row'}
      w="calc(100% - 64px)"
      h="auto"
    >
      <Flex direction="column">
        {/* <Text
          mt="0px"
          fontSize={p2r(24)}
          fontWeight="700"
          lineHeight="150%"
          color={selectedColorInfo.mainText}
        >
          警戒レベル{props.level}
        </Text> */}
        <Text
          fontSize={p2r(32)}
          fontWeight="700"
          lineHeight="150%"
          color={selectedColorInfo.mainText}
        >
          {props.title}
        </Text>
        <Text
          mt="4px"
          color={selectedColorInfo.subText}
          fontSize={p2r(16)}
          lineHeight="150%"
          fontWeight="400"
        >
          {props.date}
        </Text>
      </Flex>
      <Flex columnGap="32px" align="center" mt={isMobile ? p2r(16) : 0}>
        <Button
          p="16px"
          h="56px"
          display="flex"
          columnGap="4px"
          borderWidth={props.level === 1 ? '2px' : '0px'}
          borderColor="grand.grayLight"
          borderRadius="8px"
          variant="unstyled"
          backgroundColor="grand.white"
          onClick={() => {
            navigate(`/kinkyu/${props.contentId}`)
          }}
        >
          <Text fontSize={p2r(20)} color="text.headline" fontWeight="700">
            {f('詳細を確認する')}
          </Text>
          <Icon as={MdArrowForwardIos} boxSize="24px" color="text.headline" />
        </Button>
        <Button
          backgroundColor="grand.grayLight"
          w="44px"
          h="44px"
          borderRadius="50%"
          onClick={props.onClose}
        >
          <Icon as={MdOutlineClose} boxSize="24px" color="text.body" />
        </Button>
      </Flex>
    </Flex>
  )
}
