import { Box, useBreakpointValue, useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { SlotInfo } from 'react-big-calendar'
import { useNavigate } from 'react-router-dom'
import { AppCalendar } from '../../../components/common/AppCalendar'
import AppErrorResult from '../../../components/common/AppErrorResult'
import { TaxiSectionTitle } from '../../../components/taxi/TaxiSectionTitle'
import { TAXI_RESERVATION_LIMIT_DATE } from '../../../global-config'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { validateTaxiReservationReturnDate } from '../../../models/taxiReservation'
import { p2r } from '../../../utils/font'
import useTaxiReservationCreateState from '../useTaxiReservationCreateState'

// 日にち選択
const TaxiReservationCreateReturnSelectDate: React.FC = () => {
  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })

  const {
    setTaxiRideDateTime,
    selectedRideDate,
    selectedRideTime,
    formValues,
  } = useTaxiReservationCreateState()
  const navigate = useNavigate()
  const toast = useToast()

  const handleSelectSlot = ({ start }: SlotInfo) => {
    const d = dayjs(start).startOf('day')

    const err = validateTaxiReservationReturnDate(d, dayjs(formValues.rideAt))
    if (err) {
      toast({
        title: err,
      })
      return
    }

    setTaxiRideDateTime({
      returnDate: {
        year: d.year(),
        month: d.month() + 1,
        day: d.date(),
      },
    })
    navigate('/taxi/reservations/create')
  }

  const scrollToTime = useMemo(() => new Date(1970, 1, 1, 6), [])

  if (!selectedRideDate || !selectedRideTime) {
    return <AppErrorResult message="往路の乗車時間を選択してください" />
  }

  return (
    <>
      {!isMobile && (
        <Box px={{ base: '16px', md: '0px' }}>
          <TaxiSectionTitle
            label={f(
              `呼ぶ日にちを選択してください。今日〜${TAXI_RESERVATION_LIMIT_DATE}日後以降の日程が予約可能です。`,
            )}
            fontSize={{ base: p2r(16), md: p2r(20) }}
          />
        </Box>
      )}

      <AppCalendar
        events={[]}
        onSelectSlot={handleSelectSlot}
        selectable
        scrollToTime={scrollToTime}
        views={['month']}
      />
    </>
  )
}

export default TaxiReservationCreateReturnSelectDate
