import Linkify from 'linkify-react'
import React from 'react'

const renderLink = ({ attributes, content }: any) => {
  const { href, ...props } = attributes
  return (
    <a
      href={href}
      {...props}
      style={{
        color: 'var(--chakra-colors-theme-primary)',
        textDecoration: 'underline',
      }}
    >
      <h1>{content}</h1>
    </a>
  )
}

export default function AppLinkify({
  children,
}: {
  children: React.ReactNode
}) {
  return <Linkify options={{ render: renderLink }}>{children}</Linkify>
}
