import { Box, Flex, Heading, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import nl2br from 'react-nl2br'
import ReactPlayer from 'react-player'
import { p2r } from '../../../utils/font'
import { AppDateText } from '../AppDateText'
import AppLikeButton from '../AppLikeButton'
import AppLinkify from '../AppLinkify'
import { AppNewLabel } from '../AppNewLabel'
import { AppTagText } from '../AppTagText'

export type AppContentMovieProps = {
  moviePath: string
  title: string
  body: string
  date: string
  isShowLike?: boolean
  isLiked?: boolean | null
  likeCount?: number | null
  tags?: string[]
  playCount?: number | null
  isReaded?: boolean | null
  contentId: number
}

export const AppContentMovie: React.FC<AppContentMovieProps> = ({
  moviePath,
  title,
  body,
  date,
  isShowLike,
  isLiked,
  likeCount,
  isReaded,
  tags,
  playCount,
  contentId,
  ...props
}) => (
  <Flex
    mx="auto"
    flexDirection="column"
    gap={{ base: '16px', md: '24px' }}
    width={{ base: '91vw', md: '55vw' }}
    {...props}
  >
    <Flex justifyContent="center" position="relative" pt="57.1%">
      <Box position="absolute" top="0" left="0" width="100%" height="100%">
        <ReactPlayer url={moviePath} controls width="100%" height="100%" />
      </Box>
    </Flex>
    <Flex flexDirection="column" gap="8px">
      <HStack spacing={{ base: '8px', md: '16px' }}>
        <HStack spacing={{ base: '4px', md: '8px' }}>
          {tags &&
            tags.length > 0 &&
            tags.map((tag) => <AppTagText label={tag} key={tag} />)}
        </HStack>
        <AppDateText label={date} fontSize={p2r(16)} color="text.sub" />
        {!isReaded && <AppNewLabel />}
      </HStack>
      <Heading
        as="h1"
        color="text.headline"
        fontSize={p2r(24)}
        fontWeight="700"
        lineHeight="150%"
        noOfLines={{ base: 3, md: 2 }}
      >
        {title}
      </Heading>
      <HStack spacing="16px">
        {isShowLike && (
          <AppLikeButton isLiked={isLiked} contentId={contentId} />
        )}
        {likeCount && (
          <Text
            fontSize={p2r(16)}
            lineHeight="150%"
            fontWeight="700"
            color="grand.gray"
          >
            {likeCount}回
          </Text>
        )}
      </HStack>
      {body && (
        <Box mt={{ base: '20px', md: '40px' }} color="text.body">
          <AppLinkify>{nl2br(body)}</AppLinkify>
        </Box>
      )}
    </Flex>
  </Flex>
)
