import {
  Flex,
  Spacer,
  Icon,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { MdWarning } from 'react-icons/md'
// import Modal from 'react-modal'
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom'
import { AppLoading } from '../../components/common/AppLoading'
import AppMaintenance from '../../components/common/AppMaintenance'
import AppModal from '../../components/common/AppModal'
// import { AppSegmentedPicker } from '../../components/common/AppSegmentedPicker'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { useWeatherWarningQuery } from '../../hooks/weatherApi/useWeatherRadarQuery'
import { p2r } from '../../utils/font'

const useSetWeatherButtons = () => {
  const { f } = useFormatMessage()
  return [
    {
      id: 1,
      path: '/weather/time',
      title: f('時間ごとの天気'),
    },
    {
      id: 2,
      path: '/weather/weekly',
      title: f('週間天気予報'),
    },
    {
      id: 3,
      path: '/weather/radar',
      title: f('雨雲レーダー'),
    },
  ]
}

type WeatherLinkProps = {
  path: string
  title: string
  isActive: boolean
}
const WeatherLink: React.FC<WeatherLinkProps> = (props) => (
  <Link to={props.path}>
    <Text
      bgColor={props.isActive ? 'theme.primary' : 'grand.graySuperLight'}
      color={props.isActive ? 'text.white' : 'text.sub'}
      p="4px 60.5px"
      rounded="10"
      fontSize={p2r(20)}
      fontWeight="700"
    >
      {props.title}
    </Text>
  </Link>
)

const FLANKLIN_MAINTENANCE_CONFIG = {
  START: '2024-06-10T18:00:00+09:00',
  END: '2024-06-10T19:10:00+09:00',
}

const Weather: React.FC = () => {
  useHeaderConfig({
    title: '天気',
  })

  const isMaintenanceFlanklin = useMemo(() => {
    if (
      dayjs().isSameOrAfter(dayjs(FLANKLIN_MAINTENANCE_CONFIG.START)) &&
      dayjs().isSameOrBefore(dayjs(FLANKLIN_MAINTENANCE_CONFIG.END))
    ) {
      return true
    }

    return false
  }, [])

  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const WeatherButtonsMenu = useSetWeatherButtons()
  const location = useLocation()
  const currentPath = location.pathname
  const connectModalDisclosure = useDisclosure()

  const warningForecastQuery = useWeatherWarningQuery('360000')

  if (currentPath === '/weather') {
    return <Navigate to="/weather/time" replace />
  }

  const headlineText = warningForecastQuery.data?.headlineText
  const NotWarningWord = '注意報を解除します。'

  if (isMaintenanceFlanklin) {
    return (
      <AppMaintenance
        title="只今メンテナンス中です"
        message={
          <>
            ご迷惑をおかけしますが、メンテナンスが終了するまで機能が利用できません。
            <br />
            終了予定時間:{' '}
            {dayjs(FLANKLIN_MAINTENANCE_CONFIG.END).format('M月D日 HH:mm')}
          </>
        }
      />
    )
  }

  if (warningForecastQuery.isLoading) {
    return <AppLoading />
  }

  return (
    <>
      {!isMobile && (
        <Flex
          p="14px 32px"
          boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
          borderBottomColor="grand.grayLight"
          borderBottomWidth="1px"
        >
          <Flex>
            {WeatherButtonsMenu.map((item) => (
              <WeatherLink
                key={item.id}
                path={item.path}
                title={item.title}
                isActive={currentPath === item.path}
              />
            ))}
          </Flex>

          <Spacer />
          {headlineText === NotWarningWord ? (
            <Flex gap="10px" alignItems="center">
              <Icon as={MdWarning} boxSize="32px" color="grand.gray" />
              <Text
                color="grand.gray"
                fontWeight="700"
                fontSize={p2r(20)}
                cursor="default"
              >
                {f('注意報・警報を見る')}
              </Text>
            </Flex>
          ) : (
            <Flex alignItems="center" onClick={connectModalDisclosure.onOpen}>
              <Flex gap="10px" alignItems="center">
                <Icon as={MdWarning} boxSize="32px" color="red" />
                <Text
                  color="red"
                  fontWeight="700"
                  fontSize={p2r(20)}
                  cursor="default"
                >
                  {f('注意報・警報を見る')}
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      )}
      <Flex direction="column" align="center" minH="calc(100vh - 123px)">
        <Outlet />
      </Flex>
      <AppModal
        isOpen={connectModalDisclosure.isOpen}
        onClose={connectModalDisclosure.onClose}
        title="注意報・警報"
        onCancel={connectModalDisclosure.onClose}
        isShowOk={false}
        isShowCancel
        cancelText="戻る"
      >
        {headlineText}
        <Text mt="4" fontSize="xs">
          ※&nbsp;徳島県下の情報です。
        </Text>
      </AppModal>
    </>
  )
}

export default Weather
