import { Icon } from '@chakra-ui/react'
import React from 'react'

/**
 * Home & メニュー ナビゲーションアイコン：マイページ
 */
const NavMypage: React.FC = (props) => (
  <Icon viewBox="0 0 64 64" {...props}>
    <path
      d="M32.0001 32.0003C29.0667 32.0003 26.5556 30.9559 24.4667 28.867C22.3779 26.7781 21.3334 24.267 21.3334 21.3337C21.3334 18.4003 22.3779 15.8892 24.4667 13.8003C26.5556 11.7114 29.0667 10.667 32.0001 10.667C34.9334 10.667 37.4445 11.7114 39.5334 13.8003C41.6223 15.8892 42.6667 18.4003 42.6667 21.3337C42.6667 24.267 41.6223 26.7781 39.5334 28.867C37.4445 30.9559 34.9334 32.0003 32.0001 32.0003ZM10.6667 53.3337V45.867C10.6667 44.3559 11.0561 42.9665 11.8347 41.699C12.6116 40.4332 13.6445 39.467 14.9334 38.8003C17.689 37.4225 20.489 36.3888 23.3334 35.699C26.1779 35.011 29.0667 34.667 32.0001 34.667C34.9334 34.667 37.8223 35.011 40.6667 35.699C43.5112 36.3888 46.3112 37.4225 49.0667 38.8003C50.3556 39.467 51.3885 40.4332 52.1654 41.699C52.9441 42.9665 53.3334 44.3559 53.3334 45.867V53.3337H10.6667Z"
      fill="#1C1F21"
    />
  </Icon>
)

export default NavMypage
