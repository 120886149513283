import { Box, useToast, VStack } from '@chakra-ui/react'
import dayjs from 'dayjs'
import produce from 'immer'
import React, { useEffect, useMemo, useState } from 'react'
import { useAuthContext } from '../../../../context/AuthContext'
import { parseErrorMessages } from '../../../../hooks/coreApi/appApiClient'
import { useTaxiReservationListQuery } from '../../../../hooks/coreApi/useTaxiReservationListQuery'
import { useTaxiReservationUpdateMutation } from '../../../../hooks/coreApi/useTaxiReservationUpdateMutation'
import {
  getStatusOption,
  getUserName,
} from '../../../../models/taxiReservation'
import { AppCalendar } from '../../../common/AppCalendar'
import { AppCalendarCustomEvent } from '../../../common/AppCalendar/AppCalendar'
import { useAppConfirm } from '../../../common/AppConfirm/useAppConfirm'
import { useAdminTaxiReservationModalContext } from '../AdminTaxiReservationModalContext'

const TaxiMonthlyCalendar: React.FC = () => {
  const { me } = useAuthContext()

  const taxiReservationListQuery = useTaxiReservationListQuery({
    userId: me?.id,
    withStartTaxiLandmark: true,
    withEndTaxiLandmark: true,
    withUser: true,
    withTaxiCar: true,
    withTaxiCompany: true,
  })

  const taxiReservationUpdateMutation = useTaxiReservationUpdateMutation()

  // TODO APIでフィルタ済みを取得
  const taxiReservations = useMemo(
    () =>
      taxiReservationListQuery.data?.taxiReservations?.filter(
        (r) =>
          r.taxiCompanyId === me?.taxiCompanyId &&
          ['draft', 'fixed'].includes(r.status),
      ) || [],
    [taxiReservationListQuery],
  )

  const [events, setEvents] = useState<AppCalendarCustomEvent[]>([])
  const resetEvents = () => {
    setEvents(
      taxiReservations.map((r) => ({
        title: `${dayjs(r.rideAt).format('HH:mm')} ${getUserName(r)}`,
        allDay: false,
        start: dayjs(r.rideAt).toDate(),
        end: dayjs(r.dropAt).toDate(),
        color: getStatusOption(r)?.color,
        taxiReservation: r,
      })),
    )
  }

  useEffect(() => {
    resetEvents()
  }, [taxiReservations])

  const toast = useToast()
  const adminTaxiReservationModal = useAdminTaxiReservationModalContext()
  const appConfirm = useAppConfirm()

  const onSelectEvent = (event: any) => {
    if (!event?.taxiReservation) {
      return
    }

    adminTaxiReservationModal.showModal(event.taxiReservation)
  }

  const onError = (err: any) => {
    const messages = parseErrorMessages(err)
    toast({
      title: messages.join('\n') || 'エラーが発生しました',
      status: 'error',
    })
  }

  const onSuccess = () => {
    toast({
      title: '予約内容を変更しました',
      status: 'success',
    })
  }

  const onEventChange = async (dropOrResizeEvent: any) => {
    const reservation = dropOrResizeEvent?.event?.taxiReservation

    if (!reservation) {
      return
    }

    // 楽観的更新 先にUIを更新
    setEvents(
      produce((draft) => {
        const targetEvent = draft.find(
          (e) => e.taxiReservation?.id === reservation.id,
        )
        if (!targetEvent) {
          return
        }

        targetEvent.start = dropOrResizeEvent.start
        targetEvent.end = dropOrResizeEvent.end
      }),
    )

    const confirmOk = await appConfirm.open({
      title: '予約時間の変更',
      body: (
        <Box>
          <VStack>
            <Box>
              乗車時間: {dayjs(reservation.rideAt).format('M月D日 HH:mm')} →
              {dayjs(dropOrResizeEvent.start).format('M月D日 HH:mm')}
            </Box>
            <Box>
              降車時間: {dayjs(reservation.dropAt).format('M月D日 HH:mm')} →
              {dayjs(dropOrResizeEvent.end).format('M月D日 HH:mm')}
            </Box>
          </VStack>
        </Box>
      ),
    })

    if (!confirmOk) {
      // 楽観的更新 キャンセルの時に元に戻す
      resetEvents()
      return
    }

    await taxiReservationUpdateMutation.mutateAsync(
      {
        id: reservation.id,
        taxiReservationUpdateRequest: {
          userName: reservation.userName,
          userPhone: reservation.userPhone,
          rideAt: dayjs(dropOrResizeEvent.start).toISOString(), // drop時の値をセット
          dropAt: dayjs(dropOrResizeEvent.end).toISOString(), // drop時の値をセット
          taxiCompanyId: reservation.taxiCompanyId,
          startTaxiLandmarkId: reservation.startTaxiLandmarkId,
          endTaxiLandmarkId: reservation.endTaxiLandmarkId,
          taxiCarId: reservation.taxiCarId,
          startAddress: reservation.startAddress,
          endAddress: reservation.endAddress,
          startLatLng: '',
          endLatLng: '',
        },
      },
      {
        onSuccess,
        onError,
      },
    )

    // 楽観的更新 後からサーバーと同期
    taxiReservationListQuery.refetch()
  }

  return (
    <AppCalendar
      events={events}
      resizable
      onSelectEvent={onSelectEvent}
      onEventDrop={onEventChange}
      onEventResize={onEventChange}
    />
  )
}

export default TaxiMonthlyCalendar
