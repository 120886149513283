import {
  Button,
  ButtonProps,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useMemo, useRef } from 'react'
import { MdArrowBackIosNew, MdHomeFilled } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { useKinkyuBanner } from '../../../hooks/useKinkyuBanner'
import useOffsetTop from '../../../hooks/useOffsetTop'
import { HeaderConfig, headerConfigState } from '../../../store/headerConfig'
import { p2r } from '../../../utils/font'
import Navigation from '../../svg/Navigation'
import { AppContentMenuModal } from '../AppContentMenuModal'
import { AppKinkyu } from '../AppKinkyu'

type HeaderButtonProps = ButtonProps
const HeaderButton: React.FC<HeaderButtonProps> = (props) => {
  const { children, ...rest } = props
  return (
    <Button
      display="flex"
      alignItems="center"
      columnGap="8px"
      variant="ghost"
      backgroundColor="theme.primaryDark"
      borderWidth="2px"
      borderColor="theme.primaryLight"
      borderRadius="40px"
      height={p2r(44)}
      _hover={{ backgroundColor: 'theme.primaryDark', opacity: '0.7' }}
      _active={{ backgroundColor: 'theme.primaryDark' }}
      {...rest}
    >
      {children}
    </Button>
  )
}

export const AppHeader: React.FC = () => {
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  const headerConfig = useRecoilValue<HeaderConfig>(headerConfigState)

  // モーダル
  const contentMenuDisclosure = useDisclosure()

  /**
   * スクロールでのヘッダー追従判定
   */
  // ヘッダーの現在位置取得
  const headerRef = useRef(null)
  const { viewportTop: headerViewportTop } = useOffsetTop(headerRef)
  // ページがスクロールされているか
  const isScrollPage = useMemo(() => {
    if (headerViewportTop === undefined) {
      return true
    }
    // ヘッダーの下端よりスクロールした場合true
    return headerViewportTop < -60
  }, [headerViewportTop])

  // 緊急情報バナー
  const { kinkyuContent, isShowKinkyuBanner, onClickCloseKinkyuBanner } =
    useKinkyuBanner()

  if (!headerConfig.show) {
    return null
  }

  return (
    <>
      <Grid
        backgroundColor={isScrollPage ? 'transparent' : 'theme.primaryDark'}
        px="32px"
        py="8px"
        gridTemplateColumns="1fr 2fr 1fr"
        justifyContent={isScrollPage ? 'center' : 'space-between'}
        alignItems="center"
        ref={headerRef}
        w="100%"
        transition="0.3s"
      >
        <GridItem
          as={Flex}
          justifyContent="flex-start"
          position={isScrollPage ? 'fixed' : 'relative'}
          top={isScrollPage ? '8px' : 'unset'}
          left={isScrollPage ? '32px' : 'unset'}
          zIndex="100"
          // ref={headerItemRef}
        >
          <HeaderButton
            onClick={() => navigate(-1)}
            boxShadow={
              isScrollPage ? '0px 4px 24px rgba(13, 73, 70, 0.4)' : 'unset'
            }
            backgroundColor={
              isScrollPage ? 'rgba(69, 109, 62, 0.9)' : 'theme.primaryDark'
            }
            borderColor={isScrollPage ? 'transparent' : 'theme.primaryLight'}
          >
            <Icon as={MdArrowBackIosNew} color="text.white" boxSize={p2r(24)} />
            <Text fontSize={p2r(24)} color="text.white">
              {f('戻る')}
            </Text>
          </HeaderButton>
        </GridItem>
        <GridItem as={Flex} justifyContent="center">
          <Text
            fontSize={p2r(24)}
            color="text.white"
            fontWeight="700"
            visibility={isScrollPage ? 'hidden' : 'visible'}
          >
            {headerConfig.title}
          </Text>
        </GridItem>
        <GridItem
          as={Flex}
          justifyContent="flex-end"
          position={isScrollPage ? 'fixed' : 'relative'}
          top={isScrollPage ? '8px' : 'unset'}
          right={isScrollPage ? '32px' : 'unset'}
          zIndex="100"
        >
          <Flex alignItems="center" columnGap="16px">
            <Link to="/">
              <Flex
                w="max-content"
                px="16px"
                alignItems="center"
                columnGap="8px"
                backgroundColor={
                  isScrollPage ? 'rgba(69, 109, 62, 0.9)' : 'theme.primaryDark'
                }
                borderWidth="2px"
                borderColor={
                  isScrollPage ? 'transparent' : 'theme.primaryLight'
                }
                borderRadius="40px"
                height={p2r(44)}
                _hover={{
                  backgroundColor: 'theme.primaryDark',
                  opacity: '0.7',
                }}
                _active={{ backgroundColor: 'theme.primaryDark' }}
                boxShadow={
                  isScrollPage ? '0px 4px 24px rgba(13, 73, 70, 0.4)' : 'unset'
                }
              >
                <Icon as={MdHomeFilled} color="text.white" boxSize={p2r(24)} />
                <Text fontSize={p2r(20)} color="text.white" fontWeight="700">
                  {f('ホーム')}
                </Text>
              </Flex>
            </Link>
            <HeaderButton
              onClick={contentMenuDisclosure.onOpen}
              borderColor={isScrollPage ? 'transparent' : 'theme.primaryLight'}
              boxShadow={
                isScrollPage ? '0px 4px 24px rgba(13, 73, 70, 0.4)' : 'unset'
              }
              backgroundColor={
                isScrollPage ? 'rgba(69, 109, 62, 0.9)' : 'theme.primaryDark'
              }
            >
              <Icon as={Navigation} color="text.white" boxSize={p2r(24)} />
              <Text fontSize={p2r(18)} color="text.white">
                {f('メニュー')}
              </Text>
            </HeaderButton>
          </Flex>
        </GridItem>
      </Grid>
      {headerConfig.showKinkyuBanner === true &&
        isShowKinkyuBanner &&
        kinkyuContent && (
          <AppKinkyu
            contentId={kinkyuContent.id}
            level={kinkyuContent?.kinkyuLevel || 1}
            title={kinkyuContent.titleJa}
            date={dayjs(kinkyuContent.releaseAt).format('M月D日 HH:mm 発表')}
            onClose={onClickCloseKinkyuBanner}
          />
        )}
      <AppContentMenuModal
        isOpen={contentMenuDisclosure.isOpen}
        onClose={contentMenuDisclosure.onClose}
      />
    </>
  )
}
