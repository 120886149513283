import { TaxiDailyScheduleApiTaxiDailyScheduleMergeRequest } from '../../openapi'
import appApiClient from './appApiClient'
import useBaseQuery from './useBaseQuery'

export const useTaxiDailyScheduleMergeQuery = (
  req: TaxiDailyScheduleApiTaxiDailyScheduleMergeRequest,
) =>
  useBaseQuery({
    queryKey: ['taxiDailyScheduleMerge'],
    queryFn: (config) =>
      appApiClient.taxiDailyScheduleApi.taxiDailyScheduleMerge(req, config),
  })
