import { Flex, Icon, TabPanel, Text, Button } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { MdArrowForwardIos } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { p2r } from '../../../utils/font'

export type HomePanelProps = {
  path: string
  children: ReactNode
}
export const HomePanel: React.FC<HomePanelProps> = (props) => {
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  return (
    <TabPanel
      h="100%"
      pt="0px"
      pb="0px"
      px="0px"
      display="flex"
      flexDirection="column"
      {...props}
    >
      {props.children}
      <Flex mt="auto" mr={p2r(16)} mb={p2r(16)} justify="flex-end">
        <Button
          variant="ghost"
          h="fit-content"
          p="8px 8px 8px 16px"
          onClick={() => navigate(props.path)}
          display="flex"
          columnGap="4px"
          borderWidth="2px"
          borderColor="theme.primary"
          borderRadius="40px"
          backgroundColor="grand.white"
        >
          <Text
            fontSize={p2r(16)}
            lineHeight="120%"
            fontWeight="700"
            color="theme.primary"
          >
            {f('もっとみる')}
          </Text>
          <Icon
            as={MdArrowForwardIos}
            boxSize={p2r(20)}
            color="theme.primary"
          />
        </Button>
      </Flex>
    </TabPanel>
  )
}
