import { Box, useToast, VStack } from '@chakra-ui/react'
import dayjs from 'dayjs'
import produce from 'immer'
import React, { useEffect, useMemo, useState } from 'react'
import { useAuthContext } from '../../../../context/AuthContext'
import { parseErrorMessages } from '../../../../hooks/coreApi/appApiClient'
import { useTaxiCarListQuery } from '../../../../hooks/coreApi/useTaxiCarListQuery'
import { useTaxiReservationListQuery } from '../../../../hooks/coreApi/useTaxiReservationListQuery'
import { useTaxiReservationUpdateMutation } from '../../../../hooks/coreApi/useTaxiReservationUpdateMutation'
import {
  getFromLabel,
  getStatusOption,
  getToLabel,
  getUserName,
} from '../../../../models/taxiReservation'
import {
  AppResourceCalendar,
  ResourceCalendarTitleElement,
} from '../../../common/AppCalendar/AppResourceCalendar'
import { useAppConfirm } from '../../../common/AppConfirm/useAppConfirm'
import { useAdminTaxiReservationModalContext } from '../AdminTaxiReservationModalContext'

const NO_ASSIGN_RESOURCE_ID = Infinity

const reservationStatusToType: { [key in string]: string } = {
  draft: 'type2',
  fixed: 'type3',
}

const TaxiResourceCalendar: React.FC = () => {
  const { me } = useAuthContext()
  const taxiReservationUpdateMutation = useTaxiReservationUpdateMutation()
  const taxiReservationListQuery = useTaxiReservationListQuery({
    userId: me?.id,
    withStartTaxiLandmark: true,
    withEndTaxiLandmark: true,
    withUser: true,
    withTaxiCar: true,
    withTaxiCompany: true,
  })

  // TODO APIでフィルタ済みを取得
  const taxiReservations = useMemo(
    () =>
      taxiReservationListQuery.data?.taxiReservations?.filter(
        (r) =>
          r.taxiCompanyId === me?.taxiCompanyId &&
          ['draft', 'fixed'].includes(r.status),
      ) || [],
    [taxiReservationListQuery],
  )

  const [events, setEvents] = useState<any>([])
  const resetEvents = () => {
    setEvents(
      taxiReservations.map((r) => ({
        title: (
          <ResourceCalendarTitleElement
            name={getUserName(r)}
            from={getFromLabel(r)}
            to={getToLabel(r)}
            type={reservationStatusToType[r.status] || 'type1'}
          />
        ),
        allDay: false,
        start: dayjs(r.rideAt).toDate(),
        end: dayjs(r.dropAt).toDate(),
        resourceId: r.taxiCarId || NO_ASSIGN_RESOURCE_ID,
        type: reservationStatusToType[r.status] || 'type1',
        desc: '',
        taxiReservation: r,
        color: getStatusOption(r)?.color,
      })),
    )
  }
  useEffect(() => {
    resetEvents()
  }, [taxiReservations])

  const taxiCarListQuery = useTaxiCarListQuery({})

  // APIでフィルタ済みを取得
  const taxiCars = useMemo(
    () =>
      taxiCarListQuery?.data?.taxiCars?.filter(
        (c) => c.taxiCompanyId === me?.taxiCompanyId,
      ),
    [me, taxiCarListQuery],
  )

  const resourceMap = useMemo(() => {
    const newResourceMap = [
      { resourceId: NO_ASSIGN_RESOURCE_ID, resourceTitle: '担当車なし' },
    ]
    taxiCars?.forEach((c) => {
      newResourceMap.push({
        resourceId: c.id,
        resourceTitle: c.name,
      })
    })
    return newResourceMap
  }, [taxiCars])

  const toast = useToast()
  const appConfirm = useAppConfirm()
  const reservationDetailModalContext = useAdminTaxiReservationModalContext()

  const onSelectEvent = (event: any) => {
    if (!event?.taxiReservation) {
      return
    }

    reservationDetailModalContext.showModal(event.taxiReservation)
  }

  const onSuccess = () => {
    toast({
      title: '予約内容を変更しました',
      status: 'success',
    })
  }

  const onError = (err: any) => {
    const messages = parseErrorMessages(err)
    toast({
      title: messages.join('\n') || 'エラーが発生しました',
      status: 'error',
    })
  }

  const onEventChange = async (dropOrResizeEvent: any) => {
    const reservation = dropOrResizeEvent?.event?.taxiReservation

    if (!reservation) {
      return
    }

    // 楽観的更新 先にUIを更新
    setEvents(
      produce((draft) => {
        // eslint-disable-next-line
        // @ts-ignore
        const targetEvent = draft.find(
          (e: any) => e.taxiReservation?.id === reservation.id,
        )
        if (!targetEvent) {
          return
        }

        targetEvent.start = dropOrResizeEvent.start
        targetEvent.end = dropOrResizeEvent.end
        targetEvent.resourceId = dropOrResizeEvent.resourceId
      }),
    )

    const beforeResourceName =
      resourceMap.find((r) => r.resourceId === reservation.taxiCarId)
        ?.resourceTitle || '担当車なし'
    const afterResourceName =
      resourceMap.find((r) => r.resourceId === dropOrResizeEvent.resourceId)
        ?.resourceTitle || '担当車なし'

    // 確認ダイアログ render hooksで手続き的に表示
    const confirmOk = await appConfirm.open({
      title: '予約時間の変更',
      body: (
        <Box>
          <VStack>
            <Box>
              乗車時間: {dayjs(reservation.rideAt).format('M月D日 HH:mm')} →
              {dayjs(dropOrResizeEvent.start).format('M月D日 HH:mm')}
            </Box>
            <Box>
              降車時間: {dayjs(reservation.dropAt).format('M月D日 HH:mm')} →
              {dayjs(dropOrResizeEvent.end).format('M月D日 HH:mm')}
            </Box>
            <Box>
              担当車: {beforeResourceName} →{afterResourceName}
            </Box>
          </VStack>
        </Box>
      ),
    })

    if (!confirmOk) {
      // 楽観的更新 キャンセルの時に元に戻す
      resetEvents()
      return
    }

    // resourceIDを変換（「担当車なし」はInitinityを割り当てているためnullに変換）
    let newTaxiCarId = dropOrResizeEvent.resourceId
    if (dropOrResizeEvent.resourceId === NO_ASSIGN_RESOURCE_ID) {
      newTaxiCarId = null
    }

    await taxiReservationUpdateMutation.mutateAsync(
      {
        id: reservation.id,
        taxiReservationUpdateRequest: {
          userName: reservation.userName,
          userPhone: reservation.userPhone,
          rideAt: dayjs(dropOrResizeEvent.start).toISOString(), // drop時の値をセット
          dropAt: dayjs(dropOrResizeEvent.end).toISOString(), // drop時の値をセット
          taxiCompanyId: reservation.taxiCompanyId,
          startTaxiLandmarkId: reservation.startTaxiLandmarkId,
          endTaxiLandmarkId: reservation.endTaxiLandmarkId,
          taxiCarId: newTaxiCarId,
          startAddress: reservation.startAddress,
          endAddress: reservation.endAddress,
          startLatLng: '',
          endLatLng: '',
        },
      },
      {
        onSuccess,
        onError,
      },
    )

    // 楽観的更新 後からサーバーと同期
    taxiReservationListQuery.refetch()
  }

  return (
    <AppResourceCalendar
      events={events}
      resourceMap={resourceMap}
      resizable
      fullWidth
      // eslint-disable-next-line
      // @ts-ignore
      onSelectEvent={onSelectEvent}
      // eslint-disable-next-line
      // @ts-ignore
      onEventDrop={onEventChange}
      onEventResize={onEventChange}
    />
  )
}

export default TaxiResourceCalendar
