import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import { p2r } from '../../../utils/font'

type VideoModalProps = {
  children: ReactNode
} & ModalProps
const VideoModal: React.FC<VideoModalProps> = (props) => {
  const { children, ...rest } = props

  return (
    <Modal isCentered {...rest}>
      <ModalOverlay />
      <ModalContent
        px="24px"
        py="32px"
        w={{ base: '92%', md: 'auto' }}
        minW={{ base: 'unset', md: '720px' }}
        borderRadius="10px"
      >
        <ModalCloseButton
          as={MdOutlineClose}
          color="grand.grayDark"
          boxSize={p2r(40)}
          top="16px"
          left="24px"
        />
        <ModalBody px="0px" py="0px">
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default VideoModal
