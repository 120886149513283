import { Center } from '@chakra-ui/react'
import React from 'react'
import TaxiResourceCalendar from '../../../components/taxi/admin/TaxiResourceCalendar'

const AdminTaxiCalendarToday: React.FC = () => (
  <Center w="100%" h="100%" flex="1">
    <TaxiResourceCalendar />
  </Center>
)

export default AdminTaxiCalendarToday
