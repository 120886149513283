import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { ResetPassword, resetPasswordState } from '../store/resetPassword'
import useResetPasswordMutation from './coreApi/useResetPasswordMutation'

export const useResetPassword = () => {
  const [resetState, setResetState] =
    useRecoilState<ResetPassword>(resetPasswordState)

  const { resetPasswordMutation, resetPasswordVerifyMutation } =
    useResetPasswordMutation()
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    setResetState({
      ...resetState,
      isValidStep1: resetState.sendValue === '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetState.sendValue])

  useEffect(() => {
    setResetState({
      ...resetState,
      isValidStep2:
        resetState.authCode === '' ||
        resetState.password === '' ||
        resetState.passwordConfirmation === '' ||
        resetState.password !== resetState.passwordConfirmation,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resetState.authCode,
    resetState.password,
    resetState.passwordConfirmation,
  ])

  const handleStep1Submit = async () => {
    resetPasswordMutation.mutate(
      { userResetPasswordRequest: { emailOrPhone: resetState.sendValue } },
      {
        onSuccess(res) {
          console.log(res)
          setResetState({
            ...resetState,
            step: 2,
          })
          toast({
            title: '電話番号もしくはメールアドレス宛に認証コードを送信しました',
            status: 'success',
          })
        },
        onError() {
          toast({
            title: `送信できませんでした`,
            status: 'error',
          })
        },
      },
    )
  }

  const handleStep2Submit = async (pageType?: 'mypage' | 'login') => {
    resetPasswordVerifyMutation.mutate(
      {
        userResetPasswordVerifyRequest: {
          emailOrPhone: resetState.sendValue,
          password: resetState.password,
          passwordConfirmation: resetState.passwordConfirmation,
          authCode: resetState.authCode,
        },
      },
      {
        onSuccess(res) {
          console.log(res)
          if (pageType === 'mypage') {
            navigate('/mypage/password/completed')
          } else {
            navigate('/login/reset/completed')
          }

          toast({
            title: 'パスワードを変更しました', // TODO サーバーエラーのメッセージ
            status: 'success',
          })
        },
        onError() {
          toast({
            title: 'パスワードを変更できませんでした', // TODO サーバーエラーのメッセージ
            status: 'error',
          })
        },
      },
    )
  }

  return {
    resetState,
    setResetState,
    handleStep1Submit,
    handleStep2Submit,
  }
}
