import React from 'react'
import { Outlet } from 'react-router-dom'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppLoading } from '../../components/common/AppLoading'
import AppSearchHeader from '../../components/common/AppSearchHeader'
import {
  AppThumbnailList,
  AppThumbnailListItem,
} from '../../components/common/AppThumbnailList'
import {
  useContentSearchContext,
  withContentSearch,
} from '../../context/ContentSearchContext'
import { CONTENT_DEFAULT_PAGE_SIZE } from '../../global-config'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { getYoutubeThumbnailUrl } from '../../models/content'
import { getDateFormat } from '../../utils/date'

const DekigotoList: React.FC = () => {
  useHeaderConfig({
    title: 'かみやまch',
  })

  const { query } = useContentSearchContext()

  const params = {
    page: 1,
    pageSize: CONTENT_DEFAULT_PAGE_SIZE,
    search: query.search,
    startDate: query?.startDate,
    endDate: query?.endDate,
    tags: query?.tag ? [query.tag] : undefined,
  }
  const { isLoading, isError, error, data, fetchNextPage, hasNextPage } =
    useContentListQuery('dekigoto', params)

  if (isLoading) {
    return <AppLoading />
  }

  if (isError) {
    return <AppErrorResult error={error} />
  }

  return (
    <>
      <AppSearchHeader
        contentType="dekigoto"
        isShowTag
        defaultValues={{
          search: '',
          year: '',
          placeId: '',
          tag: '',
        }}
      />
      <AppThumbnailList
        isMore={hasNextPage}
        onClickMore={() => {
          fetchNextPage()
        }}
      >
        {data.pages.map((page) =>
          page.contents.map((content) => (
            <AppThumbnailListItem
              key={content.id}
              title={content.titleJa}
              date={getDateFormat(content.releaseAt)}
              image={getYoutubeThumbnailUrl(content?.dekigotoVideoUrl)}
              isShowLike
              // eslint-disable-next-line
              // @ts-ignore
              isLiked={content?.isLiked}
              playCount={content?.likeCount}
              tags={content?.contentTags?.map((tag) => tag.label) || []}
              contentId={content.id}
              link={`/dekigoto/${content.id}`}
              isReaded={content.isRead}
            />
          )),
        )}
      </AppThumbnailList>
      <Outlet />
    </>
  )
}

export default withContentSearch(DekigotoList)
