export const LS_KEY = {
  i18n: 'i18n',
  redirectPath: 'redirectPath',
  readedContentIds: 'readedContentIds',
}

export const COOKIE_KEY = {
  idToken: 'idToken',
  refreshToken: 'refreshToken',
  tenantId: 'tenantId',
}
