import {
  Text,
  Heading,
  VStack,
  Table,
  Tbody,
  Flex,
  Image,
  Tr,
  Td,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React from 'react'
import { useParams } from 'react-router-dom'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppLoading } from '../../components/common/AppLoading'
import useShikyoQuery from '../../hooks/coreApi/useShikyoQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { p2r } from '../../utils/font'

type ShikyoTdProps = {
  label: string
}
const ShikyoTd: React.FC<ShikyoTdProps> = (props) => {
  const { label } = props
  return (
    <Td
      w={{ base: 'unset', md: '200px' }}
      pl={{ base: '8px', md: '8px' }}
      py={{ base: '16px', md: '32px' }}
      fontSize={{ base: p2r(18), md: p2r(24) }}
      borderWidth="1px"
      borderColor="grand.grayLight"
    >
      {label}
    </Td>
  )
}

const ShikyoDetail: React.FC = () => {
  useHeaderConfig({
    title: '市況情報',
  })
  const { id } = useParams()
  const { f } = useFormatMessage()

  const { data: products, isLoading, isError } = useShikyoQuery()
  if (isLoading) {
    return <AppLoading />
  }

  // 一覧から該当する品目情報の特定
  const product = products.find((p: any) => p['品目ID'] === id)

  if (isError || !product) {
    return <AppErrorResult message="コンテンツが見つかりませんでした" />
  }

  // 等級ラベル
  const gradeLabels: string[] = []
  const gradeRegex = /^等級\d+$/

  // 階級ラベル
  const rankLabels: string[] = []
  const rankRegex = /^階級\d+$/

  Object.keys(product).forEach((key) => {
    // 等級ラベルの取得
    if (gradeRegex.test(key)) {
      if (product[key]) {
        gradeLabels.push(product[key])
      }
    }
    // 階級・価格のcolumns作成
    else if (rankRegex.test(key)) {
      if (product[key]) {
        rankLabels.push(product[key])
      }
    }
  })

  /**
   * jsonから 価格11 価格23 などの等級・階級に応じた値を取得（`価格{階級数}{等級数}`）
   */
  // Columnとして<Tr>で表示できるように集約用Columns配列生成
  const columns: string[][] = []
  const MAX_GRADE_NUMBER = gradeLabels.length
  // 階級のカウント数は固定値（階級が存在しない場合もあるため）
  const MAX_RANK_NUMBER = 5
  for (let rankIndex = 0; rankIndex < MAX_RANK_NUMBER; rankIndex += 1) {
    // 階級名を1番目に追加
    const column: string[] = []
    for (let gradeIndex = 1; gradeIndex <= MAX_GRADE_NUMBER; gradeIndex += 1) {
      // 値が存在する場合のみ結果用columnに追加
      if (product[`価格${rankIndex + 1}${gradeIndex}`] !== '') {
        column.push(product[`価格${rankIndex + 1}${gradeIndex}`])
      } else {
        column.push('-')
      }
    }
    const isColumnValueEmpty = column.every((c) => c === '-')

    // 結果用columnに追加（空の場合は追加しない）
    if (!isColumnValueEmpty) {
      columns.push([rankLabels[rankIndex] || '', ...column])
    }
  }

  return (
    <Flex
      gap="16px"
      direction="column"
      px={{ base: '16px', md: '32px' }}
      pt={{ base: '16px', md: '40px' }}
      pb={{ base: '16px', md: '34px' }}
      bgColor="theme.background"
      minH={{ base: 'calc(100vh - 168px)', md: 'calc(100vh - 56px)' }}
    >
      <VStack spacing={{ base: '2', md: '4' }} align="flex-start">
        <Text color="text.sub" fontSize={p2r(16)}>
          {dayjs(product['最終更新日']).format('YYYY.MM.DD')}
        </Text>
        <Heading size="lg" color="text.body">
          {product['品目名']}
        </Heading>
      </VStack>

      <Table
        backgroundColor="grand.white"
        w={{ base: '100%', md: 'fit-content' }}
      >
        <Tbody borderWidth="1px" borderColor="grand.grayLight">
          <Tr>
            <ShikyoTd label={product['等級階級ラベル']} />
            {gradeLabels.map((label, index) => {
              // そのままindexを使うとeslintエラーのため変数に格納
              const labelIndex = index
              return <ShikyoTd key={`gradeLabel-${labelIndex}`} label={label} />
            })}
          </Tr>
          {columns.map((column, index) => {
            const columnsIndex = index
            return (
              <Tr
                key={`tr-${columnsIndex}`}
                borderWidth="1px"
                borderColor="grand.grayLight"
              >
                {column.map((item, itemIndex) => {
                  const columnIndex = itemIndex
                  return <ShikyoTd key={`td-${columnIndex}`} label={item} />
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Flex mt="24px" align="center">
        <Text
          fontSize={p2r(16)}
          lineHeight="150%"
          color="text.body"
          fontWeight="bold"
        >
          {f('情報提供元')} ： JA名西郡 /
        </Text>
        <Image src="/images/shikyo/tvtokushima_logo.svg" alt="テレビトクシマ" />
      </Flex>
    </Flex>
  )
}

export default ShikyoDetail
