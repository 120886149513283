import {
  Box,
  Button,
  ButtonProps,
  HStack,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { MdThumbUpOffAlt } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../context/AuthContext'
import { QUERY_KEY_CONTENT_LIST } from '../../../hooks/coreApi/useContentListQuery'
import { useLikeMutation } from '../../../hooks/coreApi/useLikeMutation'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { p2r } from '../../../utils/font'
import AppModal from '../AppModal'

/**
 * いいねボタン
 * @param isActive ユーザーがいいねしているか
 * @param count いいね数
 */
export type AppLikeButtonProps = {
  isLiked?: boolean | null | undefined
  count?: number
  contentId: number
} & ButtonProps

const AppLikeButton: React.FC<AppLikeButtonProps> = ({
  isLiked = false,
  count = 0,
  contentId,
  ...props
}) => {
  const queryClient = useQueryClient()
  const likeMutation = useLikeMutation()
  const [isLikedForDisplay, setIsLikedForDisplay] = useState(isLiked)
  const [countForDisplay, setCountForDisplay] = useState(count)
  const { f } = useFormatMessage()
  const { isAuthorized } = useAuthContext()
  const authGuardModalDisclosure = useDisclosure()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <>
      <Button
        onClick={() => {
          if (!isAuthorized) {
            authGuardModalDisclosure.onOpen()
            return
          }
          setIsLikedForDisplay((prevState) => !prevState)
          setCountForDisplay(
            isLikedForDisplay ? countForDisplay - 1 : countForDisplay + 1,
          )
          likeMutation.mutate(
            {
              id: contentId,
              contentLikeRequest: {
                isLiked: !isLikedForDisplay,
              },
            },
            {
              onSuccess: () => {
                // like更新のためクエリをリセット
                queryClient.invalidateQueries([QUERY_KEY_CONTENT_LIST])
              },
            },
          )
        }}
        w="fit-content"
        h="fit-content"
        px={{ base: '8px', md: '4px' }}
        py={{ base: '2px', md: '2px' }}
        gap="4px"
        bgColor={isLikedForDisplay ? 'theme.primary' : 'grand.white'}
        borderColor={isLikedForDisplay ? 'theme.primary' : 'grand.LightGray'}
        borderWidth="1px"
        alignItems="center"
        variant="unset"
        rounded="8px"
        minW="unset"
        {...props}
      >
        <Icon
          as={MdThumbUpOffAlt}
          boxSize={p2r(16)}
          color={isLikedForDisplay ? 'text.white' : 'text.sub'}
        />
        <Text
          fontSize={p2r(12)}
          lineHeight="150%"
          fontWeight="700"
          color={isLikedForDisplay ? 'text.white' : 'text.sub'}
        >
          {f('いいね！')}
        </Text>
      </Button>
      <AppModal
        isOpen={authGuardModalDisclosure.isOpen}
        onClose={authGuardModalDisclosure.onClose}
        isShowCancel={false}
        isShowOk={false}
        title=""
      >
        <Box>
          <Box pt="4" mb="4">
            {f('この機能を利用するためにはログインが必要です')}
          </Box>
          <HStack display="flex" justifyContent="flex-end">
            <Button
              onClick={() => {
                authGuardModalDisclosure.onClose()
              }}
            >
              キャンセル
            </Button>
            <Button
              colorScheme="primary"
              onClick={() => {
                navigate('/login', {
                  state: {
                    location,
                  },
                })
                authGuardModalDisclosure.onOpen()
              }}
            >
              ログインする
            </Button>
          </HStack>
        </Box>
      </AppModal>
    </>
  )
}

export default AppLikeButton
