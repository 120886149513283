import { Box } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import React from 'react'

type Props = {
  error?: AxiosError
  message?: string
}

const AppErrorResult: React.FC<Props> = (props) => {
  const { error, message } = props

  if (!error) {
    return <Box>{message || 'エラーが発生しました'}</Box>
  }

  if (error.code === '404') {
    return <Box>コンテンツが見つかりませんでした</Box>
  }

  if (error.code === '403') {
    return <Box>コンテンツにアクセスする権限がありません</Box>
  }

  // その他のサーバーエラー
  if (Number(error.code) >= 500) {
    if (error.message) {
      return <Box>{error.message}</Box>
    }

    return <Box>サーバーエラーが発生しました</Box>
  }

  return null
}

export default AppErrorResult
