import { Flex, Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { MypageButton } from '../../components/mypage/MypageButton'
import { MypageSectionTitle } from '../../components/mypage/MypageSectionTitle'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'

const MypagePasswordCompleted: React.FC = () => {
  const { f } = useFormatMessage()
  useHeaderConfig({
    title: 'マイページ | パスワード変更完了',
  })
  const navigate = useNavigate()
  return (
    <Flex
      direction="column"
      justify="center"
      alignItems="center"
      backgroundColor="grand.white"
      rowGap="48px"
      py="48px"
      minH="100vh"
    >
      <Stack spacing="32px" alignItems="center">
        <MypageSectionTitle label={f('パスワードの再設定が完了しました')} />
        {/* フロー不明のため再ログインが必要か確認 */}
        {/* <Text fontSize={p2r(16)} color="text.body">
          {f(
            '新しいパスワードへの変更が完了したので、ログイン画面から再度ログインしてください。',
          )}
        </Text> */}
      </Stack>
      <Stack spacing="16px">
        <MypageButton
          onClick={() => navigate('/mypage/profile')}
          bgColor="theme.primary"
          color="text.white"
        >
          {f('マイページへ')}
        </MypageButton>
      </Stack>
    </Flex>
  )
}

export default MypagePasswordCompleted
