import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { isReadWithLocalStorage } from '../utils/content'
import { dateToFormatRFC3339 } from '../utils/date'
import {
  QUERY_KEY_CONTENT_LIST,
  useContentListQuery,
} from './coreApi/useContentListQuery'
import { useContentReadMutation } from './coreApi/useContentReadMutation'

export const useKinkyuBanner = () => {
  const queryClient = useQueryClient()
  // 最新1件の緊急情報を取得
  const kinkyuContentQuery = useContentListQuery('kinkyu', {
    pageSize: 1,
    page: 1,
    // 24時間以内とする
    startDate: dateToFormatRFC3339(dayjs().subtract(24, 'hour')),
  })

  const kinkyuContent = kinkyuContentQuery.data?.pages[0].contents[0] || null
  // 緊急情報を表示するかどうか
  const isShowKinkyuBanner = useMemo(() => {
    if (!kinkyuContent) {
      return false
    }

    // 既読（×ボタン押下済み）未ログイン時はローカルストレージから読み取り
    if (isReadWithLocalStorage(kinkyuContent)) {
      return false
    }

    return true
  }, [kinkyuContent])

  const contentReadMutation = useContentReadMutation()

  // 緊急情報を既読にする
  const readContent = () => {
    if (!kinkyuContent) {
      return
    }
    contentReadMutation.mutate({
      contentReadRequest: {
        contentIds: [kinkyuContent.id],
      },
    })
    queryClient.resetQueries([QUERY_KEY_CONTENT_LIST])
  }

  const onClickCloseKinkyuBanner = () => {
    readContent()
  }

  return {
    kinkyuContent,
    isShowKinkyuBanner,
    onClickCloseKinkyuBanner,
    readContent,
  }
}
