import { Box } from '@chakra-ui/react'
import React from 'react'
import { AppPDFView } from '../../components/common/AppPDFView'

const PDFViewer: React.FC = () => {
  const fileUrl = '/sample.pdf'
  return (
    <Box bg="#222624">
      <AppPDFView fileUrl={fileUrl} />
    </Box>
  )
}

export default PDFViewer
