import { useEffect, useMemo } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useAuthContext } from '../../context/AuthContext'
import { useTaxiReservationListQuery } from '../../hooks/coreApi/useTaxiReservationListQuery'
import { TaxiReservationResponse } from '../../openapi/api'

const taxiReservationState = atom<TaxiReservationResponse[]>({
  key: 'taxiReservations',
  default: [],
})

const useTaxiAdminReservationCreateState = () => {
  const [taxiReservations, setTaxiReservations] =
    useRecoilState(taxiReservationState)

  const { me } = useAuthContext()

  const taxiReservationListQuery = useTaxiReservationListQuery({
    userId: me?.id,
    withStartTaxiLandmark: true,
    withEndTaxiLandmark: true,
    withUser: true,
    withTaxiCar: true,
    withTaxiCompany: true,
  })

  // TODO APIでフィルタ済みを取得
  useEffect(() => {
    if (taxiReservationListQuery.isSuccess) {
      const newTaxiReservations =
        taxiReservationListQuery.data?.taxiReservations?.filter(
          (r) =>
            r.taxiCompanyId === me?.taxiCompanyId &&
            ['draft', 'fixed'].includes(r.status),
        ) || []
      setTaxiReservations(newTaxiReservations)
    }
  }, [taxiReservationListQuery.isSuccess])

  const draftTaxiReservations = useMemo(
    () => taxiReservations.filter((r) => r.status === 'draft'),
    [taxiReservations],
  )

  const fixedTaxiReservations = useMemo(
    () => taxiReservations.filter((r) => r.status === 'draft'),
    [taxiReservations],
  )

  const refetchTaxiReservations = () => {
    taxiReservationListQuery.refetch()
  }

  return {
    taxiReservations,
    fixedTaxiReservations,
    draftTaxiReservations,
    refetchTaxiReservations,
  }
}

export default useTaxiAdminReservationCreateState
