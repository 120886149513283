import axios from 'axios'
// import { WeatherApiDailyForecastResponse } from './weatherApiType'

const WEATHER_API_BASE_URL = 'https://www.jma.go.jp'

const weatherApiAxiosInstance = axios.create({
  baseURL: `${WEATHER_API_BASE_URL}`,
})

const getDailyForecasts = async (weatherCode: string) => {
  const res = await weatherApiAxiosInstance.get(
    `/bosai/forecast/data/forecast/${weatherCode}.json`,
  )
  return res.data
}

const FRANKLIN_API_BASE_URL = 'https://weather-api.jldn.jp/weather/api'

// TODO 徳島県庁の緯度経度 マルチテナント対応の際はテナントDBに情報保持
const TOKUSHIMA_BASE_LATLNG = {
  lat: 34.0659619,
  lon: 134.5589939,
}

const weatherApiInstance = axios.create({
  baseURL: `${FRANKLIN_API_BASE_URL}`,
  headers: {
    auth: '30a8d4c395b23c24cd057c0cd8111002aeb2abef',
  },
})

const getWth10Forecasts = async () => {
  const res = await weatherApiInstance.get('/wth10', {
    params: {
      lat: TOKUSHIMA_BASE_LATLNG.lat,
      lon: TOKUSHIMA_BASE_LATLNG.lon,
    },
  })
  return res.data
}

const getPointwfForecasts = async () => {
  const res = await weatherApiInstance.get('/pointwf', {
    params: {
      lat: TOKUSHIMA_BASE_LATLNG.lat,
      lon: TOKUSHIMA_BASE_LATLNG.lon,
    },
  })
  return res.data
}

export const weatherApiClient = {
  getDailyForecasts,
  getWth10Forecasts,
  getPointwfForecasts,
}
