import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  useBreakpointValue,
  StyleProps,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { p2r } from '../../../utils/font'
import Calendar from '../../svg/Calendar'
import { AppDateText } from '../AppDateText'
import AppLikeButton from '../AppLikeButton'
import AppListMoreButton from '../AppListMoreButton'
import { AppNewLabel } from '../AppNewLabel'
import AppSoundPlayButton from '../AppSoundPlayButton'
import { AppTagText } from '../AppTagText'

export type AppListProps = {
  children: ReactNode
  isMore?: boolean
  onClickMore?: () => void
}

export const AppList: React.FC<AppListProps> = (props) => {
  const { children, isMore, onClickMore } = props
  const childrenArray = React.Children.toArray(children)
  if (childrenArray.length === 0) {
    return <Box>対象のコンテンツはありません</Box>
  }
  return (
    <>
      <Box
        borderTopWidth={{ base: '1px', md: '0px' }}
        borderTopColor="grand.grayLight"
        borderBottomWidth={{ base: '1px', md: '0px' }}
        borderBottomColor="grand.grayLight"
      >
        {children}
      </Box>
      {isMore && <AppListMoreButton onClick={onClickMore} />}
    </>
  )
}

export type AppListItemProps = {
  title: string
  date: string
  description?: string
  link?: string
  image?: string
  isShowLike?: boolean
  isLiked?: boolean | null
  likeCount?: number | null
  tags?: string[]
  soundPath?: string
  contentId: number
  isReaded?: boolean | null
  isShowMobileDescription?: boolean
}
export const AppListItem: React.FC<AppListItemProps & StyleProps> = ({
  title,
  date,
  description,
  link,
  image,
  isShowLike,
  isLiked,
  likeCount,
  tags,
  soundPath,
  contentId,
  isReaded,
  isShowMobileDescription = false,
  ...props
}) => {
  // Mobileの場合の表示制御
  const isMobile = useBreakpointValue({ base: true, md: false })
  const navigate = useNavigate()

  const imageContainer = (
    <>
      {!isMobile && <Icon as={Calendar} color="grand.gray" boxSize="48px" />}
      {!isMobile && image && (
        <Image
          src={image}
          fallbackSrc="/noimage.png"
          w="240px"
          h="135px"
          objectFit="cover"
        />
      )}
    </>
  )

  const textContainer = (
    <Flex flexDirection="column" gap={{ base: '4px', md: '8px' }} flex="1">
      <Flex columnGap="8px" rowGap="4px" wrap="wrap" alignItems="center">
        {!isReaded && <AppNewLabel />}
        {tags &&
          tags.length > 0 &&
          tags.map((tag) => <AppTagText label={tag} key={tag} />)}
        <AppDateText label={date} />
      </Flex>
      <Text
        fontSize={p2r(24)}
        fontWeight={isReaded ? '400' : '700'}
        color={
          isReaded ? 'text.body' : { base: '#3a3a3a', md: 'text.headline' }
        }
        lineHeight="150%"
        noOfLines={2}
      >
        {title}
      </Text>
      {(isShowMobileDescription || !isMobile) && description && (
        <Text
          pt={{ base: '0', md: '8px' }}
          fontSize={p2r(16)}
          fontWeight="400"
          lineHeight="150%"
          color="text.sub"
          noOfLines={2}
        >
          {description}
        </Text>
      )}
    </Flex>
  )

  return (
    <Flex
      px={{ base: '16px', md: '32px' }}
      py={{ base: '8px', md: '16px' }}
      gap={{ base: '8px', md: '16px' }}
      alignItems="center"
      borderBottomWidth="1px"
      _last={{ borderBottomWidth: 0 }}
      borderBottomColor="grand.grayLight"
      bgColor={isReaded ? 'grand.graySuperLight' : 'grand.white'}
      {...props}
    >
      {imageContainer}
      <Flex flexDirection="column" gap="8px" flex="1">
        {/* linkの有無でaタグ付与切り替え */}
        {link ? (
          <Box
            onClick={() => {
              navigate(link)
            }}
          >
            {textContainer}
          </Box>
        ) : (
          <Box>{textContainer}</Box>
        )}
        {isShowLike && (
          <AppLikeButton isLiked={isLiked} contentId={contentId} />
        )}
      </Flex>
      {soundPath && <AppSoundPlayButton soundPath={soundPath} />}
    </Flex>
  )
}
