import { atom } from 'recoil'
import {
  UserConfig,
  UserConfigFontSize,
  UserConfigHomeTypeMobile,
} from '../models/user'
import { getTenantId } from '../utils/auth'

export const userConfigState = atom<UserConfig>({
  key: 'userConfig',
  default: {
    fontSize: UserConfigFontSize.MEDIUM,
    tenantId: Number(getTenantId()) || null,
    homeTypeMobile: UserConfigHomeTypeMobile.TYPE_B,
    playingAudioUrl: null,
  },
})
