import {
  useQuery,
  UseQueryResult,
  QueryKey,
  UseQueryOptions,
} from '@tanstack/react-query'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

type BaseQueryOptions<TRes> = {
  queryKey: QueryKey
  queryFn: (config: AxiosRequestConfig) => Promise<AxiosResponse<TRes, any>>
} & UseQueryOptions

const useBaseQuery = <TRes>(
  options: BaseQueryOptions<TRes>,
): UseQueryResult<TRes, AxiosError> => {
  const { queryKey, queryFn, ...rest } = options
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const config: AxiosRequestConfig = {
        headers: {},
      }
      const res = await queryFn(config)
      return res.data
    },
    cacheTime: 0, // react-queryのcacheがデフォルト5分になっているため、OFFにする（ログイン情報などのキャッシュを防ぐため）
    ...rest,
  })

  return query as UseQueryResult<TRes, AxiosError>
}

export default useBaseQuery
