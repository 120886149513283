import { Box, ChakraProvider, extendTheme } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from 'react-router-dom'
import './App.css'
import { RecoilRoot } from 'recoil'
import AppConfirmProvider from './components/common/AppConfirm/AppConfirmProvider'
import AuthGuard from './components/guard/AuthGuard'
import NotAuthGuard from './components/guard/NotAuthGuard'
import { AuthProvidor } from './context/AuthContext'
import { UserPersistConfigProvider } from './context/UserPersistConfigContext'
import LocaleProvider from './i18n/LocaleProvider'
import BasicLayout from './layouts/BasicLayout'

// page components
import Authentications from './pages/Authentications'
import BousaiDetail from './pages/BousaiDetail'
import BousaiHome from './pages/BousaiHome'
import BousaiList from './pages/BousaiList'
import BousaiMovie from './pages/BousaiMovie'
import BousaiSeries from './pages/BousaiSeries'
import BousaiSonae from './pages/BousaiSonae'
import BousaiSonaeMap from './pages/BousaiSonaeMap'
import BousaiUryou from './pages/BousaiUryou'
import Calendar from './pages/Calendar'
import Debug from './pages/Debug'
import DebugVideoPrivateTest from './pages/Debug/VideoPrivateTest'
import DekigotoDetail from './pages/DekigotoDetail'
import DekigotoList from './pages/DekigotoList'
import Home from './pages/Home'
import KairanDetail from './pages/KairanDetail'
import KairanList from './pages/KairanList'
import KinkyuDetail from './pages/KinkyuDetail'
import Login from './pages/Login'
import LoginResetPassword from './pages/LoginResetPassword'
import LoginResetPasswordCompleted from './pages/LoginResetPassword/completed'
import MusenList from './pages/MusenList'
import Mypage from './pages/Mypage'
import MypageEdit from './pages/MypageEdit'
import MypageFont from './pages/MypageFont'
import MypageMyNumber from './pages/MypageMyNumber'
import MypageNotification from './pages/MypageNotification'
import MypagePassword from './pages/MypagePassword'
import MypagePasswordCompleted from './pages/MypagePassword/completed'
import MypageProfile from './pages/MypageProfile'
import MypageSample from './pages/MypageSample'
import MypageTaxi from './pages/MypageTaxi'
import MypageUpdateEmail from './pages/MypageUpdateEmail'
import MypageUpdatePassword from './pages/MypageUpdatePassword'
import MypageUpdatePhone from './pages/MypageUpdatePhone'
import MypageVideo from './pages/MypageVideo'
import MypageWrapper from './pages/MypageWrapper'
import Notification from './pages/Notification'
import OAuthLogin from './pages/OAuthLogin'
import PDFViewer from './pages/PDFViewer'
import Policy from './pages/Policy'
import ShikyoDetail from './pages/ShikyoDetail'
import ShikyoList from './pages/ShikyoList'
import Signup from './pages/Signup'
import SignupCompleted from './pages/Signup/completed'
import Term from './pages/Term'
import Trash from './pages/Trash'
import TrashCalendar from './pages/TrashCalendar'
import TrashLatest from './pages/TrashLatest'
import VideoFriendAdd from './pages/VideoFriendAdd'
import VideoFriendBlock from './pages/VideoFriendBlock'
import VideoGroup from './pages/VideoGroup'
import VideoHome from './pages/VideoHome'
import VideoPrivate from './pages/VideoPrivate'
import VideoRoomMeetingList from './pages/VideoRoomMeetingList'
import VideoScreenSample from './pages/VideoScreenSample'
import Weather from './pages/Weather'
import WeatherRadar from './pages/WeatherRadar'
import WeatherResponsive from './pages/WeatherResponsive'
import WeatherTime from './pages/WeatherTime'
import WeatherWeekly from './pages/WeatherWeekly'
import AdminTaxiCalendar from './pages/taxi/AdminTaxiCalendar'
import AdminTaxiCalendarMonth from './pages/taxi/AdminTaxiCalendarMonth'
import AdminTaxiCalendarThreedays from './pages/taxi/AdminTaxiCalendarThreedays'
import AdminTaxiCalendarToday from './pages/taxi/AdminTaxiCalendarToday'
import AdminTaxiReservationCreate from './pages/taxi/AdminTaxiReservationCreate'
import AdminTaxiReservationCreateSelectInput from './pages/taxi/AdminTaxiReservationCreateSelectInput'
import AdminTaxiReservationOnline from './pages/taxi/AdminTaxiReservationOnline'
import TaxiCompanyList from './pages/taxi/TaxiCompanyList'
import TaxiReservationCreate from './pages/taxi/TaxiReservationCreate'
import TaxiReservationCreateConfirm from './pages/taxi/TaxiReservationCreateConfirm'
import TaxiReservationCreateReturnSelectDate from './pages/taxi/TaxiReservationCreateReturnSelectDate'
import TaxiReservationCreateReturnSelectTime from './pages/taxi/TaxiReservationCreateReturnSelectTime'
import TaxiReservationCreateSelectCompany from './pages/taxi/TaxiReservationCreateSelectCompany'
import TaxiReservationCreateSelectDate from './pages/taxi/TaxiReservationCreateSelectDate'
import TaxiReservationCreateSelectFrom from './pages/taxi/TaxiReservationCreateSelectFrom'
import TaxiReservationCreateSelectTime from './pages/taxi/TaxiReservationCreateSelectTime'
import TaxiReservationCreateSelectTo from './pages/taxi/TaxiReservationCreateSelectTo'
import TaxiReservationDetail from './pages/taxi/TaxiReservationDetail'
import TaxiReservationFailed from './pages/taxi/TaxiReservationFailed'
import TaxiReservationFix from './pages/taxi/TaxiReservationFix'
import TaxiReservationPreFix from './pages/taxi/TaxiReservationPreFix'
import TaxiReservationTel from './pages/taxi/TaxiReservationTel'
import TaxiTop from './pages/taxi/TaxiTop'

import customTheme from './theme'

const theme = extendTheme(customTheme)

const queryClient = new QueryClient()

// NOTE: 本番環境では非公開にするコンテンツ
// <Route element={<PreparationGuard />}>〜</Route> とする
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<BasicLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/term" element={<Term />} />
      <Route path="/dekigoto" element={<DekigotoList />}>
        <Route path=":id" element={<DekigotoDetail />} />
      </Route>
      <Route path="/kairan" element={<KairanList />} />
      <Route path="/kairan/:id" element={<KairanDetail />} />
      <Route path="/kinkyu/:id" element={<KinkyuDetail />} />
      <Route path="/musen" element={<MusenList />} />
      <Route path="/weather" element={<Weather />}>
        <Route path="weekly" element={<WeatherWeekly />} />
        <Route path="radar" element={<WeatherRadar />} />
        <Route path="time" element={<WeatherTime />} />
        <Route path="forecast" element={<WeatherResponsive />} />
      </Route>
      <Route path="/bousai" element={<BousaiHome />} />
      <Route path="/bousai/sonae" element={<BousaiSonae />} />
      <Route path="/bousai/sonae-map" element={<BousaiSonaeMap />} />
      <Route path="/bousai/movie" element={<BousaiMovie />} />
      <Route path="/bousai/all" element={<BousaiList />} />
      <Route path="/bousai/series" element={<BousaiSeries />} />
      <Route path="/bousai/:id" element={<BousaiDetail />} />
      <Route path="/trash" element={<Trash />}>
        <Route path="latest" element={<TrashLatest />} />
        <Route path="calendar" element={<TrashCalendar />} />
      </Route>
      <Route path="/shikyo" element={<ShikyoList />} />
      <Route path="/shikyo/:id" element={<ShikyoDetail />} />

      {/* 雨量・河川情報は非表示 */}
      <Route path="/bousai/uryou" element={<BousaiUryou />} />

      {/* ログインが必須のページ ここから */}
      <Route element={<AuthGuard />}>
        <Route path="/mypageSample" element={<MypageSample />} />
        <Route path="/mypage" element={<Mypage />} />
        <Route path="/mypage" element={<MypageWrapper />}>
          <Route path="profile" element={<MypageProfile />} />
          <Route path="edit" element={<MypageEdit />} />
          <Route path="updateEmail" element={<MypageUpdateEmail />} />
          <Route path="updatePhone" element={<MypageUpdatePhone />} />
          <Route path="updatePassword" element={<MypageUpdatePassword />} />
          <Route path="font" element={<MypageFont />} />
          <Route path="video" element={<MypageVideo />} />
          <Route path="taxi" element={<MypageTaxi />} />
          <Route path="mynumber" element={<MypageMyNumber />} />
          <Route path="password" element={<MypagePassword />} />
          <Route path="notification" element={<MypageNotification />} />
          <Route
            path="password/completed"
            element={<MypagePasswordCompleted />}
          />
        </Route>
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/video" element={<VideoHome />} />
        <Route path="/video/private" element={<VideoPrivate />} />
        <Route path="/video/group" element={<VideoGroup />} />
        <Route path="/video/friend-add" element={<VideoFriendAdd />} />
        <Route path="/video/friend-block" element={<VideoFriendBlock />} />
        <Route path="/video/room-meeting" element={<VideoRoomMeetingList />} />
        <Route path="/video/screen-sample" element={<VideoScreenSample />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/taxi" element={<TaxiTop />} />
        <Route path="/taxi/taxi-companies" element={<TaxiCompanyList />} />
        <Route
          path="/taxi/reservations/:id"
          element={<TaxiReservationDetail />}
        />
        <Route path="/taxi/reservations/tel" element={<TaxiReservationTel />} />
        <Route
          path="/taxi/reservations/create"
          element={<TaxiReservationCreate />}
        >
          <Route
            path="select-company"
            element={<TaxiReservationCreateSelectCompany />}
          />
          <Route path="select-to" element={<TaxiReservationCreateSelectTo />} />
          <Route
            path="select-from"
            element={<TaxiReservationCreateSelectFrom />}
          />
          <Route
            path="select-date"
            element={<TaxiReservationCreateSelectDate />}
          />
          <Route
            path="select-time"
            element={<TaxiReservationCreateSelectTime />}
          />
          <Route
            path="return-select-date"
            element={<TaxiReservationCreateReturnSelectDate />}
          />
          <Route
            path="return-select-time"
            element={<TaxiReservationCreateReturnSelectTime />}
          />
        </Route>
        <Route
          path="/taxi/reservations/create/confirm"
          element={<TaxiReservationCreateConfirm />}
        />
        <Route
          path="/taxi/reservations/failed"
          element={<TaxiReservationFailed />}
        />
        <Route
          path="/taxi/reservations/pre-fix"
          element={<TaxiReservationPreFix />}
        />
        <Route path="/taxi/reservations/fix" element={<TaxiReservationFix />} />
      </Route>
      <Route path="/admin/taxi/calendar" element={<AdminTaxiCalendar />}>
        <Route path="today" element={<AdminTaxiCalendarToday />} />
        <Route path="threedays" element={<AdminTaxiCalendarThreedays />} />
        <Route path="month" element={<AdminTaxiCalendarMonth />} />
      </Route>
      <Route
        path="/admin/taxi/reservations/create"
        element={<AdminTaxiReservationCreate />}
      >
        <Route
          path=""
          element={<Navigate to="select-input" replace={false} />}
        />
        <Route
          path="select-input"
          element={<AdminTaxiReservationCreateSelectInput />}
        />
      </Route>
      <Route
        path="/admin/taxi/reservations/online"
        element={<AdminTaxiReservationOnline />}
      />
      <Route path="/authentications" element={<Authentications />} />
      {/* ログインが必須のページ ここまで */}

      {/* 未ログイン状態専用のページ ここから */}
      <Route element={<NotAuthGuard />}>
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/completed" element={<SignupCompleted />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/reset" element={<LoginResetPassword />} />
        <Route path="/oauth/login" element={<OAuthLogin />} />
        <Route
          path="/login/reset/completed"
          element={<LoginResetPasswordCompleted />}
        />
      </Route>
      {/* 未ログイン状態専用のページ ここまで */}

      {/* TODO PDF動作確認用 電子回覧板に実装したら消す */}
      <Route path="/pdf-sample" element={<PDFViewer />} />
      <Route path="/debug" element={<Debug />} />
      <Route
        path="/debug/video-private-test"
        element={<DebugVideoPrivateTest />}
      />
      <Route
        path="/404"
        element={<Box>このページは削除されたか存在しないページです</Box>}
      />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Route>,
  ),
)

const App: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <RecoilRoot>
        <LocaleProvider>
          <AuthProvidor>
            <UserPersistConfigProvider>
              <AppConfirmProvider>
                <RouterProvider router={router} />
              </AppConfirmProvider>
            </UserPersistConfigProvider>
          </AuthProvidor>
        </LocaleProvider>
      </RecoilRoot>
    </ChakraProvider>
  </QueryClientProvider>
)

export default App
