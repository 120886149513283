import { Icon } from '@chakra-ui/react'
import React from 'react'

const WeatherCloudyRain: React.FC = (props) => (
  <Icon boxSize="80px" viewBox="0 0 80 80" {...props}>
    <rect width="80" height="80" rx="10" fill="#BDBDBD" />
    <g clipPath="url(#clip0_2138_26007)">
      <path
        d="M25.2994 28.2944C28.4977 25.5364 32.577 24.0132 36.8002 24C45.4082 24 52.5538 30.4 53.3314 38.6528C58.4258 39.3728 62.4002 43.6384 62.4002 48.8736C62.4002 54.6208 57.6066 59.2 51.7986 59.2H23.2994C16.6658 59.2 11.2002 53.9712 11.2002 47.4176C11.2002 41.776 15.2514 37.104 20.6146 35.92C21.0722 33.1584 22.8482 30.4064 25.2994 28.2944Z"
        fill="white"
      />
    </g>
    <path
      d="M55.5673 40.0333V32.4333H43.6673H42.6673V31.4333C42.6673 23.7393 48.3305 17.3719 55.7162 16.27C55.7167 16.2688 55.7171 16.2676 55.7175 16.2664L55.724 16.2688L55.7175 16.2664C55.8294 15.9629 56.0007 15.6847 56.2214 15.4482C56.4421 15.2116 56.7077 15.0214 57.0028 14.8888C57.2979 14.7561 57.6164 14.6836 57.9398 14.6755C58.2632 14.6674 58.585 14.7239 58.8863 14.8416C59.1877 14.9594 59.4625 15.1361 59.6947 15.3613C59.927 15.5865 60.112 15.8558 60.2389 16.1534L60.0449 16.2361L60.2389 16.1534C60.2553 16.1919 60.2707 16.2308 60.2851 16.27C67.6708 17.372 73.334 23.7408 73.334 31.4333V32.4333H72.334H60.434V40.0333C60.434 41.439 59.8756 42.787 58.8816 43.781C57.8877 44.7749 56.5396 45.3333 55.134 45.3333C53.7283 45.3333 52.3803 44.7749 51.3863 43.781C50.3924 42.787 49.834 41.439 49.834 40.0333C49.834 39.388 50.0903 38.769 50.5467 38.3127C51.003 37.8564 51.622 37.6 52.2673 37.6C52.9127 37.6 53.5316 37.8564 53.9879 38.3127C54.4443 38.769 54.7006 39.388 54.7006 40.0333C54.7006 40.1483 54.7463 40.2585 54.8276 40.3397C54.9088 40.421 55.0191 40.4667 55.134 40.4667C55.2489 40.4667 55.3591 40.421 55.4404 40.3397L56.1475 41.0468L55.4404 40.3397C55.5217 40.2585 55.5673 40.1482 55.5673 40.0333Z"
      fill="#2F80ED"
      stroke="#BDBDBD"
      strokeWidth="2"
    />
    <defs>
      <clipPath id="clip0_2138_26007">
        <rect
          width="51.2"
          height="51.2"
          fill="white"
          transform="translate(11.2002 17.6)"
        />
      </clipPath>
    </defs>
  </Icon>
)

export default WeatherCloudyRain
