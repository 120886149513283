import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

export type AppDateTextProps = {
  label: string
} & TextProps

export const AppDateText: React.FC<AppDateTextProps> = (props) => {
  const { label, ...rest } = props
  return (
    <Text
      fontSize={{ base: p2r(12), md: p2r(16) }}
      color={{ base: '#9d9d9d', md: 'text.sub' }}
      fontWeight={{ base: '400', md: '700' }}
      {...rest}
    >
      {label}
    </Text>
  )
}
