import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'
import { TaxiReservationResponse } from '../../../openapi'
import AppModal from '../../common/AppModal'
import { TaxiReservationDetail } from './TaxiReservationDetail'

type AdminTaxiReservationContextType = {
  showModal: (taxiReservation: TaxiReservationResponse) => void
  hideModal: () => void
}

export const AdminTaxiReservationModalContext =
  createContext<AdminTaxiReservationContextType>({
    showModal: () => {},
    hideModal: () => {},
  })

export function useAdminTaxiReservationModalContext() {
  const context = useContext(AdminTaxiReservationModalContext)

  if (!context)
    throw new Error(
      'useAdminTaxiReservationModalContext context must be use inside AdminTaxiReservationModalProvider',
    )

  return context
}

type Props = {
  children: ReactNode
}

export const AdminTaxiReservationModalProvider: React.FC<Props> = ({
  children,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [taxiReservation, setTaxiReservation] =
    useState<TaxiReservationResponse | null>(null)

  const showModal = (tr: TaxiReservationResponse) => {
    setTaxiReservation(tr)
    setModalOpen(true)
  }
  const hideModal = () => {
    setModalOpen(false)
  }

  const value = useMemo(
    () => ({
      showModal,
      hideModal,
    }),
    [],
  )

  return (
    <AdminTaxiReservationModalContext.Provider value={value}>
      {children}
      <AppModal
        title="予約の詳細"
        isOpen={modalOpen}
        onClose={hideModal}
        isShowOk={false}
        isShowCancel={false}
        maxWidth="80vw"
      >
        {taxiReservation !== null && (
          <TaxiReservationDetail
            taxiReservation={taxiReservation}
            onFinish={hideModal}
            onClickCancel={hideModal}
          />
        )}
      </AppModal>
    </AdminTaxiReservationModalContext.Provider>
  )
}
