import { Box, Grid, GridItem, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppLoading } from '../../components/common/AppLoading'
import useShikyoQuery from '../../hooks/coreApi/useShikyoQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { p2r } from '../../utils/font'

export type ShikyoCardProps = {
  path: string
  label: string
  imgPath: string
}
const ShikyoCard: React.FC<ShikyoCardProps> = (props) => (
  <GridItem
    bgColor="grand.white"
    rounded="8px"
    borderWidth={{
      base: '2px',
      md: '4px',
    }}
    borderColor="theme.primary"
    boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2)"
  >
    <Link to={props.path}>
      <Flex direction="column">
        <Image
          src={props.imgPath}
          h="18vh"
          objectFit="cover"
          borderRadius="4px 4px 0 0"
          fallbackSrc="/images/shikyo/noimage.png"
        />
        <Text
          py="4px"
          textAlign="center"
          fontSize={{ base: p2r(18), md: p2r(24) }}
          fontWeight="700"
          borderTopWidth={{
            base: '2px',
            md: '4px',
          }}
          borderTopColor="theme.primary"
        >
          {props.label}
        </Text>
      </Flex>
    </Link>
  </GridItem>
)

const ShikyoList: React.FC = () => {
  useHeaderConfig({
    title: '市況情報',
  })

  const { f } = useFormatMessage()

  const { data: products, isLoading, isError, error } = useShikyoQuery()

  if (isLoading) {
    return <AppLoading />
  }

  if (isError) {
    return <AppErrorResult error={error} />
  }

  return (
    <Box minH="93vh" bgColor="theme.background">
      <Box
        px={{ base: '17px', md: '112px' }}
        py={{ base: '16px', md: '2.8vh' }}
      >
        <Grid
          templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
          gap={{ base: '16px', md: '24px' }}
        >
          {products.map(
            (product: any) =>
              product['品目ID'] && (
                <ShikyoCard
                  key={product['品目ID']}
                  path={`/shikyo/${product['品目ID']}`}
                  label={product['品目名']}
                  imgPath={`/images/shikyo/${product['品目ID']}.png`}
                />
              ),
          )}
        </Grid>
        <Flex mt="24px" align="center">
          <Text
            fontSize={p2r(16)}
            lineHeight="150%"
            color="text.body"
            fontWeight="bold"
          >
            {f('情報提供元')} ： JA名西郡 /
          </Text>
          <Image
            src="/images/shikyo/tvtokushima_logo.svg"
            alt="テレビトクシマ"
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default ShikyoList
