import {
  Box,
  Flex,
  Text,
  Select,
  Grid,
  GridItem,
  useBreakpointValue,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { AppErrorAlert } from '../../components/common/AppErrorAlert'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppLoading } from '../../components/common/AppLoading'
import { TrashCalendarTypeIcon } from '../../components/trashCalendar/TrashCalendarTypeIcon'
import { useAuthContext } from '../../context/AuthContext'
import { useTrashCalendarListQuery } from '../../hooks/coreApi/useTrashCalendarListQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import { TrashCalendarResponse } from '../../openapi'
import { p2r } from '../../utils/font'

export type TrashCardProps = {
  date: string
  trashType: string
  label: string
}
const TrashCard: React.FC<TrashCardProps> = (props) => {
  const { f } = useFormatMessage()
  const { date, trashType, label } = props
  const month = dayjs(date).format('M')
  const day = dayjs(date).format('D')
  const weekday = dayjs(date).format('ddd')

  return (
    <GridItem
      flexDirection="column"
      bgColor="grand.white"
      rounded="10px"
      borderWidth="1px"
      borderColor="grand.grayLight"
      h={{ base: 'unset', md: '280px' }}
      alignItems={{ base: 'unset', md: 'center' }}
      justifyContent={{ base: 'unset', md: 'center' }}
      display="flex"
      rowGap={{ base: '8px', md: 'unset' }}
      py={{ base: '8px', md: 'unset' }}
      px={{ base: '16px', md: 'unset' }}
    >
      <Flex alignItems="baseline" pb={{ base: 'unset', md: '40px' }}>
        <Text
          fontWeight="700"
          fontSize={{ base: p2r(20), md: p2r(24) }}
          color="text.body"
          lineHeight="150%"
        >
          {month}
          <Box as="span" fontSize={p2r(16)}>
            {f('月')}
          </Box>
          {day}
          <Box as="span" fontSize={p2r(16)}>
            {f('日')}({f(weekday)})
          </Box>
        </Text>
      </Flex>
      <Flex
        gap={{ base: '16px', md: '8px' }}
        direction={{ base: 'row', md: 'column' }}
        alignItems="center"
      >
        <TrashCalendarTypeIcon
          boxSize={{ base: '48px', md: '80px' }}
          trashType={trashType || ''}
        />
        <Text
          fontSize={{ base: p2r(24), md: p2r(20) }}
          fontWeight="700"
          color="text.body"
        >
          {f(label)}
        </Text>
      </Flex>
    </GridItem>
  )
}

const TrashLatest: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { f } = useFormatMessage()
  const { me } = useAuthContext()

  // エリア選択
  const [areaId, setAreaId] = useState<number>(1)

  // ゴミ出し情報取得
  const trashCalendarListQuery = useTrashCalendarListQuery({
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().add(1, 'years').format('YYYY-MM-DD'),
    withTrashCalendarDates: true,
  })
  const trashCalendars = trashCalendarListQuery.data?.trashCalendars

  useEffect(() => {
    if (!me || !me.trashCalendarId) {
      return
    }
    setAreaId(me.trashCalendarId)
  }, [me])

  // エリア切り替え
  const areaSelect: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    setAreaId(Number(event.target.value))
  }
  // 選択中のエリア名
  const areaName = useMemo(() => {
    if (!trashCalendars) {
      return ''
    }
    return trashCalendars.find((info) => info.id === areaId)?.name
  }, [me, trashCalendars, areaId])

  const currentTrashCalendar = useMemo<
    TrashCalendarResponse | undefined
  >(() => {
    if (!trashCalendars) {
      return undefined
    }
    return trashCalendars.find((c) => c.id === areaId)
  }, [trashCalendars, areaId])

  const trashCalendarDates = useMemo(() => {
    if (!currentTrashCalendar) {
      return []
    }

    // 直近5件
    return currentTrashCalendar.trashCalendarDates?.slice(0, 5) || []
  }, [currentTrashCalendar])

  if (trashCalendarListQuery.isLoading) {
    return <AppLoading />
  }
  if (trashCalendarListQuery.isError) {
    return <AppErrorResult error={trashCalendarListQuery.error} />
  }

  if (!trashCalendars) {
    return <AppErrorAlert error="カレンダーのデータがありません" />
  }

  return (
    <Box
      bgColor="theme.background"
      py={{ base: '8px', md: 'unset' }}
      px={{ base: '16px', md: 'unset' }}
    >
      <Text
        color="text.sub"
        fontSize={{ base: p2r(16), md: p2r(20) }}
        fontWeight={{ base: '400', md: '700' }}
        pt={{ base: 'unset', md: '24px' }}
        pb={{ base: 'unset', md: '91px' }}
        px={{ base: 'unset', md: '32px' }}
        lineHeight="150%"
      >
        {f('直近5日間のゴミ出しが表示されます。')}
      </Text>

      <Flex direction="column" px={{ base: 'unset', md: '81px' }}>
        <Flex
          wrap="wrap"
          justify={{ base: 'flex-start', md: 'flex-end' }}
          gap={{ base: '8px', md: '20px' }}
          mt={{ base: '8px', md: 'unset' }}
          mb={{ base: '16px', md: 'unset' }}
        >
          {!isMobile && areaName && (
            <Flex
              bgColor="grand.white"
              color="theme.primary"
              justify={{ base: 'unset', md: 'center' }}
              alignItems="center"
              columnGap="8px"
              p={{ base: '5px 8px 5px 16px', md: '5px 16px 5px 16px' }}
              rounded="8px"
              borderWidth="1px"
              borderColor="theme.primary"
              fontSize={{ base: p2r(12), md: p2r(14) }}
              fontWeight="700"
              order={{ base: '2', md: 'unset' }}
            >
              {areaName}
            </Flex>
          )}
          <Select
            w={{ base: 'fit-content', md: '140px' }}
            h={{ base: '32px', md: '40px' }}
            placeholder={isMobile ? '' : f('エリア選択')}
            bg="theme.primary"
            color="text.white"
            fontSize={{ base: p2r(10), md: p2r(14) }}
            fontWeight="700"
            rounded="8px"
            value={isMobile ? areaId : ''}
            onChange={areaSelect}
            order={{ base: '1', md: 'unset' }}
            size={isMobile ? 'xs' : 'lg'}
          >
            {trashCalendars.map((calendarArea) => (
              <option key={calendarArea.id} value={calendarArea.id}>
                {calendarArea.name}
              </option>
            ))}
          </Select>
        </Flex>
        <Grid
          gap="8px"
          templateRows={{ base: 'repeat(5, 1fr)', md: 'unset' }}
          templateColumns={{ base: 'unset', md: 'repeat(5, 1fr)' }}
          py={{ base: 'unset', md: '24px' }}
        >
          {trashCalendarDates.map((d) => (
            <TrashCard
              key={d.id}
              date={d.date || ''}
              trashType={d.trashType || ''}
              label={d.trashType || ''}
            />
          ))}
        </Grid>
      </Flex>
    </Box>
  )
}

export default TrashLatest
