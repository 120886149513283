import { Icon } from '@chakra-ui/react'
import React from 'react'

const SelectArrowBottom: React.FC = (props) => (
  <Icon boxSize="32px" viewBox="0 0 32 32" {...props}>
    <path
      d="M25.143 10.1438C24.8682 9.60372 24.4075 9.33366 23.7608 9.33366H8.24176C7.59513 9.33366 7.13441 9.60372 6.85959 10.1438C6.56861 10.684 6.60902 11.1932 6.98083 11.6716L14.7404 22.042C15.0475 22.4587 15.4678 22.667 16.0013 22.667C16.5348 22.667 16.9551 22.4587 17.2622 22.042L25.0218 11.6716C25.3936 11.1932 25.434 10.684 25.143 10.1438Z"
      fill="currentColor"
    />
  </Icon>
)

export default SelectArrowBottom
