import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { MdPhoneInTalk } from 'react-icons/md'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { p2r } from '../../../utils/font'

/**
 * リスト
 */
export const VideoGroupList: React.FC<{ children: ReactNode }> = ({
  children,
}) => <Flex direction="column">{children}</Flex>

/**
 * メインコンポーネント
 */
export type VideoGroupListItemProps = {
  title: string // グループ名
  participantsCount?: number // 参加者
  onClick?: () => void
  isOpen: boolean
}

export const VideoGroupListItem: React.FC<VideoGroupListItemProps> = (
  props,
) => {
  const { f } = useFormatMessage()

  const isMobile = useBreakpointValue({ base: true, md: false })

  // ステータス
  const isOpen = props.isOpen || false

  // 参加者
  const participantsCount = props.participantsCount || 0

  return (
    <Flex
      px={isMobile ? '16px' : '32px'}
      py={isMobile ? '16px' : '26px'}
      justify="space-between"
      align="center"
      backgroundColor={isMobile ? 'unset' : 'grand.white'}
      columnGap="8px"
      borderBottomWidth="1px"
      borderBottomColor="#eee"
      _last={{ borderBottomWidth: 0 }}
      onClick={() => {
        if (props.onClick) {
          props.onClick()
        }
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        rowGap="4px"
        columnGap="24px"
        flexWrap="wrap"
        flex="1"
      >
        <Box
          display="flex"
          gap={isMobile ? '0px' : '8px'}
          alignItems={isMobile ? 'flex-start' : 'center'}
          w="fit-content"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Text
            backgroundColor={isOpen ? 'grand.blueLight' : 'grand.error'}
            color="text.white"
            fontSize={isMobile ? p2r(12) : p2r(16)}
            fontWeight="700"
            borderRadius="30px"
            px="8px"
            py="4px"
            height="fit-content"
            minW={isMobile ? p2r(64) : 'unset'}
            textAlign="center"
          >
            {isOpen ? f('空室') : f('通話中')}
          </Text>
          <Text
            color="text.body"
            fontSize={p2r(24)}
            fontWeight="700"
            lineHeight="150%"
            flex="1"
          >
            {props.title}
          </Text>
        </Box>

        {/* 通話中の場合は参加者アイコン表示 */}
        {!isMobile && (
          <Box minH={p2r(24)}>
            {participantsCount >= 1 && (
              <Text
                fontSize={p2r(16)}
                fontWeight="700"
                color="text.body"
                lineHeight="150%"
              >
                {participantsCount}名が参加中
              </Text>
            )}
          </Box>
        )}
      </Box>

      <Button
        px={isMobile ? '16px' : '29px'}
        py="8px"
        variant="outline"
        borderRadius="80px"
        backgroundColor={isOpen ? 'theme.primary' : 'text.white'}
        borderWidth="2px"
        borderColor="theme.primary"
        display="flex"
        columnGap="10px"
        w={isMobile ? 'fit-content' : p2r(148)}
        h={isMobile ? p2r(40) : p2r(48)}
      >
        {isOpen && (
          <Icon
            as={MdPhoneInTalk}
            boxSize={isMobile ? p2r(20) : p2r(24)}
            color="text.white"
          />
        )}
        <Text
          fontSize={p2r(16)}
          color={isOpen ? 'text.white' : 'theme.primary'}
          fontWeight="700"
        >
          {isOpen ? f('入室する') : f('使用中')}
        </Text>
      </Button>
    </Flex>
  )
}
