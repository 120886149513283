import { Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TaxiButton } from '../../../components/taxi/TaxiButton'
import { TaxiTitle } from '../../../components/taxi/TaxiTitle'
import useFormatMessage from '../../../hooks/useFormatMessage'
import useHeaderConfig from '../../../hooks/useHeaderConfig'
import { p2r } from '../../../utils/font'
import useTaxiReservationCreateState, {
  defaultFormValues,
  defaultTaxiRideDateTime,
} from '../useTaxiReservationCreateState'

// 仮予約不可
const TaxiReservationFailed: React.FC = () => {
  useHeaderConfig({
    title: 'タクシー予約',
  })
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  const isMobile = useBreakpointValue({ base: true, md: false })

  const { setFormValues, setTaxiRideDateTime } = useTaxiReservationCreateState()

  return (
    <Flex
      bgColor={{ base: '#FFFDFC', md: 'theme.background' }}
      //  TODO ヘッダー削除後、高さ変更
      minH={{ base: 'calc(100vh - 56px)', md: 'calc(100vh - 56px)' }}
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Flex
        justify="center"
        alignItems="center"
        direction="column"
        flex={{ base: '1', md: 'unset' }}
        gap={{ base: '8px', md: '32px' }}
      >
        <TaxiTitle
          label={
            isMobile
              ? f('仮予約できませんでした')
              : f('仮予約を受け付けできませんでした')
          }
        />
        <Flex
          pb={{ base: '0px', md: '56px' }}
          direction="column"
          alignItems="center"
        >
          <Text
            fontSize={{ base: p2r(16), md: p2r(20) }}
            color="text.sub"
            textAlign={{ base: 'center', md: 'left' }}
            whiteSpace="pre-wrap"
          >
            {f(
              `申し訳ございません。${
                isMobile ? '\n' : ''
              }仮予約を行うことができませんでした。`,
            )}
          </Text>
          <Text
            fontSize={{ base: p2r(16), md: p2r(20) }}
            color="text.sub"
            textAlign={{ base: 'center', md: 'left' }}
          >
            {f('内容を変更してもう一度お願いいたします。')}
          </Text>
        </Flex>
      </Flex>

      <Flex
        mt={{ base: 'auto', md: 'unset' }}
        columnGap={{ base: '0px', md: '32px' }}
        rowGap={{ base: '8px', md: '0px' }}
        direction={{ base: 'column', md: 'row' }}
        w={{ base: '100%', md: 'auto' }}
        p={{ base: '16px', md: '0px' }}
        backgroundColor={{ base: 'grand.white', md: 'unset' }}
        boxShadow={{ base: '0px 0px 10px rgba(0, 0, 0, 0.1)', md: 'unset' }}
      >
        <TaxiButton
          onClick={() => {
            // リセット
            setFormValues({
              ...defaultFormValues,
            })
            setTaxiRideDateTime({
              ...defaultTaxiRideDateTime,
            })
            navigate('/')
          }}
          isLarge
          isReverse
        >
          {f('ホームに戻る')}
        </TaxiButton>
        <TaxiButton
          onClick={() => {
            navigate('/taxi/reservations/create')
          }}
          isLarge
        >
          {f('内容を変更する')}
        </TaxiButton>
      </Flex>
    </Flex>
  )
}

export default TaxiReservationFailed
