import { IconType } from 'react-icons'
import {
  MdAlarm,
  MdCall,
  MdDelete,
  MdEvent,
  MdFilePresent,
} from 'react-icons/md'

export type NotificationType =
  | 'direct_call'
  | 'dekigoto'
  | 'kairan'
  | 'bousai'
  | 'kinkyu'
  | 'trash_day'

export type NotificationConfig = {
  url: string
  icon: IconType
}
type NotificationConfigMap = { [key in NotificationType]: NotificationConfig }
export const notificationConfigMap: NotificationConfigMap = {
  direct_call: {
    url: '/video',
    icon: MdCall,
  },
  dekigoto: {
    url: '/dekigoto',
    icon: MdEvent,
  },
  kairan: {
    url: '/kairan',
    icon: MdFilePresent,
  },
  bousai: {
    url: '/bousai',
    icon: MdEvent,
  },
  kinkyu: {
    url: '/',
    icon: MdAlarm,
  },
  trash_day: {
    url: '/trash/calendars',
    icon: MdDelete,
  },
}
