import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { MdRefresh } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { p2r } from '../../../utils/font'

const HomePanelError: React.FC = () => {
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      rowGap="16px"
      h="100%"
    >
      <Flex direction="column" rowGap="8px" alignItems="center">
        <Text color="text.body" fontSize="24px" fontWeight="700">
          {f('インターネットに接続されていません')}
        </Text>
        <Text color="text.body" fontSize="16px">
          通信状況を確認してください
        </Text>
      </Flex>
      <Button
        p="16px"
        w="fit-content"
        h="fit-content"
        backgroundColor="theme.primary"
        onClick={() => navigate(0)}
        display="flex"
        columnGap="4px"
        alignItems="center"
      >
        <Icon as={MdRefresh} boxSize={p2r(24)} color="grand.white" />
        <Text fontSize={p2r(20)} color="grand.white">
          {f('タップしてもう一度試す')}
        </Text>
      </Button>
    </Flex>
  )
}

export default HomePanelError
