import axios from 'axios'

const getWeatherRadars = async (
  futureBaseTimes: number[],
  futureValidTimes: number[],
) => {
  const futurenTimesQuantity = 11 // 未来分、2次元配列の個数
  const futureUrlList: string[] = []
  for (let i = futurenTimesQuantity; i > -1; i -= 1) {
    if (futureBaseTimes[i] && futureValidTimes[i]) {
      futureUrlList.push(
        `https://www.jma.go.jp/bosai/jmatile/data/nowc/${futureBaseTimes[i]}/none/${futureValidTimes[i]}/surf/hrpns/8/223/102.png`,
      )
    }
  }

  return futureUrlList
}

const getPastWeatherRadars = async (
  pastBaseTimes: number[],
  pastValidTimes: number[],
) => {
  const pastTimesQuantity = 38 // 過去分、2次元配列の個数
  const pastUrlList: string[] = []
  for (let i = 0; i < pastTimesQuantity; i += 1) {
    if (pastBaseTimes[i] && pastValidTimes[i]) {
      pastUrlList.push(
        `https://www.jma.go.jp/bosai/jmatile/data/nowc/${pastBaseTimes[i]}/none/${pastValidTimes[i]}/surf/hrpns/8/223/102.png`,
      )
    }
  }

  return pastUrlList
}

const WEATHER_API_BASE_URL = 'https://www.jma.go.jp'

const weatherApiAxiosInstance = axios.create({
  baseURL: `${WEATHER_API_BASE_URL}`,
})

const getWeatherWarning = async (weatherAreaCode: string) => {
  const res = await weatherApiAxiosInstance.get(
    `bosai/warning/data/warning/${weatherAreaCode}.json`,
  )
  return res.data
}

// NOTE: 他のエンドポイントが必要になったらここに追加する

export const weatherRadarApiClient = {
  getWeatherRadars,
  getPastWeatherRadars,
  getWeatherWarning,
}
