import {
  Box,
  Button,
  ButtonProps,
  Flex,
  HStack,
  Icon,
  Spacer,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react'
import React from 'react'
import { MdSettingsPhone } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppLoading } from '../../components/common/AppLoading'
import AppModal from '../../components/common/AppModal'
import { AppSegmentedPicker } from '../../components/common/AppSegmentedPicker'
import {
  VideoGroupList,
  VideoGroupListItem,
} from '../../components/video/VideoGroupList'
import {
  useSetVideoLinks,
  VideoMenuLink,
} from '../../components/video/VideoMenuLink'

import { useAuthContext } from '../../context/AuthContext'
import { useRoomListQuery } from '../../hooks/coreApi/useRoomListQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { p2r } from '../../utils/font'
import { createQuolinkVideoRoomUrl } from '../../utils/room'

/**
 * 通常ボタン
 */
export type VideoButtonProps = {
  icon: React.FC
  onClick(): void
  children: React.ReactNode
} & ButtonProps
export const VideoButton: React.FC<VideoButtonProps> = ({
  icon,
  onClick,
  children,
  ...rest
}) => (
  <Button
    h="fit-content"
    px="24px"
    py="4px"
    leftIcon={<Icon as={icon} boxSize="32px" color="grand.grayDark" />}
    color="text.headline"
    fontWeight="700"
    fontSize={p2r(16)}
    variant="outline"
    backgroundColor="grand.white"
    borderColor="theme.primary"
    borderRadius="30px"
    onClick={onClick}
    {...rest}
  >
    {children}
  </Button>
)

const VideoGroup: React.FC = () => {
  useHeaderConfig({
    title: 'ビデオ通話',
  })
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  const { me } = useAuthContext();
  // const nativeApp = useNativeApp()

  const roomListQuery = useRoomListQuery({
    withActiveUserCount: true,
    type: 'group',
  })
  const connectModalDisclosure = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const callSettingClick = () => {
    navigate('/mypage/video')
  }

  const onClickRoom = (roomId: number) => {
    // TODO 実験段階 qualinkに遷移
    if (me == null) {
      return
    }
    window.location.href = createQuolinkVideoRoomUrl(roomId, me);
    // nativeApp.openVideoRoom(roomId)
  }

  const location = useLocation()
  const currentPath = location.pathname
  const VideoMenuLinks = useSetVideoLinks()

  return (
    <Box
      pt={isMobile ? '0px' : '24px'}
      pb={isMobile ? '0px' : '110px'}
      backgroundColor="grand.white"
    >
      {isMobile && (
        <AppSegmentedPicker
          info={[
            {
              label: '個別通話',
              link: '/video/private',
              isActive: false,
            },
            {
              label: 'グループ通話',
              link: '/video/group',
              isActive: true,
            },
          ]}
        />
      )}
      {/* 検索 */}
      <Box
        px={isMobile ? '16px' : '32px'}
        pt={isMobile ? '8px' : '0px'}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {!isMobile && (
          <Flex>
            {VideoMenuLinks.map((item) => (
              <VideoMenuLink
                key={item.id}
                path={item.path}
                title={item.title}
                isActive={currentPath === item.path}
              />
            ))}
          </Flex>
        )}
        <Spacer />
        <HStack align="center" spacing="10px">
          <VideoButton icon={MdSettingsPhone} onClick={callSettingClick}>
            {f('通話の詳細設定')}
          </VideoButton>
        </HStack>
      </Box>
      <Flex mt={isMobile ? '0px' : '16px'} flexDirection="column" gap="24px">
        {(() => {
          if (roomListQuery.isLoading) {
            return <AppLoading />
          }

          if (
            roomListQuery.data?.rooms.length &&
            roomListQuery.data?.rooms.length > 0
          ) {
            return (
              <VideoGroupList>
                {roomListQuery.data &&
                  roomListQuery.data.rooms.map((room) => (
                    <VideoGroupListItem
                      key={room.id}
                      title={room?.name || ''}
                      participantsCount={room.activeUserCount || undefined}
                      isOpen={room.activeUserCount === 0}
                      onClick={() => {
                        onClickRoom(room.id)
                      }}
                    />
                  ))}
              </VideoGroupList>
            )
          }

          return <>参加できるグループがありません</>
        })()}
      </Flex>
      <AppModal
        isOpen={connectModalDisclosure.isOpen}
        onOk={() => {
          navigate('/video/screen-sample')
        }}
        onClose={connectModalDisclosure.onClose}
        title={`${f('ルーム: 「雨乞いの滝」で通話を開始しますか？')}`}
        okText={f('通話を開始する')}
      />
    </Box>
  )
}

export default VideoGroup
