import { Box } from '@chakra-ui/react'
import React from 'react'
import TaxiMonthlyCalendar from '../../../components/taxi/admin/TaxiMonthlyCalendar'

const AdminTaxiCalendarMonth: React.FC = () => (
  <Box p="24px" flex="1">
    <TaxiMonthlyCalendar />
  </Box>
)

export default AdminTaxiCalendarMonth
