export const isAbsoluteLink = (path: string) => {
  if (/^https:\/\//.test(path)) {
    return true
  }

  if (/^http:\/\//.test(path)) {
    return true
  }

  return false
}
