import { useQuery, UseQueryResult } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { usePapaParse } from 'react-papaparse'

type CsvHeaderType = {
  [key: string]: string
}
export type PlaceGraphTimeItems = {
  observationTime: string
  observationData: string
  observationStatus: string
  observationStatusCode: string
}
export type PlaceGraphItem = {
  id: number
  placeCode: string
  placeName: string
  times: PlaceGraphTimeItems[]
}
export type CSVType = 'uryou' | 'river'
export type RowType = {
  [key: string]: string
}

// 種類によってリソースURL切り替え
const URYOU_URL = `${process.env.REACT_APP_CLOUDFRONT_ASSET_BASE_URL}/1/tokushimaSftp/rain.csv`
const DAM_URL = `${process.env.REACT_APP_CLOUDFRONT_ASSET_BASE_URL}/1/tokushimaSftp/dam.csv`
const KASEN_URL = `${process.env.REACT_APP_CLOUDFRONT_ASSET_BASE_URL}/1/tokushimaSftp/river.csv`

const getCSVUrl = (type: CSVType) => {
  if (type === 'uryou') {
    return URYOU_URL
  }
  if (type === 'river') {
    return KASEN_URL
  }
  if (type === 'dam') {
    return DAM_URL
  }
  return null
}

// 種類によってリソースCSVのヘッダー項目切り替え
const getCSVHeaderTxt = (type: CSVType) => {
  if (type === 'uryou') {
    return '/rain-csv-header.txt'
  }
  if (type === 'river') {
    return '/river-csv-header.txt'
  }
  if (type === 'dam') {
    return '/dam-csv-header.txt'
  }
  return null
}

const getCSVHeaders = (type: CSVType) => {
  if (type === 'uryou') {
    return {
      placeCode: '観測局コード',
      placeName: '観測局名称',
      observationTime: '10分雨量観測時刻',
      observationData: '10分雨量観測データ',
      observationStatus: '10分雨量観測ステータス',
      observationStatusCode: '10分雨量観測ステータスコード',
    }
  }
  if (type === 'river') {
    return {
      placeCode: '観測局コード',
      placeName: '観測局名称',
      observationTime: '10分水位観測時刻',
      observationData: '10分水位観測データ',
      observationStatus: '10分水位観測ステータス',
      observationStatusCode: '10分水位観測ステータスコード',
    }
  }
  if (type === 'dam') {
    return {
      placeCode: '観測局コード',
      placeName: '観測局名称',
      observationTime: '10分貯水位観測時刻',
      observationData: '10分貯水位観測データ',
      observationStatus: '10分貯水位観測ステータス',
      observationStatusCode: '10分貯水位観測ステータスコード',
    }
  }
  return null
}

const useBousaiUryouQuery = (csvType: CSVType, timeStamp: number) => {
  const { readString } = usePapaParse()
  // CSVの種類
  const csvUrl = getCSVUrl(csvType)
  const csvHeaderTxt = getCSVHeaderTxt(csvType)
  const csvHeaders: CsvHeaderType | null = getCSVHeaders(csvType)

  return useQuery({
    queryKey: [csvUrl, timeStamp],
    queryFn: async () => {
      if (!csvUrl || !csvHeaderTxt || !csvHeaders) {
        return AxiosError
      }
      // CSV取得（文字コード指定）
      const { data: csvData } = await axios.get(csvUrl, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'text/csv;',
        },
      })
      // csvからテキスト形式でデコード（文字コード指定）
      const csvToTextValue = new TextDecoder('Shift_JIS').decode(csvData)

      // ヘッダー情報を付与
      const csvHeaderToTextValue = await fetch(csvHeaderTxt)
        .then((res) => res.text())
        .catch(() => AxiosError)
      const formatCsvToTextValue = csvHeaderToTextValue + csvToTextValue

      // textからJson形式にパース
      const textToJson: Promise<RowType[]> = new Promise((resolve) => {
        const records: RowType[] = []
        // ヘッダーをjsonのkeyとしてパース
        readString(formatCsvToTextValue, {
          header: true,
          worker: true,
          complete: ({ data: jsonData }: { data: RowType[] }) => {
            records.push(...jsonData)
            resolve(records)
          },
        })
      })

      return textToJson.then((record: RowType[]) => {
        // CSV_HEADERで定義したkey:valueのみを抽出して変換
        const formatRecord: RowType[] = []
        record.forEach((row: RowType) => {
          if (row['連番'] === '') {
            return
          }

          const formatRow: RowType = {}
          Object.keys(csvHeaders).forEach((key) => {
            formatRow[key] = row[csvHeaders[key]]
          })
          formatRecord.push(formatRow)
        })

        // 観測場所で分類
        let placeIndex = 1
        return formatRecord.reduce((acc: PlaceGraphItem[], curr: RowType) => {
          const existingStation = acc.find(
            (station: PlaceGraphItem) =>
              station.placeCode === curr.placeCode &&
              station.placeName === curr.placeName,
          )

          if (existingStation) {
            existingStation.times.push({
              observationStatus: curr.observationStatus,
              observationStatusCode: curr.observationStatusCode,
              observationData: curr.observationData,
              observationTime: curr.observationTime,
            })
          } else {
            acc.push({
              id: placeIndex,
              placeCode: curr.placeCode,
              placeName: curr.placeName,
              times: [
                {
                  observationStatus: curr.observationStatus,
                  observationStatusCode: curr.observationStatusCode,
                  observationData: curr.observationData,
                  observationTime: curr.observationTime,
                },
              ],
            })
            placeIndex += 1
          }
          return acc
        }, [])
      })
    },
    cacheTime: 0, // react-queryのcacheがデフォルト5分になっているため、OFFにする（ログイン情報などのキャッシュを防ぐため）
  }) as UseQueryResult<PlaceGraphItem[], AxiosError>
}

export default useBousaiUryouQuery
