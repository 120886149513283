import { useIntl } from 'react-intl'
import useLocale from './useLocale'

const useFormatMessage = () => {
  const { formatMessage: format } = useIntl()
  const { locale } = useLocale()

  const f = (str: string) => {
    if (locale === 'ja') return str
    return format({ id: str })
  }

  return {
    f,
  }
}

export default useFormatMessage
