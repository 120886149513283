import {
  UserApiUserResetPasswordRequest,
  UserApiUserResetPasswordVerifyRequest,
} from '../../openapi/api'
import appApiClient from './appApiClient'
import useBaseMutation from './useBaseMutation'

const useResetPasswordMutation = () => ({
  resetPasswordMutation: useBaseMutation({
    mutationFn: (req: UserApiUserResetPasswordRequest) =>
      appApiClient.userApi.userResetPassword(req),
  }),
  resetPasswordVerifyMutation: useBaseMutation({
    mutationFn: (req: UserApiUserResetPasswordVerifyRequest) =>
      appApiClient.userApi.userResetPasswordVerify(req),
  }),
})

export default useResetPasswordMutation
