import { TaxiReservationApiTaxiReservationUpdateByTaxiCompanyRequest } from '../../openapi/api'
import appApiClient from './appApiClient'
import useBaseMutation from './useBaseMutation'

export const useTaxiReservationUpdateStatusByCompanyMutation = () =>
  useBaseMutation({
    mutationFn: (
      req: TaxiReservationApiTaxiReservationUpdateByTaxiCompanyRequest,
      config,
    ) =>
      appApiClient.taxiReservationApi.taxiReservationUpdateByTaxiCompany(
        req,
        config,
      ),
  })
