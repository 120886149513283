import {
  Flex,
  Text,
  Input,
  Link,
  Divider,
  InputGroup,
  InputRightElement,
  Icon,
  Button,
  FormControl,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { useNavigate, useLocation, Location } from 'react-router-dom'
import { AuthButton } from '../../components/auth/AuthButton'
import { FormHeading } from '../../components/auth/FormHeading'
import LoginEye from '../../components/svg/LoginEye'
import { useLoginMutation } from '../../hooks/coreApi/useLoginMutation'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { LS_KEY } from '../../lib/constants'
import { isProd } from '../../utils/common'
import { p2r } from '../../utils/font'
import ConfirmTwoFA from './ConfirmTwoFA'
import { useLoginState } from './useLoginState'
// import InputTwoFA from './inputTwoFA'

const LoginForm: React.FC = () => {
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  const loginMutation = useLoginMutation()
  const { setLoginState } = useLoginState()
  const [loginId, setLoginId] = useState('')
  const [password, setPassword] = useState('')
  const [show, setShow] = useState(false)
  const toast = useToast()
  const handleClick = () => setShow(!show)
  const isError = loginId === '' || password === ''
  const handleNext = (value: { loginId: string; password: string }) => {
    loginMutation.mutate(
      {
        userLoginRequest: {
          emailOrPhone: value.loginId,
          password: value.password,
        },
      },
      {
        onSuccess() {
          setLoginState({
            step: 2,
            email: value.loginId,
            password: value.password,
          })
        },
        onError() {
          toast({
            title: 'ログインできませんでした。', // TODO サーバーエラーのメッセージ
            status: 'error',
          })
        },
      },
    )
  }

  return (
    <>
      <Flex
        alignItems={{ base: 'unset', md: 'center' }}
        direction="column"
        rowGap="24px"
        w="100%"
      >
        {/* ログインID */}
        <Flex direction="column" rowGap="16px">
          <Flex
            columnGap={{ base: 'unset', md: '24px' }}
            direction={{ base: 'column', md: 'unset' }}
          >
            <FormHeading label={f('ログインID')} />
            <Text
              pt={{ base: 'unset', md: '8px' }}
              fontSize={{ base: p2r(14), md: p2r(16) }}
              lineHeight="150%"
              color="text.body"
            >
              {f('さあ・くるIDを入力してください')}
            </Text>
          </Flex>
          <FormControl>
            <Input
              type="text"
              fontSize={{ base: p2r(18), md: p2r(20) }}
              color="#372B1F"
              bgColor="grand.white"
              value={loginId}
              minW={{ base: '100%', md: '560px' }}
              w="100%"
              h="64px"
              border="3px solid"
              borderColor="grand.grayLight"
              rounded="10px"
              focusBorderColor="theme.primary"
              placeholder="EXAMPLE_ID"
              _placeholder={{ color: 'grand.grayLight' }}
              onChange={(event) => {
                setLoginId(event.target.value)
              }}
            />
          </FormControl>
        </Flex>
        {/* パスワード */}
        <Flex direction="column" rowGap="16px">
          <Flex
            columnGap={{ base: 'unset', md: '24px' }}
            direction={{ base: 'column', md: 'unset' }}
          >
            <FormHeading label={f('パスワード')} />
          </Flex>
          <FormControl>
            <InputGroup>
              <Input
                type={show ? 'text' : 'password'}
                fontSize={{ base: p2r(18), md: p2r(20) }}
                color="#372B1F"
                bgColor="grand.white"
                value={password}
                minW={{ base: '100%', md: '560px' }}
                w="fit-content"
                h="64px"
                border="3px solid"
                borderColor="grand.grayLight"
                rounded="10px"
                focusBorderColor="theme.primary"
                onChange={(event) => {
                  setPassword(event.target.value)
                }}
              />
              <InputRightElement>
                <Button
                  onClick={handleClick}
                  bgColor="grand.white"
                  position="relative"
                  top="12px"
                  right="10px"
                  outline="none"
                >
                  <Icon
                    color="grand.gray"
                    as={show ? MdOutlineRemoveRedEye : LoginEye}
                    boxSize="24px"
                  />
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Flex>
      </Flex>
      <Text
        color="#9E9E9E"
        fontSize={p2r(16)}
        mt={{ base: '16px', md: 'unset' }}
        mb={{ base: '80px', md: '31px' }}
        minW={{ base: '100%', md: '560px' }}
      >
        {f('パスワードを忘れた方は')}
        <Link href="/login/reset" color="theme.primary" fontSize={p2r(16)}>
          {f('こちら')}
        </Link>
      </Text>
      <AuthButton
        isLoading={loginMutation.isLoading}
        isDisabled={isError}
        onClick={() => handleNext({ loginId, password })}
        bgColor={isError ? 'grand.white' : 'theme.primary'}
        color={isError ? 'grand.grayLight' : 'text.white'}
        borderColor={isError ? 'grand.grayLight' : 'text.white'}
      >
        {f('ログイン')}
      </AuthButton>
      <Divider
        my={{ base: '24px', md: '40px' }}
        border="2px solid"
        borderColor="grand.grayLight"
        w={{ base: '100%', md: '600px' }}
      />
      {!isProd() && (
        <Button
          variant="link"
          fontWeight="700"
          fontSize={p2r(28)}
          color="theme.primary"
          onClick={() => navigate('/signup')}
        >
          {f('新規登録はこちら')}
        </Button>
      )}
    </>
  )
}

type State = {
  from: Location
}

const Login: React.FC = () => {
  useHeaderConfig({
    show: false,
  })
  const { loginState, setLoginState } = useLoginState()
  const location = useLocation()
  const state = location.state as State

  useEffect(
    () => () => {
      // ログイン画面を離れた後stepをリセットする
      setLoginState({
        step: 1,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (state?.from?.pathname) {
      localStorage.setItem(LS_KEY.redirectPath, state?.from?.pathname)
    }
  }, [state?.from?.pathname])

  return (
    <Flex
      alignItems="center"
      justify="center"
      direction="column"
      backgroundColor="theme.background"
      minH="100vh"
      px={{ base: '16px', md: 'unset' }}
    >
      {loginState.step === 1 && <LoginForm />}
      {/* TODO 初回ログイン時2FA設定 */}
      {/* {activeStepNumber === 2 && (
        <InputTwoFA
          onNext={(userInfo) => {
            console.log(userInfo)
            setActiveStepNumber(3)
          }}
        />
      )} */}
      {loginState.step === 2 && (
        <ConfirmTwoFA fromPath={state?.from?.pathname || '/'} />
      )}
    </Flex>
  )
}

export default Login
