import { Flex } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { MypageSectionTitle } from '../../components/mypage/MypageSectionTitle'
import {
  MypageSwitchList,
  MypageSwitchListItem,
} from '../../components/mypage/MypageSwitchList'
import useFormatMessage from '../../hooks/useFormatMessage'

const MypageTaxi: React.FC = () => {
  const { f } = useFormatMessage()

  const ref = useRef<boolean>(true)

  // 設定値を状態管理
  const [taxi, setTaxi] = useState({
    isBeforeArrival: true,
    isCompleteReservation: true,
    test: true,
  })

  // フォーム値の更新
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // オブジェクト内の対象プロパティを置換
    setTaxi({ ...taxi, [event.target.name]: event.target.checked })
  }

  useEffect(() => {
    // 初回レンダリング時は更新処理を行わないように制御
    // ※ 開発環境のみuseEffectは2回呼ばれるため(strictMode)、
    //    レンダリング時に1回更新処理が実行されます
    if (ref.current) {
      ref.current = false
      // TODO 更新処理実装時にreturn追加
      // return
    }

    // TODO 設定値の更新
  }, [taxi])
  return (
    <Flex
      px={{ base: '0px', md: '32px' }}
      py={{ base: '0px', md: '40px' }}
      flexDirection="column"
      gap="40px"
    >
      <MypageSectionTitle label={f('タクシー予約の詳細設定')} />
      <MypageSwitchList>
        <MypageSwitchListItem
          label={f('タクシー到着前に通知を送る')}
          name="isBeforeArrival"
          value={taxi.isBeforeArrival}
          handleChange={handleChange}
        />
        <MypageSwitchListItem
          label={f('予約が完了したら通知を送る')}
          name="isCompleteReservation"
          value={taxi.isCompleteReservation}
          handleChange={handleChange}
        />
        <MypageSwitchListItem
          label={f('テキストテキストテキスト')}
          name="test"
          value={taxi.test}
          handleChange={handleChange}
        />
      </MypageSwitchList>
    </Flex>
  )
}

export default MypageTaxi
