import { UseQueryOptions } from '@tanstack/react-query'
import { NotificationApiNotificationListRequest } from '../../openapi/api'
import appApiClient from './appApiClient'
import useBaseQuery from './useBaseQuery'

export const useNotificationListQuery = (
  req: NotificationApiNotificationListRequest,
  opts?: Omit<UseQueryOptions, 'queryFn'>,
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useBaseQuery({
    queryKey: ['notificationDetail'],
    queryFn: (config) =>
      appApiClient.notificationApi.notificationList(req, config),
    ...opts,
  })
