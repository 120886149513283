import { Icon } from '@chakra-ui/react'
import React from 'react'

const Return: React.FC = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M7 19V17H14.1C15.15 17 16.0623 16.6667 16.837 16C17.6123 15.3333 18 14.5 18 13.5C18 12.5 17.6123 11.6667 16.837 11C16.0623 10.3333 15.15 10 14.1 10H7.8L10.4 12.6L9 14L4 9L9 4L10.4 5.4L7.8 8H14.1C15.7167 8 17.1043 8.525 18.263 9.575C19.421 10.625 20 11.9333 20 13.5C20 15.0667 19.421 16.375 18.263 17.425C17.1043 18.475 15.7167 19 14.1 19H7Z"
      fill="currentColor"
    />
  </Icon>
)

export default Return
