import { Box } from '@chakra-ui/react'
import React from 'react'
import AppNotionRender from '../../components/common/AppNotionRender'
import {
  mockBlock,
  mockPage,
} from '../../components/common/AppNotionRender/mockData'
import useHeaderConfig from '../../hooks/useHeaderConfig'

const Term: React.FC = () => {
  useHeaderConfig({ title: '利用規約' })
  const title = mockPage.properties.title.title[0].plain_text
  const block = mockBlock.results
  return (
    <Box my="2rem">
      <AppNotionRender title={title} blocks={block} />
    </Box>
  )
}

export default Term
