import React, { useEffect, useState } from 'react'
import useThrottle from './useThrottle'

// 要素の位置取得
// https://zenn.dev/catnose99/articles/0f0bb01ee6a940
const useOffsetTop = (ref?: React.RefObject<HTMLElement>) => {
  // 現在の表示領域の上端からの位置（通り過ぎた場合はマイナス値）
  const [viewportTop, setViewportTop] = useState<number | undefined>(undefined)
  // ページ上端からの位置
  const [pageOffsetTop, setPageOffsetTop] = useState<number | undefined>(
    undefined,
  )

  // レンダリング負荷を軽減するために、間引き処理（useThrottle）を追加
  const handler = useThrottle(() => {
    if (!ref?.current) {
      // eslint-disable-next-line no-useless-return
      return
    }

    // 現在の表示領域からの相対位置を更新
    const clientRect = ref.current.getBoundingClientRect()
    setViewportTop(clientRect.top)

    // ページ上端からの位置を更新
    const newPageOffsetTop = clientRect.top + window.pageYOffset
    setPageOffsetTop(newPageOffsetTop)
  }, 100)

  useEffect(() => {
    if (!ref?.current) {
      // eslint-disable-next-line no-useless-return
      return
    }

    handler()
    window.addEventListener('scroll', handler)

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('scroll', handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler])

  return {
    viewportTop,
    pageOffsetTop,
  }
}

export default useOffsetTop
