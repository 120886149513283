import { Box } from '@chakra-ui/react'
import React from 'react'
import { AppErrorAlert } from '../../components/common/AppErrorAlert'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppList, AppListItem } from '../../components/common/AppList'
import { AppLoading } from '../../components/common/AppLoading'
import { withContentSearch } from '../../context/ContentSearchContext'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { getDateFormat } from '../../utils/date'

const BousaiList: React.FC = () => {
  useHeaderConfig({
    title: '防災に関するお知らせ',
  })

  const PAGE_SIZE = 20
  const { isLoading, isError, error, data, fetchNextPage, hasNextPage } =
    useContentListQuery(['kinkyu'], {
      page: 1,
      pageSize: PAGE_SIZE,
    })

  const bousaiSeriesContents = data?.pages?.[0]?.contents || []

  if (isLoading) {
    return <AppLoading />
  }

  if (isError) {
    return <AppErrorResult error={error} />
  }

  if (!bousaiSeriesContents) {
    return <AppErrorAlert error="コンテンツがありません" />
  }

  return (
    <Box>
      <AppList
        isMore={hasNextPage}
        onClickMore={() => {
          fetchNextPage()
        }}
      >
        {bousaiSeriesContents.map((content) => (
          <AppListItem
            key={content.id}
            title={content.titleJa}
            date={getDateFormat(content.releaseAt)}
            isShowLike
            likeCount={content?.likeCount}
            isLiked={content?.isLiked}
            // tags={['防災シリーズ']}
            link={`/${content.type}/${content.id}`}
            contentId={content.id}
            isReaded={content.isRead}
          />
        ))}
      </AppList>
    </Box>
  )
}

export default withContentSearch(BousaiList)
