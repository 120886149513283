import { Text } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

export const AppNewLabel = () => (
  <Text
    px="8px"
    py="2px"
    fontSize={p2r(12)}
    fontWeight="700"
    color="text.white"
    borderRadius="4px"
    backgroundColor="theme.primary"
    display="flex"
    alignItems="center"
  >
    新着
  </Text>
)
