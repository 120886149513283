import { Box, Td, Tr, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppErrorResult from '../../../components/common/AppErrorResult'
import { TaxiSectionTitle } from '../../../components/taxi/TaxiSectionTitle'
import TaxiTimeNGButton from '../../../components/taxi/TaxiTimeNGButton'
import TaxiTimeOKButton from '../../../components/taxi/TaxiTimeOKButton'
import TaxiTimeTable from '../../../components/taxi/TaxiTimeTable'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { p2r } from '../../../utils/font'
import useTaxiReservationCreateState from '../useTaxiReservationCreateState'
import useTaxiSelectableTimeList from '../useTaxiSelectableTimeList'

// 時間選択
const TaxiReservationCreateSelectTime: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { f } = useFormatMessage()

  const {
    taxiRideDateTime,
    setTaxiRideDateTime,
    selectedTaxiCompany,
    selectedRideDate,
  } = useTaxiReservationCreateState()
  const navigate = useNavigate()

  // 空き時間選択
  const handleTimeSelected = ({
    hour,
    minute,
  }: {
    hour: number
    minute: number
  }) => {
    setTaxiRideDateTime({
      time: {
        hour,
        minute,
      },
    })
    navigate('/taxi/reservations/create')
  }

  const timeList = useTaxiSelectableTimeList({
    taxiCompanyId: selectedTaxiCompany?.id,
    date: taxiRideDateTime.date,
  })

  if (!selectedRideDate) {
    return <AppErrorResult message="日付を先に選択してください" />
  }

  if (!selectedTaxiCompany) {
    return <AppErrorResult message="タクシー会社を選択してください" />
  }

  return (
    <>
      <Box px={{ base: '16px', md: '0px' }}>
        <TaxiSectionTitle
          label={f(
            '時間を選択してください。※ご希望時間が無い場合はタクシー会社へ電話でお問い合わせください。',
          )}
        />
      </Box>

      <TaxiTimeTable>
        {timeList.map((time) => (
          <Tr key={time.hour}>
            <Td
              px="0"
              py="0"
              w={isMobile ? p2r(60) : p2r(120)}
              fontSize={isMobile ? p2r(16) : p2r(24)}
              fontWeight="bold"
              textAlign="center"
              borderRightWidth="1px"
              borderRightColor="grand.grayLight"
            >
              {`${time.hour}時`}
            </Td>
            {time.children.map((item, index) => (
              <Td
                py="8px"
                px={isMobile ? '8px' : '16px'}
                pl={{ base: '8px', md: index === 0 ? '32px' : '16px' }}
                key={`${time.hour}-${item.minute}`}
              >
                {item.status === 0 && (
                  <TaxiTimeOKButton
                    isActive={
                      time.hour === taxiRideDateTime.time.hour &&
                      item.minute === taxiRideDateTime.time.minute
                    }
                    onClick={() => {
                      handleTimeSelected({
                        hour: time.hour,
                        minute: item.minute,
                      })
                    }}
                  />
                )}
                {item.status === 1 && <TaxiTimeNGButton />}
              </Td>
            ))}
            {!isMobile && <Td py="8px" />}
          </Tr>
        ))}
      </TaxiTimeTable>
    </>
  )
}

export default TaxiReservationCreateSelectTime
