import {
  AspectRatio,
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { p2r } from '../../../utils/font'
import { AppDateText } from '../AppDateText'
import AppLikeButton from '../AppLikeButton'
import AppListMoreButton from '../AppListMoreButton'
import { AppNewLabel } from '../AppNewLabel'
import { AppTagText } from '../AppTagText'

export type AppThumbnailListProps = {
  children: ReactNode
  isMore?: boolean
  onClickMore(): void
}

export const AppThumbnailList: React.FC<AppThumbnailListProps> = (props) => {
  const { children, isMore, onClickMore } = props
  const childrenArray = React.Children.toArray(children)
  if (childrenArray.length === 0) {
    return <Box px="4">対象のコンテンツはありません</Box>
  }

  return (
    <Box>
      <Grid
        px={{ base: '0', md: '32px' }}
        py={{ base: '0', md: '40px' }}
        gridTemplateColumns={{ base: 'unset', md: '1fr 1fr 1fr' }}
        gap={{ base: '0', md: '40px' }}
        borderTopWidth={{ base: '1px', md: '0px' }}
        borderTopColor="grand.grayLight"
        borderBottomWidth={{ base: '1px', md: '0px' }}
        borderBottomColor="grand.grayLight"
      >
        {children}
      </Grid>
      {isMore && <AppListMoreButton onClick={onClickMore} />}
    </Box>
  )
}

export type AppThumbnailListItemProps = {
  title: string
  date: string
  link?: string
  image: string
  isShowLike?: boolean
  isLiked?: boolean | null
  playCount?: number | null
  tags?: string[]
  px?: { base: string; md: string }
  py?: { base: string; md: string }
  contentId: number
  isReaded?: boolean | null
}

export const AppThumbnailListItem: React.FC<AppThumbnailListItemProps> = ({
  title,
  date,
  link,
  image,
  isShowLike,
  isLiked = false,
  playCount,
  tags,
  px,
  py,
  contentId,
  isReaded,
  ...props
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false })

  const imageContainer = (
    <Box position="relative" w="100%" {...props}>
      <Image
        src={image}
        fallbackSrc="/noimage.png"
        w="100%"
        h="100%"
        objectFit="cover"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
      />
    </Box>
  )

  const textContainer = (
    <Flex flexDirection="column" gap={{ base: '4px', md: '8px' }}>
      <Flex columnGap="8px" rowGap="4px" wrap="wrap" alignItems="center">
        {!isReaded && <AppNewLabel />}
        {tags &&
          tags.length > 0 &&
          tags.map((tag) => <AppTagText label={tag} key={tag} />)}
        <AppDateText label={date} />
      </Flex>
      <Text
        fontSize={{ base: p2r(24), md: p2r(20) }}
        lineHeight="150%"
        letterSpacing={{ base: '0.05em', md: '0' }}
        noOfLines={2}
        fontWeight={isReaded ? '400' : '700'}
        color={isReaded ? 'text.body' : { base: '#3a3a3a', md: 'text.body' }}
      >
        {title}
      </Text>
    </Flex>
  )

  return (
    <GridItem
      px={{ base: px?.base || '16px', md: px?.md || '0px' }}
      py={{ base: py?.base || '8px', md: py?.md || '0px' }}
      display="flex"
      flexDirection="column"
      gap="8px"
      borderBottomWidth={{ base: '1px', md: '0px' }}
      _last={{ borderBottomWidth: 0 }}
      borderBottomColor="grand.grayLight"
      bgColor="grand.white"
    >
      <Flex
        flexDirection={{ base: 'row', md: 'column' }}
        gap="16px"
        align={{ base: 'center', md: 'unset' }}
      >
        {/* 画像 */}
        <AspectRatio
          my={{ base: '8px', md: '0px' }}
          ratio={{ base: 12 / 7, md: 14 / 8 }}
          flex="1 1 auto"
          maxW={{ base: '120px', md: 'unset' }}
          h={{ base: '70px', md: 'unset' }}
        >
          {link ? (
            <Link to={link}>{imageContainer}</Link>
          ) : (
            <Box>{imageContainer}</Box>
          )}
        </AspectRatio>

        {/* テキスト */}
        <Flex flexDirection="column" gap="8px" flex="1">
          {link ? (
            <Link to={link}>{textContainer}</Link>
          ) : (
            <Box>{textContainer}</Box>
          )}
          {isMobile && isShowLike && (
            <Box mt="auto">
              <AppLikeButton isLiked={isLiked} contentId={contentId} />
            </Box>
          )}
        </Flex>
      </Flex>

      {!isMobile && (
        <HStack spacing="16px">
          {isShowLike && (
            <AppLikeButton isLiked={isLiked} contentId={contentId} />
          )}
          {playCount && (
            <Text
              fontSize={p2r(14)}
              lineHeight="150%"
              fontWeight="700"
              color="grand.gray"
            >
              {playCount}回
            </Text>
          )}
        </HStack>
      )}
    </GridItem>
  )
}
