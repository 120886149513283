import {
  Box,
  BoxProps,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdModeEdit, MdPinDrop } from 'react-icons/md'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { p2r } from '../../../utils/font'
import TaxiPointSelectButton from '../TaxiPointSelectButton'

type TaxiFreewordFormProps = {
  handleSubmit: (value: string) => void
} & BoxProps
const TaxiFreewordForm: React.FC<TaxiFreewordFormProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { handleSubmit, ...rest } = props
  const { f } = useFormatMessage()

  // 自由入力
  const [freewordValue, setFreewordValue] = useState<string>('')
  return (
    <Box {...rest}>
      <Flex py="8px" columnGap="8px">
        <Icon as={MdPinDrop} boxSize={p2r(24)} color="grand.gray" />
        <Text fontSize={p2r(16)} color="grand.gray" fontWeight="700">
          {f('入力する')}
        </Text>
      </Flex>
      <Flex mt={isMobile ? '8px' : '16px'} columnGap="16px" align="center">
        <InputGroup size="lg" flex="1">
          <InputLeftElement cursor="pointer">
            <Icon as={MdModeEdit} boxSize={p2r(32)} color="grand.gray" />
          </InputLeftElement>
          <Input
            type="text"
            fontSize={p2r(16)}
            placeholder="神山町役場"
            _placeholder={{ color: 'grand.grayLight' }}
            bg="grand.white"
            borderRadius="8px"
            borderColor="grand.grayLight"
            focusBorderColor="theme.primary"
            onBlur={(e) => setFreewordValue(e.target.value)}
          />
        </InputGroup>
        <TaxiPointSelectButton
          isActive={freewordValue !== ''}
          onClick={() => {
            handleSubmit(freewordValue)
          }}
        >
          {f('決定')}
        </TaxiPointSelectButton>
      </Flex>
    </Box>
  )
}

export default TaxiFreewordForm
