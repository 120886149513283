import { Flex } from '@chakra-ui/react'
import React from 'react'
import { Outlet } from 'react-router-dom'
import useHeaderConfig from '../../../hooks/useHeaderConfig'

const AdminTaxiReservationCreate: React.FC = () => {
  useHeaderConfig({
    title: '電話予約受付',
  })
  return (
    <Flex direction="column" flex="1" minH="calc(100vh - 56px)">
      <Outlet />
    </Flex>
  )
}

export default AdminTaxiReservationCreate
