import { Flex, Stack, Text, Input } from '@chakra-ui/react'
import { MypageButton } from '../../components/mypage/MypageButton'
import { MypageSectionTitle } from '../../components/mypage/MypageSectionTitle'
import useFormatMessage from '../../hooks/useFormatMessage'
import { useResetPassword } from '../../hooks/useResetPassword'
import { p2r } from '../../utils/font'

export const Step1: React.FC = () => {
  const { f } = useFormatMessage()
  const { resetState, setResetState, handleStep1Submit } = useResetPassword()
  return (
    <>
      <Flex direction="column" rowGap="40px" minW="560px" w="100%">
        <Stack spacing="16px">
          <MypageSectionTitle label={f('パスワードの再設定')} />
          <Text
            fontSize={p2r(16)}
            lineHeight="150%"
            color="text.body"
            whiteSpace="pre-wrap"
          >
            {f(`Eメールアドレスか電話番号による再設定のリンクをお送りします。`)}
          </Text>
        </Stack>

        <Stack spacing="32px">
          <Stack spacing="16px">
            <Text
              fontSize={p2r(16)}
              lineHeight="100%"
              color="text.body"
              whiteSpace="pre-wrap"
            >
              Eメールアドレスか電話番号
            </Text>
            <Input
              placeholder="EXAMPL@EXAMPLE.COM"
              _placeholder={{ color: 'grand.grayLight' }}
              bgColor="grand.white"
              borderWidth="3px"
              borderColor="grand.grayLight"
              rounded="10px"
              minH="64px"
              onChange={(e) => {
                setResetState({
                  ...resetState,
                  sendValue: e.target.value,
                })
              }}
            />
          </Stack>
        </Stack>
      </Flex>
      <Flex justifyContent="flex-end" w="full">
        <MypageButton
          isDisabled={resetState.isValidStep1}
          onClick={() => handleStep1Submit()}
        >
          {f('送信する')}
        </MypageButton>
      </Flex>
    </>
  )
}
