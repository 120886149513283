import { Heading } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

export type TaxiTitleProps = {
  label: string
}
export const TaxiTitle: React.FC<TaxiTitleProps> = ({ label }) => (
  <Heading
    as="h1"
    fontSize={{ base: p2r(24), md: p2r(32) }}
    fontWeight="700"
    lineHeight="150%"
    color="text.body"
  >
    {label}
  </Heading>
)
