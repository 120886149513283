import { TaxiLandmarkApiTaxiLandmarkListRequest } from '../../openapi'
import appApiClient from './appApiClient'
import useBaseQuery from './useBaseQuery'

export const useTaxiLandmarkListQuery = (
  req: TaxiLandmarkApiTaxiLandmarkListRequest,
) =>
  useBaseQuery({
    queryKey: ['taxiLandmarkList'],
    queryFn: (config) =>
      appApiClient.taxiLandmarkApi.taxiLandmarkList(req, config),
  })
