import dayjs from 'dayjs'
import _ from 'lodash'
import { useMemo } from 'react'
import { useTaxiCompanyListQuery } from '../../hooks/coreApi/useTaxiCompanyListQuery'
import { useTaxiDailyScheduleMergeQuery } from '../../hooks/coreApi/useTaxiDailyScheduleMergeQuery'
import { isInBusinessHour } from '../../models/taxiCompany'
import { TaxiRideDateTimeType } from './useTaxiReservationCreateState'

type Options = {
  taxiCompanyId: number | null | undefined
  date: TaxiRideDateTimeType['date']
}

type TimeListType = {
  hour: number
  children: {
    minute: 0 | 15 | 30 | 45
    status: 0 | 1
  }[]
}

function useTaxiSelectableTimeList(options: Options) {
  const date = dayjs()
    .year(options.date.year)
    .month(options.date.month - 1)
    .date(options.date.day)
  // タクシー会社
  const taxiCompanyListQuery = useTaxiCompanyListQuery({
    id: options.taxiCompanyId || 0,
  })

  const taxiDailyScheduleQuery = useTaxiDailyScheduleMergeQuery({
    taxiCompanyId: options.taxiCompanyId || 0,
    date: date.format('YYYY-MM-DD'),
  })

  const taxiCompany = useMemo(
    () => taxiCompanyListQuery?.data?.taxiCompanies?.[0] || null,
    [taxiCompanyListQuery?.data],
  )

  const reservedTimes = useMemo(
    () => taxiDailyScheduleQuery?.data?.times || [],
    [taxiCompany, taxiDailyScheduleQuery?.data],
  )

  const timeList = useMemo(() => {
    const tmpTimeList: TimeListType[] = []

    // タクシー会社を選択していない場合
    if (!taxiCompany) {
      return tmpTimeList
    }

    // 選択した会社の営業時間
    _.range(0, 25).forEach((hour) => {
      if (!isInBusinessHour(taxiCompany, hour, 0)) {
        return
      }

      const row = {
        hour,
        children: [],
      }

      _.range(0, 60, 15).forEach((minute) => {
        const child = {
          minute,
          status: 0,
        }

        const d = dayjs().hour(hour).minute(minute)

        // タクシー会社 taxiCompanyから取得
        if (!isInBusinessHour(taxiCompany, hour, minute)) {
          child.status = 1
        }

        // 予約済時間
        if (reservedTimes.some((t) => t === d.format('HH:mm'))) {
          child.status = 1
        }

        row.children.push(child as never)
      })
      tmpTimeList.push(row)
    })
    return tmpTimeList
  }, [reservedTimes])

  return timeList
}

export default useTaxiSelectableTimeList
