import {
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { p2r } from '../../../utils/font'
import TaxiTimeTableTh from '../TaxiTimeTableTh'

const TaxiTimeTable: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { f } = useFormatMessage()
  return (
    <TableContainer pt="16px" overflowX="unset" overflowY="unset">
      <Table variant="simple">
        <Thead
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.05)"
          // position="sticky"
          // top="0"
          zIndex="docked"
          backgroundColor="grand.white"
        >
          <Tr>
            <Th
              w={isMobile ? p2r(60) : p2r(120)}
              borderRightWidth="1px"
              borderRightColor="grand.grayLight"
            />
            <TaxiTimeTableTh pl={isMobile ? '0px' : '16px'}>
              {f('00分')}
            </TaxiTimeTableTh>
            <TaxiTimeTableTh>{f('15分')}</TaxiTimeTableTh>
            <TaxiTimeTableTh>{f('30分')}</TaxiTimeTableTh>
            <TaxiTimeTableTh>{f('45分')}</TaxiTimeTableTh>
            {!isMobile && <Th />}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  )
}

export default TaxiTimeTable
