import { Box, Center } from '@chakra-ui/react'
import { css } from '@emotion/css'
import theme from '../../../theme'

const loaderStyle = css`
  font-size: 10px;
  text-indent: -9999px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #6ebfba;
  background: -moz-linear-gradient(left, #a7d8d5 40%, #d5eceb);
  background: -webkit-linear-gradient(left, #a7d8d5 40%, #d5eceb);
  background: -o-linear-gradient(left, #a7d8d5 40%, #d5eceb);
  background: -ms-linear-gradient(left, #a7d8d5 40%, #d5eceb);
  background: linear-gradient(to right, #a7d8d5 40%, #d5eceb);
  position: relative;
  -webkit-animation: loading 1.4s infinite linear;
  animation: loading 1.4s infinite linear;

  &:before {
    width: 100%;
    height: 50%;
    background: ${theme.colors.theme.primary};
    background: -moz-linear-gradient(
      right,
      ${theme.colors.theme.primary},
      #a7d8d5 90%
    );
    background: -webkit-linear-gradient(
      right,
      ${theme.colors.theme.primary},
      #a7d8d5 90%
    );
    background: -o-linear-gradient(
      right,
      ${theme.colors.theme.primary},
      #a7d8d5 90%
    );
    background: -ms-linear-gradient(
      right,
      ${theme.colors.theme.primary},
      #a7d8d5 90%
    );
    background: linear-gradient(
      to left,
      ${theme.colors.theme.primary},
      #a7d8d5 90%
    );
    border-radius: 100px 100px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: #fffbf5;
    width: 70%;
    height: 70%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export const AppLoading: React.FC = () => (
  <Center
    bgColor="theme.background"
    minH="calc(100vh - 126px)"
    overflow="hidden"
  >
    <Box className={loaderStyle}>Loading...</Box>
  </Center>
)
