import { Flex, Radio, RadioProps, Text } from '@chakra-ui/react'
import React, { forwardRef, ComponentPropsWithoutRef } from 'react'
import { p2r } from '../../../utils/font'

export type TaxiCompanyRadioProps = {
  companyId: number
  label: string
  startTime: string
  endTime: string
  address: string
  radioName: string
  // onChange(event: React.ChangeEvent<HTMLInputElement>): void
} & ComponentPropsWithoutRef<'input'> &
  RadioProps

export const TaxiCompanyRadio = forwardRef<
  HTMLInputElement,
  TaxiCompanyRadioProps
>((props, ref) => {
  const { companyId, label, startTime, endTime, address, radioName, ...rest } =
    props
  return (
    <Flex
      px={{ base: '16px', md: '32px' }}
      py="16px"
      justify="space-between"
      backgroundColor="grand.white"
      direction={{ base: 'column', md: 'row' }}
      rowGap={{ base: '16px', md: '0px' }}
      borderWidth="2px"
      borderColor="grand.grayLight"
      borderRadius="10px"
    >
      <Radio
        value={companyId}
        size="lg"
        colorScheme="radio"
        columnGap="8px"
        name={radioName}
        {...rest}
        ref={ref}
      >
        {label}
      </Radio>
      <Flex direction="column" rowGap="8px">
        <Flex justify="space-between" columnGap="56px">
          <Text
            color="text.sub"
            fontSize={{ base: p2r(14), md: p2r(16) }}
            fontWeight="400"
            lineHeight="150%"
          >
            営業時間
          </Text>
          <Text
            color="text.body"
            fontSize={{ base: p2r(14), md: p2r(16) }}
            fontWeight="700"
            lineHeight="150%"
          >
            {startTime}-{endTime}
          </Text>
        </Flex>
        <Flex justify="space-between" columnGap="56px">
          <Text
            color="text.sub"
            fontSize={{ base: p2r(14), md: p2r(16) }}
            fontWeight="400"
            lineHeight="150%"
          >
            住所
          </Text>
          <Text
            color="text.body"
            fontSize={{ base: p2r(14), md: p2r(16) }}
            fontWeight="700"
            lineHeight="150%"
          >
            {address}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
})
