import { Flex } from '@chakra-ui/react'
import React from 'react'
import { MypageSectionTitle } from '../../components/mypage/MypageSectionTitle'
import {
  MypageSwitchList,
  MypageSwitchListItem,
} from '../../components/mypage/MypageSwitchList'
import { useUserPersistConfigContext } from '../../context/UserPersistConfigContext'
import useFormatMessage from '../../hooks/useFormatMessage'

const MypageNotification: React.FC = () => {
  const { f } = useFormatMessage()
  const { userPersistConfig, setConfigValue } = useUserPersistConfigContext()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfigValue(event.target.name, event.target.checked)
  }

  return (
    <Flex
      px={{ base: '0px', md: '32px' }}
      py={{ base: '0px', md: '40px' }}
      flexDirection="column"
      gap="40px"
    >
      <MypageSectionTitle label={f('各種通知設定')} />
      <MypageSwitchList>
        <MypageSwitchListItem
          label={f('タクシー予約確定時に通知を送信する')}
          name="cnfSendNotifTaxiReservationFixed"
          value={userPersistConfig.cnfSendNotifTaxiReservationFixed}
          handleChange={handleChange}
        />
        <MypageSwitchListItem
          label={f('タクシー予約キャンセル時に通知を送信する')}
          name="cnfSendNotifTaxiReservationRejected"
          value={userPersistConfig.cnfSendNotifTaxiReservationRejected}
          handleChange={handleChange}
        />
        <MypageSwitchListItem
          label={f('タクシー確定予約リマインド通知を送信する')}
          name="cnfSendNotifTaxiReservationReminder"
          value={userPersistConfig.cnfSendNotifTaxiReservationReminder}
          handleChange={handleChange}
        />
        <MypageSwitchListItem
          label={f('ゴミの日の通知(当日 7:00)を送信する')}
          name="cnfSendNotifTrashDay"
          value={userPersistConfig.cnfSendNotifTrashDay}
          handleChange={handleChange}
        />
        <MypageSwitchListItem
          label={f('ゴミの日の通知(前日 17:00)を送信する')}
          name="cnfSendNotifTrashDayBefore"
          value={userPersistConfig.cnfSendNotifTrashDayBefore}
          handleChange={handleChange}
        />
        <MypageSwitchListItem
          label={f('防災無線の通知を送信するか')}
          name="cnfSendNotifBousai"
          value={userPersistConfig.cnfSendNotifBousai}
          handleChange={handleChange}
        />
        <MypageSwitchListItem
          label={f('お知らせの通知を送信する')}
          name="cnfSendNotifKairan"
          value={userPersistConfig.cnfSendNotifKairan}
          handleChange={handleChange}
        />
        <MypageSwitchListItem
          label={f('かみやまchの通知を送信する')}
          name="cnfSendNotifDekigoto"
          value={userPersistConfig.cnfSendNotifDekigoto}
          handleChange={handleChange}
        />
      </MypageSwitchList>
    </Flex>
  )
}

export default MypageNotification
