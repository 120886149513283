import _ from 'lodash'
import { TaxiCompanyResponse } from '../openapi'

export const getStartBusinessTime = (taxiCompany: TaxiCompanyResponse) =>
  taxiCompany.startBusinessHour.slice(0, 5)

export const getEndBusinessTime = (taxiCompany: TaxiCompanyResponse) =>
  taxiCompany.endBusinessHour.slice(0, 5)

// 営業時間
export const getBusinessTimeDurationText = (taxiCompany: TaxiCompanyResponse) =>
  `${taxiCompany.startBusinessHour.slice(
    0,
    5,
  )}〜${taxiCompany.endBusinessHour.slice(0, 5)}`

// 営業時間のレンジ
export const getBusinessTimeHourRange = (
  taxiCompany: TaxiCompanyResponse,
): [number, number] => [
  Number(taxiCompany.startBusinessHour.slice(0, 2)),
  Number(taxiCompany.endBusinessHour.slice(0, 2)),
]

// 営業時間内かどうか判定
export const isInBusinessHour = (
  taxiCompany: TaxiCompanyResponse,
  hour: number,
  minute: number,
) => {
  const hm = `${_.padStart(String(hour), 2, '0')}${_.padStart(
    String(minute),
    2,
    '0',
  )}`
  const startHm = taxiCompany.startBusinessHour.slice(0, 5).replace(':', '')
  const endHm = taxiCompany.endBusinessHour.slice(0, 5).replace(':', '')

  if (hm < startHm) {
    return false
  }

  if (hm >= endHm) {
    return false
  }

  return true
}
