import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { UserConfig } from '../models/user'
import { userConfigState } from '../store/userConfig'

const useUserTmpConfig = () => {
  const [userConfig, setUserConfigOriginal] = useRecoilState(userConfigState)

  const setUserConfig = useCallback(
    (params: Partial<UserConfig>) => {
      setUserConfigOriginal({
        ...userConfig,
        ...params,
      })
    },
    [userConfig, setUserConfigOriginal],
  )

  return {
    userConfig,
    setUserConfig,
  }
}

export default useUserTmpConfig
