import { Image } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import useHeaderConfig from '../../hooks/useHeaderConfig'

// TODO ネイティブ側で実装する通話画面のサンプル ネイティブ遷移実装後に消す
const VideoScreenSample: React.FC = () => {
  useHeaderConfig({
    show: false,
  })

  const navigate = useNavigate()

  return (
    <Image
      src="https://user-images.githubusercontent.com/1800369/197743257-77b2ab1e-6c9a-4bf5-9c2a-bd426432b94d.png"
      onClick={() => {
        navigate(-1)
      }}
    />
  )
}

export default VideoScreenSample
