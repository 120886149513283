import {
  FieldValues,
  UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form'

// defaultValues を Partial<FormType> | undefined ではなく FormTypeで型付できるようにするようにwrap
// ref:https://zenn.dev/yuitosato/articles/292f13816993ef
const useAppForm = <FormType extends FieldValues>(
  props: UseFormProps<FormType> & {
    defaultValues: FormType
  },
): UseFormReturn<FormType> => useForm(props)

export default useAppForm
