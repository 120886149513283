import {
  Flex,
  Grid,
  Text,
  Icon,
  useBreakpointValue,
  Box
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { AppLoading } from '../../components/common/AppLoading'
import WeatherTimeArrow from '../../components/svg/WeatherTimeArrow'
import WeatherIconMap from '../../components/weather/WeatherIconMap'
import useFormatMessage from '../../hooks/useFormatMessage'
import { usePointwfForecastQuery } from '../../hooks/weatherApi/useDailyForecastQuery'
import { p2r } from '../../utils/font'

export type WeatherTimeDateProps = {
  month: number
  date: number
  // day: string
  isHidden: boolean
}
const WeatherTimeDate: React.FC<WeatherTimeDateProps> = (props) => {
  const { f } = useFormatMessage()
  return (
    <Flex
      py={{ base: 'unset', md: '15.5px' }}
      h={{ base: '40px', md: 'unset' }}
      px={props.isHidden ? 'unset' : '8px'}
      alignItems={{ base: 'center', md: 'baseline' }}
      border="1px solid"
      borderColor="grand.grayLight"
      borderTopWidth={{ base: '0px', md: '1px' }}
      borderBottomWidth="0px"
      justify={props.isHidden ? 'center' : 'unset'}
    >
      <Text fontWeight="700" color="text.body">
        <Box
          as="span"
          fontSize={{ base: p2r(1), md: p2r(22) }}
          pr={{
            base: props.isHidden ? '0px' : '8px',
            md: props.isHidden ? '0px' : '16px',
          }}
          paddingRight={{ base:"1px"}}
        >
          {/* {props.title} */}
        </Box>
        {!props.isHidden && (
          <>
            <Box as="span" fontSize={{ base: p2r(10), md: p2r(22) }}>
              {props.month}
            </Box>
            <Box as="span" fontSize={{ base: p2r(10), md: p2r(14) }}>
              {f('/')}
            </Box>
            <Box as="span" fontSize={{ base: p2r(10), md: p2r(22) }}>
              {props.date}
            </Box>
          </>
        )}
      </Text>
    </Flex>
  )
}

export type WeatherTimeCardProps = {
  time: string
  weatherIcon: React.FC
  rainfall: number |string
  temp: number
  humidity: number
  windIcon: number
  wind: number
}
const WeatherTimeCard: React.FC<WeatherTimeCardProps> = (props) => {
  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })
  
  return (
    <Flex
      direction="column"
      borderColor="grand.grayLight"
      borderWidth="1px"
      px={{ base: '5px', md: '13px' }}
      maxWidth="103.74px"
    >
      <Flex
        h={{ base: '48px', md: '9.6vh' }}
        alignItems="center"
        justify="center"
      >
        <Text
          color="text.sub"
          fontSize={{ base: p2r(10), md: p2r(24) }}
          fontWeight="700"
        >
          <>
            {props.time}
            {f('時')}
          </>
        </Text>
      </Flex>
      <Flex
        h={{ base: '56px', md: '100px' }}
        alignItems="center"
        justify="center"
      >
        <Icon as={props.weatherIcon} boxSize={{ base: '32px', md: '9.6vh' }} />
      </Flex>
      <Flex
        h={{ base: '48px', md: '9.6vh' }}
        alignItems="center"
        justify="center"
      >
        <Text
          color="text.body"
          fontSize={{ base: p2r(10), md: p2r(24) }}
          fontWeight="700"
          whiteSpace="nowrap"
        >
          {props.rainfall} mm
        </Text>
      </Flex>
      <Flex
        h={{ base: '48px', md: '9.6vh' }}
        alignItems="center"
        justify="center"
        gap={{ base: '4px', md: '8px' }}
      >
        <Text
          color={props.temp > 0 ? 'red' : 'blue'}
          fontSize={{ base: p2r(10), md: p2r(24) }}
          fontWeight="700"
        >
          {props.temp}℃
        </Text>
      </Flex>
      {!isMobile && (
        <>
          <Flex h="9.6vh" alignItems="center" justify="center">
            <Text color="text.body" fontSize={p2r(24)} fontWeight="700">
              {props.humidity}%
            </Text>
          </Flex>
          <Flex
            h="9.6vh"
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Flex transform={`rotate(${props.windIcon * 22.5}deg)`}>
              <Icon as={WeatherTimeArrow} boxSize="80px" />
            </Flex>
            <Flex alignItems="baseline">
              <Text color="text.body" fontSize={p2r(24)} fontWeight="700">
                {props.wind}
              </Text>
              <Text color="text.body" fontSize={p2r(20)} fontWeight="700">
                m
              </Text>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  )
}

const WeatherTime: React.FC = () => {
  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const currentDay = dayjs()
  const nextDay = dayjs().add(1, 'day')

  type WeatherCardType = {
    hour: string
    weatherIcon: React.FC
    rainfall: number
    temp: number
    humidity: number
    windIcon: number
    wind: number
  }

  type WeatherDataType = {
    date: Date
    wth: number
    temp: number
    rain_1h: number
    wind_d: number
    wind_s: number
    hum: number
  }
  const [weatherDataList, setWeatherDataList] = useState<
    Array<WeatherDataType>
  >([])
  const [isLoading, setIsLoading] = useState<boolean>()
  const pointwfForecastQuery = usePointwfForecastQuery()

  useEffect(() => {
    if (pointwfForecastQuery.data) {
      setWeatherDataList(pointwfForecastQuery.data.data)
      setIsLoading(pointwfForecastQuery.isLoading)
    }
  }, [pointwfForecastQuery.data])

  // 読み込み中はインジケータをレンダリング
  if (isLoading === undefined) {
    return <AppLoading />
  }

  const dateTimes: string[] = []
  const weatherCords: number[] = []
  const temps: number[] = []
  const rains: number[] = []
  const windDirections: number[] = []
  const windSpeed: number[] = []
  const humidities: number[] = []
  const dates: string[] = []

  weatherDataList.slice(0, 22).map((weatherData, index: number) => {
    if (index % 3 === 0) {
      dateTimes.push(dayjs(weatherData.date).format('H'))
      weatherCords.push(weatherData.wth)
      temps.push(Math.round(weatherData.temp))
      rains.push(Math.round(weatherData.rain_1h))
      windDirections.push(weatherData.wind_d)
      windSpeed.push(weatherData.wind_s)
      humidities.push(weatherData.hum)
      dates.push(dayjs(weatherData.date).format('D'))
    }
    return undefined
  })

  const DUMMY_RESPONSE = [
    {
      date: currentDay.date(),
      hours: [
        {
          hour: dateTimes[0],
          weatherIcon: WeatherIconMap[weatherCords[0]],
          rainfall: rains[0],
          temp: temps[0],
          humidity: humidities[0],
          windIcon: windDirections[0],
          wind: windSpeed[0],
        },
        {
          hour: dateTimes[1],
          weatherIcon: WeatherIconMap[weatherCords[1]],
          rainfall: rains[1],
          temp: temps[1],
          humidity: humidities[1],
          windIcon: windDirections[1],
          wind: windSpeed[1],
        },
        {
          hour: dateTimes[2],
          weatherIcon: WeatherIconMap[weatherCords[2]],
          rainfall: rains[2],
          temp: temps[2],
          humidity: humidities[2],
          windIcon: windDirections[3],
          wind: windSpeed[2],
        },
        {
          hour: dateTimes[3],
          weatherIcon: WeatherIconMap[weatherCords[3]],
          rainfall: rains[3],
          temp: temps[3],
          humidity: humidities[3],
          windIcon: windDirections[3],
          wind: windSpeed[3],
        },
        {
          hour: dateTimes[4],
          weatherIcon: WeatherIconMap[weatherCords[4]],
          rainfall: rains[4],
          temp: temps[4],
          humidity: humidities[4],
          windIcon: windDirections[4],
          wind: windSpeed[4],
        },
        {
          hour: dateTimes[5],
          weatherIcon: WeatherIconMap[weatherCords[5]],
          rainfall: rains[5],
          temp: temps[5],
          humidity: humidities[5],
          windIcon: windDirections[5],
          wind: windSpeed[5],
        },
        {
          hour: dateTimes[6],
          weatherIcon: WeatherIconMap[weatherCords[6]],
          rainfall: rains[6],
          temp: temps[6],
          humidity: humidities[6],
          windIcon: windDirections[6],
          wind: windSpeed[6],
        },
        {
          hour: dateTimes[7],
          weatherIcon: WeatherIconMap[weatherCords[7]],
          rainfall: rains[7],
          temp: temps[7],
          humidity: humidities[7],
          windIcon: windDirections[7],
          wind: windSpeed[7],
        },
      ],
    },
  ]

  const displayWeatherCards: WeatherCardType[] = []
  const todayWeatherCount = []
  const tomorrowWeatherCount = []
  dates.map((date) => {
    if (currentDay.date() === Number(date)) {
      todayWeatherCount.push(date)
    }
    if (nextDay.date() === Number(date)) {
      tomorrowWeatherCount.push(date)
    }
    return undefined
  })

  displayWeatherCards.push(...DUMMY_RESPONSE[0].hours)

  return (
    <Flex>
      <Flex
        justify={{ base: 'unset', md: 'center' }}
        pt={{ base: '16px', md: '4.8vh' }}
        pb={{ base: '32px', md: '4.8vh' }}
        gap={{ base: 'unset', md: '32px' }}
        overflowX="scroll"
        w="100%"
      >
        <Flex
          direction="column"
          justify="space-between"
          zIndex={{ base: '100', md: 'unset' }}
          position={{ base: 'sticky', md: 'unset' }}
          left={{ base: '0', md: 'unset' }}
          bgColor={{ base: 'grand.white', md: 'unset' }}
          boxShadow={{ base: ' 4px 0px 8px rgba(0, 0, 0, 0.1)', md: 'unset' }}
        >
          <Flex
            h={{ base: '40px', md: '7.6vh' }}
            w={{ base: '48px', md: 'unset' }}
            alignItems="center"
            justify={{ base: 'center', md: 'unset' }}
          >
            <Text fontSize={{ base: p2r(11), md: p2r(22) }} color="text.sub">
              {f('日')}
            </Text>
          </Flex>
          <Flex
            h={{ base: '48px', md: '9.6vh' }}
            alignItems="center"
            justify={{ base: 'center', md: 'unset' }}
          >
            <Text fontSize={{ base: p2r(11), md: p2r(22) }} color="text.sub">
              {f('時間')}
            </Text>
          </Flex>
          <Flex
            h={{ base: '56px', md: '100px' }}
            alignItems="center"
            justify={{ base: 'center', md: 'unset' }}
          >
            <Text fontSize={{ base: p2r(11), md: p2r(22) }} color="text.sub">
              {f('天気')}
            </Text>
          </Flex>
          <Flex
            h={{ base: '48px', md: '9.6vh' }}
            alignItems="center"
            justify={{ base: 'center', md: 'unset' }}
          >
            <Text fontSize={{ base: p2r(11), md: p2r(22) }} color="text.sub">
              {f('降水')}
            </Text>
          </Flex>
          <Flex
            h={{ base: '48px', md: '9.6vh' }}
            alignItems="center"
            justify={{ base: 'center', md: 'unset' }}
          >
            <Text fontSize={{ base: p2r(11), md: p2r(22) }} color="text.sub">
              {f('気温')}
            </Text>
          </Flex>
          {!isMobile && (
            <>
              <Flex h="9.6vh" alignItems="center">
                <Text fontSize={p2r(22)} color="text.sub">
                  {f('湿度')}
                </Text>
              </Flex>
              <Flex h="9.6vh" alignItems="center">
                <Text fontSize={p2r(22)} color="text.sub">
                  {f('風')}
                </Text>
              </Flex>
            </>
          )}
        </Flex>

        <Flex
          direction="column"
          bgColor={{ base: 'grand.white', md: 'unset' }}
          mr={{ base: '16px', md: 'unset' }}
        >
          <Grid
            h={{ base: '40px', md: '7.6vh' }}
            templateColumns={{
              base: `calc(${todayWeatherCount.length * 12.5}%) 1fr`,
              md: `calc(${todayWeatherCount.length * 12.5}%) 1fr`,
            }}
          >
            <WeatherTimeDate
              month={currentDay.month() + 1}
              date={currentDay.date()}
              // day={f(currentDay.format('ddd'))}
              isHidden={todayWeatherCount.length === 0}
            />
            <WeatherTimeDate
              month={nextDay.month() + 1}
              date={nextDay.date()}
              // day={f(nextDay.format('ddd'))}
              isHidden={tomorrowWeatherCount.length === 0}
            />
          </Grid>
          <Grid templateColumns="repeat(8, 1fr)">
            {displayWeatherCards.map((weatherItem) => (
              <WeatherTimeCard
                key={weatherItem.hour}
                time={weatherItem.hour}
                weatherIcon={weatherItem.weatherIcon}
                rainfall={weatherItem.rainfall}
                temp={weatherItem.temp}
                humidity={weatherItem.humidity}
                windIcon={weatherItem.windIcon}
                wind={weatherItem.wind}
              />
            ))}
          </Grid>
        </Flex>
      </Flex>
      <Flex
        display="block"
        alignItems="left"
        gap={p2r(4)}
        fontSize={{base: p2r(3), md: p2r(16)}}
        fontWeight="700"
        margin="10px"
        position="absolute"
        left="0px"
        bottom={{base: '65px', md: '0px'}}
      >
        情報提供元 : 気象庁 / 株式会社フランクリン・ジャパン
      </ Flex>
    </Flex>
  )
}

export default WeatherTime
