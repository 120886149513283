import { Flex, FlexProps, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { IconType } from 'react-icons'
import { MdArrowForwardIos } from 'react-icons/md'
import { p2r } from '../../../utils/font'

export type TaxiPointItemProps = {
  leftIconPath?: IconType
  onClick(): void
  children: React.ReactNode
} & FlexProps
const TaxiPointItem: React.FC<TaxiPointItemProps> = (props) => {
  const { leftIconPath, onClick, children, ...rest } = props
  return (
    <Flex
      h="fit-content"
      p="16px"
      align="center"
      justify="space-between"
      columnGap="16px"
      backgroundColor="grand.white"
      cursor="pointer"
      hover={{ backgroundColor: 'grand.grayLight' }}
      onClick={onClick}
      {...rest}
    >
      <Flex flex="1" columnGap="8px" align="center">
        {leftIconPath && (
          <Icon as={leftIconPath} boxSize="24px" color="grand.gray" />
        )}
        <Text
          fontSize={p2r(24)}
          fontWeight="700"
          lineHeight="150%"
          color="text.body"
          flex="1"
          textAlign="left"
          noOfLines={1}
          whiteSpace="normal"
        >
          {children}
        </Text>
        {/* ここにキャレット右向きアイコン追加してほしい */}
        <Icon as={MdArrowForwardIos} boxSize="24px" color="grand.gray" />
      </Flex>
    </Flex>
  )
}
export default TaxiPointItem
