import { Flex } from '@chakra-ui/react'
import React from 'react'
// import { Navigate } from 'react-router-dom'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { useResetPassword } from '../../hooks/useResetPassword'
import { Step1 } from './Step1'
import { Step2 } from './Step2'

const MypagePassword: React.FC = () => {
  useHeaderConfig({
    title: 'マイページ | パスワード変更',
  })
  const { resetState } = useResetPassword()
  const { step } = resetState
  return (
    <div>
      <Flex
        alignItems="center"
        py="48px"
        px="32px"
        bgColor="theme.background"
        minH="100vh"
        direction="column"
        rowGap="48px"
        backgroundColor="grand.white"
      >
        {step === 1 ? <Step1 /> : <Step2 />}
      </Flex>
    </div>
  )
}

export default MypagePassword
