import { Heading, Text, Box, Image } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

type AppNotionRenderProps = {
  title: string
  blocks: any[]
}

const AppNotionRender: React.FC<AppNotionRenderProps> = ({ title, blocks }) => {
  const generateElement = (block: any): any => {
    if (block.type === 'paragraph') {
      const contents = block.paragraph?.rich_text
      return contents.map((item: any) => (
        <Text
          as={contents.length <= 1 ? 'p' : 'span'}
          fontSize={p2r(16)}
          my="0.6rem"
          lineHeight="1.5"
          fontWeight={item?.annotations.bold ? 'bold' : 'normal'}
          color={
            item?.annotations.color === 'default'
              ? '#1A202C'
              : item?.annotations.color
          }
          fontStyle={item?.annotations.italic ? 'italic' : 'normal'}
          textDecoration={
            item?.annotations.strikethrough ? 'line-through' : 'none'
          }
        >
          {item?.plain_text}
        </Text>
      ))
    }

    if (block.type === 'quote') {
      const contents = block.quote?.rich_text
      return (
        <Box borderLeft="3px solid #333" pl="1rem" my="3rem">
          {contents.map((item: any) => (
            <Text as="p" whiteSpace="pre-wrap">
              {item?.plain_text}
            </Text>
          ))}
        </Box>
      )
    }

    if (block.type === 'image') {
      const imageUrl = block?.image.file?.url

      return <Image src={imageUrl} />
    }

    if (block.type === 'heading_1') {
      return (
        <Heading as="h2" fontSize={p2r(24)} mt="2rem">
          {block.heading_1?.rich_text[0]?.plain_text}
        </Heading>
      )
    }

    if (block.type === 'heading_2') {
      return (
        <Heading as="h3" fontSize={p2r(20)} mt="2rem">
          {block.heading_2?.rich_text[0]?.plain_text}
        </Heading>
      )
    }

    if (block.type === 'heading_3') {
      return (
        <Heading as="h4" fontSize={p2r(18)} mt="2rem">
          {block.heading_3?.rich_text[0]?.plain_text}
        </Heading>
      )
    }
    return false
  }
  return (
    <Box maxW="600px" m="auto">
      <Heading as="h1" fontSize={p2r(32)} mb={p2r(40)}>
        {title}
      </Heading>
      {blocks.map((block) => (
        <>{generateElement(block)}</>
      ))}
    </Box>
  )
}
export default AppNotionRender
