import { Icon } from '@chakra-ui/react'
import React from 'react'
/**
 * 再生アイコン
 */
const SlideShow: React.FC = (props) => (
  <Icon boxSize="24px" viewBox="0 0 24 24" {...props}>
    <mask
      id="mask0_6661_25886"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_6661_25886)">
      <path
        d="M10 16.5L17 12L10 7.5V16.5ZM5.5 21C4.95 21 4.479 20.8043 4.087 20.413C3.69567 20.021 3.5 19.55 3.5 19V5C3.5 4.45 3.69567 3.979 4.087 3.587C4.479 3.19567 4.95 3 5.5 3H19.5C20.05 3 20.521 3.19567 20.913 3.587C21.3043 3.979 21.5 4.45 21.5 5V19C21.5 19.55 21.3043 20.021 20.913 20.413C20.521 20.8043 20.05 21 19.5 21H5.5Z"
        fill="currentColor"
      />
    </g>
  </Icon>
)

export default SlideShow
