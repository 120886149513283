import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Radio,
  RadioGroup,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import {
  MdClose,
  MdDone,
  MdFlag,
  MdPerson,
  MdPinDrop,
  MdSchedule,
} from 'react-icons/md'
import { useAppConfirm } from '../../../components/common/AppConfirm/useAppConfirm'
import AppModal, { AppModalProps } from '../../../components/common/AppModal'
import {
  AdminTaxiReservationModalProvider,
  useAdminTaxiReservationModalContext,
} from '../../../components/taxi/admin/AdminTaxiReservationModalContext'
import TaxiMonthlyCalendar from '../../../components/taxi/admin/TaxiMonthlyCalendar'
import { useAuthContext } from '../../../context/AuthContext'
import { useTaxiReservationListQuery } from '../../../hooks/coreApi/useTaxiReservationListQuery'
import { useTaxiReservationUpdateStatusByCompanyMutation } from '../../../hooks/coreApi/useTaxiReservationUpdateStatusByCompanyMutation'
import useFormatMessage from '../../../hooks/useFormatMessage'
import useHeaderConfig from '../../../hooks/useHeaderConfig'
import { getFromLabel, getToLabel } from '../../../models/taxiReservation'
import { TaxiReservationResponse } from '../../../openapi'
import { p2r } from '../../../utils/font'
import useTaxiAdminReservationCreateState from '../useTaxiAdminReservationCreateState'

type ReservationCardProps = {
  month: number // 月
  day: number // 日
  dayOfWeek: string // 曜日
  time: string // 時間
  name: string // 予約者
  from: string // 出発地
  to: string // 目的地
  onClickSelected(): void // カード選択
  onClickConfirmed(): void // 予約確定
  onClickCancel(): void // 予約キャンセル
  isActive: boolean
}
const ReservationCard: React.FC<ReservationCardProps> = (props) => (
  <Box
    borderWidth="2px"
    borderColor={props.isActive ? 'theme.primary' : 'grand.grayLight'}
    borderRadius="8px"
    position="relative"
  >
    <Button
      display="block"
      pl="14px"
      pr="14px"
      pt="16px"
      pb="64px"
      variant="unstyled"
      w="fit-content"
      h="fit-content"
      onClick={props.onClickSelected}
    >
      <Flex direction="column" rowGap="8px">
        <Flex columnGap="8px" align="center">
          <Icon as={MdSchedule} color="text.sub" boxSize="24px" />
          <Text
            flex="1"
            fontSize={p2r(20)}
            fontWeight="700"
            whiteSpace="normal"
            textAlign="left"
          >
            {props.month}
            <Box as="span" fontSize={p2r(16)} fontWeight="400">
              月
            </Box>
            {props.day}
            <Box as="span" fontSize={p2r(16)} fontWeight="400">
              日({props.dayOfWeek})
            </Box>
            {props.time}-
          </Text>
        </Flex>

        <Flex columnGap="8px" align="center">
          <Icon as={MdPinDrop} color="text.sub" boxSize="24px" />
          <Text
            flex="1"
            fontSize={p2r(16)}
            fontWeight="700"
            whiteSpace="normal"
            textAlign="left"
          >
            {props.from}
          </Text>
        </Flex>

        <Flex columnGap="8px" align="center">
          <Icon as={MdFlag} color="text.sub" boxSize="24px" />
          <Flex flex="1" whiteSpace="normal" justify="flex-start">
            <Center
              boxSize="24px"
              px="4px"
              py="1px"
              backgroundColor="theme.primary"
              borderRadius="50%"
              color="text.white"
              fontSize={p2r(14)}
              lineHeight="1"
            >
              内
            </Center>
            <Text
              ml="4px"
              fontSize={p2r(16)}
              fontWeight="700"
              whiteSpace="normal"
              textAlign="left"
            >
              {props.to}
            </Text>
          </Flex>
        </Flex>

        <Flex columnGap="8px" align="center">
          <Icon as={MdPerson} color="text.sub" boxSize="24px" />
          <Text
            flex="1"
            fontSize={p2r(16)}
            fontWeight="700"
            whiteSpace="normal"
            textAlign="left"
          >
            {props.name}
            <Box as="span" fontSize={p2r(12)} ml="12px">
              さま
            </Box>
          </Text>
        </Flex>
      </Flex>
    </Button>

    <Flex
      position="absolute"
      left="auto"
      bottom="19px"
      px="14px"
      justify="flex-end"
      columnGap="8px"
      w="100%"
    >
      <Button
        leftIcon={<Icon as={MdClose} boxSize="24px" color="grand.gray" />}
        px="8px"
        py="4px"
        fontSize={p2r(14)}
        backgroundColor="grand.white"
        color="text.body"
        iconSpacing="0px"
        h="fit-content"
        fontWeight="700"
        borderWidth="1px"
        borderColor="grand.grayLight"
        onClick={props.onClickCancel}
      >
        断る
      </Button>
      <Button
        leftIcon={<Icon as={MdDone} boxSize="24px" color="text.white" />}
        px="8px"
        py="4px"
        fontSize={p2r(14)}
        backgroundColor="theme.primary"
        color="text.white"
        iconSpacing="0px"
        h="fit-content"
        fontWeight="700"
        borderWidth="1px"
        borderColor="theme.primary"
        onClick={props.onClickConfirmed}
      >
        予約確定
      </Button>
    </Flex>
  </Box>
)

const cancelReasonTexts: string[] = [
  '大変申し訳ありませんが、ご予約をお受けすることができません',
  '大変申し訳ありませんが、満車のためご予約をお受けすることができません',
  'ご指定頂いた時間は予約多数につき、時間の調整をさせていただければ乗車できます。ご登録の電話番号にご連絡差し上げます',
  '予約に関して、ご登録の電話番号にご連絡差し上げます',
  'その他',
]

export type TaxiReservationDetailCancelReasonModalProps = AppModalProps & {
  taxiReservationId: number
  onFinish(): void
}

const TaxiReservationDetailCancelReasonModal: React.FC<
  TaxiReservationDetailCancelReasonModalProps
> = (props) => {
  const [cancelReasonTextIndex, setCancelReasonTextIndex] = useState<number>(0)
  const [cancelReasonOtherText, setCancelReasonOtherText] = useState<string>('')

  // 「その他」の場合
  const isShowOtherTextArea = useMemo(
    () => cancelReasonTextIndex === cancelReasonTexts.length - 1,
    [cancelReasonTextIndex],
  )

  const updateMutation = useTaxiReservationUpdateStatusByCompanyMutation()
  const toast = useToast()

  const onSubmit = () => {
    updateMutation.mutate(
      {
        id: props.taxiReservationId,
        taxiReservationUpdateByTaxiCompanyRequest: {
          status: 'rejected',
          cancelReason: isShowOtherTextArea
            ? cancelReasonOtherText
            : cancelReasonTexts[cancelReasonTextIndex],
        },
      },
      {
        onSuccess() {
          toast({
            title: '予約をキャンセルしました',
            status: 'success',
          })
          props.onFinish()
        },
        onError() {
          toast({
            title: '予約のキャンセルに失敗しました。',
            status: 'error',
          })
        },
      },
    )
  }

  return (
    <AppModal
      {...props}
      title="キャンセル理由を選択"
      cancelText="閉じる"
      onOk={onSubmit}
    >
      <VStack>
        <Box w="100%">
          <RadioGroup
            defaultValue={0}
            value={cancelReasonTextIndex}
            onChange={(val) => {
              setCancelReasonTextIndex(Number(val))
            }}
          >
            <Flex justifyContent="flex-start" flexDirection="column" gap="4">
              {cancelReasonTexts.map((text, index) => (
                <Radio value={index}>{text}</Radio>
              ))}
            </Flex>
          </RadioGroup>
          {isShowOtherTextArea && (
            <Box mt="4">
              <Textarea
                rows={3}
                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setCancelReasonOtherText(evt.target.value)
                }}
              />
            </Box>
          )}
        </Box>
      </VStack>
    </AppModal>
  )
}

const AdminTaxiReservationOnline: React.FC = () => {
  useHeaderConfig({
    title: 'オンライン予約確認',
  })
  const { f } = useFormatMessage()
  const { refetchTaxiReservations } = useTaxiAdminReservationCreateState()

  const [currentTaxiReservationId, setCurrentTaxiReservationId] =
    useState<number>(0)

  const taxiReservationUpdateStatusMutation =
    useTaxiReservationUpdateStatusByCompanyMutation()

  const reservationReasonModalDisclosure = useDisclosure()

  const toast = useToast()
  const { me } = useAuthContext()

  const taxiReservationListQuery = useTaxiReservationListQuery({
    userId: me?.id,
    withStartTaxiLandmark: true,
    withEndTaxiLandmark: true,
    withUser: true,
    withTaxiCar: true,
    withTaxiCompany: true,
  })

  // TODO APIでフィルタ済みを取得
  const allTaxiReservations = useMemo(
    () =>
      taxiReservationListQuery.data?.taxiReservations?.filter(
        (r) =>
          r.taxiCompanyId === me?.taxiCompanyId &&
          ['draft', 'fixed'].includes(r.status),
      ) || [],
    [me, taxiReservationListQuery],
  )

  const draftReservations = useMemo(
    () => allTaxiReservations.filter((r) => r.status === 'draft'),
    [allTaxiReservations],
  )

  const appConfirm = useAppConfirm()
  const reservationDetailModalContext = useAdminTaxiReservationModalContext()

  const onError = () => {
    toast({
      title: '予約のステータス変更に失敗しました。',
      status: 'error',
    })
  }

  const onClickSelected = (reservation: TaxiReservationResponse) => {
    reservationDetailModalContext.showModal(reservation)
  }

  const onClickConfirmed = async (reservation: TaxiReservationResponse) => {
    const confirmOk = await appConfirm.open({
      title: '予約の確定',
      body: 'この予約を確定しますか？',
    })

    if (!confirmOk) {
      return
    }

    await taxiReservationUpdateStatusMutation.mutate(
      {
        id: reservation.id,
        taxiReservationUpdateByTaxiCompanyRequest: {
          status: 'fixed',
        },
      },
      {
        onSuccess() {
          taxiReservationListQuery.refetch()
          refetchTaxiReservations()
          toast({
            title: '予約を確定しました',
            status: 'success',
          })
        },
        onError,
      },
    )
  }

  // 選択中の予約カード
  const [activeCardIndex, setActiveCardIndex] = useState<number>(0)

  return (
    <>
      <Flex minHeight="calc(100vh - 56px)">
        <Box flex="1">
          <TaxiMonthlyCalendar />
        </Box>
        <Box
          py="24px"
          pl="24px"
          pr="32px"
          width="328px"
          borderRightWidth="1px"
          borderRightColor="grand.grayLight"
          boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
        >
          <Text
            fontSize={p2r(16)}
            lineHeight="1"
            fontWeight="700"
            color="text.body"
          >
            {f('未対応のオンライン予約')}
          </Text>
          <Flex mt="16px" direction="column" rowGap="16px">
            {!draftReservations.length && (
              <Text>未対応のオンライン予約はありません</Text>
            )}
            {draftReservations.map((r, index) => {
              const key = `reservationCard-${index}`
              return (
                <ReservationCard
                  month={dayjs(r.rideAt).month() + 1}
                  day={dayjs(r.rideAt).date()}
                  dayOfWeek={dayjs(r.rideAt).format('ddd')}
                  time={dayjs(r.rideAt).format('HH:mm')}
                  name={r.user?.nickname || ''}
                  from={getFromLabel(r)}
                  to={getToLabel(r)}
                  onClickSelected={() => {
                    setActiveCardIndex(index)
                    onClickSelected(r)
                  }}
                  onClickConfirmed={() => {
                    onClickConfirmed(r)
                  }}
                  onClickCancel={() => {
                    setCurrentTaxiReservationId(r.id)
                    reservationReasonModalDisclosure.onOpen()
                  }}
                  key={key}
                  isActive={index === activeCardIndex}
                />
              )
            })}
          </Flex>
        </Box>
      </Flex>
      <TaxiReservationDetailCancelReasonModal
        taxiReservationId={currentTaxiReservationId}
        isOpen={reservationReasonModalDisclosure.isOpen}
        onClose={reservationReasonModalDisclosure.onClose}
        onFinish={() => {
          reservationReasonModalDisclosure.onClose()
          refetchTaxiReservations()
        }}
      />
    </>
  )
}

const AdminTaxiReservationOnlineWrapper = () => (
  <AdminTaxiReservationModalProvider>
    <AdminTaxiReservationOnline />
  </AdminTaxiReservationModalProvider>
)

export default AdminTaxiReservationOnlineWrapper
