import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Grid,
  GridItem,
  GridItemProps,
  HStack,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import React, { ReactNode } from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'
import { useTaxiLandmarkListQuery } from '../../../../hooks/coreApi/useTaxiLandmarkListQuery'
import { useTaxiReservationDeleteMutation } from '../../../../hooks/coreApi/useTaxiReservationDeleteMutation'
import { TAXI_RESERVATION_LIST_QUERY_KEY } from '../../../../hooks/coreApi/useTaxiReservationListQuery'
import { useTaxiReservationUpdateMutation } from '../../../../hooks/coreApi/useTaxiReservationUpdateMutation'
import { getStatusName } from '../../../../models/taxiReservation'
import { TaxiReservationResponse } from '../../../../openapi'
import { useAppConfirm } from '../../../common/AppConfirm/useAppConfirm'
import AppFormDateTime from '../../../form/AppFormDateTime'
import AppFormProvider from '../../../form/AppFormProvider'
import AppFormSelect from '../../../form/AppFormSelect'
import AppFormText from '../../../form/AppFormText'
import useAppForm from '../../../form/useAppForm'

type ItemProps = GridItemProps & {
  title: ReactNode
  children?: ReactNode
}
const Item: React.FC<ItemProps> = ({ title, children, ...other }) => (
  <GridItem {...other}>
    <Box w="100%">{title}</Box>
    <Box w="100%">{children}</Box>
  </GridItem>
)

type TaxiLandmarkSelectProps<T extends FieldValues> = { name: FieldPath<T> }

const TaxiLandmarkSelect = <FormType extends FieldValues>({
  name,
}: TaxiLandmarkSelectProps<FormType>) => {
  const query = useTaxiLandmarkListQuery({})
  const taxiLandmarks = query?.data?.taxiLandmarks || []
  return (
    <AppFormSelect name={name}>
      <option value="">未選択</option>
      {taxiLandmarks.map((landmark) => (
        <option key={landmark.id} value={landmark.id}>
          {landmark.name}
        </option>
      ))}
    </AppFormSelect>
  )
}

export type TaxiReservationDetailProps = {
  taxiReservation: TaxiReservationResponse
  onFinish(): void
  onClickCancel(): void
}

type FormValues = {
  userName: string
  userPhone: string
  rideAt: string
  dropAt: string
  startTaxiLandmarkId: number | null
  startAddress: string
  endTaxiLandmarkId: number | null
  endAddress: string
}

export const TaxiReservationDetail: React.FC<TaxiReservationDetailProps> = ({
  taxiReservation,
  onFinish,
  onClickCancel,
}) => {
  const queryClient = useQueryClient()
  const taxiReservationDeleteMutation = useTaxiReservationDeleteMutation()
  const taxiReservationUpdateMutation = useTaxiReservationUpdateMutation()
  const toast = useToast()
  const appConfirm = useAppConfirm()

  const form = useAppForm<FormValues>({
    defaultValues: {
      userName:
        taxiReservation.userName || taxiReservation.user?.nickname || '',
      userPhone:
        taxiReservation.userPhone ||
        taxiReservation.user?.userInfo?.telephone ||
        '',
      rideAt: dayjs(taxiReservation.rideAt).format('YYYY-MM-DDTHH:mm'),
      dropAt: dayjs(taxiReservation.dropAt).format('YYYY-MM-DDTHH:mm'),
      startTaxiLandmarkId: taxiReservation.startTaxiLandmarkId || null,
      endTaxiLandmarkId: taxiReservation.endTaxiLandmarkId || null,
      startAddress: taxiReservation.startAddress || '',
      endAddress: taxiReservation.endAddress || '',
    },
  })

  const onClickDelete = async () => {
    const confirmOk = await appConfirm.open({
      title: '予約の削除',
      body: (
        <>
          この予約を削除しますか？
          <br />
          （お客様への自動通知が行われないため、電話等での事前確認を推奨します）
        </>
      ),
    })

    if (!confirmOk) {
      return
    }

    taxiReservationDeleteMutation.mutate(
      {
        id: taxiReservation.id,
      },
      {
        onSuccess() {
          toast({
            title: '予約を削除しました',
            status: 'success',
          })
          // 他の画面の予約も再取得
          queryClient.refetchQueries([TAXI_RESERVATION_LIST_QUERY_KEY])
        },
        onError() {
          toast({
            title: '予約の削除に失敗しました',
            status: 'error',
          })
        },
      },
    )
  }

  const onSubmit = (values: FormValues) => {
    taxiReservationUpdateMutation.mutate(
      {
        id: taxiReservation.id,
        taxiReservationUpdateRequest: {
          taxiCarId: taxiReservation.taxiCarId,
          taxiCompanyId: taxiReservation.taxiCompanyId,
          startLatLng: taxiReservation.startLatLng,
          endLatLng: taxiReservation.endLatLng,
          userName: values.userName,
          userPhone: values.userPhone,
          rideAt: dayjs(values.rideAt).toISOString(),
          dropAt: dayjs(values.dropAt).toISOString(),
          startTaxiLandmarkId:
            values.startTaxiLandmarkId === null
              ? null
              : Number(values.startTaxiLandmarkId),
          endTaxiLandmarkId:
            values.endTaxiLandmarkId === null
              ? null
              : Number(values.endTaxiLandmarkId),
          startAddress: values.startAddress,
          endAddress: values.endAddress,
        },
      },
      {
        onSuccess() {
          toast({
            title: '予約を更新しました',
            status: 'success',
          })
          onFinish()
          // 他の画面の予約も再取得
          queryClient.refetchQueries([TAXI_RESERVATION_LIST_QUERY_KEY])
        },
        onError(err: any) {
          toast({
            title: '予約の更新に失敗しました',
            status: 'error',
          })
          form.setError('startAddress', err?.response?.data?.start?.[0])
          form.setError('endAddress', {
            message: err?.response?.data?.end?.[0],
          })
          form.setError('rideAt', {
            message: err?.response?.data?.rideAt?.[0],
          })
        },
      },
    )
  }

  return (
    <AppFormProvider methods={form} onSubmit={form.handleSubmit(onSubmit)}>
      <Box width="100%">
        <Grid
          gap={6}
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        >
          <Item title="さあ・くるID">
            {taxiReservation?.user?.userInfo?.code || ''}
          </Item>
          <Item title="メールアドレス">
            {taxiReservation?.user?.userInfo?.mailaddress || '登録なし'}
          </Item>
          <Item title="住所">
            {taxiReservation?.user?.userInfo?.address || '登録なし'}
          </Item>
          <Item title="Let's登録">
            {taxiReservation?.user?.taxiDiscountFlag ? 'あり' : 'なし'}
          </Item>
          <Item title="お名前">
            <AppFormText<FormValues> name="userName" />
          </Item>
          <Item title="電話番号">
            <AppFormText<FormValues> name="userPhone" />
          </Item>
          <Item title="乗車場所">
            <TaxiLandmarkSelect<FormValues> name="startTaxiLandmarkId" />
          </Item>
          <Item title="乗車場所(住所手入力)" colSpan={{ sm: 1, md: 2 }}>
            <AppFormText<FormValues> name="startAddress" />
          </Item>
          <Item title="目的地">
            <TaxiLandmarkSelect<FormValues> name="endTaxiLandmarkId" />
          </Item>
          <Item title="目的地(住所手入力)" colSpan={{ sm: 1, md: 2 }}>
            <AppFormText<FormValues> name="endAddress" />
          </Item>
          <Item title="乗車時間" colSpan={{ sm: 1, md: 2 }}>
            <AppFormDateTime<FormValues> name="rideAt" />
          </Item>
          <Item title="降車時間" colSpan={{ sm: 1, md: 2 }}>
            <AppFormDateTime<FormValues> name="dropAt" />
          </Item>
          <Item title="予約ステータス">{getStatusName(taxiReservation)}</Item>
          <Item title="予約作成者">
            {taxiReservation.isCreatedByTaxiCompany ? '運行会社' : '乗客'}
          </Item>
          <Item title="予約の削除">
            <Button
              leftIcon={<DeleteIcon />}
              variant="link"
              onClick={onClickDelete}
            >
              削除する
            </Button>
          </Item>
        </Grid>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="flex-end" mt="6">
        <VStack width="100%">
          <HStack width="100%" justifyContent="right">
            <Button onClick={onClickCancel}>キャンセル</Button>
            <Button colorScheme="primary" type="submit">
              保存
            </Button>
          </HStack>
        </VStack>
      </Box>
    </AppFormProvider>
  )
}
