import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { MdPlayArrow } from 'react-icons/md'
import AppErrorResult from '../../components/common/AppErrorResult'
// import { AppList, AppListItem } from '../../components/common/AppList'
import { AppLoading } from '../../components/common/AppLoading'
import VideoHeading from '../../components/video/VideoHeading'
import VideoModal from '../../components/video/VideoModal'
import {
  VideoUserList,
  VideoUserListItem,
} from '../../components/video/VideoUserList'
import { useRoomMeetingCreateCompositionMutation } from '../../hooks/coreApi/useRoomMeetingCreateCompositionMutation'
import { useRoomMeetingListQuery } from '../../hooks/coreApi/useRoomMeetingListQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { RoomMeetingResponse } from '../../openapi'
import { getDateFormat } from '../../utils/date'
import { p2r } from '../../utils/font'
import { getRoomMeetingStatusText } from '../../utils/room'

const VideoRoomMeetingList: React.FC = () => {
  useHeaderConfig({
    title: 'ビデオ通話 - 録画したビデオ通話',
  })
  const { f } = useFormatMessage()
  const roomMeetingListQuery = useRoomMeetingListQuery({
    withCompositionVideoUrl: true,
  })
  const roomMeetingCreateCompositionMutation =
    useRoomMeetingCreateCompositionMutation()
  const toast = useToast()

  const meetings = useMemo(
    () =>
      roomMeetingListQuery.data?.meetings?.filter((m) => {
        // 録画リリース以後
        if (dayjs(m.createdAt).isBefore(dayjs('2024-02-12 00:00:00'))) {
          return false
        }

        return true
      }),
    [roomMeetingListQuery.data],
  )

  // const [currentModalFriend, setCurrentModalFriend] =
  //   useState<FriendResponse | null>(null)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentMeeting, setCurrentMeeting] =
    useState<RoomMeetingResponse | null>(null)
  const handleClickItem = (meeting: RoomMeetingResponse) => {
    setCurrentMeeting(meeting)
    onOpen()
  }

  // エラーハンドリング
  // const onError = () => {
  //   toast({
  //     title: 'エラーが発生しました',
  //     status: 'error',
  //   })
  // }

  const onClickConpositionStart = () => {
    if (!currentMeeting) {
      return
    }

    roomMeetingCreateCompositionMutation.mutate(
      {
        id: currentMeeting.id,
      },
      {
        onSuccess() {
          toast({
            title: '動画ファイルの作成を開始しました',
            status: 'success',
          })
        },
        onError() {
          // onError()
        },
      },
    )
  }

  if (roomMeetingListQuery.isLoading) {
    return <AppLoading />
  }

  if (!meetings) {
    return <AppErrorResult message="録画したビデオ通話がありません" />
  }

  // return (
  //   <AppList isMore={false} onClickMore={() => {}}>
  //     {meetings.map((meeting) => (
  //       <AppListItem
  //         key={meeting.id}
  //         date={getDateFormat(meeting.createdAt)}
  //         title={`${getDateFormat(meeting.roomCreatedAt)}〜${getDateFormat(
  //           meeting.roomEndedAt,
  //         )}のビデオ通話`}
  //         description=""
  //         isShowLike={false}
  //         // likeCount={0}
  //         // isLiked={false}
  //         // soundPath={content.fi}
  //         contentId={meeting.id}
  //         isReaded
  //       />
  //     ))}
  //   </AppList>
  // )

  return (
    <Box>
      <VideoHeading
        label={`${f('録画一覧')} ${String(meetings?.length || 0)}件`}
      />

      <VideoUserList>
        {meetings?.map((meeting) => {
          const title = `[${getRoomMeetingStatusText(meeting)}] ${getDateFormat(
            meeting.roomCreatedAt,
          )}〜${getDateFormat(meeting.roomEndedAt)}`

          return (
            <VideoUserListItem
              isShowStatus={false}
              name={title}
              nameKn={meeting.roomSid}
              status="blocked"
              onClickMore={() => {
                handleClickItem(meeting)
              }}
              onClickStatus={() => {
                handleClickItem(meeting)
              }}
              key={meeting.id}
            />
          )
        })}
      </VideoUserList>

      <VideoModal isOpen={isOpen} onClose={onClose}>
        <Flex direction="column">
          <Flex pt="24px" direction="column" alignItems="center" rowGap="8px">
            <Text
              mt="0.5em"
              fontSize={p2r(18)}
              fontWeight="700"
              lineHeight="150%"
              color="text.body"
            >
              {getDateFormat(currentMeeting?.roomCreatedAt || '')}〜
              {getDateFormat(currentMeeting?.roomEndedAt || '')}のビデオ通話
            </Text>
            {!currentMeeting?.compositionVideoUrl ? (
              <Text fontSize={p2r(16)} lineHeight="150%" color="text.body">
                動画ファイルは未作成です。
                <br />
                以下のボタン押してから数分の変換処理の後、再生URLを発行します。
                {/* <pre>{JSON.stringify(currentMeeting, null, 2)}</pre> */}
              </Text>
            ) : (
              <Text fontSize={p2r(16)} lineHeight="150%" color="text.body">
                以下から再生できます。
              </Text>
            )}

            {/* <Text
              w={isMobile ? '70%' : '80%'}
              textAlign="center"
              fontSize={p2r(16)}
              lineHeight="150%"
              color="text.body"
            >
              ID: {currentModalFriend?.id}
            </Text> */}
          </Flex>
          <Flex mt="56px" columnGap="48px" justify="center">
            {!currentMeeting?.compositionVideoUrl ? (
              <Button
                isDisabled={currentMeeting?.compositionStatus !== null}
                variant="ghost"
                borderWidth="4px"
                fontWeight="700"
                rounded="8px"
                minW="300px"
                h="68px"
                borderColor="theme.primary"
                color="theme.primary"
                fontSize={p2r(24)}
                onClick={() => {
                  onClickConpositionStart()
                }}
              >
                {f('再生ファイルを作成する')}
              </Button>
            ) : (
              <Button
                borderWidth="4px"
                fontWeight="700"
                rounded="8px"
                minW="300px"
                h="68px"
                borderColor="theme.primary"
                color="theme.primary"
                fontSize={p2r(24)}
                leftIcon={<MdPlayArrow />}
                onClick={() => {
                  window.location.href =
                    currentMeeting?.compositionVideoUrl || ''
                }}
              >
                {f('動画を再生する')}
              </Button>
            )}
          </Flex>
        </Flex>
      </VideoModal>
    </Box>
  )
}

export default VideoRoomMeetingList
