import { Heading, HeadingProps } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

export type FormHeadingProps = {
  label: string
} & HeadingProps
export const FormHeading: React.FC<FormHeadingProps> = ({
  label,
  ...props
}) => (
  <Heading
    as="h2"
    color="text.headline"
    fontSize={{ base: p2r(24), md: p2r(32) }}
    fontWeight="700"
    lineHeight="150%"
    {...props}
  >
    {label}
  </Heading>
)
