import { Box, Button, Icon, Text, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { MdLocalActivity, MdLocalTaxi } from 'react-icons/md'
import { Navigate } from 'react-router-dom'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { p2r } from '../../utils/font'

type ServiceButtonProps = {
  type: 'coupon' | 'taxi'
  label: string
  onClick(): void
}

const ServiceButton: React.FC<ServiceButtonProps> = (props) => {
  const { type, label, onClick } = props
  const iconType = (() => {
    if (type === 'taxi') {
      return MdLocalTaxi
    }
    return MdLocalActivity
  })()

  return (
    <Button
      w="100%"
      display="flex"
      minH={p2r(87)}
      justifyContent="flex-start"
      alignItems="center"
      px="16px"
      columnGap="8px"
      variant="ghost"
      onClick={onClick}
      borderBottomWidth="1px"
      borderBottomColor="grand.grayLight"
      _hover={{
        backgroundColor: 'grand.white',
      }}
    >
      <Icon as={iconType} boxSize={p2r(40)} color="grand.gray" />
      <Text fontSize={p2r(24)} color="#3a3a3a" fontWeight="bold">
        {label}
      </Text>
    </Button>
  )
}

const Authentications: React.FC = () => {
  useHeaderConfig({
    title: '認証',
  })
  const isMobile = useBreakpointValue({ base: true, md: false })

  // PCアクセスの場合はTOPへ転送
  if (!isMobile) {
    return <Navigate to="/" replace />
  }

  const DUMMY_CONTENTS = [
    {
      id: 1,
      type: 'coupon',
      label: '神山クーポン',
    },
    {
      id: 2,
      type: 'taxi',
      label: 'タクシー認証',
    },
    {
      id: 3,
      type: 'coupon',
      label: '神山クーポン',
    },
    {
      id: 4,
      type: 'taxi',
      label: 'タクシー認証',
    },
    {
      id: 5,
      type: 'coupon',
      label: '神山クーポン',
    },
    {
      id: 6,
      type: 'taxi',
      label: 'タクシー認証',
    },
  ] as {
    id: number
    type: 'coupon' | 'taxi'
    label: string
  }[]

  return (
    <Box>
      {DUMMY_CONTENTS.map((content) => (
        <ServiceButton
          key={content.id}
          type={content.type}
          label={content.label}
          onClick={() => {}}
        />
      ))}
    </Box>
  )
}

export default Authentications
