import { Box, Flex, Text } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { TaxiReservationStatus } from '../../../models/taxiReservation'
import { p2r } from '../../../utils/font'

// タクシー会社 目的地 乗車場所 日時 帰路 のコンポーネント
export type TextGroupProps = {
  label: string
  children: ReactNode
}
export const TextGroup: React.FC<TextGroupProps> = (props) => (
  <Flex rowGap="8px" direction="column">
    <Text
      fontSize={p2r(16)}
      color="text.sub"
      fontWeight="400"
      lineHeight="150%"
    >
      {props.label}
    </Text>
    <Text
      fontSize={p2r(24)}
      color="text.body"
      fontWeight="700"
      lineHeight="150%"
    >
      {props.children}
    </Text>
  </Flex>
)

export type TaxiBoxProps = {
  from: string
  fromTime: string
  to: string
  toTime?: string
  companies: string[]
  isReverse?: boolean
  isGoback?: boolean
  status?: TaxiReservationStatus
  cancelReason?: string
}
export const TaxiBox: React.FC<TaxiBoxProps> = (props) => {
  const { f } = useFormatMessage()
  const isReverse = props.isReverse || false
  const company = props.companies.join(', ')
  let statusText = ''
  switch (props.status) {
    case 'fixed':
      statusText = '確定'
      break
    case 'draft':
      statusText = '予約確認中'
      break
    case 'rejected':
      statusText = '予約受付不可'
      break
    case 'canceled':
      statusText = 'キャンセル済'
      break
    default:
  }

  return (
    <Flex
      rowGap={{ base: '8px', md: '1.9vh' }}
      border={isReverse ? '2px solid' : '3px solid'}
      borderColor={isReverse ? 'grand.grayLight' : 'theme.primary'}
      bgColor={isReverse ? 'theme.primaryLight' : 'grand.white'}
      rounded="10px"
      py={{ base: '16px', md: '2.2vh' }}
      px={{ base: '16px', md: '2.8vh' }}
      direction="column"
      w={{ base: '100%', md: '400px' }}
    >
      <TextGroup label={f('タクシー会社')}>{f(company)}</TextGroup>
      <TextGroup label={f('目的地')}>{props.to}</TextGroup>
      <TextGroup label={f('乗車場所')}>{props.from}</TextGroup>
      <TextGroup label={f('日時')}>{props.fromTime}</TextGroup>
      {props.status && (
        <TextGroup label={f('ステータス')}>
          {statusText}
          {props.cancelReason && (
            <Box>
              <Text fontSize="sm">({props.cancelReason})</Text>
            </Box>
          )}
        </TextGroup>
      )}

      <TextGroup label={f('帰路')}>
        {props.isGoback ? props.toTime || '' : 'なし'}
      </TextGroup>
    </Flex>
  )
}
