type PayloadUserType = {
  id: number
  name: string
}

export type OpenVideoCallNativePayload = {
  user: PayloadUserType
  targetUser: PayloadUserType
}

export type OpenMyNumberCardEntryNativePayload = {
  isUsePin: boolean
}

// ネイティブメソッドを呼び出すためのインターフェース
// iOS: messageHandlers
const iOSMessageHandlers = (window as any).webkit?.messageHandlers
// Android: JavascriptInterface
const androidJavascriptInterface = (window as any).Android

// iOSネイティブアプリか判定
export const isIOSNative = (): boolean => {
  // messageHandlerが有効かどうかで判別
  if (iOSMessageHandlers) {
    return true
  }

  return false
}

// Androidネイティブアプリか判定
export const isAndroidNative = (): boolean => {
  // messageHandlerが有効かどうかで判別
  if (androidJavascriptInterface) {
    return true
  }

  return false
}

// ネイティブアプリかどうか判定
export const isNativeApp = () => isIOSNative() || isAndroidNative()

// --- 以下ネイティブアプリ側のメソッド呼び出し
// バージョンによって未定義のメソッドもあるため、メソッドの存在チェックを必ず行うようにする

// ネイティブアプリのビデオ通話画面を開く
export const openVideoRoomNative = (roomId: number) => {
  if (isIOSNative()) {
    if (iOSMessageHandlers?.openVideoRoom) {
      iOSMessageHandlers.openVideoRoom.postMessage({
        scene: 'videoRoom',
        payload: {
          roomId,
        },
      })
    }
  } else if (isAndroidNative()) {
    if (androidJavascriptInterface?.openVideoRoom) {
      androidJavascriptInterface.openVideoRoom(
        JSON.stringify({
          scene: 'videoRoom',
          payload: {
            roomId,
          },
        }),
      )
    }
  }
}

// ネイティブアプリの通話ダイレクトコール画面を開く
export const openVideoCallNative = (payload: OpenVideoCallNativePayload) => {
  if (isIOSNative()) {
    if (iOSMessageHandlers?.openVideoCall) {
      iOSMessageHandlers.openVideoCall.postMessage({
        scene: 'videoCall',
        payload,
      })
    }
  } else if (isAndroidNative()) {
    if (androidJavascriptInterface?.openVideoCall) {
      androidJavascriptInterface.openVideoCall(
        JSON.stringify({
          scene: 'videoCall',
          payload,
        }),
      )
    }
  }
}

// ネイティブアプリのマイナンバー登録画面を開く
export const openMyNumberCardEntryNative = (
  payload: OpenMyNumberCardEntryNativePayload,
) => {
  if (isIOSNative()) {
    if (iOSMessageHandlers?.openMyNumberCardEntry) {
      iOSMessageHandlers.openMyNumberCardEntry.postMessage({
        scene: 'myNumberCardEntry',
        payload,
      })
    }
  } else if (isAndroidNative()) {
    if (androidJavascriptInterface?.openMyNumberCardEntry) {
      androidJavascriptInterface.openMyNumberCardEntry(
        JSON.stringify({
          scene: 'myNumberCardEntry',
          payload,
        }),
      )
    }
  }
}

// ログイン（ネイティブアプリ側でトークンを同期）
export const didLoginNative = () => {
  if (isIOSNative()) {
    if (iOSMessageHandlers?.didLogin) {
      iOSMessageHandlers.didLogin.postMessage(document.cookie)
    }
  } else if (isAndroidNative()) {
    if (androidJavascriptInterface?.didLogin) {
      androidJavascriptInterface.didLogin(document.cookie)
    }
  }
}

// ログアウト（ネイティブアプリ側でトークンを削除）
export const didLogoutNative = () => {
  if (isIOSNative()) {
    if (iOSMessageHandlers?.didLogout) {
      iOSMessageHandlers?.didLogout?.postMessage({})
    }
  } else if (isAndroidNative()) {
    if (androidJavascriptInterface?.didLogout) {
      androidJavascriptInterface.didLogout()
    }
  }
}
