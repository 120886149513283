import { Button, ButtonProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { p2r } from '../../../utils/font'

type TaxiPointSelectButtonProps = {
  isActive: boolean
  children: ReactNode
} & ButtonProps
const TaxiPointSelectButton: React.FC<TaxiPointSelectButtonProps> = (props) => {
  const { isActive, children, ...rest } = props
  return (
    <Button
      w={p2r(96)}
      fontSize={p2r(16)}
      color={isActive ? 'grand.white' : 'grand.grayLight'}
      borderWidth="2px"
      borderColor={isActive ? 'theme.primary' : 'grand.grayLight'}
      borderRadius="10px"
      bg={isActive ? 'theme.primary' : 'grand.white'}
      isDisabled={!isActive}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default TaxiPointSelectButton
