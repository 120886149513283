import { Flex, Text, Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { AuthButton } from '../../components/auth/AuthButton'
import { FormHeading } from '../../components/auth/FormHeading'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { p2r } from '../../utils/font'

const LoginResetPasswordCompleted: React.FC = () => {
  const { f } = useFormatMessage()
  useHeaderConfig({
    show: false,
  })
  const navigate = useNavigate()
  return (
    <Flex
      direction="column"
      justify="center"
      alignItems="center"
      bgColor="theme.background"
      minH="100vh"
      rowGap="64px"
    >
      <Stack spacing="32px" alignItems="center">
        <FormHeading label={f('パスワードの再設定が完了しました')} />
        <Text fontSize={p2r(16)} color="text.body">
          {f(
            '新しいパスワードへの変更が完了したので、ログイン画面から再度ログインしてください。',
          )}
        </Text>
      </Stack>
      <Stack spacing="16px">
        <AuthButton
          onClick={() => navigate('/login')}
          bgColor="theme.primary"
          color="text.white"
        >
          {f('ログイン画面へ')}
        </AuthButton>
      </Stack>
    </Flex>
  )
}

export default LoginResetPasswordCompleted
