import {
  Flex,
  Stack,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Button,
} from '@chakra-ui/react'
import { useState } from 'react'
import { AuthButton } from '../../components/auth/AuthButton'
import { FormHeading } from '../../components/auth/FormHeading'
import useFormatMessage from '../../hooks/useFormatMessage'
import { useResetPassword } from '../../hooks/useResetPassword'
import { p2r } from '../../utils/font'

export const Step2: React.FC = () => {
  const { f } = useFormatMessage()
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false)
  const { resetState, setResetState, handleStep2Submit } = useResetPassword()

  return (
    <>
      <Flex direction="column" rowGap="40px" minW="560px">
        <Stack spacing="16px">
          <FormHeading label={f('認証コード')} />
          <Text
            fontSize={p2r(16)}
            lineHeight="150%"
            color="text.body"
            whiteSpace="pre-wrap"
          >
            {f(
              'メールアドレスか電話番号（SMS）に送信された認証コードを入力してください。',
            )}
          </Text>

          <Input
            _placeholder={{ color: 'grand.grayLight' }}
            bgColor="grand.white"
            borderWidth="3px"
            borderColor="grand.grayLight"
            rounded="10px"
            minH="64px"
            value={resetState.authCode}
            onChange={(e) => {
              setResetState({
                ...resetState,
                authCode: e.target.value,
              })
            }}
          />
        </Stack>

        <div>
          <Stack spacing="32px">
            <Stack spacing="16px">
              <FormHeading label={f('新しいパスワード')} />
              <InputGroup>
                <Input
                  isInvalid={
                    resetState.password !== resetState.passwordConfirmation
                  }
                  type={showPassword ? 'text' : 'password'}
                  placeholder={f('半角英数字・記号のみ、８文字以上')}
                  _placeholder={{ color: 'grand.grayLight' }}
                  bgColor="grand.white"
                  borderWidth="3px"
                  borderColor="grand.grayLight"
                  rounded="10px"
                  minH="64px"
                  value={resetState.password}
                  onChange={(e) => {
                    setResetState({
                      ...resetState,
                      password: e.target.value,
                    })
                  }}
                  pr="4.5rem"
                />
                <InputRightElement width="4.5rem" top="12px" right="4px">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Text fontSize={p2r(16)} color="text.body">
                {f('確認のためもう一度入力してください')}
              </Text>
              <InputGroup>
                <Input
                  isInvalid={
                    resetState.password !== resetState.passwordConfirmation
                  }
                  type={showPasswordConfirmation ? 'text' : 'password'}
                  placeholder={f('半角英数字・記号のみ、８文字以上')}
                  _placeholder={{ color: 'grand.grayLight' }}
                  bgColor="grand.white"
                  borderWidth="3px"
                  borderColor="grand.grayLight"
                  rounded="10px"
                  minH="64px"
                  value={resetState.passwordConfirmation}
                  onChange={(e) => {
                    setResetState({
                      ...resetState,
                      passwordConfirmation: e.target.value,
                    })
                  }}
                  pr="4.5rem"
                />
                <InputRightElement width="4.5rem" top="12px" right="4px">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() =>
                      setShowPasswordConfirmation((prevState) => !prevState)
                    }
                  >
                    {showPasswordConfirmation ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Stack>
          </Stack>
        </div>
      </Flex>
      <Flex>
        <AuthButton
          onClick={() => handleStep2Submit()}
          isDisabled={resetState.isValidStep2}
          bgColor={resetState.isValidStep2 ? 'grand.white' : 'theme.primary'}
          color={resetState.isValidStep2 ? 'grand.grayLight' : 'text.white'}
        >
          {f('コードを送信')}
        </AuthButton>
      </Flex>
    </>
  )
}
