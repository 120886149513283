import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdArrowForwardIos, MdAutorenew, MdOutlineClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Calendar from '../../../components/svg/Calendar'
import SelectArrowBottom from '../../../components/svg/SelectArrowBottom'
import TaxiResourceCalendar from '../../../components/taxi/admin/TaxiResourceCalendar'
import { useAuthContext } from '../../../context/AuthContext'
import { parseErrorMessages } from '../../../hooks/coreApi/appApiClient'
import { useTaxiCarListQuery } from '../../../hooks/coreApi/useTaxiCarListQuery'
import { useTaxiLandmarkListQuery } from '../../../hooks/coreApi/useTaxiLandmarkListQuery'
import { useTaxiReservationCreateByTaxiCompany } from '../../../hooks/coreApi/useTaxiReservationCreateByTaxiCompany'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { getDefaultDropAt } from '../../../models/taxiReservation'
import { getTaxiHomeText } from '../../../models/user'
import {
  TaxiReservationCreateByTaxiCompanyRequest,
  UserResponse,
} from '../../../openapi'
import { p2r } from '../../../utils/font'
import { toHalfWidth } from '../../../utils/string'
import { searchUserByCode } from './useUserSearchQuery'

type FormItemProps = {
  htmlId: string
  label: string
  children: ReactNode
}
const FormItem: React.FC<FormItemProps> = (props) => (
  <Flex
    direction="column"
    sx={{
      '.chakra-select__icon-wrapper': {
        paddingRight: '24px',
      },
    }}
  >
    <FormLabel
      htmlFor={props.htmlId}
      color="text.sub"
      fontSize={p2r(20)}
      mb="0"
    >
      {props.label}
    </FormLabel>
    <Box mt="8px">{props.children}</Box>
  </Flex>
)

type InputMenuItemType = {
  label: string
  onClick: () => void
}
const InputMenuItem: React.FC<InputMenuItemType> = (props) => {
  const { label, onClick } = props
  return (
    <MenuItem
      onClick={() => {
        onClick()
      }}
      fontSize={p2r(24)}
      px="32px"
      py="16px"
      color="text.body"
      _notLast={{
        borderBottomWidth: '1px',
        borderBottomColor: 'grand.grayLight',
      }}
    >
      {label}
    </MenuItem>
  )
}

type InputMenuType = {
  selectedValue: string
  placeholder: string
  children: ReactNode
}
const InputMenu: React.FC<InputMenuType> = (props) => {
  const { selectedValue = '', placeholder, children } = props
  return (
    <Box position="relative">
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={
            <Icon as={SelectArrowBottom} color="grand.gray" boxSize={p2r(32)} />
          }
          height="68px"
          fontSize={p2r(24)}
          borderWidth="2px"
          borderColor="grand.grayLight"
          backgroundColor="grand.white"
          textAlign="left"
          color={selectedValue === '' ? 'grand.grayLight' : 'text.headline'}
          variant="ghost"
          w="100%"
        >
          {selectedValue === '' ? placeholder : selectedValue}
        </MenuButton>
        <MenuList
          minW="470px"
          maxH="40vh"
          overflowY="scroll"
          py="0px"
          borderWidth="2px"
          borderColor="grand.grayLight"
        >
          {children}
        </MenuList>
      </Menu>
    </Box>
  )
}

type FormValueType = {
  // APIのPOSTで必須
  code: string
  rideAt: string
  startTaxiLandmarkId: number | null
  endTaxiLandmarkId: number | null
  startAddress: string
  endAddress: string
  startLatLng: string
  endLatLng: string
  isInBound: boolean
  // APIのPOSTで任意
  returnRideAt: string
  // フォームの入力値（APIのInputには存在しない）
  userName: string
  userPhone: string
  taxiCarId: number | null
}

type LandmarkOption = { label: string; value: number | null }

// 「自宅」選択の場合
const HOME_LANDMARK_OPTION_VALUE = Infinity

const AdminTaxiReservationCreateSelectInput: React.FC = () => {
  const isError = false
  const { f } = useFormatMessage()
  const reservationCreateMutation = useTaxiReservationCreateByTaxiCompany()
  const toast = useToast()
  const navigate = useNavigate()
  const { me } = useAuthContext()

  // ランドマーク
  const taxiLandmarkListQuery = useTaxiLandmarkListQuery({})
  const taxiLandmarks = taxiLandmarkListQuery?.data?.taxiLandmarks || []

  // 車
  const taxiCarListQuery = useTaxiCarListQuery({})

  // TODO API taxiCompanyIDでフィルター
  const taxiCars =
    taxiCarListQuery.data?.taxiCars?.filter(
      (c) => me?.taxiCompanyId === c.taxiCompanyId,
    ) || []

  const [selectedValue, setSelectedValue] = useState({
    endPoint: '',
    endLatLng: '',
    endTaxiLandmarkId: '',
    startPoint: '',
    startLatLng: '',
    startTaxiLandmarkId: '',
    isInBound: false,
    rideDate: '',
    rideTime: '',
    returnRideTime: '',
    taxiCarId: '',
  })

  const handleChange = (key: string, value: string | boolean | null) => {
    setSelectedValue({
      ...selectedValue,
      [key]: value,
    })
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isValid },
    watch,
  } = useForm<FormValueType>({
    mode: 'all',
  })

  // お客様コード(code)ので検索してHITした場合、カナと電話番号を自動入力
  const [searchedUser, setSearchedUser] = useState<UserResponse | null>(null)

  const onErrorSarch = () => {
    toast({
      title: '割引適用ができないお客様コードが入力されています',
      status: 'error',
    })
  }

  const onClickSearch = async () => {
    const code = watch('code')
    // 全角も半角に変換
    const replacedCode = toHalfWidth(code)
    let user = null
    user = await searchUserByCode(replacedCode).catch(() => {
      user = null
    })

    if (!user) {
      onErrorSarch()
      return
    }

    setSearchedUser(user)
    setValue('userName', user?.userInfo?.commonName || '')
    setValue('userPhone', user?.userInfo?.phone || '')
  }

  // 手入力 + 自宅を選択肢に含める
  const landmarkOptions = useMemo<LandmarkOption[]>(() => {
    if (!taxiLandmarks) {
      return []
    }

    const userHomeText = getTaxiHomeText(searchedUser)

    let newLandmarkOptions: LandmarkOption[] = taxiLandmarks.map((l) => ({
      label: l.name,
      value: l.id,
    }))

    newLandmarkOptions = [
      {
        label: '手入力',
        value: null,
      },
      {
        label: userHomeText,
        value: HOME_LANDMARK_OPTION_VALUE,
      },
      ...newLandmarkOptions,
    ]

    return newLandmarkOptions
  }, [taxiLandmarks, searchedUser])

  const onSuccess = () => {
    toast({
      title: '予約を作成しました',
    })
    navigate('/admin/taxi/calendar')
  }

  const onError = (err: any) => {
    toast({
      title: parseErrorMessages(err).join('\n'),
      status: 'error',
    })
  }

  // 予約送信処理
  const onSubmit: SubmitHandler<FormValueType> = (values) => {
    if (!searchedUser || !searchedUser.id) {
      toast({
        title: '正しいお客様コードを入力してください',
        status: 'error',
      })
      return
    }

    const params: TaxiReservationCreateByTaxiCompanyRequest = {
      ...values,
      userId: searchedUser.id,
      dropAt: getDefaultDropAt(dayjs(values.rideAt)).utc().format(),
    }

    if (values.isInBound) {
      params.returnRideAt = dayjs(values.returnRideAt).utc().format()
      params.returnDropAt = getDefaultDropAt(dayjs(values.rideAt))
        .utc()
        .format()
    }

    reservationCreateMutation.mutate(
      {
        taxiReservationCreateByTaxiCompanyRequest: params,
      },
      {
        onSuccess,
        onError,
      },
    )
  }

  // 乗車日時の算出
  useEffect(() => {
    if (!selectedValue.rideDate || !selectedValue.rideTime) {
      return
    }
    // rideAtの算出
    const formatRideAt = dayjs(
      selectedValue.rideDate + selectedValue.rideTime,
    ).format()

    // フォームに登録
    setValue('rideAt', String(formatRideAt))
  }, [selectedValue.rideDate, selectedValue.rideTime])

  // 帰路日時の算出（日付は乗車日時のものを使用）
  useEffect(() => {
    if (!selectedValue.rideDate || !selectedValue.returnRideTime) {
      return
    }
    // rideAtの算出
    const formatReturnRideAt = dayjs(
      selectedValue.rideDate + selectedValue.returnRideTime,
    ).format()

    // フォームに登録
    setValue('returnRideAt', String(formatReturnRideAt))
  }, [selectedValue.rideDate, selectedValue.returnRideTime])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Accordion allowToggle>
        <AccordionItem
          display="flex"
          sx={{
            '.chakra-collapse': {
              width: '50%',
            },
            '.rbc-btn-group:last-child': {
              display: 'none',
            },
          }}
        >
          {({ isExpanded }) => (
            <>
              <Flex
                direction="column"
                rowGap="16px"
                px="32px"
                py="24px"
                width={isExpanded ? '50%' : '100%'}
              >
                <Grid columnGap="16px" templateColumns="repeat(3, 1fr)">
                  <GridItem>
                    <FormControl isInvalid={!!errors.code}>
                      <FormItem htmlId="code" label="お客様コード">
                        <HStack>
                          <Input
                            type="text"
                            id="code"
                            height="68px"
                            fontSize={p2r(24)}
                            placeholder="123456"
                            borderWidth="2px"
                            borderColor="grand.grayLight"
                            fontWeight="700"
                            color="text.headline"
                            autoComplete="off"
                            _placeholder={{ color: 'grand.grayLight' }}
                            {...register('code', {
                              required: 'お客様コードは必須です',
                            })}
                          />
                          <Box>
                            <Button
                              h="68px"
                              leftIcon={
                                <Icon as={MdAutorenew} boxSize="28px" />
                              }
                              onClick={onClickSearch}
                              fontSize="10px"
                            >
                              お客様
                              <br />
                              情報更新
                              <br />
                            </Button>
                          </Box>
                        </HStack>

                        <FormErrorMessage>
                          {errors.code && errors.code.message}
                        </FormErrorMessage>
                      </FormItem>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={!!errors.userName}>
                      <FormItem htmlId="userName" label="名前(カナ)">
                        <Input
                          type="text"
                          id="userName"
                          height="68px"
                          fontSize={p2r(24)}
                          placeholder="タナカタロウ"
                          borderWidth="2px"
                          borderColor="grand.grayLight"
                          fontWeight="700"
                          color="text.headline"
                          autoComplete="off"
                          _placeholder={{ color: 'grand.grayLight' }}
                          {...register('userName', {
                            required: '名前(カナ)は必須です',
                          })}
                        />
                      </FormItem>
                      <FormErrorMessage>
                        {errors.userName && errors.userName.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={!!errors.userPhone}>
                      <FormItem htmlId="userPhone" label="電話番号">
                        <Input
                          type="userPhone"
                          id="userPhone"
                          height="68px"
                          fontSize={p2r(24)}
                          placeholder="00000000000"
                          borderWidth="2px"
                          borderColor="grand.grayLight"
                          fontWeight="700"
                          color="text.headline"
                          autoComplete="off"
                          _placeholder={{ color: 'grand.grayLight' }}
                          {...register('userPhone', {
                            required: '電話番号は必須です',
                            minLength: {
                              value: 10,
                              message: '10文字以上入力してください。',
                            },
                            maxLength: {
                              value: 11,
                              message: '11文字以下で入力してください。',
                            },
                          })}
                        />
                      </FormItem>
                      <FormErrorMessage>
                        {errors.userPhone && errors.userPhone.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                </Grid>
                <Grid
                  columnGap="16px"
                  templateColumns={isExpanded ? '1fr 1fr' : '1.5fr 1.5fr 1fr'}
                >
                  <GridItem>
                    <FormControl>
                      <FormItem htmlId="date" label="日付">
                        <Input
                          type="date"
                          id="date"
                          height="68px"
                          fontSize={p2r(24)}
                          placeholder="日付を選択する"
                          borderWidth="2px"
                          borderColor="grand.grayLight"
                          fontWeight="700"
                          color="text.headline"
                          _placeholder={{ color: 'grand.grayLight' }}
                          onChange={(event) => {
                            handleChange('rideDate', event.target.value)
                          }}
                        />
                      </FormItem>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormItem htmlId="time" label="時間">
                      <Input
                        type="time"
                        id="time"
                        height="68px"
                        fontSize={p2r(24)}
                        placeholder="時間を選択する"
                        borderWidth="2px"
                        borderColor="grand.grayLight"
                        fontWeight="700"
                        color="text.headline"
                        _placeholder={{ color: 'grand.grayLight' }}
                        onChange={(event) => {
                          handleChange('rideTime', event.target.value)
                        }}
                      />
                    </FormItem>
                  </GridItem>
                  <GridItem display="grid" alignContent="end">
                    {!isExpanded && (
                      <AccordionButton
                        color="grand.white"
                        backgroundColor="theme.primary"
                        display="flex"
                        columnGap="4px"
                        fontSize={p2r(20)}
                        fontWeight="700"
                        minH="68px"
                        borderRadius="8px"
                      >
                        <Icon as={Calendar} boxSize={p2r(24)} />
                        {f('現在の予約状況を見る')}
                        <Icon as={MdArrowForwardIos} boxSize={p2r(24)} />
                      </AccordionButton>
                    )}
                  </GridItem>
                </Grid>

                <FormControl>
                  <FormItem htmlId="to" label="目的地">
                    <InputMenu
                      selectedValue={selectedValue.endPoint}
                      placeholder="目的地を選択してください"
                    >
                      {landmarkOptions.map((option) => (
                        <InputMenuItem
                          key={option.value}
                          onClick={() => {
                            handleChange('endPoint', option.label)
                            // registerと同様の処理
                            if (option.value === HOME_LANDMARK_OPTION_VALUE) {
                              setValue('endAddress', '自宅')
                            } else {
                              setValue('endAddress', '')
                            }
                            setValue('endTaxiLandmarkId', option.value)
                          }}
                          label={option.label}
                        />
                      ))}
                    </InputMenu>
                  </FormItem>
                  {watch('endTaxiLandmarkId') === null && (
                    <FormItem htmlId="to" label="">
                      <Input
                        height="68px"
                        fontSize={p2r(24)}
                        borderWidth="2px"
                        borderColor="grand.grayLight"
                        backgroundColor="grand.white"
                        textAlign="left"
                        variant="ghost"
                        w="100%"
                        placeholder="目的地を入力してください"
                        {...register('endAddress')}
                      />
                    </FormItem>
                  )}
                </FormControl>

                <FormControl>
                  <FormItem htmlId="from" label="乗車場所">
                    <InputMenu
                      selectedValue={selectedValue.startPoint}
                      placeholder="乗車地を選択してください"
                    >
                      {landmarkOptions.map((option) => (
                        <InputMenuItem
                          key={option.value}
                          onClick={() => {
                            handleChange('startPoint', option.label)
                            // registerと同様の処理
                            if (option.value === HOME_LANDMARK_OPTION_VALUE) {
                              setValue('startAddress', '自宅')
                            } else {
                              setValue('startAddress', '')
                            }
                            setValue('startTaxiLandmarkId', option.value)
                          }}
                          label={option.label}
                        />
                      ))}
                    </InputMenu>
                  </FormItem>
                  {watch('startTaxiLandmarkId') === null && (
                    <FormItem htmlId="to" label="">
                      <Input
                        height="68px"
                        fontSize={p2r(24)}
                        borderWidth="2px"
                        borderColor="grand.grayLight"
                        backgroundColor="grand.white"
                        textAlign="left"
                        variant="ghost"
                        w="100%"
                        placeholder="乗車地を入力してください"
                        {...register('startAddress')}
                      />
                    </FormItem>
                  )}
                </FormControl>

                <FormControl>
                  <FormItem htmlId="kiro" label="帰路">
                    <InputMenu
                      selectedValue={selectedValue.isInBound ? 'あり' : 'なし'}
                      placeholder="あり"
                    >
                      <InputMenuItem
                        onClick={() => {
                          handleChange('isInBound', true)
                          setValue('isInBound', true)
                        }}
                        label="あり"
                      />
                      <InputMenuItem
                        onClick={() => {
                          handleChange('isInBound', false)
                          setValue('isInBound', false)
                        }}
                        label="なし"
                      />
                    </InputMenu>
                  </FormItem>
                </FormControl>

                {/* 帰路用の日時選択 */}
                {selectedValue.isInBound && (
                  <Grid
                    columnGap="16px"
                    templateColumns={isExpanded ? '1fr 1fr' : '1.5fr 1.5fr 1fr'}
                  >
                    <GridItem>
                      <FormItem htmlId="returnRideTime" label="帰路時間">
                        <Input
                          type="time"
                          id="returnRideTime"
                          height="68px"
                          fontSize={p2r(24)}
                          placeholder="時間を選択する"
                          borderWidth="2px"
                          borderColor="grand.grayLight"
                          fontWeight="700"
                          color="text.headline"
                          onChange={(event) => {
                            handleChange('returnRideTime', event.target.value)
                          }}
                        />
                      </FormItem>
                    </GridItem>
                  </Grid>
                )}

                <FormControl>
                  <FormItem htmlId="taxiCarId" label="担当車">
                    <InputMenu
                      selectedValue={
                        taxiCars.find(
                          (c) => String(c.id) === selectedValue.taxiCarId,
                        )?.name || '担当車なし'
                      }
                      placeholder="担当車を選択してください"
                    >
                      <InputMenuItem
                        label="担当車なし"
                        onClick={() => {
                          handleChange('taxiCarId', null)
                          setValue('taxiCarId', null)
                        }}
                      />
                      {taxiCars.map((car) => (
                        <InputMenuItem
                          key={car.id}
                          label={car.name}
                          onClick={() => {
                            handleChange('taxiCarId', String(car.id))
                            setValue('taxiCarId', car.id)
                          }}
                        />
                      ))}
                    </InputMenu>
                  </FormItem>
                </FormControl>

                {!isExpanded && (
                  <Flex justify="flex-end">
                    <Button
                      type="submit"
                      isDisabled={isError}
                      color="text.white"
                      variant="unstyled"
                      backgroundColor={
                        isError ? 'grand.grayLight' : 'theme.primary'
                      }
                      pl="64px"
                      pr="64px"
                      py="16px"
                      w="fit-content"
                      h="56px"
                      fontSize={p2r(20)}
                      lineHeight="1"
                      onClick={() => {}}
                      isLoading={isSubmitting}
                      disabled={!isValid}
                    >
                      予約を保存する
                    </Button>
                  </Flex>
                )}
              </Flex>
              {isExpanded && (
                <AccordionPanel
                  w="100%"
                  h="100%"
                  borderLeftWidth="1px"
                  borderLeftColor="grand.grayLight"
                >
                  <AccordionButton
                    fontSize={p2r(24)}
                    color="text.headline"
                    fontWeight="700"
                    display="flex"
                    columnGap="8px"
                  >
                    <Icon
                      as={MdOutlineClose}
                      boxSize={p2r(40)}
                      color="grand.grayDark"
                    />
                    閉じる
                  </AccordionButton>
                  <Box>
                    <TaxiResourceCalendar />
                  </Box>
                </AccordionPanel>
              )}
            </>
          )}
        </AccordionItem>
      </Accordion>
    </form>
  )
}

export default AdminTaxiReservationCreateSelectInput
