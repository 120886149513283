export const markdown = `
### さあ・くる利用規約

この「さあ・くる利用規約」（以下「本規約」と言う。）は、「さあ・くる」アプリを利用するための条件等を定めるものです。

第 1 条（総則）

1 「さあ・くる」アプリ（以下「本アプリ」と言う。）とは、「まちのクルマ Let’s 予約」、「ビデオ通話」、「かみやま ch」、「天気」、「ゴミ出し」、「おしらせ」、「防災無線」、「安心・安全（防災情報）」、「市況情報」およびその他機能や付随する機能を含む自治体住民向けのプラットホームアプリです。

2 本アプリは、イツモスマイル株式会社（以下「当社」と言う。）が運営および提供を行います。

3 本アプリで提供される各種機能（以下「本サービス」と言う。）は、本アプリの利用登録を行った人が利用できます。ただし、本サービスの一部については、対象自治体での利用申請が必要です。

4 本規約は、本サービスを利用する全ての人（以下「利用者」と言う。）に適用されます。

5 本サービスの利用料は、無料とします。

6 利用者は、本規約に同意することで、本サービスを利用することができます。なお、本

規約に同意する旨の手続を行う代わりに、実際に本サービスを利用することで、本規約に

同意したものとみなします。

第 2 条（本サービスの内容）

1 当社は本サービスの内容に瑕疵やバグがないことを保証しておらず、これにより生じた利用者の一切の損害につき賠償義務を負いません。

2 当社は、利用者にあらかじめ通知することなく、本サービスの内容や仕様を変更したり、提供を停止したり、中止したりすることができるものとします。

第 3 条（利用環境の整備）

1 利用者は、利用者自らの責任と負担において、本サービスの利用（本アプリのダウンロードを含む。以下同じ） に必要となる通信機器、ソフトウエア、通信回線、その他の環境を準備するものとします。なお、推奨動作環境に関しては随時更新するものとします。

■ 推奨動作環境

・iOS

＜ OS ＞ iOS13.0 以降

＜端末＞ NFC 搭載の iPhone7 以降の iPhone 端末

・Android

＜ OS ＞ Android8.0 以降

＜端末＞マイナンバーカードに対応した NFC 搭載スマートフォン

動作確認端末

・galaxyA32

2 本サービスの利用に伴い発生する通信料は、利用者の負担とします。

3 利用者は、当社または関係官庁等が提供する情報を参考にして、自己の利用環境に応

じ、 コンピューターウィルスの感染、不正アクセスおよび情報漏洩の防止等の適切な情

報セキュリティを保持するものとします。

4 利用者は、本アプリをインストールしたスマートフォンを処分する場合、または本サ

ービスの利用を終了する場合、本アプリを必ず削除するものとします。

第 4 条（本アプリの利用制限）

1 当社は、本アプリのご利用について、ID を登録された人に限定したり、一定の年齢以

上の人に限定したり、当社が定める本人確認等の手続を経て一定の要件を満たした利用

者のみに限定したりする等、条件を付すことができるものとします。なお、未成年者の利

用については、一部コンテンツに関して、親権者の同意が必要な場合があります。

2 当社は、反社会的勢力（第 8 条第 1 項で定義する。）の構成員（過去に構成員であった

人を含む。）およびその関係者、または本アプリを悪用したり、第三者に迷惑をかけたり

する利用者に対して、本アプリの利用を禁止します。

第 5 条（ID、パスワード等）

1 利用者が ID を登録する場合には、以下の ① 及び ② について、利用者は同意する必要があります。なお、利用者が登録できる ID は、利用者一人につき１つとします。

① 真実かつ正確な情報を登録すること

② 利用者の登録内容が最新となるように、利用者自身で適宜修正すること

2 利用者を特定する所定の認証方法（ID とパスワードの組み合わせや携帯電話事業者から送信される携帯電話番号ごとに一意に付与される符号、および登録情報との一致確認による認証を含むが、これらに限らない。）によりログインされた場合には、当社は、当該利用者自身による利用であるとみなします。

第 6 条（個人情報の取扱い）

1 本サービスの提供にあたり、当社が取得する個人情報の取扱いについては、別に定める「プライバシーポリシー」によるものとします。

2 当社は、以下の各号の場合には、本サービスの利用に伴い、当社が入手した利用者の個人情報を一切の責任を負うことなく、第三者に開示できるものとします。

① 当社が利用者より承諾を得た場合

② 当社が法令または権限ある官公庁により利用者の個人情報開示を求められた場合

③ 本サービスの技術的または経済的機能向上のため必要な場合

④ その他、本サービスの運営に必要な場合

第 7 条（禁止事項）

1 本サービスのご利用に際して、利用者が行う以下の行為（それらを誘発する行為や準備行為も含む。）を禁止します。

① 本サービスの提供の目的を超えて、本サービスおよび本サービスを構成するデータを利用すること

② 日本国または利用者が利用の際に所在する国・地域の法令に違反する行為

③ 社会規範・公序良俗に反する行為、または他人の権利を侵害し、もしくは他人の迷惑となるようなものを投稿、掲載、開示、提供または送信（以下これらを総称して「投稿等」と言う。）する行為

④ 他の利用者の使用するソフトウエアおよびハードウエア等の機能を破壊したり、妨害したりするようなプログラム等の投稿等を行ったりする行為

⑤ 当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為

⑥ 当社のサービス、当社の配信する広告、または当社のサイト上で提供されているサービスおよび広告を妨害する行為

⑦ 個人情報等、他の利用者に関する情報を利用者に無断で収集したり蓄積したりする行為

⑧ 本サービスの趣旨に照らして、本アプリを本来のサービス提供の目的とは異なる目的で利用する行為

⑨ 他の利用者の ID を使用して本サービスを利用する行為

⑩ 手段を問わず、他人から ID やパスワードを入手したり、他人に ID やパスワードを開示したり提供したりする行為

2 当社は、前項の行為を差し止める権利を有します。また、当社は、前項の行為を行った

利用者に対して、当該行為によって被った損害を請求する場合があります。

第 8 条（反社会的勢力の排除）

1 利用者は、過去、現在および将来にわたり、反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から 5 年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者を言う。）に該当しないことを保証し、および暴力的行為、詐術・脅迫行為、業務妨害行為等、法令に抵触する行為またはそのおそれのある行為を行わないものとします。

2 利用者が前項の規定に違反した場合には、当社は事前に通告することなく、当該利用者の本サービスの利用を停止し、または登録を削除する等の措置を講じることができるものとします。これにより、当該利用者に何らかの不利益または損害が生じたとしても、当社は一切の責任を負わないものとします。

第 9 条（当社に対する補償）

本規約に反して、利用者の行為が原因で生じたクレーム等に関連して、当社に費用が発生した場合、または当社が賠償金等の支払を行った場合には、当該利用者は、当社が支払った費用や賠償金等（当社が支払った弁護士費用を含む。）を負担するものとします。

第 10 条（利用者のデータ、アプリおよびコンテンツ等の取扱い）

1 当社管理のサーバーに保存している利用者のデータに関する取扱いは、当社の「プライ

バシーポリシー」に準ずるものとします。また、当社は、バックアップの義務を負わない

ものとします。

2 本アプリの保守や改良等の必要が生じた場合には、当社は必要な範囲で、当社管理のサ

ーバーに保存する利用者のデータを複製等することができるものとします。

3 当社は、本アプリ及び本サービスにおける知的財産権は当社および当社にライセンスを許諾している者が保有します。また、利用者が本サービスを利用しても、当社サービスまたはサービスを通じて利用可能になったコンテンツまたは情報の所有権は、利用者に付与されません。

4 本アプリでの閲覧用に公開された電子データ等、不特定または多数の利用者がアクセ

ス・閲覧できるコンテンツに対して、投稿権限者が投稿等を行ったコンテンツについては、

投稿者または当該コンテンツの著作権者に著作権が帰属します。また、当該コンテンツに

ついて、利用者は当社に対して、日本の国内外で無償かつ非独占的に利用（複製、上映、

公衆送信、展示、頒布、譲渡、貸与、翻訳、翻案、出版を含む。）する権利を期限の定め

なく、許諾（サブライセンス権を含む。）したものとみなします。なお、投稿者は著作者

人格権を行使しないものとします。

第 11 条（本サービスの利用停止等）

当社は、本サービスを適正に提供するために、以下の場合には、あらかじめ利用者に通

知することなく、データやコンテンツを削除したり、サービスの全部または一部の利用

をお断りしたり、利用者の ID を削除したりする等の措置を講じることができるものと

します。

① 利用者が本規約に定められている事項に違反した場合、もしくはそのおそれがあると当社が判断した場合

② ID が反社会的勢力の者またはその関係者によって登録または使用された場合、もしくはそのおそれがあると当社が判断した場合

③ 利用者が一定期間にわたって、 ID または本アプリを使用していない場合

④ その他、利用者との信頼関係が失われた場合等、当社と利用者との契約関係の維持が困難であると当社が判断した場合

第 12 条（免責事項）

1 当社の債務不履行責任は、当社の故意または重過失以外、免責されるものとします。

2 本規約に基づく本サービスの利用に関する契約が、消費者契約法に定める消費者契約

に該当する場合、前項の免責は適用されないものとし、当社は、当社の故意・重過失に起

因する場合を除き、直接かつ現実の損害（逸失利益、間接損害を除く）の範囲内で損害賠償責任を負うものとします。

第 13 条（個別サービスのガイドライン）

1 本サービスの利用については、別途「個別サービスのガイドライン」を設けるものとします。

2 本規約が規定する利用条件等と「個別サービスのガイドライン」の利用条件等が異なっ

ている場合には、特段の定めがない限り、当該サービスに関しては、「個別サービスのガ

イドライン」の利用条件等が、本規約に規定する利用条件等に優先して適用されるものと

します。

第 14 条（本規約の変更）

1 当社が必要と判断した場合には、本規約を変更することができるものとします。この場合、当社は、登録時のメールアドレスへの送付その他の適切な方法によって、本規約を変更する旨および変更後の本規約の内容、ならびにその効力発生日を利用者に周知するものとします。

2 変更後の本規約は、効力発生日より適用されます。

第 15 条（利用者からの連絡）

1 利用者が当社への連絡を希望する場合には、当社が設けた当社が指定するメールアドレスinfo@cir-cle.com宛てのメールによって行うものとします。

2 当社は、利用者からの問い合わせに対する回答を原則としてメールのみで行います。

第 16 条（権利義務等の譲渡の禁止）

利用者は、本規約に基づく全ての契約について、その契約上の地位およびこれにより生じる権利義務の全部または一部を当社の書面による事前の承諾なく、第三者に譲渡することはできません。

第 17 条（本規約の適用制限）

本規約の規定が、本規約に基づく利用者との契約に適用される関連法令に反する場合、

当該規定は、その限りにおいて、当該利用者との契約には適用されないものとします。

ただし、この場合においても、本規約の他の規定の効力には影響しないものとします。

第 18 条（存続条項）

本規約第 6 条第 2 項、第 7 条第 2 項、 第 9 条、第 10 条、第 12 条、第 19 条および本条の規定は、利用者が本サービスを終了した後、または利用停止後も存続します。

第 19 条（準拠法および裁判管轄）

1 本規約の成立、効力発生、解釈にあたっては、日本の国内法を準拠法とします。

2 当社のサービス（掲載内容や広告などを含む。）、ソフトウエアに起因または関連して当

社と利用者との間で生じた紛争については、徳島地方裁判所を管轄裁判所とします。

第 21 条（その他）

本アプリおよび本サービスの利用に関し、本規約に定めのない事項については、当社の

判断により決定するものとします。

■ 本件の問い合わせ先

イツモスマイル株式会社

メールアドレス：info@cir-cle.com

2023 年 3 月 20 日 制定

### プライバシーポリシー

本アプリ上で提供する本サービスにおける，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。

第 1 条（個人情報）

「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。

第 2 条（個人情報の収集方法）

当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。

第 3 条（個人情報を収集・利用する目的）

当社が個人情報を収集・利用する目的は，以下のとおりです。

1. 当社サービスの提供・運営のため
1. ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
1. ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため
1. メンテナンス，重要なお知らせなど必要に応じたご連絡のため
1. 利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため
1. ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため
1. 本サービスは基本無料ですが、今後、一部の有料サービスが始まった場合，ユーザーに利用料金を請求するため
1. 上記の利用目的に付随する目的

第 4 条（利用目的の変更）

9. 当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
10. 利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本アプリ上に公表するものとします。

第 5 条（個人情報の第三者提供）

11. 当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
    1. 人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき
    1. 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき
    1. 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
    1. 予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき
       1. 利用目的に第三者への提供を含むこと
       1. 第三者に提供されるデータの項目
       1. 第三者への提供の手段または方法
       1. 本人の求めに応じて個人情報の第三者への提供を停止すること
       1. 本人の求めを受け付ける方法
12. 前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。
    1. 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
    1. 合併その他の事由による事業の承継に伴って個人情報が提供される場合
    1. 個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合

第 6 条（個人情報の開示）

13. 当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。
    1. 本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合
    1. 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
    1. その他法令に違反することとなる場合
14. 前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。

第 7 条（個人情報の訂正および削除）

15. ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
16. 当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
17. 当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。

第 8 条（個人情報の利用停止等）

18. 当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
19. 前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
20. 当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。
21. 前 2 項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。

第 9 条（プライバシーポリシーの変更）

22. 本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
23. 当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本アプリに掲載したときから効力を生じるものとします。

第 10 条（お問い合わせ窓口）

イツモスマイル株式会社

メールアドレス：info@cir-cle.com

2023 年 3 月 20 日 制定

### 個別サービスのガイドライン

この「個別サービスのガイドライン」は、本アプリで提供する各サービスの内容、使用方法等を説明するものです。なお、本ガイドラインで使用している用語（当社、本アプリ、本サービス、利用者等）の定義は、「さあ・くる利用規約」に準じます。

**１.タクシー予約機能についてのガイドライン**

① 「Let’s 予約機能」とは、本アプリ内における「Let's 予約」機能によって、「まちのクルマ Let’s サービス」予約（以下「予約」と言う。）ができる機能を言います。

② 利用に関するルール

利用者は、本アプリを用いて、利用者の希望する場所・日時を指定交通事業者に対してタクシー運送車両の仮予約を送信することができます。これを受けて、交通事業者から利用者に、仮予約の受託可否が送信されます。 なお、本アプリは、予約、乗車経路、乗降順を保証しません。

③ 交通制度や利用制度の問い合わせについて

まちのクルマ Let’s サービスの制度に関しては神山町役場までお問合せください。

**２.ビデオ通話に関する規則（ガイドライン）**

① 「ビデオ通話機能」とは、本アプリ内における「ビデオ通話」機能によって、ビデオ通話できる機能を言います。 本アプリのビデオ通話機能については、「株式会社 KDDI ウェブコミュニケーションズ」が提供する Twilio 社の製品である「コミュニケーション API サービス『Twilio』」を利用しています。規約については、下記利用規約が優先されます。

「<https://cloudapi.kddi-web.com/legal>」

② 禁止項目

利用者は、本サービスの利用に際し、以下の各行為をしてはなりません。なお、当社は、これらの行為を調査する権利を有しますが、義務を負うものではありません。

a) 権利侵害に関する禁止事項

・当社または第三者の著作権や商標権その他の知的財産権または財産的利益を侵害するおそれのある行為をし、または侵害すること

・プライバシー権や肖像権その他の人格的権利を侵害するおそれのある行為をし、または侵害すること

・他者を不当に差別もしくは誹謗中傷し、他者への不当な差別を助長し、またはその名誉もしくは信用を毀損する、またはそのおそれのある行為をすること

・他者になりすまして本サービスを利用すること

b) 送信内容に関する禁止事項

・わいせつ、児童ポルノもしくは児童虐待に相当する音声等を送信する行為、またはこれらを収録した媒体を販売する行為、またはその送信、販売を想起させる広告を送信すること

・詐欺、規制薬物の濫用、児童売買春、預貯金口座および携帯電話の違法な売買等の犯罪に結びつく、または結びつくおそれの高い内容を送信すること

・暴行その他の残虐な情報を送信すること

・人の自傷他害行為を誘引または勧誘すること

c) 違法な行為等に関する禁止事項

・無限連鎖講を開設し、またはこれを勧誘すること

・違法行為（けん銃等の譲渡、爆発物の不正な製造、児童ポルノの提供、公文書偽造、殺人、脅迫等）を直接的かつ明示的に請け負い、仲介しまたは誘引すること

・違法な賭博もしくはギャンブルを行わせ、またはその参加を勧誘すること

・日本、米国その他の国における輸出管理および経済制裁に関する法令等に違反して本サービスを利用すること

・前各号の他法律、条例、その他の諸法令、諸規則、または公序良俗に反すること

d) 技術的な理由による禁止事項

・本サービスに対して、改変、変更、改修、リバースエンジニアリング、分解、デコンパイル等をすること、または本サービスから得られるソースコードやソフトウエア等を当社の書面による承諾なしに無断で使用すること

・本サービスを利用して緊急通報用電話番号や類似の緊急サービスにアクセスすること

・アプリケーション等から本サービスを介して音声通話を発信する際に、利用者が本サービスにおいて購入した IP 電話番号を発信者番号として指定しないこと

・法律または行政等が規制する公衆電話網の利用方法に従わないこと

・本サービスにおいて当社が提供する電話番号から発信された SMS を他の電気通信事業者に直接リレーすること

・本サービスにおける SMS を不特定多数の者に対するマーケティングや広告等の目的に利用すること

・本サービスや第三者のネットワーク、サーバー等に悪影響を与える一切のプログラムを使用すること

・本サービスに不正にアクセスし、または蓄積された情報を不正に書き換え、もしくは消去すること

・ウイルス等の有害なプログラム等を送信すること

・本サービスに必要以上の負荷をかけること

・本サービスを利用して第三者のメールアドレスや電話番号等の情報を不当に収集すること

e) その他の禁止事項

・当社が発行した ID およびパスワードを適切に管理することなく、みだりに第三者に対して公開し、利用させ、または利用できる状態におくこと

・いたずらまたはスパム等、本サービスを利用して第三者に迷惑をかけた、またはかけるおそれがあると当社が判断する行為をすること

・通話時間等について当社が定める基準を満たさず本サービスを利用すること

・本サービスの円滑な提供に必要であると判断してなされた当社の指示に従わないこと

・上記に掲げる他、当社が不適当と判断する行為をすること

③ 違反者のペナルティ

当社は、本ガイドラインもしくは「さあ・くる利用規約」に違反があった場合、あらかじめ通知することなく、データやコンテンツを削除したり、サービスの全部または一部の利用をお断りしたり、利用者の ID を削除したりするといった措置を講じることができるものとします。

④ 免責に関する事項

a) 当社は、利用者に対して、次の各号に掲げる事実について責任を負いません。

・利用者と第三者との間のアプリケーション等に関連して生じた争いや金銭上の争いを始めとする一切の争い

・本サービスの提供中および終了後に生じた本情報の紛失、破壊、改ざんもしくは漏洩等による損失、損害

・本サービスの停止によって生じた損失、損害

・利用者が当社からの電子メールおよび郵便物等の送付の停止を申し出てこれらの受信、受領を拒否したことにより、引き起こされる損失、損害

・当社が提供した情報に基づいて利用者が行動した結果の損失、損害

・サーバー等の障害による本サービスの停止、メンテナンス、セキュリティチェック作業等により生じた損失、損害

・当社が提供していないプログラム等の利用によって生じる損失、損害

・他の利用者の行為によって生じる損失、損害

・当社以外の第三者による不正な行為により生じる損失、損害

・当社の都合により予告なく本サービスの内容の一部または全部を変更したことによって発生した利用者の損失、損害

・前各号の他、本サービスの利用中に生じた損失、損害

b) 当社は、本サービスの完全性、正確性、確実性、有用性等いかなる保証も利用者に対して行いません。利用者は、これを理解し、自らの責任において本サービスを利用するものとします。

c) 利用者は、コンピューターウィルスやセキュリティの欠陥その他様々な原因により、本サービスが相当の期間にわたり利用できない場合があることを了承するものとします。

d) 当社は、本サービスを利用者に提供するために当社が利用する関連事業者における設備、またはサービス等の不具合その他の事由により、利用者が本サービスを適切に利用できなくなった場合であっても、これにより利用者に生じた損害について、責任を負いません。

⑤ 当社の責任の範囲

当社は、当社による本サービスの提供の中断、停止、終了、利用不能または変更、本アプリの利用によるデータの消失または機器の故障もしくは損傷、その他本サービスに関連して利用者が被った損害につき、賠償する責任を一切負わないものとします。 また、当社は、本サービスに関連して利用者が被った損害について、一切賠償の責任を負いません。消費者契約法の適用その他の理由により、当社が利用者に対して損害賠償責任を負う場合においても、当社の賠償責任は、直接かつ現実の損害（逸失利益、間接損害を除く）についての責任に限られるものとします。

**３.天気**

「天気」とは、本アプリ内で提供される天気予報のサービスを指します。「時間ごと」、「週間天気」、「雨雲レーダー」「注意報・警報」の閲覧が可能となります。なお、掲載データに関しては以下を参照しています。

・株式会社フランクリンジャパン ：<https://www.franklinjapan.jp/>

・気象庁

**４.かみやま ch**

「かみやま ch」とは、さあ・くる神山ラボが制作・配信する動画で、本アプリ内および Youtube で閲覧が可能です。規約については、下記利用規約が優先されます。

・https://www.youtube.com/static?template=terms&hl=ja&gl=JP

**５.おしらせ**

「おしらせ」とは、神山町内関係者が提供する各種電子版情報を本アプリ内で閲覧が可能なサービスを言います。

**６.防災無線**

「防災無線」とは、神山町で放送された防災無線の音声情報を本アプリ内で確認できるサービスを言います。

**7.安心・安全**

「安心・安全」とは、神山町内関係者が提供する暮らしの安心・安全に資する電子版情報を本アプリ内で閲覧が可能なサービスを言います。

雨量・河川水位に関しては徳島水防情報を情報元（※下記）とし、ケーブルテレビトクシマから配信される情報を本アプリ内で閲覧できるサービスを言います。

※「<https://www.kasen.pref.tokushima.lg.jp/pc/index.html」>

**８.市況情報**

「市況情報」とは、 JA 名西郡およびケーブルテレビトクシマから提供される

市況情報を本アプリ内で閲覧できるサービスを言います。

**９.マイナンバーカード認証機能についての規則**

① 「マイナンバーカード認証機能」とは、「一般社団法人 ICT まちづくり共通プラットフォーム推進機構（以下「TOPIC」と言う。）」 が提供するマイナンバーカード電子証明書認証機能サービス（以下認証機能）を言います。

② 認証機能は、行政手続における特定の個人を識別するための番号の利用等に関する法律 （平成 25 年 5 月 31 日法律第 27 号）第 17 条で規定する個人番号カードを使用することで、電子証明書を利用するサービスを含みます。本規則は、認証機能の変更に伴い、変更される場合があります。

1.本規則への同意

利用者は、本規則への同意をもって、まちのクルマ Let’s サービス利用時の利用者認証機能へ同意することとします。

2.サービス内容

認証機能では、利用者の本人確認をマイナンバーカードに格納された署名用電子

明書、または券面事項入力補助 AP および利用者証明用電子証明書を利用して行い

ます。当社は、署名用電子証明書および利用者証明用電子証明書の有効性確認を行う

ために、認証業務情報（電子署名等に係る地方公共団体情報システム機構の認証業務

に関する法律（平成１４年法律第１５３号）第４４条に規定する認証業務情報をいう）

を利用します。

3.利用者の責務

a) 認証機能の利用に際しては、本規則に同意し、遵守するものとします。

b) 認証機能のご利用にあたり、利用者本人の情報登録（氏名かな・メールアドレス）が必要な部分があります。登録する情報に関しては、最新かつ正確な情報を入力してください。

c) 利用者は、自己の責任において、認証機能に関する暗証番号等を適切に管理および保管するものとし、これを第三者に利用・貸与・譲渡・名義変更・売買等をしてはならないものとします。

4.利用者権限の失効

a) 当社は、以下に定める事由が発生した時には、利用者権限を失効させる権利を有します。

・登録情報の記載事項が事実と異なると判断された場合

・利用者が本規則に違反した場合

・認証機能を終了する場合

・その他、当社および TOPIC が必要と判断した場合

b) 当社は、利用者権限を失効させたときには、速やかに利用者本人にこれを通知します。ただし、通知することが不可能な場合は、この限りではありません。

5.免責事項

a) 当社は、認証機能の専用アプリが搭載されたスマートフォンの盗難、不正使用等によって利用者が被った損害に対して、一切の責任を負わないものとします。

b) 当社は、利用者権限の前条にあげる失効に係る申請に対し、遅滞なく失効を行った場合、利用者からの失効申請が到着する以前に発生した被害に対し、一切責任を負わないものとします。

c) 当社は、利用者が本サービスを利用する際に発生したスマートフォンのハードウエアもしくはソフトウエアへの損害について、一切賠償責任を負わないものとします。

d) 当社は、その他当機構の責に帰さない事由から生じた利用者の損害については、一切の賠償責任を負わないものとします。

6.権利帰属等

a) 認証機能に関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属します。

b) 本アプリは、利用者が提携事業者のサービスを利用するにあたっての電子的な本人認証および電子署名に使用する目的でのみ使用することができ、販売、配布および研究・開発目的など他の目的で使用することはできません。

c) 利用者は、認証機能を当社から提供された状態でのみ利用するものとし、認証機能の複製、修正、変更、または改変を行わないものとします。

d) 利用者は、当社から使用を許諾された認証機能の全部または一部を複製、解析、修正、変更、または改変を行わないものとします。

7.禁止事項

a) 当社は、利用者が以下の禁止行為のいずれかに該当する行為を行ったと判断した場合、利用者に通告することなく以降の利用及び利用者 ID を廃止する場合があります。

・利用規約または法令に違反し、もしくはそのおそれのある行為

・当社の知的財産権（著作権、商標権、特許権等）、その他財産的もしくは属人的な権利（名誉権等）、並びにプライバシー権を侵害する行為

・当社または第三者に成りすます行為、意図的に虚偽の情報を流布させる行為、第三者の個人情報などを不正に収集、開示または提供する行為、および当機構または他の利用者もしくは第三者に不利益もしくは損害を与える行為、またはこれを侵害するおそれのある行為

・他人のマイナンバーカードを利用する行為（ただし、法令で認められる場合を除く。）

・当社のシステムへの不正アクセス、コンピューターウィルスの配布、データ等の改ざん、その他認証機能の正常な運営を妨げる行為

・認証機能のネットワークまたはシステム等に対して過度な負荷をかける行為

・その他、当社が認証機能の利用者による行為として不適当と判断した場合

b) 禁止事項により当社が損害を生じた場合、およびその他当社が適切と判断する場合には、利用者への損害賠償請求や公的機関への通報を行う場合があります。

8.サービス内容の変更

当社は、当社が必要と判断する場合、あらかじめご利用者に通知することなく、いつでも認証機能の内容を変更できるものとし、これによって利用者に生じた損害について一切の責任を負いません。

9.認証機能の廃止

a) 当社は、当社が必要と判断する場合、本サービスの全部または一部機能を廃止することができるものとします。

b) 当社は、認証機能を廃止する場合、利用者に対し、廃止日の 60 日前迄に通知します。ただし、やむを得ない事情がある場合はこの限りではありません。

c) 当社は、認証機能廃止日をもって、利用者権限を失効させます。

**10.ソフトウエアに関する規則**

① ソフトウエア

本規則は、当社の提供するソフトウエアに関して適用されるガイドラインです。本章でソフトウエアとは、当社の提供するアプリケーションプログラム（ウィジェット等のプログラムを含む。）、当社ウェブサービスのアプリケーション・プログラミング・イ ンターフェース（API）、ソフトウエア・デベロップメント・キット（SDK）などの開発支援ツール（これらを介して提供される当社ウェブサービスなどのサービスを含む。）、コンテンツのビューア等当社の提供するサービス利用のために必要なソフトウエア、その他当社の提供するソフトウエア全般（アップデート版、修正版、代替品および複製物を含む。）を言います。 なお、ソフトウエアには、利用者がこれらを利用して自ら開発することができるものも含まれています。利用者がそうしたソフトウエアを利用して、自ら開発したソフトウエアコンポーネント（以下「開発ソフトウエア」と言う。）については、利用者が一切の責任を負うことになります。

② 利用上の制約

当社は、利用者への通知を行うことなく、自己の裁量に基づいていつでもソフトウエアや特定の機能に関して利用の制約（たとえば、ソフトウエアを介して当社のサービスへのアクセス回数、アクセス時間などについて制限すること等。）を行うことができるものとします。また、ソフトウエアに関するサポートや修正版（アップデート版を含む。）の提供は、 当社の裁量に基づいて行い、またあらかじめ利用者へ通知を行うことなく、それらを終了する場合があります。

③ ソフトウエアに関する順守事項

ソフトウエアのご利用にあたっては、以下の行為が禁止されます。

・リバースエンジニアリング、逆アセンブルを行うこと、また、その他の方法でソースコードを解読すること

・人の生命・身体または財産に重大な危険をおよぼすおそれのある設備などを制御するためなどにソフトウエアを利用すること

・対価を受ける目的でソフトウエアまたは開発ソフトウエアを自ら利用し、または第三者に利用させること

・書面その他当社が指定する方法による事前の承諾を得ることなく、ソフトウエアを販売、賃貸、使用許諾すること

・合理的に必要相当な数を超える利用、乱用等を行い、または、ソフトウエアの使用について当社が定める指示などを遵守しない用法で、ソフトウエアを利用すること

・ソフトウエアを提供する目的に外れた態様でソフトウエアを利用したり、当社が不適当とみなした方法・態様でソフトウエアを利用したりすること

④ 権利関係

ソフトウエアおよび実行ファイル、その他ソフトウエアに関する一切の権利は、当該ソフトウエアの著作権を有する当社または当社の提携先に帰属します。ただし、開発ソフトウエアに関して著作権法に基づき、利用者に権利が生じる場合、当該権利については上記の限りではありません。

⑤ 無保証

a) 当社は提供するソフトウエアについて、エラーやバグ、論理的誤り、不具合、中断その他の瑕疵がないこと、信頼性、正確性、完全性、有効性 について一切保証しておりません。

b) ソフトウエアは明示または黙示の有無にかかわらず、当社がその提供時において保有する状態で提供するものであり、特定の目的への適合性、有用性（有益性）、セキュリティ、権原および非侵害性について一切保証しておりません。

⑥ API に関する特約事項

当社が提供する API をご利用になる場合、以下の特約事項が適用されます。

・利用者が、当社が提供する API を使用してソフトウエアを開発する場合には、「クレジット表示ガイドライン」と「クレジット配置ルール」を遵守しなければなりません。

・利用者は、開発ソフトウエアに当社が定める開発ソフトウエアを特定するためのアプリケーション ID 等を付さなければなりません。

・アプリケーション ID 等の情報は、利用者が開発した開発ソフトウエアの使用に際し、当社に送信されることになります。

■ 本件の問い合わせ先

イツモスマイル株式会社

メールアドレス：info@cir-cle.com

2023 年 3 月 20 日 制定
`
