import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { HeaderConfig, headerConfigState } from '../store/headerConfig'

type HeaderConfigOption = {
  title?: string
  show?: boolean
  showKinkyuBanner?: boolean
}

// 参考：https://dev.classmethod.jp/articles/react-router-v6-breadcrumbs/#toc-4
const useHeaderConfig = (headerConfigOption: HeaderConfigOption) => {
  // location URL付きモーダルの開閉時にuseEffectを有効にするために必要
  const location = useLocation()

  const setHeaderConfig = useSetRecoilState<HeaderConfig>(headerConfigState)
  useEffect(() => {
    setHeaderConfig({
      title: headerConfigOption.title || '',
      show:
        headerConfigOption.show !== undefined ? headerConfigOption.show : true, // デフォルト表示ONとする
      showKinkyuBanner: true, // デフォルト表示ONとする
    })

    return () => {
      // NOTE:
      // Pageでパンくずをセットし忘れてしまったときに、
      // 以前の状態を引き継がないように、アンマウント時に空にする
      setHeaderConfig({
        title: '',
        show: true, // デフォルト表示ONとする
        showKinkyuBanner: true, // デフォルト表示ONとする
      })
    }
  }, [headerConfigOption, setHeaderConfig, location])
}

export default useHeaderConfig
