import { Icon } from '@chakra-ui/react'
import React from 'react'

/**
 * Home & メニュー ナビゲーションアイコン：安心・安全
 */
const NavBousai: React.FC = (props) => (
  <Icon width="108" height="90" viewBox="4 0 108 90" color="#1A82DD" {...props}>
    <circle
      cx="62.9994"
      cy="45.8993"
      r="38.7997"
      fill="white"
      stroke="white"
      strokeWidth="2"
    />
    <circle
      cx="62.9994"
      cy="45.8993"
      r="35.7997"
      fill="white"
      stroke="currentColor"
      strokeWidth="4"
    />
    <circle cx="49.2541" cy="39.0285" r="5.1545" fill="currentColor" />
    <circle cx="76.7468" cy="39.0285" r="5.1545" fill="currentColor" />
    <path
      d="M80.1817 49.3369H45.8184C45.8184 49.3369 45.8197 66.5186 63 66.5186C80.1803 66.5186 80.1817 49.3369 80.1817 49.3369Z"
      fill="currentColor"
    />
    <mask
      id="mask0_5501_473"
      maskUnits="userSpaceOnUse"
      x="0"
      y="-1"
      width="35"
      height="36"
    >
      <rect
        y="9"
        width="26.9998"
        height="26.9998"
        transform="rotate(-20 0 9)"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_5501_473)">
      <path
        d="M20.85 26.5832L18.8087 25.7668C16.436 24.8111 14.4534 23.9635 12.8609 23.2239C11.2683 22.4843 9.95799 21.7768 8.92989 21.1016C7.90205 20.427 7.10954 19.7518 6.55238 19.0758C5.99452 18.4001 5.56749 17.657 5.2713 16.8465C4.66605 15.1903 4.71786 13.6052 5.42672 12.0912C6.13558 10.5772 7.32146 9.5188 8.98438 8.91599C9.90429 8.58253 10.8508 8.4589 11.8239 8.54512C12.797 8.63134 13.7131 8.91781 14.5721 9.40454C14.9161 8.48174 15.4329 7.67583 16.1227 6.9868C16.8125 6.29778 17.6174 5.78653 18.5373 5.45307C20.2002 4.85026 21.7917 4.90186 23.3119 5.60785C24.8321 6.31385 25.8948 7.49494 26.5 9.15114C26.7962 9.96161 26.949 10.8041 26.9585 11.6786C26.9672 12.5534 26.7956 13.5781 26.4436 14.7529C26.0918 15.9283 25.5446 17.3091 24.802 18.8952C24.0594 20.4813 23.0877 22.3999 21.8869 24.6509L20.85 26.5832Z"
        fill="currentColor"
        stroke="white"
        strokeWidth="2"
      />
    </g>
  </Icon>
)

export default NavBousai
