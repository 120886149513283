import { Box } from '@chakra-ui/react'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { markdown } from './policyText'

const Policy: React.FC = () => {
  useHeaderConfig({ title: '利用規約' })
  return (
    <Box my="2rem" mx="1rem" position="relative">
      {/* TODO デバッグリンク */}
      <Box position="absolute" top="0" right="0" w="40px" h="40px" opacity="0">
        <Link
          to="/debug"
          style={{ width: '100%', height: '100%', display: 'block' }}
        />
      </Box>
      <ReactMarkdown components={ChakraUIRenderer()}>{markdown}</ReactMarkdown>
    </Box>
  )
}

export default Policy
