import { Flex, Text, Stack, Button, Heading } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { p2r } from '../../../utils/font'

type Props = {
  title: string
  message: ReactNode
  buttonText?: string
  buttonLink?: string
}

const AppMaintenance: React.FC<Props> = ({
  title,
  message,
  buttonText,
  buttonLink,
}) => {
  const navigate = useNavigate()
  return (
    <Flex
      direction="column"
      justify="center"
      alignItems="center"
      bgColor="theme.background"
      minH="100vh"
      rowGap="64px"
    >
      <Stack spacing="32px" alignItems="center">
        <Heading
          as="h2"
          color="text.headline"
          fontSize={{ base: p2r(24), md: p2r(32) }}
          fontWeight="700"
          lineHeight="150%"
        >
          {title}
        </Heading>
        <Text fontSize={p2r(16)} color="text.body">
          {message}
        </Text>
      </Stack>
      {buttonLink && buttonText && (
        <Stack spacing="16px">
          <Button
            onClick={() => navigate(buttonLink)}
            bgColor="theme.primary"
            color="text.white"
          >
            {buttonText}
          </Button>
        </Stack>
      )}
    </Flex>
  )
}

export default AppMaintenance
