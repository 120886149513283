import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Select,
  VStack,
  Text,
  SimpleGrid,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { MdAutorenew } from 'react-icons/md'
import { AppDataTable } from '../../components/common/AppDataTable'
import AppDebugJsonView from '../../components/common/AppDebugJsonView'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppLoading } from '../../components/common/AppLoading'
import useBousaiUryouQuery, {
  CSVType,
  PlaceGraphTimeItems,
} from '../../hooks/coreApi/useBousaiUryouQuery'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import 'chart.js/auto'

// NOTE: react-chartjs-2のサンプル
// https://react-chartjs-2.js.org/examples/line-chart

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

type CSVOptionType = {
  name: string
  unit: string
  maxY: number
  minY: number
  places?: {
    id: number
    name: string
  }[]
}

type TableRow = {
  time: string
  value: number | string | null
}

const kindOptions: { [key in CSVType]: CSVOptionType } = {
  uryou: {
    name: '雨量',
    unit: 'ミリ',
    maxY: 80,
    minY: 0,
    places: [
      {
        id: 3,
        name: '寄井',
      },
      {
        id: 10,
        name: '広野',
      },
    ],
  },
  river: {
    name: '河川',
    unit: '水位',
    maxY: 5,
    minY: -1,
    places: [
      {
        id: 11,
        name: '寄井',
      },
      {
        id: 7,
        name: '広野',
      },
    ],
  },
}

const BousaiUryou: React.FC = () => {
  useHeaderConfig({
    title: '安心・安全',
  })

  const [kind, setKind] = useState<CSVType>('uryou')
  const [placeId, setPlaceId] = useState<number>(3)

  // 現在のタイムスタンプを状態として保持
  const [timestamp, setTimestamp] = useState(dayjs().unix())

  const { data, isLoading, isFetching, isError, error, refetch } =
    useBousaiUryouQuery(kind, timestamp)

  // チャートに表示するデータ
  const chartData = useMemo<ChartData<'line'>>(() => {
    const currentData = data?.find((row) => row.id === placeId)
    if (!currentData) {
      return {
        labels: [],
        datasets: [],
      }
    }
    const ascDateData = currentData.times.sort(
      (a: PlaceGraphTimeItems, b: PlaceGraphTimeItems) =>
        dayjs(a.observationTime) > dayjs(b.observationTime) ? 1 : -1,
    )

    return {
      labels: ascDateData.map((i: PlaceGraphTimeItems) =>
        dayjs(i.observationTime).format('HH:00'),
      ),
      datasets: [
        {
          label: kindOptions[kind].name,
          data: ascDateData.map((i: PlaceGraphTimeItems) =>
            Number(i.observationData) >= 0 ? Number(i.observationData) : null,
          ),
          fill: true,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    }
  }, [data, kind, placeId])

  const tableData: TableRow[] = useMemo(() => {
    const currentData = data?.find((row) => row.id === placeId)
    if (!currentData) {
      return [
        {
          time: '',
          value: 0,
        },
      ]
    }
    const ascDateData = currentData.times.sort(
      (a: PlaceGraphTimeItems, b: PlaceGraphTimeItems) =>
        dayjs(a.observationTime) < dayjs(b.observationTime) ? 1 : -1,
    )
    return ascDateData.map((i: PlaceGraphTimeItems) => ({
      time: dayjs(i.observationTime).format('HH:00'),
      value: Number.isNaN(Number(i.observationData))
        ? '-'
        : Number(i.observationData),
    }))
  }, [data, kind, placeId])

  // チャートのオプション
  // Chart.js options
  // https://www.chartjs.org/docs/latest/general/options.html
  const lineOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 50,
    },
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        beginAtZero: true,
        max: kindOptions[kind].maxY,
      },
    },
  }

  // テーブルに表示するデータ

  const columnHelper = createColumnHelper<TableRow>()

  const tableColumns = [
    columnHelper.accessor('time', {
      cell: (info) => info.getValue(),
      header: '時刻',
    }),
    columnHelper.accessor('value', {
      cell: (info) => info.getValue(),
      header: kindOptions[kind].unit,
      meta: {
        isNumeric: true,
      },
    }),
  ]

  if (isLoading) {
    return <AppLoading />
  }

  if (isError) {
    return <AppErrorResult error={error} />
  }

  return (
    <>
      <Flex
        px={{ base: '16px', md: '64px' }}
        mt={{ base: '20px', md: 0 }}
        bg="red"
        minH={{ base: 'calc(100vh - 165px)', md: 'calc(100vh - 56px)' }}
        justify="center"
        align="center"
        bgColor="theme.background"
      >
        <VStack>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing="24px">
            <Box>
              <HStack spacing="24px">
                <HStack whiteSpace="nowrap">
                  <Box>種類:</Box>
                  <Select
                    width="100px"
                    defaultValue={kind}
                    onChange={(evt) => {
                      setKind(evt.target.value as CSVType)
                      if (evt.target.value === 'uryou') {
                        setPlaceId(3)
                      } else if (evt.target.value === 'river') {
                        setPlaceId(11)
                      } else {
                        setPlaceId(1)
                      }
                    }}
                  >
                    {(Object.keys(kindOptions) as CSVType[]).map((k) => (
                      <option key={k} value={k}>
                        {kindOptions[k].name}
                      </option>
                    ))}
                  </Select>
                </HStack>
                <HStack whiteSpace="nowrap">
                  <Box>観測局:</Box>
                  <Select
                    width="100px"
                    defaultValue={placeId}
                    onChange={(evt) => {
                      setPlaceId(Number(evt.target.value))
                    }}
                  >
                    {kindOptions[kind].places?.map((row) => (
                      <option key={row.id} value={row.id}>
                        {row.name}
                      </option>
                    ))}
                  </Select>
                </HStack>
              </HStack>
            </Box>
            <Box>
              <HStack spacing="24px">
                <Box>
                  <Button
                    leftIcon={<Icon as={MdAutorenew} />}
                    onClick={() => {
                      setTimestamp(dayjs().unix())
                      refetch()
                    }}
                    isLoading={isFetching}
                  >
                    更新
                  </Button>
                </Box>
                <Box width="200px">
                  <Text fontSize="xs">
                    更新日時: {dayjs(timestamp * 1000).format('MM/DD HH:mm')}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </SimpleGrid>
          <Box
            w="100%"
            h={{ base: 'auto', lg: 'calc(100vh - 165px)' }}
            display="flex"
            flexDirection={{ base: 'column-reverse', lg: 'row' }}
          >
            <Box h="100%" overflowY="scroll">
              <AppDataTable data={tableData} columns={tableColumns} />
            </Box>
            <Box
              maxW="800px"
              w="100%"
              h="100%"
              pl={{ base: 0, lg: '36px' }}
              pt="28px"
            >
              {kind !== 'uryou' ? (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <Line data={chartData} options={lineOptions} />
              ) : (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <Bar data={chartData} options={lineOptions} />
              )}
            </Box>
          </Box>
        </VStack>
      </Flex>
      <AppDebugJsonView
        object={{
          isFetching,
          isLoading,
          tableData,
          chartData,
          data,
        }}
      />
    </>
  )
}

export default BousaiUryou
