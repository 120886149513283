import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

export type LoginButtonProps = {
  children: React.ReactNode
} & ButtonProps
export const AuthButton: React.FC<LoginButtonProps> = ({
  children,
  ...props
}) => (
  <Button
    border={{ base: '2px solid', md: '3px solid' }}
    fontSize={{ base: p2r(24), md: p2r(28) }}
    fontWeight="700"
    rounded={{ base: '4px', md: '10px' }}
    minW={{ base: '100%', md: '480px' }}
    lineHeight="150%"
    h={{ base: '48px', md: '80px' }}
    variant="unstyled"
    {...props}
  >
    {children}
  </Button>
)
