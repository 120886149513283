import {
  TableColumnHeaderProps,
  Th,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { p2r } from '../../../utils/font'

type TaxiTimeTableThProps = {
  children: ReactNode
} & TableColumnHeaderProps
const TaxiTimeTableTh: React.FC<TaxiTimeTableThProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { children, ...rest } = props
  return (
    <Th
      px="0px"
      py="8px"
      w={isMobile ? p2r(40) : p2r(100)}
      color="text.body"
      fontSize={isMobile ? p2r(16) : p2r(24)}
      lineHeight="150%"
      fontWeight="bold"
      textAlign="center"
      {...rest}
    >
      {children}
    </Th>
  )
}
export default TaxiTimeTableTh
