import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  AlertDialogCloseButton,
} from '@chakra-ui/react'
import { useRef } from 'react'
import { useAppConfirm } from './useAppConfirm'

type Props = {
  children: React.ReactNode
}

export default function AppConfirmProvider(props: Props) {
  const { isOpen, close, dialogProps } = useAppConfirm()
  const {
    title,
    body,
    isShowOk = true,
    okText,
    isShowCancel = true,
    cancelText,
  } = dialogProps ?? {}
  const cancelRef = useRef<any>()

  return (
    <>
      {props.children}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => close(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            {title && (
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {title}
              </AlertDialogHeader>
            )}
            <AlertDialogCloseButton />

            <AlertDialogBody mt={title ? undefined : 4}>{body}</AlertDialogBody>

            <AlertDialogFooter>
              {isShowCancel && (
                <Button ref={cancelRef} onClick={() => close(false)}>
                  {cancelText || 'キャンセル'}
                </Button>
              )}

              {isShowOk && (
                <Button
                  colorScheme="primary"
                  onClick={() => close(true)}
                  ml={3}
                >
                  {okText || 'OK'}
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
