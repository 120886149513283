import { atom } from 'recoil'

export type HeaderConfig = {
  title: string
  show: boolean
  showKinkyuBanner?: boolean
}

export const headerConfigState = atom<HeaderConfig>({
  key: 'headerConfig',
  default: {
    title: '',
    show: true,
    showKinkyuBanner: true,
  },
})
