import { Heading } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

type VideoHeadingType = {
  label: string
}
const VideoHeading: React.FC<VideoHeadingType> = (props) => {
  const { label } = props
  return (
    <Heading
      as="h2"
      px={{ base: '16px', md: '32px' }}
      py="8px"
      fontSize={p2r(16)}
      fontWeight="700"
      color="text.sub"
      lineHeight="1.5"
      backgroundColor="grand.grayLight"
    >
      {label}
    </Heading>
  )
}

export default VideoHeading
