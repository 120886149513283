import { useQuery } from '@tanstack/react-query'
import { weatherRadarApiClient } from './weatherRadarApiClient'

export const useFutureWeatherRadarQuery = (futureBaseTimes: number[], futureValidTimes: number[]) =>
  useQuery({
    queryKey: ['wetherFutureApi', 'dailyFutureForecast', futureBaseTimes, futureValidTimes],
    queryFn: () => weatherRadarApiClient.getWeatherRadars(futureBaseTimes, futureValidTimes),
})

export const usePastWeatherRadarQuery = (pastBaseTimes: number[], pastValidTimes: number[]) =>
useQuery({
  queryKey: ['wetherPastApi', 'dailyPastForecast', pastBaseTimes, pastValidTimes],
  queryFn: () => weatherRadarApiClient.getPastWeatherRadars(pastBaseTimes, pastValidTimes),
})

export const useWeatherWarningQuery = (weatherAreaCode: string) =>
useQuery({
  queryKey: ['wetherWarningApi', 'dailyWarningForecast', weatherAreaCode],
  queryFn: () => weatherRadarApiClient.getWeatherWarning(weatherAreaCode),
})