import { Heading, HeadingProps } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

export type TaxiSectionTitleProps = {
  label: string
} & HeadingProps
export const TaxiSectionTitle: React.FC<TaxiSectionTitleProps> = (props) => {
  const { label, ...rest } = props
  return (
    <Heading
      as="h2"
      color="text.sub"
      fontSize={{ base: p2r(16), md: p2r(24) }}
      fontWeight={{ base: '400', md: '700' }}
      lineHeight="150%"
      {...rest}
    >
      {label}
    </Heading>
  )
}
