import { TaxiReservationApiTaxiReservationCreateByTaxiCompanyRequest } from '../../openapi/api'
import appApiClient from './appApiClient'
import useBaseMutation from './useBaseMutation'

export const useTaxiReservationCreateByTaxiCompany = () =>
  useBaseMutation({
    mutationFn: (
      req: TaxiReservationApiTaxiReservationCreateByTaxiCompanyRequest,
      config,
    ) =>
      appApiClient.taxiReservationApi.taxiReservationCreateByTaxiCompany(
        req,
        config,
      ),
  })
