import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Link,
  List,
  ListItem,
  Text,
  Button,
  useBreakpointValue,
  Spinner,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { MdArrowForwardIos, MdBackpack, MdInfo, MdMovie } from 'react-icons/md'
import { Link as RouterLink } from 'react-router-dom'
import { AppNewLabel } from '../../components/common/AppNewLabel'
import NoteHome from '../../components/svg/NoteHome'
import Wave from '../../components/svg/Wave'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { p2r } from '../../utils/font'

const CautionLabel: React.FC = () => (
  <Text
    px="8px"
    py="2px"
    fontSize={p2r(12)}
    fontWeight="700"
    color="text.white"
    borderRadius="4px"
    backgroundColor="grand.error"
  >
    重要
  </Text>
)

type NewsCardProps = {
  link: string
  date: string
  time: string
  title: string
  isCaution?: boolean
  isNew?: boolean
}
const NewsCard: React.FC<NewsCardProps> = (props) => (
  <ListItem
    borderBottomWidth={{ base: '1px', md: '0px' }}
    _last={{ borderBottomWidth: 0 }}
    borderBottomColor="grand.grayLight"
    pb={{ base: '16px', md: '0px' }}
  >
    <Link as={RouterLink} w="100%" h="100%" to={props.link}>
      <Flex direction="column">
        <Flex columnGap={{ base: '16px', md: '32px' }} alignItems="center">
          <Text fontSize={p2r(16)} color="text.sub">
            {props.date}
          </Text>
          <Text fontSize={p2r(16)} color="text.sub">
            {props.time}
          </Text>
          <Flex columnGap={{ base: '8px', md: '16px' }} alignItems="center">
            {props.isCaution && <CautionLabel />}
            {props.isNew && <AppNewLabel />}
          </Flex>
        </Flex>
        <Text
          mt="4px"
          fontSize={{ base: p2r(24), md: p2r(22) }}
          fontWeight="700"
          color="text.body"
          noOfLines={2}
        >
          {props.title}
        </Text>
      </Flex>
    </Link>
  </ListItem>
)

type NavigationCardProps = {
  link: string
  icon: React.FC
  label: string
}
const NavigationCard: React.FC<NavigationCardProps> = (props) => (
  <GridItem
    background="linear-gradient(180deg, #649A39 0%, #88CC52 100%)"
    borderRadius="10px"
    boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
    borderWidth="2px"
    borderColor="grand.grayLight"
    h={{ base: '72px', md: '14.3vh' }}
  >
    <Link
      as={RouterLink}
      w="100%"
      h="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      to={props.link}
    >
      <Flex columnGap={{ base: '8px', md: '1.9vh' }} alignItems="center">
        <Icon
          as={props.icon}
          boxSize={{ base: '24px', md: '32px' }}
          color="text.white"
        />
        <Text
          fontSize={{ base: p2r(16), md: p2r(24) }}
          color="text.white"
          fontWeight="700"
        >
          {props.label}
        </Text>
      </Flex>
    </Link>
  </GridItem>
)

const BousaiHome: React.FC = () => {
  useHeaderConfig({
    title: '安心・安全',
  })
  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })

  const PAGE_SIZE = 3
  // type: 防災情報/緊急情報両方表示
  const { isLoading, data } = useContentListQuery(['kinkyu'], {
    page: 1,
    pageSize: PAGE_SIZE,
  })

  const bousaiContents = useMemo(() => {
    if (!data?.pages?.[0]?.contents) {
      return []
    }

    return data.pages[0].contents.slice(0, 3)
  }, [data])

  const moreLink = (
    <RouterLink to="/bousai/all">
      <Button variant="ghost">
        <Flex gap={{ base: '8px', md: '4px' }}>
          <Text
            fontSize={{ base: p2r(12), md: p2r(20) }}
            lineHeight="120%"
            fontWeight="700"
            color="text.body"
          >
            {f('すべて見る')}
          </Text>
          <Icon
            as={MdArrowForwardIos}
            boxSize={{ base: '16px', md: '24px' }}
            color="text.body"
          />
        </Flex>
      </Button>
    </RouterLink>
  )

  return (
    <Box
      px={{ base: '16px', md: '32px' }}
      py={{ base: '8px', md: '2.8vh' }}
      minH="calc(100vh - 56px)"
      backgroundColor="theme.background"
    >
      <Flex
        justify="space-between"
        align="center"
        backgroundColor={{ base: 'grand.white', md: 'unset' }}
        px={{ base: '16px', md: '0px' }}
        pt={{ base: '16px', md: '0px' }}
        pb={{ base: '8px', md: '0px' }}
      >
        <Heading
          as="h2"
          fontSize={{ base: p2r(16), md: p2r(20) }}
          display="flex"
          alignItems="center"
          columnGap="8px"
        >
          <Icon
            as={MdInfo}
            color={{ base: 'grand.gray', md: 'text.body' }}
            boxSize={{ base: '24px', md: '28px' }}
          />
          {f('防災に関するお知らせ')}
        </Heading>
        {isMobile && <Box h="fit-content">{moreLink}</Box>}
      </Flex>
      <Flex
        mt={{ base: '0px', md: '1.9vh' }}
        p={{ base: '16px', md: '1.9vh' }}
        columnGap="16px"
        backgroundColor="grand.white"
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <List flex="1" display="flex" flexDirection="column" rowGap="1.9vh">
            {bousaiContents.map((c) => (
              <NewsCard
                link={`/${c.type}/${c.id}`}
                date={dayjs(c.releaseAt).format('YYYY.MM.DD')}
                time={dayjs(c.releaseAt).format('HH:mm')}
                title={c.titleJa}
                isNew={(c?.isRead || false) === false}
              />
            ))}
          </List>
        )}

        {!isMobile && <Box h="fit-content">{moreLink}</Box>}
      </Flex>
      <Grid
        mt={{ base: '16px', md: '2.8vh' }}
        templateColumns="repeat(2, 1fr)"
        columnGap={{ base: '8px', md: '24px' }}
        rowGap={{ base: '8px', md: '1.9vh' }}
      >
        <NavigationCard
          link="/bousai/sonae"
          icon={MdBackpack}
          label={f('日頃からの備え')}
        />
        <NavigationCard
          link="/bousai/series"
          icon={NoteHome}
          label={f('防災シリーズ')}
        />
        <NavigationCard
          link="/bousai/uryou"
          icon={Wave}
          label={f('雨量・河川水位')}
        />
        <NavigationCard
          link="/bousai/movie"
          icon={MdMovie}
          label={f('防災対策動画')}
        />
      </Grid>
    </Box>
  )
}

export default BousaiHome
