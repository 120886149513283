import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonProps,
  HStack,
  Icon,
  useBreakpointValue,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  VStack,
  Text,
  keyframes,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { MdPersonAddAlt1, MdPhoneInTalk, MdSettingsPhone } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link as Scroll } from 'react-scroll'
import { AppLoading } from '../../components/common/AppLoading'
import { AppSegmentedPicker } from '../../components/common/AppSegmentedPicker'
import VideoHeading from '../../components/video/VideoHeading'
import {
  useSetVideoLinks,
  VideoMenuLink,
} from '../../components/video/VideoMenuLink'
import VideoModal from '../../components/video/VideoModal'
import {
  VideoUserList,
  VideoUserListItem,
} from '../../components/video/VideoUserList'
import { useAuthContext } from '../../context/AuthContext'
import { useFriendUpdateMutation } from '../../hooks/coreApi/useFriendUpdateMutation'
import { useMeQuery } from '../../hooks/coreApi/useMeQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import useNativeApp from '../../hooks/useNativeApp'
import { FriendResponse } from '../../openapi'
import { p2r } from '../../utils/font'

/**
 * 通常ボタン
 */
export type VideoButtonProps = {
  icon: React.FC
  onClick(): void
  hasNotify?: boolean
  children: React.ReactNode
} & ButtonProps
export const VideoButton: React.FC<VideoButtonProps> = (props) => {
  const { icon, onClick, hasNotify = false, children, ...rest } = props

  // 点滅
  const flashing = keyframes`
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  `
  const flashingAnimation = `${flashing} infinite 1s linear`
  return (
    <Button
      px="32px"
      height={p2r(48)}
      leftIcon={<Icon as={icon} boxSize={p2r(32)} color="text.body" />}
      color="text.body"
      fontSize={p2r(16)}
      variant="outline"
      backgroundColor="grand.white"
      borderWidth="2px"
      borderColor={hasNotify ? 'grand.notification' : 'theme.primary'}
      borderRadius="30px"
      onClick={onClick}
      position="relative"
      _after={{
        display: hasNotify ? 'block' : 'none',
        content: '""',
        position: 'absolute',
        top: '12px',
        right: '20px',
        width: p2r(8),
        height: p2r(8),
        backgroundColor: 'grand.notification',
        borderRadius: '50%',
        animation: flashingAnimation,
      }}
      {...rest}
    >
      {children}
    </Button>
  )
}

type InitialLineProps = {
  value: string
  label: string
}
const InitialLine: React.FC<InitialLineProps> = (props) => (
  <Scroll to={props.value} smooth offset={-52}>
    <Text fontSize={p2r(24)} color="text.sub" lineHeight="150%">
      {props.label}
    </Text>
  </Scroll>
)

const useSetKanaInfo = () => {
  const { f } = useFormatMessage()
  return [
    {
      label: f('あ'),
      baseChar: 'ア',
      list: ['ア', 'イ', 'ウ', 'エ', 'オ'],
    },
    {
      label: f('か'),
      baseChar: 'カ',
      list: ['カ', 'キ', 'ク', 'ケ', 'コ'],
    },
    {
      label: f('さ'),
      baseChar: 'サ',
      list: ['サ', 'シ', 'ス', 'セ', 'ソ'],
    },
    {
      label: f('た'),
      baseChar: 'タ',
      list: ['タ', 'チ', 'ツ', 'テ', 'ト'],
    },
    {
      label: f('な'),
      baseChar: 'ナ',
      list: ['ナ', 'ニ', 'ヌ', 'ネ', 'ノ'],
    },
    {
      label: f('は'),
      baseChar: 'ハ',
      list: ['ハ', 'ヒ', 'フ', 'ヘ', 'ホ'],
    },
    {
      label: f('ま'),
      baseChar: 'マ',
      list: ['マ', 'ミ', 'ム', 'メ', 'モ'],
    },
    {
      label: f('や'),
      baseChar: 'ヤ',
      list: ['ヤ', 'ユ', 'ヨ'],
    },
    {
      label: f('ら'),
      baseChar: 'ラ',
      list: ['ラ', 'リ', 'ル', 'レ', 'ロ'],
    },
    {
      label: f('わ'),
      baseChar: 'ワ',
      list: ['ワ', 'ヲ', 'ン'],
    },
  ]
}

const VideoPrivate: React.FC = () => {
  useHeaderConfig({
    title: 'ビデオ通話',
  })
  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState<string>('')

  const friendAddClick = () => {
    navigate('/video/friend-add')
  }
  const callSettingClick = () => {
    navigate('/mypage/video')
  }
  const kanaList = useSetKanaInfo()

  const meQuery = useMeQuery({
    withFriends: true,
  })
  const friends = useMemo(
    () =>
      meQuery.data?.user?.friends?.filter(
        (friend) => friend.status === 'accepted',
      ) || [],
    [meQuery.fetchStatus],
  )

  // 承認待ち(相手→自分)の友達一覧
  const inRequestFriends = useMemo(
    () => friends?.filter((friend) => friend.status === 'in_request'),
    [friends],
  )

  // friendを五十音順に並べ替え
  let sortedFriends = useMemo(() => {
    if (!friends) {
      return []
    }
    return friends
      .slice()
      .sort((a, b) =>
        (a.friendUser?.nicknameKana || '').localeCompare(
          b.friendUser?.nicknameKana || '',
        ),
      )
  }, [friends])

  // 検索
  sortedFriends = sortedFriends.filter((friend) => {
    const nickname = friend?.friendUser?.nickname
    const nicknameKana = friend?.friendUser?.nicknameKana
    if (searchText === '') {
      return true
    }

    if (nickname?.includes(searchText) || nicknameKana?.includes(searchText)) {
      return true
    }

    return false
  })

  const onChangeSearch = (evt: any) => {
    setSearchText(evt.target.value)
  }

  // 友達の名前の頭文字のみを抽出
  const friendsInitials = sortedFriends.map((friend) =>
    friend.friendUser?.nickname.charAt(0),
  )
  // 配列から友達の頭文字との重複値
  const initialsIndex = (index: string[]) =>
    index.filter((indexes) => friendsInitials.includes(indexes))

  const location = useLocation()
  const currentPath = location.pathname
  const VideoMenuLinks = useSetVideoLinks()

  const toast = useToast()
  const friendUpdateMutation = useFriendUpdateMutation()

  const [currentModalFriend, setCurrentModalFriend] =
    useState<FriendResponse | null>(null)
  const callConfirmModalDisclosure = useDisclosure()
  const friendDetailModalDisclosure = useDisclosure()

  const authContext = useAuthContext()
  const me = authContext?.me
  const nativeApp = useNativeApp()

  if (!me) {
    return <AppLoading />
  }

  // エラーハンドリング
  const onError = () => {
    toast({
      title: 'エラーが発生しました',
      status: 'error',
    })
  }

  // 通話確認モーダルを開く
  const handleClickCallConfirm = (friend: FriendResponse | null) => {
    if (!friend) {
      throw new Error('handleClickCallConfirm')
    }
    setCurrentModalFriend(friend)
    callConfirmModalDisclosure.onOpen()
  }

  // 通話確認モーダルを開く
  const handleClickFriendDetail = (friend: FriendResponse | null) => {
    if (!friend) {
      throw new Error('handleClickFriendDetail')
    }
    setCurrentModalFriend(friend)
    friendDetailModalDisclosure.onOpen()
  }

  // 通話するボタンクリック
  const handleClickCallStart = (friend: FriendResponse | null) => {
    if (!friend || !friend.friendUserId || !friend.friendUser) {
      throw new Error('handleClickCallStart')
    }
    nativeApp.openVideoCall({
      targetUser: {
        id: friend.friendUserId,
        name: friend.friendUser.nickname || '',
      },
      user: {
        id: me.id,
        name: me.nickname,
      },
    })
  }

  const handleClickBlock = (friend: FriendResponse | null) => {
    if (!friend) {
      throw new Error('handleClickBlock')
    }

    friendUpdateMutation.mutate(
      {
        id: friend.id,
        friendUpdateRequest: {
          status: 'is_blocked',
          isHidden: false,
          isBlocked: true,
        },
      },
      {
        onSuccess() {
          toast({
            title: `${friend?.friendUser?.nickname}をブロックしました`,
            status: 'success',
          })
          meQuery.refetch()
          friendDetailModalDisclosure.onClose()
        },
        onError,
      },
    )
  }

  if (meQuery.isLoading) {
    return <AppLoading />
  }

  return (
    <>
      <Box>
        {isMobile && (
          <Box
            px="16px"
            pt="8px"
            pb="16px"
            borderBottomWidth="1px"
            borderBottomColor="grand.grayLight"
          >
            <AppSegmentedPicker
              info={[
                {
                  label: '個別通話',
                  link: '/video/private',
                  isActive: true,
                },
                {
                  label: 'グループ通話',
                  link: '/video/group',
                  isActive: false,
                },
              ]}
            />
          </Box>
        )}
        {/* 検索 */}
        {!isMobile && (
          <Flex px="32px" py="16px" justify="space-between">
            <Flex>
              {VideoMenuLinks.map((item) => (
                <VideoMenuLink
                  key={item.id}
                  path={item.path}
                  title={item.title}
                  isActive={currentPath === item.path}
                />
              ))}
            </Flex>
            <HStack align="center" spacing="10px">
              <VideoButton
                icon={MdPersonAddAlt1}
                onClick={friendAddClick}
                hasNotify={inRequestFriends && inRequestFriends.length > 0}
              >
                {f('友達を追加')}
              </VideoButton>
              <VideoButton
                icon={MdSettingsPhone}
                onClick={callSettingClick}
                px="24px"
              >
                {f('通話の詳細設定')}
              </VideoButton>
            </HStack>
          </Flex>
        )}

        <Flex px={isMobile ? '16px' : '32px'} py="16px" columnGap="8px">
          <InputGroup maxW="314px" flex="1">
            <InputLeftElement cursor="pointer">
              <SearchIcon color="grand.grayLight" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder={f('友達を探す')}
              _placeholder={{ color: 'grand.grayLight' }}
              bg="#fff"
              fontSize={p2r(14)}
              borderRadius="8px"
              onBlur={() => {}}
              onChange={onChangeSearch}
            />
          </InputGroup>
          {isMobile && (
            <>
              <Button
                variant="ghost"
                p="8px"
                backgroundColor="grand.white"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderWidth="2px"
                borderColor="theme.primary"
                w={p2r(40)}
                h={p2r(40)}
                borderRadius="8px"
                onClick={friendAddClick}
              >
                <Icon
                  as={MdPersonAddAlt1}
                  boxSize={p2r(24)}
                  color="theme.primary"
                />
              </Button>
              <Button
                variant="ghost"
                p="8px"
                backgroundColor="grand.white"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderWidth="2px"
                borderColor="theme.primary"
                w={p2r(40)}
                h={p2r(40)}
                borderRadius="8px"
                onClick={callSettingClick}
              >
                <Icon
                  as={MdSettingsPhone}
                  boxSize={p2r(24)}
                  color="theme.primary"
                />
              </Button>
            </>
          )}
        </Flex>

        {!isMobile && (
          <VideoHeading
            label={`${f('友達リスト')} ${sortedFriends.length}人`}
          />
        )}

        <VideoUserList
          borderTopWidth={isMobile ? '1px' : '0px'}
          borderTopColor="grand.grayLight"
        >
          {sortedFriends.map((friend) => (
            <VideoUserListItem
              name={friend.friendUser?.nickname || ''}
              nameKn={(friend.friendUser?.nicknameKana || '').charAt(0)}
              status="accepted"
              onClickStatus={() => {
                handleClickCallConfirm(friend)
              }}
              onClickMore={() => {
                handleClickFriendDetail(friend)
              }}
              key={friend.id}
            />
          ))}
        </VideoUserList>

        {/* 通話開始確認モーダル */}
        <VideoModal
          isOpen={callConfirmModalDisclosure.isOpen}
          onClose={callConfirmModalDisclosure.onClose}
        >
          <Flex direction="column">
            <Flex pt="24px" direction="column" alignItems="center" rowGap="8px">
              <Text
                fontSize={p2r(24)}
                fontWeight="700"
                lineHeight="150%"
                color="text.body"
              >
                {currentModalFriend?.friendUser?.nickname}
              </Text>
              {/* <Text fontSize={p2r(16)} lineHeight="150%" color="text.body">
                ID: {currentModalFriend?.friendUserId}
              </Text> */}
            </Flex>
            <Flex
              mt="56px"
              direction={isMobile ? 'column' : 'row'}
              columnGap="48px"
              justify="center"
            >
              <Button
                order={isMobile ? 1 : 2}
                leftIcon={<Icon as={MdPhoneInTalk} boxSize="32px" />}
                variant="unstyled"
                borderWidth="4px"
                borderColor="theme.primary"
                color="#FFFDFC"
                fontWeight="700"
                rounded="8px"
                bgColor="theme.primary"
                minW="300px"
                h="68px"
                fontSize={p2r(24)}
                onClick={() => {
                  handleClickCallStart(currentModalFriend)
                }}
                display="flex"
              >
                {f('通話する')}
              </Button>
            </Flex>
          </Flex>
        </VideoModal>

        {/* 友達詳細モーダル */}
        <VideoModal
          isOpen={friendDetailModalDisclosure.isOpen}
          onClose={friendDetailModalDisclosure.onClose}
        >
          <Flex direction="column">
            <Flex pt="24px" direction="column" alignItems="center" rowGap="8px">
              <Text
                fontSize={p2r(24)}
                fontWeight="700"
                lineHeight="150%"
                color="text.body"
              >
                {currentModalFriend?.friendUser?.nickname}
              </Text>
              {/* <Text fontSize={p2r(16)} lineHeight="150%" color="text.body">
                ID: {currentModalFriend?.friendUserId}
              </Text> */}
            </Flex>
            <Flex
              mt="56px"
              direction={isMobile ? 'column' : 'row'}
              columnGap="48px"
              justify="center"
            >
              <Button
                order={isMobile ? 2 : 1}
                variant="ghost"
                borderWidth="4px"
                fontWeight="700"
                rounded="8px"
                minW="300px"
                h="68px"
                fontSize={p2r(24)}
                onClick={() => {
                  handleClickBlock(currentModalFriend)
                }}
              >
                {f('ブロックする')}
              </Button>
            </Flex>
          </Flex>
        </VideoModal>
      </Box>
      {!isMobile && (
        <VStack
          px="8px"
          py="16px"
          spacing="8px"
          rounded="40px"
          bgColor="grand.graySuperLight"
          position="fixed"
          top="180px"
          right="40px"
          w="40px"
        >
          {kanaList.map((kana, index) => {
            const key = `kana-${index}`
            return (
              <InitialLine
                value={initialsIndex(kana.list)[0]}
                label={kana.label}
                key={key}
              />
            )
          })}
        </VStack>
      )}
    </>
  )
}

export default VideoPrivate
