import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Heading,
  Icon,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { MdArrowForwardIos } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { AppErrorAlert } from '../../components/common/AppErrorAlert'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppLoading } from '../../components/common/AppLoading'
import Note from '../../components/svg/Note'
import {
  useContentSearchContext,
  withContentSearch,
} from '../../context/ContentSearchContext'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { p2r } from '../../utils/font'

type PDFCardProps = {
  title: string
  onClick(): void
} & ButtonProps
const PDFCard: React.FC<PDFCardProps> = (props) => {
  const { title, onClick, ...rest } = props
  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <Button
      onClick={onClick}
      variant="unstyled"
      w="100%"
      h="fit-content"
      _last={{
        borderBottomWidth: 0,
      }}
      borderRadius="0px"
      borderBottomWidth={{ base: '1px', md: '0px' }}
      borderBottomColor="grand.grayLight"
      sx={{
        _last: {
          '.contentBorder': {
            height: '0px',
          },
        },
      }}
      {...rest}
    >
      <Flex
        px={{ base: '16px', md: '32px' }}
        py={{ base: '8px', md: '16px' }}
        align="center"
        columnGap="24px"
      >
        <Flex flex="1" columnGap="16px" align="center">
          <Icon
            as={Note}
            color="grand.gray"
            boxSize={{ base: '40px', md: '48px' }}
          />
          <Box flex="1" textAlign="left" position="relative">
            <Text
              w="fit-content"
              px="8px"
              py="2px"
              h="22px"
              borderWidth="1px"
              borderColor="grand.grayLight"
              backgroundColor="grand.white"
              borderRadius="4px"
              fontSize={p2r(12)}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              PDF
            </Text>
            <Text
              mt="4px"
              fontSize={p2r(24)}
              color="text.headline"
              whiteSpace="normal"
            >
              {title}
            </Text>
            {!isMobile && (
              <Box
                w="100%"
                h="1px"
                backgroundColor="rgba(0, 0, 0, 0.1)"
                position="absolute"
                bottom="-16px"
                left="0px"
                className="contentBorder"
              />
            )}
          </Box>
        </Flex>
        {!isMobile && (
          <Icon as={MdArrowForwardIos} color="grand.gray" boxSize="32px" />
        )}
      </Flex>
    </Button>
  )
}

const BousaiSonaeMap: React.FC = () => {
  useHeaderConfig({
    title: 'ハザードマップ',
  })
  const navigate = useNavigate()
  const { f } = useFormatMessage()

  const { query } = useContentSearchContext()

  const PAGE_SIZE = 100
  const { isLoading, isError, error, data } = useContentListQuery('bousai', {
    page: 1,
    pageSize: PAGE_SIZE,
    withFiles: true,
    placeIds: query.placeId ? [query.placeId] : [],
    tags: ['ハザードマップ'],
  })

  const bousaiMapContents = useMemo(() => {
    if (!data?.pages?.[0]?.contents) {
      return []
    }

    return data?.pages?.[0]?.contents
  }, [data])

  if (isLoading) {
    return <AppLoading />
  }

  if (isError) {
    return <AppErrorResult error={error} />
  }

  if (!bousaiMapContents) {
    return <AppErrorAlert error="" />
  }
  return (
    <>
      <Box as="section">
        <Heading
          as="h2"
          w="100%"
          color="text.body"
          fontSize={{ base: p2r(16), md: p2r(20) }}
          fontWeight="700"
          px={{ base: '16px', md: '32px' }}
          py="8px"
          backgroundColor="grand.grayLight"
        >
          {f('避難所')}
        </Heading>
        <Flex direction="column">
          <PDFCard
            onClick={() => {
              navigate('/bousai/23')
            }}
            title="神山町避難施設一覧表"
          />
        </Flex>
      </Box>
      <Box as="section">
        <Heading
          as="h2"
          w="100%"
          color="text.body"
          fontSize={{ base: p2r(16), md: p2r(20) }}
          fontWeight="700"
          px={{ base: '16px', md: '32px' }}
          py="8px"
          backgroundColor="grand.grayLight"
        >
          {f('ハザードマップ')}
        </Heading>
        <Flex direction="column">
          {bousaiMapContents.map((content) => (
            <PDFCard
              onClick={() => {
                navigate(`/bousai/${content.id}`)
              }}
              title={content.titleJa}
            />
          ))}
        </Flex>
      </Box>
    </>
  )
}

export default withContentSearch(BousaiSonaeMap)
