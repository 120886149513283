import {
  Text,
  Flex,
  Box,
  FormControl,
  Input,
  FormLabel,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useForm, UseFormRegister, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useAppConfirm } from '../../components/common/AppConfirm/useAppConfirm'
import { AppErrorAlert } from '../../components/common/AppErrorAlert'
import { MypageButton } from '../../components/mypage/MypageButton'
import { useAuthContext } from '../../context/AuthContext'
import { useUserUpdatePhoneMutation } from '../../hooks/coreApi/useUserUpdatePhoneMutation'
import { useUserUpdatePhoneVerifyMutation } from '../../hooks/coreApi/useUserUpdatePhoneVerifyMutation'
import useFormatMessage from '../../hooks/useFormatMessage'
import { p2r } from '../../utils/font'

type MypageEditFormInput = {
  phone: string
  phoneConfirm: string
  code: string
}

/**
 * テキスト入力
 */
type InputTextProps = {
  label: string
  bgColor?: string
}
const InputText = React.forwardRef<
  HTMLInputElement,
  InputTextProps & ReturnType<UseFormRegister<MypageEditFormInput>>
>((props, ref) => (
  <Flex
    py="16px"
    backgroundColor={props.bgColor ? props.bgColor : 'grand.white'}
    flexDirection="column"
    w="100%"
    minW="242px"
    rowGap="8px"
  >
    <FormLabel
      fontSize={p2r(16)}
      lineHeight="150%"
      fontWeight="400"
      color="text.sub"
      mb="0"
      htmlFor={props.label}
    >
      {props.label}
    </FormLabel>
    <Input
      ref={ref}
      name={props.name}
      onChange={props.onChange}
      onBlur={props.onBlur}
      type="text"
      p="16px"
      lineHeight="150%"
      fontSize={p2r(24)}
      fontWeight="400"
      color="text.body"
      variant="unstyled"
      border="1px solid"
      borderColor="grand.grayLight"
      borderRadius="10px"
    />
  </Flex>
))

const MypageUpdatePhone: React.FC = () => {
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  const updatePhoneMutation = useUserUpdatePhoneMutation()
  const updatePhoneVerifyMutation = useUserUpdatePhoneVerifyMutation()
  const toast = useToast()
  const appConfirm = useAppConfirm()
  const { me, refetchMe } = useAuthContext()

  const form = useForm<MypageEditFormInput>()

  // フォームの初期値セット
  useEffect(() => {
    if (!me) {
      return
    }
    form.reset({
      phone: '',
      phoneConfirm: '',
      code: '',
    })
  }, [me])

  const onSubmit: SubmitHandler<MypageEditFormInput> = (values) => {
    // プロフィール更新処理
    updatePhoneMutation.mutate(
      {
        userUpdatePhoneRequest: {
          phone: values.phone,
        },
      },
      {
        async onSuccess() {
          if (form.getValues('phone') !== form.getValues('phoneConfirm')) {
            toast({
              title: '確認用電話番号が一致しません。',
              status: 'error',
            })
            return
          }
          const confirmOk = await appConfirm.open({
            title: '確認用コードを入力',
            body: (
              <Input
                onChange={(evt) => {
                  form.setValue('code', evt.target.value)
                }}
              />
            ),
          })

          if (!confirmOk) {
            return
          }

          updatePhoneVerifyMutation.mutate(
            {
              userUpdatePhoneVerifyRequest: {
                authCode: form.getValues('code'),
              },
            },
            {
              onSuccess() {
                toast({
                  title: '電話番号を変更しました。',
                })
                refetchMe()
                navigate('/mypage/profile')
              },
            },
          )
        },
      },
    )
  }

  if (!me) {
    return null
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <FormControl>
        <Box px={{ base: '16px', md: '32px' }} py={{ base: '0', md: '32px' }}>
          <Flex
            justifyContent={{ base: 'unset', md: 'space-between' }}
            align="center"
            direction={{ base: 'column', md: 'row' }}
            rowGap="8px"
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              columnGap="16px"
              order={{ base: '2', md: '1' }}
              mr={{ base: 'auto', md: 'unset' }}
            >
              <Text color="text.body" fontSize={p2r(24)} fontWeight="700">
                電話番号の変更
              </Text>
            </Flex>
          </Flex>
          <Flex pt="16px" direction="column">
            {updatePhoneMutation.isError && (
              <AppErrorAlert error={updatePhoneMutation.error} />
            )}
            {updatePhoneVerifyMutation.isError && (
              <AppErrorAlert error={updatePhoneVerifyMutation.error} />
            )}
            <InputText
              required
              label={f('新しい電話番号')}
              {...form.register('phone')}
            />
            <InputText
              required
              label={f('新しい電話番号(確認用)')}
              {...form.register('phoneConfirm')}
            />
            <MypageButton
              mt="4"
              type="submit"
              onClick={() => {
                form.handleSubmit(onSubmit)
              }}
            >
              {f('変更する')}
            </MypageButton>
          </Flex>
        </Box>
      </FormControl>
    </form>
  )
}

export default MypageUpdatePhone
