import { Icon } from '@chakra-ui/react'
import React from 'react'

const Delete: React.FC = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <mask
      id="mask0_2338_4507"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="21"
      height="20"
    >
      <rect x="0.5" width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2338_4507)">
      <path
        d="M6.33325 17.5C5.87492 17.5 5.4827 17.3369 5.15659 17.0108C4.82992 16.6842 4.66659 16.2917 4.66659 15.8333V5H3.83325V3.33333H7.99992V2.5H12.9999V3.33333H17.1666V5H16.3333V15.8333C16.3333 16.2917 16.1702 16.6842 15.8441 17.0108C15.5174 17.3369 15.1249 17.5 14.6666 17.5H6.33325ZM7.99992 14.1667H9.66658V6.66667H7.99992V14.1667ZM11.3333 14.1667H12.9999V6.66667H11.3333V14.1667Z"
        fill="currentColor"
      />
    </g>
  </Icon>
)

export default Delete
