import { Box, Flex, Heading, HStack, Image } from '@chakra-ui/react'
import React from 'react'
import nl2br from 'react-nl2br'
import { p2r } from '../../../utils/font'
import { AppDateText } from '../AppDateText'
import AppLikeButton from '../AppLikeButton'
import AppLinkify from '../AppLinkify'
import { AppTagText } from '../AppTagText'

export type AppContentBasicProps = {
  title?: string
  body?: string
  thumbnailImageUrl?: string
  date: string
  isShowLike?: boolean
  isLiked?: boolean
  likeCount?: number
  tags?: string[]
  contentId: number
}

export const AppContentBasic: React.FC<AppContentBasicProps> = ({
  title,
  body,
  thumbnailImageUrl,
  date,
  isShowLike,
  isLiked,
  likeCount = 0,
  tags,
  contentId,
  ...props
}) => (
  <Box>
    <Box
      px={{ base: '16px', md: '32px' }}
      py={{ base: '20px', md: '40px' }}
      bgColor="theme.background"
      h={{ base: 'calc(100vh - 165px)', md: 'calc(100vh - 56px)' }}
      {...props}
    >
      <Flex flexDirection="column" gap={{ base: '8px', md: '16px' }}>
        <HStack spacing="16px">
          <AppDateText label={date} fontSize={{ base: p2r(14), md: p2r(16) }} />
          {tags && tags.length > 0 && (
            <Flex gap="8px">
              {tags.map((tag) => (
                <AppTagText label={tag} key={tag} />
              ))}
            </Flex>
          )}
        </HStack>
        <Heading
          as="h1"
          color="text.headline"
          fontSize={{ base: p2r(16), md: p2r(24) }}
          fontWeight="700"
          lineHeight="150%"
        >
          {title}
        </Heading>
        {thumbnailImageUrl && (
          <Box>
            <Image src={thumbnailImageUrl} alt={title} />
          </Box>
        )}
        {isShowLike && (
          <AppLikeButton
            isLiked={isLiked}
            contentId={contentId}
            count={likeCount}
          />
        )}
      </Flex>
      {body && (
        <Box mt={{ base: '20px', md: '40px' }} color="text.body">
          {/* <Linkify options={{ render: renderLink }}>{nl2br(body)}</Linkify> */}
          <AppLinkify>{nl2br(body)}</AppLinkify>
        </Box>
      )}
    </Box>
  </Box>
)
