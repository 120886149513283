import {
  Button,
  Flex,
  GridItem,
  Icon,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
// import useUserTmpConfig from '../../../hooks/useUserTmpConfig'
// import { UserConfigFontSize } from '../../../models/user'
import { p2r } from '../../../utils/font'
import { isAbsoluteLink } from '../../../utils/path'

export type AppNavigationCardProps = {
  path: string
  title: string
  imgPath: React.FC
  imgW: string
  h: string
  bg: string
  border: string
  iconColor?: string
  onClick?(): void
  isFontLarge?: boolean
}

export const AppNavigationCard: React.FC<AppNavigationCardProps> = (props) => {
  const {
    path,
    title,
    imgPath,
    imgW,
    h,
    bg,
    iconColor,
    border,
    onClick,
    isFontLarge = false,
  } = props

  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  const pcFlexDirection = isFontLarge ? 'row' : 'column'
  const flexDirection = isMobile ? 'column' : pcFlexDirection

  const inner = (
    <>
      {!isFontLarge && (
        <Flex
          background={bg}
          boxShadow={isMobile ? 'unset' : '0px 0px 10px rgba(0, 0, 0, 0.2)'}
          borderRadius="8px"
          overflow="hidden"
          margin="0px"
          w={isMobile ? '100%' : 'auto'}
          h={isMobile ? 'fit-content' : h}
          position="relative"
          borderWidth={isMobile ? '2px' : '4px'}
          borderColor={border}
          direction={flexDirection}
          align="center"
        >
          <Flex
            justify="center"
            align="center"
            w={isMobile ? '35vw' : imgW}
            h={isMobile ? '12vh' : `calc(100% - ${(20 / 16) * 1.8}rem)`}
            p="12px"
          >
            <Icon
              as={imgPath}
              w={isMobile ? '100%' : imgW}
              h="100%"
              color={iconColor}
            />
          </Flex>
          <Text
            py="3.5px"
            color="text.headline"
            fontSize={isMobile ? p2r(16) : p2r(20)}
            lineHeight="150%"
            fontWeight="700"
            textAlign="center"
            backgroundColor="grand.white"
            w="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            h="auto"
            borderTopColor={border === 'none' ? 'grand.grayLight' : border}
            borderTopWidth={isMobile ? '2px' : '3px'}
          >
            {title}
          </Text>
        </Flex>
      )}
      {isFontLarge && (
        <Flex
          background={bg}
          boxShadow={isMobile ? 'unset' : '0px 0px 10px rgba(0, 0, 0, 0.2)'}
          borderRadius="8px"
          overflow="hidden"
          margin="0px"
          w={isMobile ? '100%' : imgW}
          h={isMobile ? 'fit-content' : h}
          position="relative"
          borderWidth={isMobile ? '2px' : '4px'}
          borderColor={border}
          direction={flexDirection}
          align="center"
        >
          <Flex
            justify="center"
            align="center"
            w={isMobile ? '35vw' : '160px'}
            h={isMobile ? '12vh' : '100%'}
            p="12px"
            borderRightColor={{
              base: 'transparent',
              md: border === 'none' ? 'grand.grayLight' : border,
            }}
            borderRightWidth={isMobile ? '0px' : '5px'}
          >
            <Icon
              as={imgPath}
              w={isMobile ? '100%' : '80%'}
              h="100%"
              color={iconColor}
            />
          </Flex>
          <Text
            py="3.5px"
            color="text.headline"
            fontSize={isMobile ? p2r(16) : '48px'}
            lineHeight="150%"
            fontWeight="700"
            textAlign="center"
            backgroundColor="grand.white"
            flex={isMobile ? 'unset' : '1'}
            w={isMobile ? '100%' : 'auto'}
            display="flex"
            alignItems="center"
            justifyContent="center"
            h={isMobile ? 'auto' : '100%'}
            borderTopColor={border === 'none' ? 'grand.grayLight' : border}
            borderTopWidth={isMobile ? '2px' : '0px'}
          >
            {title}
          </Text>
        </Flex>
      )}
    </>
  )

  if (isAbsoluteLink(path)) {
    return (
      <GridItem>
        <a href={path} target="_blank" rel="noreferrer">
          {inner}
        </a>
      </GridItem>
    )
  }

  if (onClick) {
    return (
      <GridItem>
        <Button onClick={onClick} h="fit-content" variant="unstyled">
          {inner}
        </Button>
      </GridItem>
    )
  }

  return (
    <GridItem>
      <Link to={path}>{inner}</Link>
    </GridItem>
  )
}
