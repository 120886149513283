import { Box, Td, Tr, useBreakpointValue, useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import AppErrorResult from '../../../components/common/AppErrorResult'
import { TaxiSectionTitle } from '../../../components/taxi/TaxiSectionTitle'
import TaxiTimeNGButton from '../../../components/taxi/TaxiTimeNGButton'
import TaxiTimeOKButton from '../../../components/taxi/TaxiTimeOKButton'
import TaxiTimeTable from '../../../components/taxi/TaxiTimeTable'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { isInBusinessHour } from '../../../models/taxiCompany'
import { validateTaxiReservationReturnTime } from '../../../models/taxiReservation'
import { p2r } from '../../../utils/font'
import useTaxiReservationCreateState from '../useTaxiReservationCreateState'

type TimeListType = {
  hour: number
  children: {
    minute: 0 | 15 | 30 | 45
    status: 0 | 1
  }[]
}

// 時間選択
const TaxiReservationCreateReturnSelectTime: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { f } = useFormatMessage()

  const {
    taxiRideDateTime,
    setTaxiRideDateTime,
    selectedTaxiCompany,
    selectedRideDate,
    selectedRideTime,
    formValues,
  } = useTaxiReservationCreateState()
  const navigate = useNavigate()
  const toast = useToast()

  const timeList = useMemo(() => {
    const tmpTimeList: TimeListType[] = []
    // タクシー会社を選択していない場合
    if (!selectedTaxiCompany) {
      return tmpTimeList
    }

    // 選択した会社の営業時間
    _.range(7, 25).forEach((hour) => {
      const row = {
        hour,
        children: [],
      }

      _.range(0, 60, 15).forEach((minute) => {
        const child = {
          minute,
          status: 0,
        }

        // TODO タクシー会社 taxiCompanyから取得
        if (!isInBusinessHour(selectedTaxiCompany, hour, minute)) {
          child.status = 1
        }
        row.children.push(child as never)
      })
      tmpTimeList.push(row)
    })
    return tmpTimeList
  }, [])

  // 空き時間選択
  const handleTimeSelected = ({
    hour,
    minute,
  }: {
    hour: number
    minute: number
  }) => {
    const { year, month, day } = taxiRideDateTime.returnDate
    const d = dayjs(`${year}-${month}-${day} ${hour}:${minute}`)
    const err = validateTaxiReservationReturnTime(d, dayjs(formValues.rideAt))
    if (err) {
      toast({
        title: err,
      })
      return
    }

    setTaxiRideDateTime({
      returnTime: {
        hour,
        minute,
      },
    })
    navigate('/taxi/reservations/create')
  }

  if (!selectedTaxiCompany) {
    return <AppErrorResult message="タクシー会社を選択してください" />
  }

  if (!selectedRideDate || !selectedRideTime) {
    return <AppErrorResult message="往路の乗車時間を選択してください" />
  }

  return (
    <>
      <Box px={{ base: '16px', md: '0px' }}>
        <TaxiSectionTitle
          label={f(
            '時間を選択してください。※ご希望時間が無い場合はタクシー会社へ電話でお問い合わせください。',
          )}
        />
      </Box>

      <TaxiTimeTable>
        {timeList.map((time) => (
          <Tr key={time.hour}>
            <Td
              px="0"
              py="0"
              w={isMobile ? p2r(60) : p2r(120)}
              fontSize={isMobile ? p2r(16) : p2r(24)}
              fontWeight="bold"
              textAlign="center"
              borderRightWidth="1px"
              borderRightColor="grand.grayLight"
            >
              {`${time.hour}時`}
            </Td>
            {time.children.map((item, index) => (
              <Td
                py="8px"
                px={isMobile ? '8px' : '16px'}
                pl={{ base: '8px', md: index === 0 ? '32px' : '16px' }}
                key={`${time.hour}-${item.minute}`}
              >
                {item.status === 0 && (
                  <TaxiTimeOKButton
                    isActive={
                      time.hour === taxiRideDateTime.time.hour &&
                      item.minute === taxiRideDateTime.time.minute
                    }
                    onClick={() => {
                      handleTimeSelected({
                        hour: time.hour,
                        minute: item.minute,
                      })
                    }}
                  />
                )}
                {item.status === 1 && <TaxiTimeNGButton />}
              </Td>
            ))}
            {!isMobile && <Td py="8px" />}
          </Tr>
        ))}
      </TaxiTimeTable>
    </>
  )
}

export default TaxiReservationCreateReturnSelectTime
