import { Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { p2r } from '../../../utils/font'

export const useSetVideoLinks = () => {
  const { f } = useFormatMessage()
  return [
    {
      id: 1,
      path: '/video/private',
      title: f('個別通話'),
    },
    {
      id: 2,
      path: '/video/group',
      title: f('グループ通話'),
    },
  ]
}

type VideoMenuLinkProps = {
  path: string
  title: string
  isActive: boolean
}
export const VideoMenuLink: React.FC<VideoMenuLinkProps> = (props) => (
  <Link to={props.path}>
    <Text
      bgColor={props.isActive ? 'theme.primary' : 'grand.graySuperLight'}
      color={props.isActive ? 'text.white' : 'text.sub'}
      p="8px"
      rounded="10"
      fontSize={p2r(20)}
      fontWeight="700"
      w={p2r(260)}
      textAlign="center"
    >
      {props.title}
    </Text>
  </Link>
)
