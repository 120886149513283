import {
  Icon,
  HStack,
  Heading,
  Text,
  Stack,
  StackDivider,
  Box,
  Tag,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { MdFilePresent } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useNotificationListQuery } from '../../hooks/coreApi/useNotificationListQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { p2r } from '../../utils/font'
import { notificationConfigMap, NotificationType } from './notificationConfig'

const Notification: React.FC = () => {
  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })
  // const notificationList = NotificationList()
  useHeaderConfig({
    title: '通知',
  })

  const notificationListQuery = useNotificationListQuery({})
  const notifications = notificationListQuery?.data?.notifications

  const notificationsByDay = useMemo(() => {
    const res = []
    for (let i = 0; i < 2; i += 1) {
      const currentDayStart = dayjs().add(i, 'day').startOf('day')
      const currentDayEnd = dayjs().add(i, 'day').endOf('day')
      const currentDayNotifications =
        notifications?.filter((n) => {
          const d = dayjs(n.createdAt)
          return d.isSameOrAfter(currentDayStart) && d.isBefore(currentDayEnd)
        }) || []

      const isToday = currentDayStart.date() === dayjs().date()

      const row = {
        date: isToday ? '今日' : currentDayStart.format('M月D日(ddd)'),
        list: currentDayNotifications.map((n) => {
          const notificationConfig =
            notificationConfigMap?.[n.type as NotificationType]

          return {
            id: n.id,
            icon: notificationConfig?.icon || MdFilePresent,
            label: n.titleJa,
            time: dayjs(n.createdAt).format('HH:mm'),
            description: n.bodyJa,
            link: notificationConfig?.url,
          }
        }),
        // list: [
        //   {
        //     id: 1,
        //     icon: MdCall,
        //     label: f('不在着信'),
        //     time: f('9:50'),
        //     description: f('山田花子さんから着信がありました'),
        //     link: '/video',
        //   },
        //   {
        //     id: 2,
        //     icon: MdLocalTaxi,
        //     label: f('タクシー予約が確定しました'),
        //     time: f('9:41'),
        //     description: f('5月11日（水）12:00 自宅→川又郵便局'),
        //     link: '/taxi/reservations/:id',
        //   },
        //   {
        //     id: 3,
        //     icon: MdDelete,
        //     label: f('ゴミ出し'),
        //     time: f('9:00'),
        //     description: f('明日は燃えるゴミの日です'),
        //     link: '/trash',
        //   },
        // ],
      }

      res.push(row)
    }

    return res
  }, [notifications])

  console.log(notificationsByDay)

  return (
    <Stack
      gap={{ base: '16px', md: '56px' }}
      bgColor="theme.background"
      py={{ base: '8px', md: '16px' }}
      px={{ base: 'unset', md: '32px' }}
      minH="calc(100vh - 56px)"
    >
      {notificationsByDay.map((item) => (
        <Flex
          direction="column"
          key={item.date}
          gap={{ base: '8px', md: '16px' }}
        >
          <Heading
            fontSize={p2r(20)}
            color="text.headline"
            px={{ base: '16px', md: 'unset' }}
          >
            {item.date}
          </Heading>
          <Stack divider={<StackDivider />} spacing="0" bgColor="grand.white">
            {item.list.length === 0 && <Box p="4">通知はありません</Box>}
            {item.list.map((detail) => (
              // TODO 一時的にリンクを外している
              <Link key={detail.id} to="">
                <Box
                  pt={{ base: '8px', md: '21px' }}
                  px="16px"
                  pb={{ base: '8px', md: '11px' }}
                >
                  {!isMobile ? (
                    <>
                      <HStack spacing="16px" mb="8px">
                        <Icon
                          as={detail.icon}
                          color="text.sub"
                          boxSize="24px"
                        />
                        <Heading
                          fontSize={p2r(24)}
                          color="text.body"
                          lineHeight="150%"
                        >
                          {detail.label}
                        </Heading>
                        <Text as="span" color="text.sub" fontSize={p2r(16)}>
                          {detail.time}
                        </Text>
                        {item.date === '今日' ? (
                          <Tag
                            borderRadius="full"
                            lineHeight="150%"
                            fontSize={p2r(12)}
                            color="text.white"
                            fontWeight="700"
                            bgColor="theme.primary"
                          >
                            {f('新着')}
                          </Tag>
                        ) : (
                          ''
                        )}
                      </HStack>
                      <Text
                        color="text.sub"
                        lineHeight="150%"
                        fontSize={p2r(16)}
                      >
                        {detail.description}
                      </Text>
                    </>
                  ) : (
                    <Flex gap="8px" alignItems="center">
                      <Icon as={detail.icon} color="text.sub" boxSize="40px" />
                      <Stack spacing="8px">
                        <HStack spacing="4px">
                          <Text
                            as="span"
                            color="#9D9D9D"
                            lineHeight="150%"
                            fontSize={p2r(16)}
                          >
                            {detail.time}
                          </Text>
                          {item.date === '今日' ? (
                            <Tag
                              borderRadius="4px"
                              lineHeight="150%"
                              fontSize={p2r(12)}
                              color="text.white"
                              fontWeight="700"
                              bgColor="theme.primary"
                            >
                              {f('新着')}
                            </Tag>
                          ) : (
                            ''
                          )}
                        </HStack>
                        <Heading
                          fontSize={p2r(24)}
                          color="text.body"
                          lineHeight="150%"
                        >
                          {detail.label}
                        </Heading>
                        <Text
                          color="text.sub"
                          lineHeight="150%"
                          fontSize={p2r(16)}
                        >
                          {detail.description}
                        </Text>
                      </Stack>
                    </Flex>
                  )}
                </Box>
              </Link>
            ))}
          </Stack>
        </Flex>
      ))}
    </Stack>
  )
}

export default Notification
