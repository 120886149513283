import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'
import {
  TrashBottles,
  TrashBulky,
  TrashBurn,
  TrashCans,
  TrashDiapers,
  TrashFood,
  TrashPaper,
  TrashPet,
  TrashPlastic,
} from '../../svg/Trash'

const TRASH_INFO = [
  {
    name: 'リサイクルプラ',
    icon: TrashPlastic,
  },
  {
    name: '燃やすごみ',
    icon: TrashBurn,
  },
  {
    name: '紙資源',
    icon: TrashPaper,
  },
  {
    name: '粗大ごみ',
    icon: TrashBulky,
  },
  {
    name: '生ごみ',
    icon: TrashFood,
  },
  {
    name: '紙おむつ',
    icon: TrashDiapers,
  },
  {
    name: '空き瓶',
    icon: TrashBottles,
  },
  {
    name: '空き缶',
    icon: TrashCans,
  },
  {
    name: '空きペットボトル',
    icon: TrashPet,
  },
]

const getTrashIcon = (trashType: string) => {
  const info = TRASH_INFO.find((i) => i.name === trashType)
  if (!info) {
    return TrashPaper // TODO その他アイコン
  }

  return info.icon
}

export type TrashCalendarTypeIconProps = {
  trashType: string
} & IconProps

export const TrashCalendarTypeIcon: React.FC<TrashCalendarTypeIconProps> = ({
  trashType,
  ...rest
}) => {
  const icon = getTrashIcon(trashType)
  return <Icon {...rest} as={icon} />
}
