import { Box, Flex, FlexProps, Text } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { p2r } from '../../../utils/font'
import AppLikeButton from '../../common/AppLikeButton'
import { AppNewLabel } from '../../common/AppNewLabel'
import AppSoundPlayButton from '../../common/AppSoundPlayButton'
import { AppTagText } from '../../common/AppTagText'

export type HomePanelListItemProps = {
  title: string
  date: string
  link?: string
  isShowLike?: boolean
  isLiked?: boolean | null
  likeCount?: number | null
  tags?: string[]
  soundPath?: string
  contentId: number
  isReaded?: boolean
}

export const HomePanelListItem: React.FC<HomePanelListItemProps> = ({
  title,
  date,
  link,
  isShowLike,
  isLiked,
  likeCount,
  tags,
  soundPath,
  contentId,
  isReaded,
  ...props
}) => {
  const linkInner = (
    <Text
      color="text.body"
      minH={p2r(64)}
      fontSize={p2r(22)}
      lineHeight={p2r(32)}
      fontWeight={isReaded ? '400' : '700'}
      noOfLines={2}
    >
      {title}
    </Text>
  )

  return (
    <Flex
      py="8px"
      px="16px"
      rowGap="8px"
      columnGap="16px"
      flexDirection="column"
      bgColor={isReaded ? 'grand.graySuperLight' : 'transparent'}
      {...props}
    >
      <Flex columnGap="16px" align="center">
        <Flex columnGap="8px" alignItems="center">
          {!isReaded && <AppNewLabel />}
          <Text
            fontSize={p2r(14)}
            lineHeight="150%"
            color="text.sub"
            fontWeight="400"
          >
            {date}
          </Text>
          {tags &&
            tags.length > 0 &&
            tags.map((tag) => <AppTagText label={tag} key={tag} />)}
          {isShowLike && (
            <AppLikeButton ml="8px" isLiked={isLiked} contentId={contentId} />
          )}
        </Flex>
      </Flex>
      <Flex flex="1" align="center" rowGap="4px" columnGap="8px">
        {link ? (
          <Box flex="1">
            <Link to={link}>{linkInner}</Link>
          </Box>
        ) : (
          <Box flex="1">{linkInner}</Box>
        )}
        {soundPath && (
          <AppSoundPlayButton
            soundPath={soundPath}
            height="52px"
            px="16px"
            py="14px"
            fontSize={p2r(16)}
          />
        )}
      </Flex>
    </Flex>
  )
}

type HomePanelListProps = {
  children: ReactNode
} & FlexProps
export const HomePanelList: React.FC<HomePanelListProps> = (props) => {
  const { children, ...rest } = props
  return (
    <Flex direction="column" h="100%" {...rest}>
      {children}
    </Flex>
  )
}
