import {
  Box,
  Button,
  ButtonProps,
  Center,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { MdDone } from 'react-icons/md'
import { MypageSectionTitle } from '../../components/mypage/MypageSectionTitle'
import { useUserPersistConfigContext } from '../../context/UserPersistConfigContext'
import useFormatMessage from '../../hooks/useFormatMessage'
import { UserConfigFontSize, UserConfigFontSizeType } from '../../models/user'
import { p2r } from '../../utils/font'

type FontSizeButtonProps = {
  // fontSize: number
  onClick(): void
  isActive?: boolean
  children: React.ReactNode
} & ButtonProps
const FontSizeButton: React.FC<FontSizeButtonProps> = ({
  onClick,
  isActive,
  children,
  ...rest
}) => (
  <Box>
    <Button
      w={{ base: '80px', md: '120px' }}
      h={{ base: '80px', md: '120px' }}
      variant="unstyled"
      onClick={onClick}
      color={isActive ? 'text.white' : 'text.body'}
      backgroundColor={isActive ? 'theme.primary' : 'grand.white'}
      borderWidth="4px"
      borderColor="theme.primary"
      {...rest}
    >
      <Center>{children}</Center>
    </Button>
    {isActive && (
      <Flex mt="8px" justify="center" align="center">
        <Icon
          as={MdDone}
          boxSize={{ base: p2r(14), md: p2r(24) }}
          color="theme.primary"
        />
        <Text
          fontSize={{ base: p2r(16), md: p2r(20) }}
          fontWeight="700"
          lineHeight="150%"
          color="theme.primary"
        >
          選択中
        </Text>
      </Flex>
    )}
  </Box>
)

const MypageFont: React.FC = () => {
  const { f } = useFormatMessage()
  const sizeNameInfo: { [key in number]: string } = {
    [UserConfigFontSize.SMALL]: f('小'),
    [UserConfigFontSize.MEDIUM]: f('中'),
    [UserConfigFontSize.LARGE]: f('大'),
  }

  const { userPersistConfig, setConfigValue } = useUserPersistConfigContext()

  const handleChange = (id: UserConfigFontSizeType) => {
    setConfigValue('cnfFontSize', id)
  }

  return (
    <Box px={{ base: '0px', md: '32px' }} pt={{ base: '0px', md: '72px' }}>
      <MypageSectionTitle label={f('文字の大きさ')} />
      <Flex
        mt="32px"
        mx="auto"
        px={{ base: '28px', md: '32px' }}
        py="32px"
        w={{ base: '100%', md: '100%' }}
        columnGap={{ base: '24px', md: '64px' }}
        justify="center"
        backgroundColor="grand.white"
        borderWidth="2px"
        borderColor="grand.grayLight"
        borderRadius="10px"
      >
        <FontSizeButton
          fontSize={{ base: '16px', md: '24px' }}
          onClick={() => {
            handleChange(1)
          }}
          isActive={userPersistConfig.cnfFontSize === UserConfigFontSize.SMALL}
        >
          {f('小')}
        </FontSizeButton>
        <FontSizeButton
          fontSize={{ base: '32px', md: '48px' }}
          onClick={() => {
            handleChange(2)
          }}
          isActive={userPersistConfig.cnfFontSize === UserConfigFontSize.MEDIUM}
        >
          {f('中')}
        </FontSizeButton>
        <FontSizeButton
          fontSize={{ base: '48px', md: '64px' }}
          onClick={() => {
            handleChange(3)
          }}
          isActive={userPersistConfig.cnfFontSize === UserConfigFontSize.LARGE}
        >
          {f('大')}
        </FontSizeButton>
      </Flex>
      <Text
        mx="auto"
        mt={{ base: '24px', md: '40px' }}
        p="16px"
        fontSize={{ base: p2r(18), md: p2r(21) }}
        fontWeight="700"
        letterSpacing="0.05em"
        color="text.sub"
        backgroundColor="grand.white"
        borderWidth="2px"
        borderColor="grand.grayLight"
        borderRadius="10px"
      >
        {f(
          `サイズを変更することで、画面内のテキストサイズが変更されます。今は${
            sizeNameInfo[userPersistConfig.cnfFontSize]
          }サイズが選択されています。`,
        )}
      </Text>
    </Box>
  )
}

export default MypageFont
