import {
  Flex,
  Heading,
  Text,
  Stack,
  Button,
  Icon,
  useBreakpointValue,
} from '@chakra-ui/react'
import React from 'react'
import { MdCall } from 'react-icons/md'
import AppErrorResult from '../../../components/common/AppErrorResult'
import { AppLoading } from '../../../components/common/AppLoading'
import { TaxiSectionTitle } from '../../../components/taxi/TaxiSectionTitle'
import { useTaxiCompanyListQuery } from '../../../hooks/coreApi/useTaxiCompanyListQuery'
import useFormatMessage from '../../../hooks/useFormatMessage'
import useHeaderConfig from '../../../hooks/useHeaderConfig'
import { getBusinessTimeDurationText } from '../../../models/taxiCompany'
import { p2r } from '../../../utils/font'

type CompanyInfoProps = {
  label: string
  infoDetail: string
}

const CompanyInfo: React.FC<CompanyInfoProps> = (props) => (
  <Flex justify="space-between">
    <Text color="text.sub" fontSize={p2r(16)} lineHeight="150%">
      {props.label}
    </Text>
    <Text
      color="text.body"
      fontSize={p2r(16)}
      fontWeight="700"
      lineHeight="150%"
    >
      {props.infoDetail}
    </Text>
  </Flex>
)

type ReservationTelCardProps = {
  company: string
  tel: string
  address: string
  businessTime: string
}

const ReservationTelCard: React.FC<ReservationTelCardProps> = (props) => {
  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      bgColor="grand.white"
      alignItems="center"
      justify="space-between"
      px={{ base: '16px', md: '32px' }}
      py={{ base: '8px', md: '16px' }}
      rounded="10px"
      gap={{ base: '8px', md: 'unset' }}
    >
      <Stack
        direction="column"
        spacing={{ base: '8px', md: '16px' }}
        w={{ base: '311px', md: '320px' }}
      >
        <Heading
          as="h2"
          color="text.body"
          fontSize={p2r(24)}
          fontWeight="700"
          lineHeight="150%"
        >
          {props.company}
        </Heading>
        <Stack spacing="8px">
          <CompanyInfo label={f('営業時間')} infoDetail={props.businessTime} />
          <CompanyInfo label={f('住所')} infoDetail={props.address} />
          <CompanyInfo label={f('電話番号')} infoDetail={props.tel} />
        </Stack>
      </Stack>
      {isMobile && (
        <Flex direction="row" columnGap={{ base: '16px', md: '40px' }}>
          <a href={`tel:${props.tel}`}>
            <Button
              leftIcon={<Icon as={MdCall} boxSize="24px" />}
              rounded="8px"
              color="text.white"
              bgColor="theme.primary"
              fontSize={{ base: p2r(16), md: p2r(20) }}
              fontWeight="700"
              iconSpacing="4px"
              py={{ base: '12px', md: '16px' }}
              px={{ base: '8px', md: '16px' }}
              h="fit-content"
            >
              {f('電話で予約')}
            </Button>
          </a>
        </Flex>
      )}
    </Flex>
  )
}

const TaxiReservationTel: React.FC = () => {
  useHeaderConfig({
    title: 'タクシー予約',
  })
  const { f } = useFormatMessage()

  const taxiCompanyListQuery = useTaxiCompanyListQuery({})
  const taxiCompanies = taxiCompanyListQuery?.data?.taxiCompanies

  if (taxiCompanyListQuery.isLoading) {
    return <AppLoading />
  }

  if (taxiCompanyListQuery.isError) {
    return <AppErrorResult error={taxiCompanyListQuery.error} />
  }

  return (
    <Flex
      direction="column"
      bgColor="theme.background"
      minH="calc(100vh - 56px)"
      px={{ base: '16px', md: '32px' }}
      py={{ base: '8px', md: '40px' }}
    >
      <TaxiSectionTitle
        label={f(
          "まちのクルマLet'sの割引適用に関しては、別途利用申請を行う必要があります。利用申請をしていない方は割引は適用されず通常料金での利用となります。",
        )}
      />
      <Stack
        spacing={{ base: '8px', md: '24px' }}
        pt={{ base: '0px', md: '40px' }}
      >
        {taxiCompanies?.map((c) => (
          <ReservationTelCard
            company={c.name}
            tel={c.phone}
            address={c.address}
            businessTime={getBusinessTimeDurationText(c)}
          />
        ))}
      </Stack>
    </Flex>
  )
}

export default TaxiReservationTel
