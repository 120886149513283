import { TaxiReservationApiTaxiReservationUpdateByUserRequest } from '../../openapi/api'
import appApiClient from './appApiClient'
import useBaseMutation from './useBaseMutation'

export const useTaxiReservationUpdateStatusByUserMutation = () =>
  useBaseMutation({
    mutationFn: (
      req: TaxiReservationApiTaxiReservationUpdateByUserRequest,
      config,
    ) =>
      appApiClient.taxiReservationApi.taxiReservationUpdateByUser(req, config),
  })
