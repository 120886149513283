import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { p2r } from '../../../utils/font'
import SwipeVertical from '../../svg/SwipeVertical'

type TaxiAreaSelectButtonProps = {
  isActive: boolean
  children: ReactNode
} & ButtonProps
export const TaxiAreaSelectButton: React.FC<TaxiAreaSelectButtonProps> = (
  props,
) => {
  const { isActive, children, ...rest } = props
  return (
    <Button
      h="fit-content"
      py="16px"
      borderWidth="3px"
      borderColor={isActive ? 'theme.primary' : 'grand.grayLight'}
      backgroundColor="grand.white"
      fontSize={p2r(24)}
      fontWeight="700"
      color="text.body"
      _hover={{
        background: 'grand.white',
      }}
      {...rest}
    >
      {children}
    </Button>
  )
}

const TaxiAreaSelect: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <Box>
      <Flex py="8px" columnGap="8px">
        <Icon as={SwipeVertical} boxSize={p2r(24)} color="grand.gray" />
        <Text
          fontSize={isMobile ? p2r(16) : p2r(24)}
          color="grand.gray"
          fontWeight="700"
        >
          選択する
        </Text>
      </Flex>
      <Flex mt="8px" columnGap="16px">
        {children}
      </Flex>
    </Box>
  )
}

export default TaxiAreaSelect
