import { LS_KEY } from '../lib/constants'
import { ContentResponse } from '../openapi'

// 既読状態の判定
// ログイン時 → APIの既読フラグ
// 未ログイン時 → ローカルストレージの既読IDs
export const isReadWithLocalStorage = (content: ContentResponse) => {
  const localReadedContentIds = JSON.parse(
    localStorage.getItem(LS_KEY.readedContentIds) || '[]',
  ) as number[]

  if (content?.isRead) {
    return true
  }

  if (localReadedContentIds?.includes(content.id)) {
    return true
  }

  return false
}

export const LEVEL_COLOR_INFO = [
  {
    mainText: 'text.headline',
    subText: 'grand.gray',
    background: 'rgba(255, 255, 255, 0.8)',
  },
  {
    mainText: 'text.headline',
    subText: 'grand.gray',
    background: 'rgba(242, 231, 0, 0.8)',
  },
  {
    mainText: 'text.white',
    subText: 'text.white',
    background: 'grand.red',
  },
  {
    mainText: 'text.white',
    subText: 'text.white',
    background: 'grand.purple',
  },
  {
    mainText: 'text.white',
    subText: 'text.white',
    background: 'grand.blackLight',
  },
]
