import { Text } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

export type AppTagTextProps = {
  label: string
}

export const AppTagText: React.FC<AppTagTextProps> = ({ label }) => (
  <Text
    w="fit-content"
    px="8px"
    py="2px"
    fontSize={p2r(14)}
    fontWeight="700"
    color="#494c4f"
    border="1px solid #dfe1e4"
    borderRadius="8px"
    backgroundColor="grand.white"
  >
    {label}
  </Text>
)
