import {
  Text,
  Flex,
  Box,
  FormControl,
  Input,
  FormLabel,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useForm, UseFormRegister, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useAppConfirm } from '../../components/common/AppConfirm/useAppConfirm'
import { AppErrorAlert } from '../../components/common/AppErrorAlert'
import { MypageButton } from '../../components/mypage/MypageButton'
import { useAuthContext } from '../../context/AuthContext'
import { useUserUpdateEmailMutation } from '../../hooks/coreApi/useUserUpdateEmailMutation'
import { useUserUpdateEmailVerifyMutation } from '../../hooks/coreApi/useUserUpdateEmailVerifyMutation'
import useFormatMessage from '../../hooks/useFormatMessage'
import { p2r } from '../../utils/font'

type MypageEditFormInput = {
  email: string
  emailConfirm: string
  code: string
}

/**
 * テキスト入力
 */
type InputTextProps = {
  label: string
  bgColor?: string
}
const InputText = React.forwardRef<
  HTMLInputElement,
  InputTextProps & ReturnType<UseFormRegister<MypageEditFormInput>>
>((props, ref) => (
  <Flex
    py="16px"
    backgroundColor={props.bgColor ? props.bgColor : 'grand.white'}
    flexDirection="column"
    w="100%"
    minW="242px"
    rowGap="8px"
  >
    <FormLabel
      fontSize={p2r(16)}
      lineHeight="150%"
      fontWeight="400"
      color="text.sub"
      mb="0"
      htmlFor={props.label}
    >
      {props.label}
    </FormLabel>
    <Input
      ref={ref}
      name={props.name}
      onChange={props.onChange}
      onBlur={props.onBlur}
      type="text"
      p="16px"
      lineHeight="150%"
      fontSize={p2r(24)}
      fontWeight="400"
      color="text.body"
      variant="unstyled"
      border="1px solid"
      borderColor="grand.grayLight"
      borderRadius="10px"
    />
  </Flex>
))

const MypageUpdateEmail: React.FC = () => {
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  const updateEmailMutation = useUserUpdateEmailMutation()
  const updateEmailVerifyMutation = useUserUpdateEmailVerifyMutation()
  const toast = useToast()
  const appConfirm = useAppConfirm()
  const { me, refetchMe } = useAuthContext()

  const form = useForm<MypageEditFormInput>()

  // フォームの初期値セット
  useEffect(() => {
    if (!me) {
      return
    }
    form.reset({
      email: '',
      emailConfirm: '',
      code: '',
    })
  }, [me])

  const onSubmit: SubmitHandler<MypageEditFormInput> = (values) => {
    // プロフィール更新処理
    updateEmailMutation.mutate(
      {
        userUpdateEmailRequest: {
          email: values.email,
        },
      },
      {
        async onSuccess() {
          if (form.getValues('email') !== form.getValues('emailConfirm')) {
            toast({
              title: '確認用Eメールアドレスが一致しません。',
              status: 'error',
            })
            return
          }
          const confirmOk = await appConfirm.open({
            title: '確認用コードを入力',
            body: (
              <Input
                onChange={(evt) => {
                  form.setValue('code', evt.target.value)
                }}
              />
            ),
          })

          if (!confirmOk) {
            return
          }

          updateEmailVerifyMutation.mutate(
            {
              userUpdateEmailVerifyRequest: {
                authCode: form.getValues('code'),
              },
            },
            {
              onSuccess() {
                toast({
                  title: 'Eメールアドレスを変更しました。',
                })
                refetchMe()
                navigate('/mypage/profile')
              },
            },
          )
        },
      },
    )
  }

  if (!me) {
    return null
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <FormControl>
        <Box px={{ base: '16px', md: '32px' }} py={{ base: '0', md: '32px' }}>
          <Flex
            justifyContent={{ base: 'unset', md: 'space-between' }}
            align="center"
            direction={{ base: 'column', md: 'row' }}
            rowGap="8px"
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              columnGap="16px"
              order={{ base: '2', md: '1' }}
              mr={{ base: 'auto', md: 'unset' }}
            >
              <Text color="text.body" fontSize={p2r(24)} fontWeight="700">
                Eメールアドレスの変更
              </Text>
            </Flex>
          </Flex>
          <Flex pt="16px" direction="column">
            {updateEmailMutation.isError && (
              <AppErrorAlert error={updateEmailMutation.error} />
            )}
            {updateEmailVerifyMutation.isError && (
              <AppErrorAlert error={updateEmailVerifyMutation.error} />
            )}
            <InputText
              required
              label={f('新しいEメールアドレス')}
              {...form.register('email')}
            />
            <InputText
              required
              label={f('新しいEメールアドレス(確認用)')}
              {...form.register('emailConfirm')}
            />
            <MypageButton
              mt="4"
              type="submit"
              onClick={() => {
                form.handleSubmit(onSubmit)
              }}
            >
              {f('変更する')}
            </MypageButton>
          </Flex>
        </Box>
      </FormControl>
    </form>
  )
}

export default MypageUpdateEmail
