import {
  Box,
  Flex,
  Text,
  Grid,
  GridItem,
  Image,
  HStack,
  Icon,
  useBreakpointValue,
  useDisclosure,
  ModalContent,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import {
  MdOutlineLocalTaxi,
  MdOutlinePinDrop,
  MdSchedule,
  MdOutlineArrowForwardIos,
  MdLocalTaxi,
  // MdPinDrop,
  MdOutlineSchedule,
  MdLocationPin,
  MdDone,
  MdHourglassEmpty,
  MdCancel,
} from 'react-icons/md'
import nl2br from 'react-nl2br'
import { Link, useNavigate } from 'react-router-dom'
import { useAppConfirm } from '../../../components/common/AppConfirm/useAppConfirm'
import { TaxiButton } from '../../../components/taxi/TaxiButton'
import { TaxiSectionTitle } from '../../../components/taxi/TaxiSectionTitle'
import { useAuthContext } from '../../../context/AuthContext'
import { useTaxiReservationListQuery } from '../../../hooks/coreApi/useTaxiReservationListQuery'
import useFormatMessage from '../../../hooks/useFormatMessage'
import useHeaderConfig from '../../../hooks/useHeaderConfig'
import { TaxiReservationStatus } from '../../../models/taxiReservation'
import { p2r } from '../../../utils/font'

// 左側のコンポーネント
export type ReservationSelectProps = {
  image: string
  label: string
  note: string
  onClick(): void
  isReverse?: boolean
}
const ReservationSelect: React.FC<ReservationSelectProps> = (props) => {
  const { image, label, note, isReverse, onClick } = props
  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <GridItem onClick={onClick}>
      <Box
        bgColor={{
          base: isReverse ? 'theme.primary' : 'grand.white',
          md: 'grand.white',
        }}
        borderWidth={isMobile ? '2px' : '4px'}
        borderColor="rgba(106, 164, 59, 0.8)"
        rounded={isMobile ? '4px' : '8px'}
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.2);"
        display={isMobile ? 'flex' : 'block'}
        flexDirection={isMobile ? 'row' : 'unset'}
        justifyContent={isMobile ? 'center' : 'unset'}
        alignItems={isMobile ? 'center' : 'unset'}
        columnGap={isMobile ? '16px' : 'unset'}
      >
        {!isMobile && (
          <Image
            src={image}
            w="100%"
            mx="auto"
            borderRadius="4px 4px 0px 0px"
          />
        )}
        <Flex
          justify="center"
          borderTopWidth={{ base: '0px', md: '4px' }}
          borderTopColor="rgba(106, 164, 59, 0.8)"
        >
          <Text
            py={{ base: '6px', md: '6px' }}
            fontSize={{ base: p2r(24), md: p2r(28) }}
            color={{
              base: isReverse ? 'grand.white' : 'theme.primary',
              md: 'text.headline',
            }}
            fontWeight="700"
          >
            {label}
          </Text>
        </Flex>
      </Box>
      {!isMobile && (
        <Text
          mt="16px"
          textAlign="center"
          fontSize={{ base: p2r(14), md: p2r(16) }}
          fontWeight="700"
          color="grand.gray"
        >
          {note}
        </Text>
      )}
    </GridItem>
  )
}

type ReservationCardLabelProps = {
  status: TaxiReservationStatus
}
export const ReservationCardLabel: React.FC<ReservationCardLabelProps> = (
  props,
) => {
  let statusText = ''
  let icon = MdDone
  switch (props.status) {
    case 'fixed':
      statusText = '確定'
      icon = MdDone
      break
    case 'draft':
      statusText = '予約確認中'
      icon = MdHourglassEmpty
      break
    case 'rejected':
      statusText = '予約受付不可'
      icon = MdCancel
      break
    case 'canceled':
      statusText = 'キャンセル済'
      icon = MdCancel
      break
    default:
  }
  const isFixed = props.status === 'fixed'

  return (
    <Flex
      alignItems="center"
      p="4px 8px"
      bgColor={isFixed ? 'theme.primary' : 'none'}
      border={isFixed ? 'none' : '1px solid'}
      borderColor={isFixed ? 'none' : 'grand.grayLight'}
      rounded={{ base: '4px', md: '8px' }}
      columnGap="4px"
      w="fit-content"
      h="fit-content"
    >
      <Icon
        as={icon}
        boxSize={{ base: '16px', md: '24px' }}
        color={isFixed ? 'text.white' : 'grand.gray'}
      />
      <Text
        fontSize={{ base: p2r(12), md: p2r(14) }}
        color={isFixed ? 'text.white' : 'text.body'}
        fontWeight="700"
        lineHeight="150%"
      >
        {statusText}
      </Text>
    </Flex>
  )
}

export type ReservationCardProps = {
  taxiReservationId: number
  status: TaxiReservationStatus
  company: string
  from: string
  to: string
  month: number
  date: number
  day: string
  hour: number
  minute: number
}
const ReservationCard: React.FC<ReservationCardProps> = (props) => {
  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Link to={`/taxi/reservations/${props.taxiReservationId}`}>
      <Flex
        borderWidth="2px"
        borderColor={{ base: 'grand.grayLight', md: 'theme.primary' }}
        rounded="10px"
        p={{ base: '8px 16px', md: '12px 8px' }}
        direction={{ base: 'row', md: 'column' }}
        columnGap={{ base: '8px', md: 'unset' }}
        align={{ base: 'center', md: 'unset' }}
        backgroundColor="grand.white"
      >
        <Flex
          direction={{ base: 'row', md: 'column' }}
          align={{ base: 'center', md: 'unset' }}
          columnGap={{ base: '8px', md: 'unset' }}
        >
          {isMobile && <ReservationCardLabel status={props.status} />}
          <Flex direction="column" rowGap="8px" flex="1">
            <Flex alignItems="center" gap="8px">
              <Icon
                as={isMobile ? MdLocalTaxi : MdOutlineLocalTaxi}
                boxSize="24px"
                color={{ base: 'grand.gray', md: '#1C1B1F' }}
              />
              <Flex
                wrap="wrap"
                align="center"
                columnGap={{ base: '4px', md: '8px' }}
                rowGap="4px"
                flex="1"
              >
                {!isMobile && <ReservationCardLabel status={props.status} />}
                <Text
                  fontSize={p2r(16)}
                  color="text.sub"
                  fontWeight={{ base: '700', md: '400' }}
                >
                  {props.company}
                </Text>
              </Flex>
            </Flex>
            <Flex alignItems="center" gap="8px">
              <Icon
                as={isMobile ? MdLocationPin : MdOutlinePinDrop}
                boxSize="24px"
                color={{ base: 'grand.gray', md: '#1C1B1F' }}
              />
              <Text>
                <Text
                  as="span"
                  fontSize={p2r(16)}
                  color="text.sub"
                  fontWeight={{ base: '700', md: '400' }}
                >
                  {props.from}
                </Text>
                <Text as="span" fontSize={p2r(16)} color="text.sub">
                  →
                </Text>
                <Text
                  as="span"
                  fontSize={{ base: p2r(16), md: p2r(18) }}
                  color="text.headline"
                  fontWeight="700"
                >
                  {props.to}
                </Text>
              </Text>
            </Flex>
            <Flex alignItems="center" gap="8px">
              <Icon
                as={isMobile ? MdSchedule : MdOutlineSchedule}
                boxSize="24px"
                color={{ base: 'grand.gray', md: '#1C1B1F' }}
              />
              <Flex display="flex" flexWrap="wrap">
                <Box>
                  <Text
                    as="span"
                    fontSize={{ base: p2r(16), md: p2r(20) }}
                    color="text.headline"
                    fontWeight="700"
                  >
                    {props.month}
                  </Text>
                  <Text
                    as="span"
                    fontSize={{ base: p2r(12), md: p2r(16) }}
                    color="text.body"
                    fontWeight={{ base: '700', md: '400' }}
                  >
                    {f('月')}
                  </Text>
                  <Text
                    as="span"
                    fontSize={{ base: p2r(16), md: p2r(20) }}
                    color="text.headline"
                    fontWeight="700"
                  >
                    {props.date}
                  </Text>
                  <Text
                    as="span"
                    fontSize={{ base: p2r(12), md: p2r(16) }}
                    color="text.body"
                    fontWeight={{ base: '700', md: '400' }}
                  >
                    {f('日')}
                  </Text>
                  <Text
                    as="span"
                    fontSize={{ base: p2r(12), md: p2r(16) }}
                    color="text.body"
                    fontWeight={{ base: '700', md: '400' }}
                  >
                    ({props.day})
                  </Text>
                </Box>
                <Box>
                  <Text
                    as="span"
                    fontSize={{ base: p2r(16), md: p2r(20) }}
                    color="text.headline"
                    fontWeight="700"
                  >
                    {String(props.hour).padStart(2, '0')}:
                    {String(props.minute).padStart(2, '0')}
                  </Text>
                  <Text
                    as="span"
                    fontSize={{ base: p2r(12), md: p2r(16) }}
                    color="text.body"
                    fontWeight={{ base: '700', md: '400' }}
                  >
                    {f('発')}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {isMobile && (
          <Icon
            as={MdOutlineArrowForwardIos}
            boxSize="18px"
            color="theme.primary"
          />
        )}
      </Flex>
    </Link>
  )
}

// トップ
const TaxiTop: React.FC = () => {
  useHeaderConfig({
    title: 'タクシー予約',
  })
  const { f } = useFormatMessage()
  const navigate = useNavigate()
  const appConfirm = useAppConfirm()
  const discountModalDisclosure = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, md: false })

  const { me } = useAuthContext()

  const taxiReservationListQuery = useTaxiReservationListQuery({
    userId: me?.id,
  })

  // TODO APIでフィルタ済みを取得
  const myTaxiReservations = useMemo(
    () =>
      taxiReservationListQuery.data?.taxiReservations?.filter((r) => {
        if (
          r.userId === me?.id &&
          ['draft', 'fixed', 'rejected', 'canceled'].includes(r.status) &&
          dayjs(r.rideAt).isAfter(dayjs())
        ) {
          return true
        }
        return false
      }) || [],
    [me, taxiReservationListQuery],
  )

  let reservationSideText = ''

  if (!me?.taxiDiscountFlag) {
    reservationSideText = f(
      "まちのクルマLet'sの利用登録がされていません。\nマイナンバーカードとパスワード（マイナンバーカード登録時に設定している6桁のパスワード）をご持参いただき、神山町役場・広野支所・各公民館にて利用登録を行ってください。",
    )
  } else if (myTaxiReservations.length > 0) {
    reservationSideText =
      myTaxiReservations.length + f('件の予約状況をご確認ください')
  } else {
    reservationSideText = f('現在の予約はありません')
  }

  return (
    <>
      <Flex
        className="test"
        backgroundColor="theme.background"
        h={{ base: 'unset', md: 'calc(100vh - 56px)' }}
        minH={{ base: 'unset', md: 'calc(100vh - 56px)' }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Box
          p={isMobile ? '16px' : '40px 89px 30px 33px'}
          w={isMobile ? '100%' : 'calc(100% - 328px)'}
          flex="1"
          order={isMobile ? '2' : '1'}
          backgroundColor={isMobile ? 'grand.white' : 'unset'}
          boxShadow={isMobile ? '0px 0px 10px rgba(0, 0, 0, 0.1)' : 'unset'}
          display="flex"
          flexDirection="column"
        >
          {!isMobile && (
            <TaxiSectionTitle
              label={f('タクシーの予約方法を選択してください。')}
            />
          )}
          <Grid
            gap={{ base: '8px', md: '48px' }}
            templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
            flex="1"
            alignItems={{ base: 'unset', md: 'center' }}
          >
            <ReservationSelect
              onClick={() => {
                navigate('/taxi/reservations/tel')
              }}
              image="/images/taxi/tell.svg"
              label={f('電話で予約')}
              note={f('電話もしくはビデオ通話で予約できます')}
            />
            <ReservationSelect
              onClick={() => {
                // タクシー割引登録なしはブロック
                if (!me?.taxiDiscountFlag) {
                  appConfirm.open({
                    title: 'タクシー割引について',
                    body: (
                      <>
                        オンライン予約はまちのクルマLet&apos;sの利用登録がされていないとご利用いただけません。
                      </>
                    ),
                  })
                  return
                }

                navigate('/taxi/reservations/create')
              }}
              image="/images/taxi/calendar.svg"
              label={f('オンラインで予約')}
              note={f('いつでも仮予約ができます')}
              isReverse
            />
          </Grid>
        </Box>

        <Flex
          flexDirection="column"
          w={isMobile ? '100%' : '328px'}
          backgroundColor={isMobile ? 'unset' : 'grand.white'}
          p={isMobile ? '16px 16px 38px' : '42px 24px'}
          order={isMobile ? '1' : '2'}
          overflowY="auto"
        >
          <HStack
            spacing="2px"
            pb={{ base: '16px', md: '26px' }}
            justifyContent={{ base: 'flex-start', md: 'center' }}
          >
            <Text
              fontSize={{ base: p2r(16), md: p2r(19) }}
              color={{ base: 'text.sub', md: 'text.body' }}
              fontWeight={{ base: '400', md: '700' }}
              lineHeight="1.3em"
            >
              {nl2br(reservationSideText)}
            </Text>
          </HStack>
          <Flex direction="column" rowGap={{ base: '8px', md: '16px' }}>
            {myTaxiReservations.map((r) => (
              <ReservationCard
                status={r.status as TaxiReservationStatus}
                company={r.taxiCompany?.name || ''}
                from={r.startAddress || `${r.startTaxiLandmark?.name}`}
                to={r.endAddress || `${r.endTaxiLandmark?.name}`}
                month={dayjs(r.rideAt).month() + 1}
                date={dayjs(r.rideAt).date()}
                day={dayjs(r.rideAt).format('ddd')}
                hour={dayjs(r.rideAt).hour()}
                minute={dayjs(r.rideAt).minute()}
                taxiReservationId={r.id}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Modal
        isOpen={discountModalDisclosure.isOpen}
        onClose={discountModalDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>タクシー割引について</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            まちのクルマLet&apos;sの割引適用に関しては、別途利用申請を行う必要があります。利用申請をしていない方は割引は適用されず通常料金での利用となります。
          </ModalBody>
          <ModalFooter>
            <TaxiButton onClick={discountModalDisclosure.onClose}>
              {f('確認しました')}
            </TaxiButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TaxiTop
