// YoutubeのURLからIDを取得

export const getYoutubeThumbnailUrl = (youtubeUrl?: string | null): string => {
  if (!youtubeUrl) {
    return ''
  }

  const regExp =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=|v\/)|youtu\.be\/)([^\s&?\\/]+)/
  const match = youtubeUrl.match(regExp)

  let videoId = ''
  if (match && match[1]) {
    // eslint-disable-next-line prefer-destructuring
    videoId = match[1]
  }

  const url = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
  return url
}
