import {
  FormControl,
  FormErrorMessage,
  Input,
  InputProps,
} from '@chakra-ui/react'
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form'

export type AppFormTextProps<T extends FieldValues> = InputProps & {
  name: FieldPath<T>
}

const AppFormText = <T extends FieldValues>({
  name,
  ...other
}: AppFormTextProps<T>) => {
  const {
    control: { register },
    getFieldState,
  } = useFormContext()

  const { error } = getFieldState(name)

  return (
    <FormControl isInvalid={!!error?.message}>
      <Input {...register(name)} fullWidth {...other} />
      <FormErrorMessage>{error?.message || ''}</FormErrorMessage>
    </FormControl>
  )
}

export default AppFormText
