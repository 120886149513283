import { TaxiReservationApiTaxiReservationCreateByUserRequest } from '../../openapi/api'
import appApiClient from './appApiClient'
import useBaseMutation from './useBaseMutation'

export const useTaxiReservationCreateByUserMutation = () =>
  useBaseMutation({
    mutationFn: (
      req: TaxiReservationApiTaxiReservationCreateByUserRequest,
      config,
    ) =>
      appApiClient.taxiReservationApi.taxiReservationCreateByUser(req, config),
  })
