import { Button, ButtonProps, Icon } from '@chakra-ui/react'
import React from 'react'
import { MdBlock, MdMail, MdPhoneInTalk, MdSend } from 'react-icons/md'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { p2r } from '../../../utils/font'

type VideoStatusButtonProps = {
  status: 'accepted' | 'blocked' | 'in_waiting' | 'in_request'
} & ButtonProps
const VideoStatusButton: React.FC<VideoStatusButtonProps> = (props) => {
  const { status, ...rest } = props
  const { f } = useFormatMessage()
  const BUTTON_TYPES = [
    {
      status: 'accepted',
      icon: MdPhoneInTalk,
      label: f('通話する'),
      bgColor: 'theme.primary',
      borderColor: 'theme.primary',
      textColor: '#FFFDFC',
    },
    {
      status: 'in_request',
      icon: MdMail,
      label: f('承認待ち'),
      bgColor: 'grand.white',
      borderColor: 'theme.primary',
      textColor: 'theme.primary',
    },
    {
      status: 'in_waiting',
      icon: MdSend,
      label: f('申請中'),
      bgColor: 'grand.white',
      borderColor: 'transparent',
      textColor: 'theme.primary',
    },
    {
      status: 'blocked',
      icon: MdBlock,
      label: f('ブロック中'),
      bgColor: 'grand.white',
      borderColor: '#C84545',
      textColor: '#C84545',
    },
  ]

  const currentType = BUTTON_TYPES.find((type) => type.status === status)
  return (
    <Button
      borderWidth="2px"
      fontSize={p2r(16)}
      fontWeight="700"
      lineHeight="150%"
      rounded="80px"
      minW={p2r(148)}
      iconSpacing="10px"
      display="flex"
      variant="unstyled"
      leftIcon={
        <Icon as={currentType?.icon} boxSize={{ base: '16px', md: '24px' }} />
      }
      bgColor={currentType?.bgColor}
      borderColor={currentType?.borderColor}
      color={currentType?.textColor}
      {...rest}
    >
      {currentType?.label}
    </Button>
  )
}

export default VideoStatusButton
