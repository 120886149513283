import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Grid,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../context/AuthContext'
import { useUserPersistConfigContext } from '../../../context/UserPersistConfigContext'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { UserConfigFontSize } from '../../../models/user'
import { isProd } from '../../../utils/common'
import { p2r } from '../../../utils/font'
import NavBousai from '../../svg/NavBousai'
import NavMypage from '../../svg/NavMypage'
import NavNotification from '../../svg/NavNotification'
import NavPractice from '../../svg/NavPractice'
import NavShikyo from '../../svg/NavShikyo'
import NavTaxi from '../../svg/NavTaxi'
import NavVideo from '../../svg/NavVideo'
import { AppNavigationCard } from '../AppNavigationList'

const CloseButton: React.FC<ButtonProps> = (props) => {
  const { f } = useFormatMessage()
  const { ...rest } = props
  return (
    <Button
      w="fit-content"
      h={{ base: '64px', md: p2r(90) }}
      pl={p2r(21)}
      pr={p2r(44)}
      py={p2r(16)}
      display="flex"
      alignItems="center"
      variant="unstyled"
      background="rgba(255, 255, 255, 0.4)"
      border="1px solid rgba(0, 0, 0, 0.1)"
      borderRadius="96px"
      columnGap={p2r(28)}
      {...rest}
    >
      <Icon
        as={MdOutlineCancel}
        boxSize={{ base: p2r(32), md: p2r(56) }}
        color="grand.white"
      />
      <Text
        color="text.white"
        fontSize={{ base: p2r(24), md: p2r(32) }}
        fontWeight="700"
      >
        {f('閉じる')}
      </Text>
    </Button>
  )
}

// コンテンツ一覧 -> モーダル内の一覧
const useSetModalContents = () => {
  const { f } = useFormatMessage()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { me } = useAuthContext()

  const contents = useMemo(() => {
    const newContents = [
      {
        id: 1,
        path: '/taxi',
        title: f('予約'),
        imgPath: NavTaxi,
        bg: 'linear-gradient(360deg, #216542 0%, #144F35 100%)',
        border: 'rgba(33, 101, 66, 0.8)',
        iconColor: 'grand.white',
      },
      {
        id: 2,
        path: isMobile ? '/video/private' : '/video',
        title: f('ビデオ通話'),
        imgPath: NavVideo,
        bg: 'linear-gradient(360deg, #FF912B 0%, #FF7A00 100%)',
        border: 'rgba(255, 145, 43, 0.8)',
      },
      {
        id: 3,
        path: '/bousai',
        title: f('安心・安全'),
        imgPath: NavBousai,
        bg: 'linear-gradient(360deg, #1A82DD 0%, #0B74CF 100%)',
        border: 'rgba(26, 130, 221, 0.8)',
        iconColor: '#1A82DD',
      },
      {
        id: 4,
        path: '/shikyo',
        title: f('市況情報'),
        imgPath: NavShikyo,
        bg: 'linear-gradient(0deg, #54CF51 0%, #46C21A 100%)',
        border: 'rgba(106, 223, 103, 0.8)',
      },
      {
        id: 5,
        path: '/notification',
        title: f('通知'),
        imgPath: NavNotification,
        bg: '#ffffff',
        border: 'rgba(245, 179, 80, 0.8)',
      },
      {
        id: 6,
        path: 'https://game.cir-cle.app/3x3.html',
        title: f('あそぶ・まなぶ'),
        imgPath: NavPractice,
        bg: '#ffffff',
        border: 'none',
      },
      {
        id: 7,
        path: isMobile ? '/mypage' : '/mypage/profile',
        title: f('マイページ'),
        imgPath: NavMypage,
        bg: '#ffffff',
        border: 'none',
      },
    ]

    // タクシー会社ユーザーのみ表示
    if (me && me.taxiCompanyId) {
      newContents.push({
        id: 7,
        path: '/admin/taxi/calendar',
        title: f('タクシー管理'),
        imgPath: NavMypage,
        bg: '#ffffff',
        border: 'none',
      })
    }

    return newContents
  }, [me])

  return contents
}

const COLUMNS_INFO: { [key in number]: any } = {
  // 小
  [UserConfigFontSize.SMALL]: {
    columns: 3,
    rows: 2,
    rowGap: '24px',
    columnGap: '16px',
    imgW: '20vh',
    h: '15.4vh',
  },
  // 中
  [UserConfigFontSize.MEDIUM]: {
    columns: 3,
    rows: 2,
    rowGap: '2.9vh',
    columnGap: '27px',
    imgW: '30vh',
    h: '21.1vh',
  },
  // 大
  [UserConfigFontSize.LARGE]: {
    columns: 2,
    rows: 4,
    rowGap: '24px',
    columnGap: '24px',
    imgW: '65.7vh',
    h: '21.1vh',
  },
}

export type AppContentMenuModalProps = {
  isOpen: boolean
  onClose(): void
}
export const AppContentMenuModal: React.FC<AppContentMenuModalProps> = (
  props,
) => {
  const navigationModalContents = useSetModalContents()
  const navigate = useNavigate()
  const { userPersistConfig } = useUserPersistConfigContext()

  const columnsInfo = COLUMNS_INFO[userPersistConfig.cnfFontSize]
  const isFontMedium =
    userPersistConfig.cnfFontSize === UserConfigFontSize.MEDIUM
  const isFontLarge = userPersistConfig.cnfFontSize === UserConfigFontSize.LARGE
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
    >
      <ModalOverlay background="blackAlpha.600" backdropFilter="blur(10px)" />
      <ModalContent
        my="0"
        pt={{ base: '24px', md: isFontMedium ? '80px' : '0px' }}
        background="none"
        boxShadow="none"
        position="relative"
        h="100%"
        display="flex"
        alignItems="center"
        justifyContent={{
          base: 'unset',
          md: isFontMedium ? 'unset' : 'center',
        }}
      >
        <Grid
          gridTemplateColumns={{
            base: 'repeat(2, 1fr)',
            md: `repeat(${columnsInfo.columns}, 1fr)`,
          }}
          gridTemplateRows={{
            base: 'repeat(4, 1fr)',
            md: `repeat(${columnsInfo.rows}, 1fr)`,
          }}
          columnGap={{ base: '16px', md: `${columnsInfo.columnGap}` }}
          rowGap={{ base: '16px', md: `${columnsInfo.rowGap}` }}
          gridAutoFlow={{ base: 'row', md: isFontLarge ? 'column' : 'row' }}
        >
          {navigationModalContents.map((content) => (
            <AppNavigationCard
              path={content.path}
              title={content.title}
              imgPath={content.imgPath}
              imgW={columnsInfo.imgW}
              h={columnsInfo.h}
              bg={content.bg}
              border={content.border}
              iconColor={content.iconColor || ''}
              key={content.id}
              onClick={() => {
                // モーダルを閉じて遷移
                navigate(content.path)
                props.onClose()
              }}
              isFontLarge={isFontLarge}
            />
          ))}
          <Flex align="center" justify="center">
            <CloseButton
              position={{
                base: 'absolute',
                md: isFontLarge ? 'relative' : 'absolute',
              }}
              top={{ base: 'auto', md: isFontLarge ? '0' : 'auto' }}
              bottom={{ base: '24px', md: isFontLarge ? '0' : '40px' }}
              left={{ base: '50%', md: isFontLarge ? '0' : '50%' }}
              transform={{
                base: 'translateX(-50%)',
                md: isFontLarge ? 'unset' : 'translateX(-50%)',
              }}
              onClick={props.onClose}
            />
          </Flex>
        </Grid>
        {/* デバッグ画面リンク 本番以外で表示 */}
        {!isProd() && (
          <Box
            position="absolute"
            bottom="6"
            right="0"
            fontSize="20"
            zIndex="9999"
            p="4"
            color="white"
          >
            <Button
              variant="ghost"
              onClick={() => {
                // モーダルを閉じて遷移
                navigate('/debug')
                props.onClose()
              }}
            >
              デバッグ
            </Button>
          </Box>
        )}
      </ModalContent>
    </Modal>
  )
}
