import JsonView from '@uiw/react-json-view'
import useDebugUtil from '../../../hooks/coreApi/useDebugUtil'

type AppDebugJsonViewProps = {
  object: any
}

export default function AppDebugJsonView({ object }: AppDebugJsonViewProps) {
  const { isDebugUser } = useDebugUtil()
  if (isDebugUser === false) {
    return null
  }

  return <JsonView value={object} />
}
