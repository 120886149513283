import { useQuery, UseQueryResult } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { usePapaParse } from 'react-papaparse'

const MARKET_URL = `${process.env.REACT_APP_CLOUDFRONT_ASSET_BASE_URL}/1/tokushimaSftp/market.csv`
const MARKET_CSV_HEADER = [
  'ID',
  'ソートキー',
  '画面制御',
  '公開期間　開始',
  '公開期間　終了',
  '品目ID',
  '品目名',
  '荷姿',
  '売立日',
  '等級階級ラベル',
  '等級1',
  '等級2',
  '等級3',
  '階級1',
  '価格11',
  '価格12',
  '価格13',
  '階級2',
  '価格21',
  '価格22',
  '価格23',
  '階級3',
  '価格31',
  '価格32',
  '価格33',
  '階級4',
  '価格41',
  '価格42',
  '価格43',
  '階級5',
  '価格51',
  '価格52',
  '価格53',
  '出荷量ラベル',
  '出荷1',
  '出荷2',
  '出荷3',
  '備考',
  '登録日',
  '登録者',
  '最終更新日',
  '最終更新者',
  '表示順',
]

const useShikyoQuery = <TRes = any>(): UseQueryResult<TRes, AxiosError> => {
  const { readString } = usePapaParse()

  return useQuery({
    queryKey: [MARKET_URL],
    queryFn: async () => {
      // CSV取得（文字コード指定）
      const { data: csvData } = await axios.get(MARKET_URL, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'text/csv;',
        },
      })
      // csvからテキスト形式でデコード（文字コード指定）
      const csvToTextValue = new TextDecoder('Shift_JIS').decode(csvData)

      // ヘッダー情報を付与
      const csvHeaderToTextValue = MARKET_CSV_HEADER.join(',').concat('\n')
      const formatCsvToTextValue = csvHeaderToTextValue + csvToTextValue

      // textからJson形式にパース
      const textToJson = new Promise((resolve) => {
        const records: any = []
        // ヘッダーをjsonのkeyとしてパース
        readString(formatCsvToTextValue, {
          header: true,
          worker: true,
          complete: ({ data: jsonData }) => {
            records.push(...jsonData)
            resolve(records)
          },
        })
      })

      return textToJson.then((value) => value)
    },
    cacheTime: 0, // react-queryのcacheがデフォルト5分になっているため、OFFにする（ログイン情報などのキャッシュを防ぐため）
  }) as UseQueryResult<TRes, AxiosError>
}

export default useShikyoQuery
