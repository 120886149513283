import {
  Text,
  Flex,
  Image,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useBreakpointValue,
  Box,
  Tooltip,
  SliderMark,
  useMediaQuery,
  // IconButton,
} from '@chakra-ui/react'
import axios from 'axios'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
// import { MdPlayArrow, MdStop } from 'react-icons/md'
import { AppLoading } from '../../components/common/AppLoading'
import { AppSegmentedPicker } from '../../components/common/AppSegmentedPicker'
import { WeatherWarning } from '../../components/weather/Warning'
import useFormatMessage from '../../hooks/useFormatMessage'
import {
  usePastWeatherRadarQuery,
  useFutureWeatherRadarQuery,
} from '../../hooks/weatherApi/useWeatherRadarQuery'
import { p2r } from '../../utils/font'
import TempChart from './TempChart.png'
import TokushimaMap from './TokushimaMap.png'

const WeatherRadar: React.FC = () => {
  // 注意報警報
  const warning = false
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [isTablet] = useMediaQuery('(max-width: 1024px)')

  const { f } = useFormatMessage()
  type T = {
    basetime: number
    validtime: number
    elements: string
  }
  const [pastTimes, setPastTimes] = useState<Array<T>>([])
  const [futureTimes, setFutureTimes] = useState<Array<T>>([])
  const [showTooltip, setShowTooltip] = React.useState(false)
  const [radarTime, setRadarTime] = useState<string>(
    moment().format('HH時mm分'),
  )
  const [weatherRadarMap, setWeatherRadarMap] = useState<string>('')
  const [futureUrlList, setFutureUrlList] = useState<string[]>([])
  const [pastUrlList, setPastUrlList] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>()
  const [sliderValue, setSliderValue] = useState<number>(0)

  // const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)

  // 5分毎、過去3時間分取得
  useEffect(() => {
    axios
      .get('https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N1.json')
      .then((res) => setPastTimes(res.data))
  }, [])

  // 5分毎、未来1時間分取得
  useEffect(() => {
    axios
      .get('https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N2.json')
      .then((res) => setFutureTimes(res.data))
  }, [])

  // スライダーの値に伴う雨雲図を表示
  const getWeatherRadarMap = () => {
    const number = Math.floor(sliderValue / 5)
    if (number >= 0) {
      const url: string = futureUrlList[number]
      return url
    }
    const pastNumber = Math.abs(number) // 配列から取得する為、絶対値に変換
    const url: string = pastUrlList[pastNumber]
    return url
  }

  // スライダーの値の変化によって表示される雨雲図と時間を変更
  useEffect(() => {
    const weatherRadarURL: any = getWeatherRadarMap()
    setWeatherRadarMap(weatherRadarURL)
    const sliderTime = moment().add(sliderValue, 'minutes').format('HH時mm分')
    setRadarTime(sliderTime)
  }, [sliderValue])

  // useEffect(() => {
  //   if (intervalId) {
  //     const cleanup = () => clearInterval(intervalId)
  //     return cleanup
  //   }

  //   return undefined
  // }, [intervalId])

  // 2次元配列を1次元配列に入れ直す
  const pastBaseTimes: number[] = []
  const pastValidTimes: number[] = []
  pastTimes.map((pastTime) => {
    pastBaseTimes.push(pastTime.basetime)
    pastValidTimes.push(pastTime.validtime)
    return undefined
  })

  const futureBaseTimes: number[] = []
  const futureValidTimes: number[] = []
  futureTimes.map((futureTime) => {
    futureBaseTimes.push(futureTime.basetime)
    futureValidTimes.push(futureTime.validtime)
    return undefined
  })

  // 未来の雨雲図を取得、リスト化
  const weatherRadarQuery = useFutureWeatherRadarQuery(
    futureBaseTimes,
    futureValidTimes,
  )

  useEffect(() => {
    if (weatherRadarQuery.data) {
      setFutureUrlList(weatherRadarQuery.data)
      setIsLoading(weatherRadarQuery.isLoading)
    }
  }, [weatherRadarQuery.data])

  // 過去の雨雲図を取得、リスト化
  const pastWeatherRadarQuery = usePastWeatherRadarQuery(
    pastBaseTimes,
    pastValidTimes,
  )

  useEffect(() => {
    if (pastWeatherRadarQuery.data) {
      setPastUrlList(pastWeatherRadarQuery.data)
      setIsLoading(pastWeatherRadarQuery.isLoading)
    }
  }, [pastWeatherRadarQuery.data])

  if (isLoading === undefined) {
    return <AppLoading />
  }

  const onChangeValue = (val: number) => {
    // touchイベントの場合、valがスライダーの範囲を超えないようにする
    if (val < -180 || val > 50) {
      return
    }
    setSliderValue(val)
  }

  // const handlePlayClick = () => {
  //   if (!intervalId) {
  //     const id = setInterval(() => {
  //       setSliderValue((prevValue) => {
  //         if (prevValue === 60) {
  //           return -180
  //         }
  //         return prevValue + 1
  //       })
  //     }, 100)
  //     setIntervalId(id)
  //   }
  // }

  // const handleStopClick = () => {
  //   clearInterval(intervalId!)
  //   setIntervalId(null)
  // }

  const minValue = -179
  const maxValue = 59

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    const touch = event.touches[0]
    const rect = event.currentTarget.getBoundingClientRect()
    const x = touch.clientX - rect.left
    const percentage = (x / rect.width) * 100
    const newValue = Math.round(
      (percentage / 100) * (maxValue - minValue) + minValue,
    )
    setShowTooltip(true)
    onChangeValue(newValue)
  }

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    const touch = event.touches[0]
    const rect = event.currentTarget.getBoundingClientRect()
    const x = touch.clientX - rect.left
    const percentage = (x / rect.width) * 100
    const newValue = Math.round(
      (percentage / 100) * (maxValue - minValue) + minValue,
    )

    onChangeValue(newValue)
  }
  return (
    <Box
      pt={{ base: '8px', md: 'unset' }}
      pb={{ base: '33px', md: 'unset' }}
      px={{ base: '16px', md: 'unset' }}
      bgColor={{ base: 'theme.background', md: 'unset' }}
    >
      {isMobile && (
        <>
          <AppSegmentedPicker
            info={[
              {
                label: '天気',
                link: '/weather/forecast',
                isActive: false,
              },
              {
                label: '雨雲レーダー',
                link: '/weather/radar',
                isActive: true,
              },
            ]}
          />
          {warning ? <WeatherWarning isWarning pb /> : ''}
          <Text
            pt="8px"
            fontSize={p2r(16)}
            lineHeight="150%"
            color="text.sub"
            fontWeight="700"
          >
            {f('雨雲レーダー')}
          </Text>
        </>
      )}
      <Flex justify="center" pt={{ base: '16px', md: '16px' }}>
        <Image
          src={TokushimaMap}
          h={{ base: '314px', md: '546px' }}
          w={{ base: '343px', md: '595px' }}
          objectFit="cover"
          fallbackSrc="/noimage.png"
        />
        {!weatherRadarMap ? (
          <Image
            src={pastUrlList[0]}
            h={{ base: '311px', md: '541px' }}
            w={{ base: '340px', md: '590px' }}
            objectFit="cover"
            position="absolute"
            padding={{ top: '22px' }}
            opacity="0.8"
          />
        ) : (
          <Image
            src={weatherRadarMap}
            h={{ base: '314px', md: '546px' }}
            w={{ base: '340px', md: '590px' }}
            objectFit="cover"
            position="absolute"
            padding={{ top: '22px' }}
            opacity="0.8"
          />
        )}
        {isTablet ? (
          ''
        ) : (
          <Image
            src={TempChart}
            h={{ base: '256px', md: '256px' }}
            w={{ base: '197px', md: '197px' }}
            position="absolute"
            right="5%"
            bottom="15%"
          />
        )}
      </Flex>

      <Flex
        w={{ base: 'unset', md: '966px' }}
        h="44px"
        justify="space-between"
        alignItems="center"
        m="0 auto"
        pb={{ base: '32px', md: 'unset' }}
        mt={{ base: '32px', md: 'unset' }}
      >
        {/* {!intervalId ? (
          <IconButton
            size="sm"
            aria-label="再生"
            icon={<MdPlayArrow />}
            onClick={handlePlayClick}
            disabled={intervalId !== null}
          />
        ) : (
          <IconButton
            size="sm"
            aria-label="停止"
            icon={<MdStop />}
            onClick={handleStopClick}
            disabled={intervalId === null}
            padding={{ base: '1px' }}
          />
        )} */}
        <Text
          color="text.body"
          lineHeight="150%"
          fontSize={{ base: p2r(10), md: p2r(20) }}
          fontWeight="700"
        >
          {f('3時間前')}
        </Text>
        <Slider
          min={minValue}
          max={maxValue}
          defaultValue={0}
          value={sliderValue}
          w={{ base: '210px', md: '756px' }}
          onChange={(val: number) => onChangeValue(val)}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          aria-label="slider-ex-1"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={() => setShowTooltip(false)}
        >
          <SliderMark value={0} mt="1" ml="-2.5" fontSize="sm">
            <Box position="relative">
              <Box bg="white" borderRadius="full" w="6px" h="6px" />
              <Box
                position="absolute"
                left="-12px"
                top="-15px"
                bg={sliderValue > 0 ? 'grand.gray' : 'theme.primary'}
                borderRadius="full"
                w="24px"
                h="24px"
              />
            </Box>
          </SliderMark>
          <SliderMark value={-3} mt="1" ml="-2.5" fontSize="sm">
            <Text
              color="theme.primary"
              lineHeight="150%"
              fontSize={{ base: p2r(10), md: p2r(20) }}
              fontWeight="700"
              mt={{ base: '20px', md: '20px' }}
            >
              現在
            </Text>
          </SliderMark>
          <SliderTrack bg="theme.primary" height="8px" borderRadius="4px">
            <SliderFilledTrack bg="grand.gray" />
          </SliderTrack>
          <Tooltip
            hasArrow
            bg="teal.500"
            color="white"
            placement="top"
            isOpen={showTooltip}
            label={radarTime}
          >
            <SliderThumb
              boxSize={{ base: '40px', md: '44px' }}
              borderRadius="4px"
              borderColor="grand.grayLight"
              border="2px"
              boxShadow="none"
            />
          </Tooltip>
        </Slider>
        <Text
          color="theme.primary"
          lineHeight="150%"
          fontSize={{ base: p2r(10), md: p2r(20) }}
          fontWeight="700"
        >
          {f('1時間後')}
        </Text>
      </Flex>
      {isMobile &&
        (!warning ? (
          <>
            <Box
              display="flex"
              alignItems="left"
              gap={p2r(4)}
              fontSize={p2r(8)}
              fontWeight="700"
              left="0px"
              mt={{ base: '20px', md: '20px' }}
            >
              情報提供元：気象庁/株式会社フランクリン・ジャパン
            </Box>
            <WeatherWarning />
          </>
        ) : (
          ''
        ))}
      {isMobile ? (
        ''
      ) : (
        <Text
          position="absolute"
          left="0px"
          fontSize={p2r(16)}
          fontWeight="700"
        >
          情報提供元：気象庁/株式会社フランクリン・ジャパン
        </Text>
      )}
    </Box>
  )
}

export default WeatherRadar
