import React from 'react'
import useFormatMessage from '../../hooks/useFormatMessage'
import useLocale from '../../hooks/useLocale'

const MypageSample: React.FC = () => {
  const { locale, saveLocale } = useLocale()
  const { f } = useFormatMessage()
  return (
    <>
      {f('テスト')}
      {/* 漢字も大丈夫か確認 */}
      {f('黒田')}
      <button
        onClick={() => {
          locale === 'ja' ? saveLocale('en') : saveLocale('ja')
        }}
      >
        {locale === 'ja' ? '英語' : '日本語'}
      </button>
    </>
  )
}

export default MypageSample
