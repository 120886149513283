import { Flex, RadioGroup } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import AppErrorResult from '../../../components/common/AppErrorResult'
import { AppLoading } from '../../../components/common/AppLoading'
import { TaxiButton } from '../../../components/taxi/TaxiButton'
import { TaxiCompanyRadio } from '../../../components/taxi/TaxiRadioButton'
import { TaxiSectionTitle } from '../../../components/taxi/TaxiSectionTitle'
import { useTaxiCompanyListQuery } from '../../../hooks/coreApi/useTaxiCompanyListQuery'
import useFormatMessage from '../../../hooks/useFormatMessage'
import {
  getEndBusinessTime,
  getStartBusinessTime,
} from '../../../models/taxiCompany'
import useTaxiReservationCreateState, {
  FormValuesType,
} from '../useTaxiReservationCreateState'

// タクシー会社選択
const TaxiReservationCreateSelectCompany: React.FC = () => {
  const { f } = useFormatMessage()
  const { register, handleSubmit } = useForm()

  const { formValues, setFormValues, setTaxiCompanies } =
    useTaxiReservationCreateState()

  const [taxiCompanyId, setTaxiCompanyId] = useState<
    FormValuesType['taxiCompanyId']
  >(formValues.taxiCompanyId)

  const isError = taxiCompanyId === null

  const navigate = useNavigate()

  // タクシー会社一覧取得
  const taxiCompanyListQuery = useTaxiCompanyListQuery({})

  useEffect(() => {
    if (!taxiCompanyListQuery.data) {
      return
    }
    setTaxiCompanies(taxiCompanyListQuery.data.taxiCompanies)
  }, [taxiCompanyListQuery.data])

  if (taxiCompanyListQuery.isLoading) {
    return <AppLoading />
  }

  if (taxiCompanyListQuery.isError) {
    return <AppErrorResult error={taxiCompanyListQuery.error} />
  }

  const handleNext = (selectedTaxiCompanyId: number | null) => {
    if (!selectedTaxiCompanyId) {
      return
    }
    setFormValues({
      taxiCompanyId: selectedTaxiCompanyId,
    })

    // 選択一覧へ遷移
    navigate('/taxi/reservations/create')
  }

  return (
    <form
      onSubmit={handleSubmit(() => handleNext(taxiCompanyId))}
      style={{ height: '100%' }}
    >
      <Flex direction="column" rowGap={{ base: '52px', md: '72px' }}>
        <Flex
          direction="column"
          rowGap={{ base: '16px', md: '40px' }}
          px={{ base: '16px', md: '0px' }}
        >
          <TaxiSectionTitle label={f('タクシー会社を選択してください。')} />

          <RadioGroup
            onChange={(changedValue: string) =>
              setTaxiCompanyId(Number(changedValue))
            }
            value={taxiCompanyId || ''}
          >
            <Flex direction="column" rowGap={{ base: '8px', md: '24px' }}>
              {taxiCompanyListQuery?.data?.taxiCompanies.map((company) => (
                <TaxiCompanyRadio
                  key={`companyRadio-${company.id}`}
                  companyId={company.id}
                  label={company.name}
                  startTime={getStartBusinessTime(company)}
                  endTime={getEndBusinessTime(company)}
                  radioName="taxiCompanyId"
                  address={company.address}
                  {...register('taxiCompanyId')}
                />
              ))}
            </Flex>
          </RadioGroup>
        </Flex>
        <Flex
          mt="auto"
          justify="center"
          p={{ base: '16px', md: '0px' }}
          backgroundColor={{ base: 'grand.white', md: 'unset' }}
          boxShadow={{ base: '0px 0px 10px rgba(0, 0, 0, 0.1)', md: 'unset' }}
        >
          <TaxiButton type="submit" isInvalid={isError}>
            {f('決定')}
          </TaxiButton>
        </Flex>
      </Flex>
    </form>
  )
}

export default TaxiReservationCreateSelectCompany
