import dayjs from 'dayjs'
import queryString from 'query-string'
import React, { createContext, useContext, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { dateToFormatRFC3339 } from '../utils/date'

type ContentSearchQuery = {
  search: string
  startDate?: string
  endDate?: string
  placeId?: number
  tag?: string
}

type ContentSearchContextType = {
  query: ContentSearchQuery
}

export const ContentSearchContext =
  createContext<ContentSearchContextType | null>(null)

type ContentSearchProvidorProps = {
  children: React.ReactNode
}

// const convertNumberArray = (stringArray: string | (string | null)[] | null) => {
//   if (typeof stringArray === 'string') {
//     return [Number(stringArray)]
//   }

//   if (stringArray?.length) {
//     return stringArray.map(Number)
//   }

//   return []
// }

const convertStartDateByYearString = (yearString: string) => {
  const year = Number(yearString)
  const startDate = dayjs(new Date(year, 0, 1))
  return dateToFormatRFC3339(startDate)
}

const convertEndDateByYearString = (yearString: string) => {
  const year = Number(yearString)
  const endDate = dayjs(new Date(year, 11, 31))
  return dateToFormatRFC3339(endDate)
}

export const ContentSearchProvidor: React.FC<ContentSearchProvidorProps> = (
  props,
) => {
  const [searchParams] = useSearchParams()
  const memorizedValue = useMemo(() => {
    const parsed = queryString.parse(searchParams.toString(), {
      arrayFormat: 'bracket',
    })

    return {
      query: {
        search: parsed.search as string,
        startDate: parsed?.year ? convertStartDateByYearString(parsed.year as string) : undefined,
        endDate: parsed?.year ? convertEndDateByYearString(parsed.year as string) : undefined,
        placeId: Number(parsed.placeId),
        tag: (parsed?.tag as string) || '',
      },
    }
  }, [searchParams])

  return (
    <ContentSearchContext.Provider value={memorizedValue}>
      {props.children}
    </ContentSearchContext.Provider>
  )
}

export const useContentSearchContext = () => {
  const context = useContext(ContentSearchContext)

  if (!context)
    throw new Error(
      'useContentSearchContext context must be use inside ContentSearchProvidor',
    )

  return context
}

export const withContentSearch = (WrappedComponent: React.FC) => {
  const EnhancedComponent = () => (
    <ContentSearchProvidor>
      <WrappedComponent />
    </ContentSearchProvidor>
  )
  return EnhancedComponent
}
