import { Flex } from '@chakra-ui/react'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { useResetPassword } from '../../hooks/useResetPassword'
import { Step1 } from './Step1'
import { Step2 } from './Step2'

const LoginResetPassword: React.FC = () => {
  useHeaderConfig({
    show: false,
  })
  const { resetState } = useResetPassword()
  const { step } = resetState
  return (
    <div>
      <Flex
        alignItems="center"
        py="48px"
        bgColor="theme.background"
        minH="100vh"
        direction="column"
        rowGap="80px"
      >
        {step === 1 ? <Step1 /> : <Step2 />}
      </Flex>
    </div>
  )
}

export default LoginResetPassword
