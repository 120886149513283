import { InputProps, Input } from '@chakra-ui/react'

type AppDateSelectProps = InputProps & {
  stepMinutes?: number
}

const AppSelectDate = ({ ...other }: AppDateSelectProps) => (
  <Input type="date" {...other} />
)

export default AppSelectDate
