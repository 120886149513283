import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { LS_KEY } from '../lib/constants'
import { localeState } from '../store/locale'
import { isBrowser, isBrowserLanguage, LanguageTypes } from '../utils/language'

const useLocale = () => {
  const DefaultLocale = isBrowserLanguage()
  const [locale, setLocale] = useRecoilState<string>(localeState)

  const saveLocale = (lang: LanguageTypes) => {
    setLocale(lang)
    if (isBrowser()) {
      localStorage.setItem(LS_KEY.i18n, lang)
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      const l = localStorage.getItem(LS_KEY.i18n)
      if (l) setLocale(l || DefaultLocale)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { locale, saveLocale }
}

export default useLocale
