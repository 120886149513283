import { TaxiReservationApiTaxiReservationListRequest } from '../../openapi'
import appApiClient from './appApiClient'
import useBaseQuery from './useBaseQuery'

export const TAXI_RESERVATION_LIST_QUERY_KEY = 'taxiReservationList'

const defaultRequestOptions: Partial<TaxiReservationApiTaxiReservationListRequest> =
  {
    withTaxiCompany: true,
    withStartTaxiLandmark: true,
    withEndTaxiLandmark: true,
  }

export const useTaxiReservationListQuery = (
  req: TaxiReservationApiTaxiReservationListRequest & {
    id?: number
    userId?: number
  },
) =>
  useBaseQuery({
    queryKey: [TAXI_RESERVATION_LIST_QUERY_KEY, req],
    queryFn: (config) =>
      appApiClient.taxiReservationApi.taxiReservationList(
        { ...req, ...defaultRequestOptions },
        config,
      ),
  })
