import dayjs from 'dayjs'
import { useAuthContext } from '../../context/AuthContext'
import { useTrashCalendarListQuery } from '../../hooks/coreApi/useTrashCalendarListQuery'

export const useTrashCalendars = () => {
  const { me } = useAuthContext()
  const trashCalendarListQuery = useTrashCalendarListQuery({
    id: me?.trashCalendarId || 1,
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().add(1, 'month').format('YYYY-MM-DD'),
    withTrashCalendarDates: true,
  })

  const trashCalendarDates =
    trashCalendarListQuery.data?.trashCalendars?.[0]?.trashCalendarDates?.slice(
      0,
      2, // 直近2件
    )

  return trashCalendarDates
}
