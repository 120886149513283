import { Icon } from '@chakra-ui/react'
import React from 'react'
/**
 * 安心安全アイコン
 */
const Bousai: React.FC = (props) => (
  <Icon boxSize="24px" viewBox="0 0 24 24" {...props}>
    <path
      d="M22.4001 12.1998C22.4001 16.839 18.6393 20.5998 14.0001 20.5998C9.36091 20.5998 5.6001 16.839 5.6001 12.1998C5.6001 7.56061 9.36091 3.7998 14.0001 3.7998C18.6393 3.7998 22.4001 7.56061 22.4001 12.1998Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9717 12.1999C22.9717 17.1547 18.955 21.1714 14.0002 21.1714C9.04546 21.1714 5.02881 17.1547 5.02881 12.1999C5.02881 7.24516 9.04546 3.22852 14.0002 3.22852C18.955 3.22852 22.9717 7.24516 22.9717 12.1999ZM14.0002 20.6C18.6394 20.6 22.4002 16.8391 22.4002 12.1999C22.4002 7.56076 18.6394 3.79995 14.0002 3.79995C9.36105 3.79995 5.60024 7.56076 5.60024 12.1999C5.60024 16.8391 9.36105 20.6 14.0002 20.6Z"
      fill="white"
    />
    <path
      d="M22.4001 12.1998C22.4001 16.839 18.6393 20.5998 14.0001 20.5998C9.36091 20.5998 5.6001 16.839 5.6001 12.1998C5.6001 7.56061 9.36091 3.7998 14.0001 3.7998C18.6393 3.7998 22.4001 7.56061 22.4001 12.1998Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0001 19.4569C18.0081 19.4569 21.2572 16.2078 21.2572 12.1998C21.2572 8.1918 18.0081 4.94267 14.0001 4.94267C9.99209 4.94267 6.74296 8.1918 6.74296 12.1998C6.74296 16.2078 9.99209 19.4569 14.0001 19.4569ZM14.0001 20.5998C18.6393 20.5998 22.4001 16.839 22.4001 12.1998C22.4001 7.56061 18.6393 3.7998 14.0001 3.7998C9.36091 3.7998 5.6001 7.56061 5.6001 12.1998C5.6001 16.839 9.36091 20.5998 14.0001 20.5998Z"
      fill="currentColor"
    />
    <path
      d="M12.0912 10.6728C12.0912 11.3054 11.5784 11.8183 10.9457 11.8183C10.3131 11.8183 9.80029 11.3054 9.80029 10.6728C9.80029 10.0402 10.3131 9.52734 10.9457 9.52734C11.5784 9.52734 12.0912 10.0402 12.0912 10.6728Z"
      fill="currentColor"
    />
    <path
      d="M18.2006 10.6728C18.2006 11.3054 17.6877 11.8183 17.0551 11.8183C16.4225 11.8183 15.9097 11.3054 15.9097 10.6728C15.9097 10.0402 16.4225 9.52734 17.0551 9.52734C17.6877 9.52734 18.2006 10.0402 18.2006 10.6728Z"
      fill="currentColor"
    />
    <path
      d="M17.8185 12.9639H10.1821C10.1821 12.9639 10.1824 16.782 14.0003 16.782C17.8182 16.782 17.8185 12.9639 17.8185 12.9639Z"
      fill="currentColor"
    />
    <mask
      id="mask0_6680_25985"
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="8"
      height="9"
    >
      <path
        d="M0 4L5.63816 1.94788L7.69028 7.58603L2.05212 9.63816L0 4Z"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_6680_25985)">
      <path
        d="M4.63337 7.90745L4.17974 7.72603C3.65247 7.51367 3.21189 7.3253 2.85799 7.16094C2.50408 6.99658 2.2129 6.83937 1.98443 6.68932C1.75602 6.53941 1.57991 6.38936 1.45609 6.23914C1.33212 6.08898 1.23722 5.92385 1.1714 5.74374C1.0369 5.37569 1.04842 5.02344 1.20594 4.68699C1.36347 4.35054 1.627 4.11534 1.99654 3.98138C2.20097 3.90728 2.4113 3.87981 2.62755 3.89897C2.8438 3.91812 3.04737 3.98179 3.23827 4.08995C3.3147 3.88488 3.42956 3.70579 3.58285 3.55267C3.73614 3.39955 3.915 3.28594 4.11943 3.21184C4.48897 3.07788 4.84264 3.08934 5.18046 3.24623C5.51827 3.40312 5.75443 3.66559 5.88893 4.03364C5.95475 4.21374 5.98871 4.40097 5.99082 4.5953C5.99276 4.7897 5.95462 5.01742 5.87639 5.27848C5.79822 5.5397 5.67662 5.84654 5.51159 6.19901C5.34657 6.55148 5.13063 6.97783 4.86378 7.47807L4.63337 7.90745Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09459 4.24983C1.79168 4.35964 1.58856 4.54453 1.46502 4.80839C1.34151 5.07219 1.33001 5.34549 1.44 5.64645C1.49451 5.79562 1.573 5.93244 1.67659 6.05792C1.77639 6.17899 1.92792 6.311 2.14116 6.45095C2.35416 6.59084 2.63207 6.7415 2.97816 6.90223C3.32596 7.06376 3.76152 7.25005 4.2859 7.46126L4.50182 7.54761L4.61131 7.34357C4.87669 6.84608 5.0903 6.42419 5.25248 6.07779C5.41387 5.7331 5.5295 5.43987 5.60237 5.19635C5.67538 4.95272 5.7064 4.75467 5.70486 4.59791C5.7031 4.43546 5.67485 4.28034 5.6203 4.1311C5.51032 3.83013 5.32512 3.62813 5.06025 3.50512C4.79532 3.38208 4.5204 3.37048 4.21748 3.48028C4.0522 3.5402 3.909 3.6312 3.78529 3.75477C3.66124 3.87868 3.56859 4.02289 3.50629 4.19006L3.38941 4.50365L3.09749 4.33825C2.94187 4.25007 2.77782 4.19892 2.60282 4.18341C2.42831 4.16795 2.25988 4.18991 2.09459 4.24983ZM0.946828 4.56577C1.13834 4.15674 1.46229 3.87122 1.89845 3.71311C2.14202 3.62482 2.39426 3.59184 2.65225 3.61469C2.8071 3.62841 2.95674 3.66143 3.10063 3.71344C3.17728 3.58148 3.27061 3.46039 3.38038 3.35075C3.56325 3.16808 3.77777 3.03186 4.02134 2.94357C4.4575 2.78546 4.88993 2.79679 5.30063 2.98752C5.71139 3.17829 5.99851 3.50123 6.15753 3.93635C6.23463 4.14732 6.27429 4.36665 6.27674 4.59288C6.27906 4.82486 6.23376 5.08253 6.15035 5.36089C6.06688 5.63977 5.93933 5.9602 5.77068 6.3204C5.60284 6.67887 5.38445 7.10991 5.11622 7.61274L4.76488 8.26746L4.07384 7.9911C3.54384 7.77763 3.0977 7.58698 2.73778 7.41983C2.37611 7.25186 2.0716 7.08806 1.82767 6.92786M0.946828 4.56577C0.755287 4.97488 0.743761 5.40607 0.902776 5.8412L0.946828 4.56577ZM0.902776 5.8412C0.979905 6.05225 1.09121 6.24569 1.23556 6.42054L0.902776 5.8412ZM1.23556 6.42054L1.45608 6.23923L1.23556 6.42054ZM1.23556 6.42054L1.45608 6.23923L1.23556 6.42054ZM1.23556 6.42054C1.38338 6.59987 1.58412 6.76802 1.82767 6.92786L1.23556 6.42054Z"
        fill="white"
      />
      <path
        d="M1.82767 6.92786L0.946828 4.56577L0.924802 5.20349L0.902776 5.8412L1.23556 6.42054L1.82767 6.92786Z"
        fill="white"
      />
    </g>
  </Icon>
)

export default Bousai
