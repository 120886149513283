import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { p2r } from '../../../utils/font'

type PickerItemType = {
  label: string
  link: string
  isActive: boolean
}
type PickerItemProps = PickerItemType
const PickerItem: React.FC<PickerItemProps> = (props) => (
  <GridItem
    backgroundColor={props.isActive ? 'grand.white' : 'unset'}
    borderRadius={props.isActive ? '7px' : '0px'}
    border={props.isActive ? '0.5px solid rgba(0, 0, 0, 0.04)' : 'none'}
    boxShadow={
      props.isActive
        ? '0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)'
        : 'none'
    }
  >
    <Link to={props.link}>
      <Flex px="8px" py="6px" align="center" justify="center">
        <Text
          fontSize={p2r(13)}
          fontWeight={props.isActive ? '700' : '400'}
          letterSpacing="-0.08px"
        >
          {props.label}
        </Text>
      </Flex>
    </Link>
  </GridItem>
)

export type AppSegmentedPickerProps = {
  info: PickerItemType[]
}
export const AppSegmentedPicker: React.FC<AppSegmentedPickerProps> = (
  props,
) => (
  <Box py="8px">
    <Grid
      gridTemplateColumns="repeat(2, 1fr)"
      backgroundColor="#F5F5F5"
      borderRadius="9px"
      p="2px"
    >
      {props.info.map((item) => (
        <PickerItem
          key={item.label}
          label={item.label}
          link={item.link}
          isActive={item.isActive}
        />
      ))}
    </Grid>
  </Box>
)
