import { Tab, TabProps } from '@chakra-ui/react'
import React from 'react'

export type HomeTabListItemProps = {
  label: string
  backgroundColor: string
  borderColor: string
} & TabProps
export const HomeTabListItem: React.FC<HomeTabListItemProps> = (props) => {
  const { label, backgroundColor, borderColor, ...rest } = props
  return (
    <Tab
      py="12px"
      fontSize="20px"
      lineHeight="150%"
      fontWeight="700"
      borderBottomWidth="4px"
      color="text.headline"
      borderWidth="2px 2px 0px 2px"
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      position="relative"
      marginBottom="0px"
      height="fit-content"
      _selected={{
        py: '16px',
        borderWidth: '4px 4px 0px 4px',
        borderColor,
        borderRadius: '10px 10px 0px 0px',
        zIndex: '10',
        _after: {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '16px',
          bottom: '-8px',
          left: '0px',
          backgroundColor,
        },
      }}
      {...rest}
    >
      {label}
    </Tab>
  )
}
