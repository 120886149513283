import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

export type MypageButtonProps = {
  children: React.ReactNode
} & ButtonProps
export const MypageButton: React.FC<MypageButtonProps> = ({
  children,
  ...rest
}) => (
  <Button
    variant="outline"
    borderWidth="2px"
    borderColor="theme.primary"
    borderRadius="8px"
    px={{ base: '16px', md: '16px' }}
    py={{ base: '8px', md: '16px' }}
    color="theme.primary"
    fontSize={{ base: p2r(16), md: p2r(20) }}
    fontWeight="700"
    h={{ base: '40px', md: '52px' }}
    {...rest}
  >
    {children}
  </Button>
)
