import { QUALINK_VIDEO_BASE_PATH } from '../global-config'
import { RoomMeetingResponse, UserResponse } from '../openapi'

export const getRoomMeetingStatusText = (roomMeeting: RoomMeetingResponse) => {
  switch (roomMeeting.compositionStatus) {
    case 'completed':
      return 'ファイル作成済'
    case 'processing':
      return 'ファイル作成中'
    case 'not_started':
    default:
      return 'ファイル未作成'
  }
}

/**
 * QuaLinkのビデオルームURLを生成する関数
 * @param me ユーザー情報
 * @returns ビデオルームURL
 */
export const createQuolinkVideoRoomUrl = (
  roomId: number,
  me: UserResponse
): string => {
  const params = new URLSearchParams({
    roomId: roomId.toString(),
    name: me.nickname,
    userId: me.id.toString(),
    mike_status: me.cnfEnableMicAtStartOfMeeting?.toString() ?? "false",
    video_status: me.cnfEnableVideoAtStartOfMeeting?.toString() ?? "false",
  });

  return `${
    QUALINK_VIDEO_BASE_PATH
  }/beforeRoom?${params.toString()}`;
};
