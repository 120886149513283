import {
  Flex,
  Text,
  Link,
  PinInput,
  PinInputField,
  Stack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { AuthButton } from '../../components/auth/AuthButton'
import { FormHeading } from '../../components/auth/FormHeading'
import { useOAuthAuthorizeMutation } from '../../hooks/coreApi/useOAuthAuthorizeMutation'
import useFormatMessage from '../../hooks/useFormatMessage'
import { p2r } from '../../utils/font'
import { useLoginState } from './useLoginState'

type Props = {
  oauthState: string | null
  redirectUrl: string | null
}

const ConfirmTwoFA: React.FC<Props> = (props) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const oauthAuthorizeMutation = useOAuthAuthorizeMutation()

  const { f } = useFormatMessage()
  const { loginState } = useLoginState()
  const [pinNumber, setPinNumber] = useState<string>('')
  const isError = pinNumber === ''
  const toast = useToast()

  const onError = () => {
    toast({
      title: 'ログインできませんでした。', // TODO サーバーエラーのメッセージ
      status: 'error',
    })
  }

  const handleNext = () => {
    if (pinNumber.length !== 6) {
      toast({
        title: 'ログインできませんでした。', // TODO サーバーエラーのメッセージ
        status: 'error',
      })
      return
    }

    // 認証コードを取得してAlexaにリダイレクト
    oauthAuthorizeMutation.mutate(
      {
        oauthAuthorizeRequest: {
          id: loginState.email,
          password: loginState.password,
          authCode: pinNumber,
        },
      },
      {
        onSuccess(res2) {
          const { code } = res2.data
          if (!code) {
            onError()
            return
          }

          // このページに付加されるクエリパラメータから取得
          // https://developer.amazon.com/ja-JP/docs/alexa/account-linking/configure-authorization-code-grant.html
          const redirectURL = `${props.redirectUrl}?code=${code}&state=${props.oauthState}`
          window.location.href = redirectURL
        },
        onError,
      },
    )
  }

  return (
    <Flex
      direction="column"
      alignItems={{ base: 'unset', md: 'center' }}
      w="100%"
    >
      <Stack
        spacing={{ base: 'unset', md: 4 }}
        alignItems={{ base: 'unset', md: 'center' }}
      >
        <FormHeading label={f('コードを確認')} />
        <Text fontSize={{ base: p2r(14), md: p2r(16) }} color="text.body">
          {f('送信されたコードを入力してください。')}
        </Text>
      </Stack>
      <Flex
        columnGap="8px"
        mt={{ base: '193px', md: '40px' }}
        mb={{ base: '222px', md: '80px' }}
        justify={{ base: 'space-between', md: 'center' }}
      >
        <PinInput
          focusBorderColor="theme.primary"
          placeholder=""
          onComplete={(event) => {
            setPinNumber(event)
          }}
        >
          <PinInputField
            fontSize={p2r(20)}
            bgColor="grand.white"
            border="2px solid"
            borderColor="grand.grayLight"
            w={{ base: '45px', md: '64px' }}
            h="64px"
          />
          <PinInputField
            fontSize={p2r(20)}
            bgColor="grand.white"
            border="2px solid"
            borderColor="grand.grayLight"
            w={{ base: '45px', md: '64px' }}
            h="64px"
          />
          <PinInputField
            fontSize={p2r(20)}
            bgColor="grand.white"
            border="2px solid"
            borderColor="grand.grayLight"
            w={{ base: '45px', md: '64px' }}
            h="64px"
            mr={{ base: '40px', md: '32px' }}
          />
          <PinInputField
            fontSize={p2r(20)}
            bgColor="grand.white"
            border="2px solid"
            borderColor="grand.grayLight"
            w={{ base: '45px', md: '64px' }}
            h="64px"
          />
          <PinInputField
            fontSize={p2r(20)}
            bgColor="grand.white"
            border="2px solid"
            borderColor="grand.grayLight"
            w={{ base: '45px', md: '64px' }}
            h="64px"
          />
          <PinInputField
            fontSize={p2r(20)}
            bgColor="grand.white"
            border="2px solid"
            borderColor="grand.grayLight"
            w={{ base: '45px', md: '64px' }}
            h="64px"
          />
        </PinInput>
      </Flex>
      {isMobile && (
        <Text color="text.sub" fontSize={p2r(16)} textAlign="center" pb="16px">
          {f('コードが届かない方は')}
          <Link href="/" color="theme.primary" fontSize={p2r(16)}>
            <Text as="u" fontWeight="700">
              {f('こちら')}
            </Text>
          </Link>
        </Text>
      )}
      <AuthButton
        isLoading={oauthAuthorizeMutation.isLoading}
        isDisabled={isError}
        onClick={handleNext}
        bgColor={isError ? 'grand.white' : 'theme.primary'}
        color={isError ? 'grand.grayLight' : 'text.white'}
      >
        {f('ログイン')}
      </AuthButton>
      {!isMobile && (
        <Text color="text.sub" fontSize={p2r(16)} pt="16px">
          {f('コードが届かない方は?')}
          <Link onClick={handleNext} color="theme.primary" fontSize={p2r(16)}>
            <Text as="u" fontWeight="700">
              {f('コードを再送する')}
            </Text>
          </Link>
        </Text>
      )}
    </Flex>
  )
}

export default ConfirmTwoFA
