import {
  Box,
  Button,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { MdClose } from 'react-icons/md'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { p2r } from '../../../utils/font'

type AppContentModalProps = {
  children: React.ReactNode
  isOpen: boolean
  onClose(): void
}
const AppContentModal: React.FC<AppContentModalProps> = (props) => {
  const { f } = useFormatMessage()
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="full">
      <ModalOverlay background="blackAlpha.500" />
      <ModalContent
        mt="4.8vh"
        px={{ base: '16px', md: '32px' }}
        pt={{ base: '80px', md: '15.5vh' }}
        pb={{ base: '40px', md: '2.8vh' }}
        position="relative"
        backgroundColor="theme.background"
        w="100vw"
        minH="calc(100% - 4.8vh) !important"
        borderRadius="20px 20px 0 0"
        justifyContent={{ base: 'center', md: 'unset' }}
      >
        <Button
          leftIcon={
            <Icon as={MdClose} boxSize={{ base: '32px', md: '48px' }} />
          }
          variant="unstyled"
          color="theme.primary"
          h="fit-content"
          display="flex"
          position="absolute"
          top="16px"
          left={{ base: 'unset', md: '32px' }}
          onClick={props.onClose}
        >
          <Text
            as="span"
            fontSize={{ base: p2r(24), md: p2r(32) }}
            fontWeight="700"
          >
            {f('閉じる')}
          </Text>
        </Button>
        <Box>{props.children}</Box>
      </ModalContent>
    </Modal>
  )
}

export default AppContentModal
