import { Icon } from '@chakra-ui/react'
import React from 'react'

const NoteHome: React.FC = (props) => (
  <Icon boxSize="32px" viewBox="0 0 33 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.61701 28.551C7.13879 29.0728 7.76634 29.3337 8.49967 29.3337H24.4997C25.233 29.3337 25.861 29.0728 26.3837 28.551C26.9055 28.0283 27.1663 27.4003 27.1663 26.667V10.667L19.1663 2.66699H8.49967C7.76634 2.66699 7.13879 2.92788 6.61701 3.44966C6.09434 3.97233 5.83301 4.60033 5.83301 5.33366V26.667C5.83301 27.4003 6.09434 28.0283 6.61701 28.551ZM24.4997 12.0003H17.833V5.33366L24.4997 12.0003ZM13.2053 22.4061C13.3793 22.58 13.5885 22.667 13.8329 22.667H15.6107V19.5559H17.3885V22.667H19.1662C19.4107 22.667 19.62 22.58 19.7942 22.4061C19.9682 22.2319 20.0551 22.0226 20.0551 21.7781V17.7781C20.0551 17.6374 20.0237 17.504 19.9609 17.3781C19.8978 17.2522 19.8107 17.1485 19.6996 17.067L17.0329 15.067C16.9514 15.0077 16.8662 14.9633 16.7773 14.9337C16.6885 14.904 16.5959 14.8892 16.4996 14.8892C16.4033 14.8892 16.3107 14.904 16.2218 14.9337C16.1329 14.9633 16.0477 15.0077 15.9662 15.067L13.2996 17.067C13.1885 17.1485 13.1015 17.2522 13.0387 17.3781C12.9756 17.504 12.944 17.6374 12.944 17.7781V21.7781C12.944 22.0226 13.0311 22.2319 13.2053 22.4061Z"
      fill="currentColor"
    />
  </Icon>
)

export default NoteHome
