import { Icon } from '@chakra-ui/react'
import React from 'react'

const WeatherTimeArrow: React.FC = () => (
  <Icon w="22px" h="30px">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4652 23.125L9.99969 0.875L0.53418 23.125H0.568852L9.99969 16.489L19.4305 23.125H19.4652Z"
      fill="#494C4F"
    />
  </Icon>
)

export default WeatherTimeArrow
