import { Icon } from '@chakra-ui/react'
import React from 'react'

/**
 * Home & メニュー ナビゲーションアイコン：通知
 */
const NavNotification: React.FC = (props) => (
  <Icon viewBox="0 0 80 80" {...props}>
    <mask
      id="mask0_5501_273"
      // style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="80"
      height="80"
    >
      <rect width="80" height="80" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_5501_273)">
      <path
        d="M13.334 63.3327V56.666H20.0007V33.3327C20.0007 28.7216 21.3895 24.6238 24.1673 21.0393C26.9451 17.4571 30.5562 15.1105 35.0007 13.9993V11.666C35.0007 10.2771 35.4873 9.09713 36.4607 8.12602C37.4318 7.15268 38.6118 6.66602 40.0007 6.66602C41.3895 6.66602 42.5695 7.15268 43.5407 8.12602C44.514 9.09713 45.0007 10.2771 45.0007 11.666V13.9993C49.4451 15.1105 53.0562 17.4571 55.834 21.0393C58.6118 24.6238 60.0007 28.7216 60.0007 33.3327V56.666H66.6673V63.3327H13.334ZM40.0007 73.3327C38.1673 73.3327 36.5984 72.6805 35.294 71.376C33.9873 70.0693 33.334 68.4993 33.334 66.666H46.6673C46.6673 68.4993 46.0151 70.0693 44.7107 71.376C43.404 72.6805 41.834 73.3327 40.0007 73.3327Z"
        fill="url(#paint0_linear_5501_273)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5501_273"
        x1="40.0007"
        y1="73.3327"
        x2="40.0007"
        y2="6.66602"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5B350" />
        <stop offset="1" stopColor="#F8A528" />
      </linearGradient>
    </defs>
  </Icon>
)

export default NavNotification
