import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { MdHomeFilled, MdPerson, MdVideocam } from 'react-icons/md'
import {
  Link,
  Outlet,
  Location,
  useLocation,
  useMatches,
  ScrollRestoration,
} from 'react-router-dom'
import { AppHeader } from '../../components/common/AppHeader'
import { AppMenuHeader } from '../../components/common/AppMenuHeader'
import NavTaxi from '../../components/svg/NavTaxi'
import useFormatMessage from '../../hooks/useFormatMessage'
import useUserTmpConfig from '../../hooks/useUserTmpConfig'
import { UserConfigHomeTypeMobile } from '../../models/user'

const useSetContents = () => {
  const { f } = useFormatMessage()
  return [
    {
      id: 1,
      path: '/',
      title: f('コンテンツ'),
      icon: MdHomeFilled,
    },
    {
      id: 2,
      path: '/video/private',
      title: f('ビデオ通話'),
      icon: MdVideocam,
    },
    {
      id: 3,
      path: '/taxi',
      title: f('タクシー配車'),
      icon: NavTaxi,
    },
    {
      id: 4,
      path: '/mypage',
      title: f('マイページ'),
      icon: MdPerson,
    },
  ]
}

type MenuCardProps = {
  link: string
  title: string
  icon: React.FC
  isActive: boolean
}
const MenuCard: React.FC<MenuCardProps> = (props) => (
  <GridItem>
    <Link to={props.link}>
      <Flex pt="7px" pb="2px" px="16px" direction="column" align="center">
        <Icon
          as={props.icon}
          boxSize="24px"
          color={props.isActive ? 'theme.primary' : '#9E9E9E'}
        />
        <Text
          mt="4px"
          color={props.isActive ? 'theme.primary' : '#9E9E9E'}
          fontWeight="500"
          fontSize="10px"
          letterSpacing="-0.24px"
        >
          {props.title}
        </Text>
      </Flex>
    </Link>
  </GridItem>
)

/**
 * 固定ボトムナビゲーション
 */
const FooterMenu: React.FC = () => {
  const navigationContents = useSetContents()
  const location = useLocation()
  // 第一階層パスを取得
  const currentPath = `/${location.pathname.split('/')[1] || ''}`

  // コンテンツ判定（トップ以外の3つのリンク以外のパスの場合はtrue）
  const isContents =
    !navigationContents.some(
      (content) => currentPath === `/${content.path.split('/')[1]}`,
    ) || currentPath === '/'

  return (
    <Grid
      as="nav"
      gridTemplateColumns="repeat(4, 1fr)"
      pb="16px"
      background="rgba(255, 255, 255, 0.94)"
      boxShadow="0px -0.5px 0px rgba(0, 0, 0, 0.3)"
      backdropFilter="blur(20px)"
      position="fixed"
      bottom="0px"
      left="0px"
      zIndex="10"
      w="100%"
    >
      {navigationContents.map((content) => {
        if (content.path === '/') {
          return (
            <MenuCard
              key={content.id}
              link={content.path}
              title={content.title}
              icon={content.icon}
              isActive={isContents}
            />
          )
        }
        return (
          <MenuCard
            key={content.id}
            link={content.path}
            title={content.title}
            icon={content.icon}
            isActive={`/${content.path.split('/')[1]}` === currentPath}
          />
        )
      })}
    </Grid>
  )
}

const BasicLayout: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const location = useLocation()
  const currentPath = location.pathname
  const getKey = useCallback(
    (_location: Location, matches: ReturnType<typeof useMatches>) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const match = matches.find((m) => (m.handle as any)?.scrollMode)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((match?.handle as any)?.scrollMode === 'pathname') {
        return _location.pathname
      }
      return location.key
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  // トップかつAパターン（フッター非表示）の場合
  const { userConfig } = useUserTmpConfig()
  const isHomeA =
    currentPath === '/' &&
    userConfig.homeTypeMobile === UserConfigHomeTypeMobile.TYPE_A

  return (
    <Box
      pb={{ base: isHomeA ? '0px' : '68px', md: '0px' }}
      // minH={{ base: 'unset', md: '100vh' }}
      minH="100vh"
      backgroundColor={{
        base: isHomeA ? 'theme.background' : 'unset',
        md: 'unset',
      }}
    >
      {isMobile ? <AppMenuHeader /> : <AppHeader />}

      <Outlet />
      {/* トップのA/B切り替え（非表示中） */}
      {isMobile && !isHomeA && <FooterMenu />}
      <ScrollRestoration getKey={getKey} />
    </Box>
  )
}

export default BasicLayout
