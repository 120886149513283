import { Flex, Box, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import MypageMenu from '../MypageMenu'

const MypageWrapper: React.FC = () => {
  useHeaderConfig({
    title: 'マイページ',
  })
  const isMobile = useBreakpointValue({ base: true, md: false })
  // 現在のパス情報取得
  const location = useLocation()
  const currentPath = location.pathname

  return (
    <Flex
      p={{ base: '24px 16px', md: '0' }}
      minH={{ base: 'unset', md: 'calc(100vh - 56px)' }}
      direction={{ base: 'column', md: 'row' }}
    >
      {/* スマホ かつ （/）以外は非表示 */}
      {!isMobile && currentPath !== '/' && <MypageMenu />}
      <Box w="full" flex="1">
        <Outlet />
      </Box>
    </Flex>
  )
}

export default MypageWrapper
