const WeatherTitleMap: { [key: string]: string; } = {
    "1": "晴れ",
    "2": "曇り",
    "3": "雨",
    "4": "みぞれ(雨)",
    "5": "みぞれ(雪)",
    "6": "雪",
    "11": "快晴",
    "12": "薄曇り",
    "13": "小雨",
    "16": "小雪",

}

export default WeatherTitleMap
