// Retrieve a page response
export const mockPage = {
  object: 'page',
  id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
  created_time: '2022-10-05T09:23:00.000Z',
  last_edited_time: '2022-10-05T09:26:00.000Z',
  created_by: { object: 'user', id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479' },
  last_edited_by: {
    object: 'user',
    id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
  },
  cover: null,
  icon: null,
  parent: { type: 'page_id', page_id: 'c787fea9-c45c-4b39-918a-0ca9070112c0' },
  archived: false,
  properties: {
    title: {
      id: 'title',
      type: 'title',
      title: [
        {
          type: 'text',
          text: { content: 'プライバシーポリシー', link: null },
          annotations: {
            bold: false,
            italic: false,
            strikethrough: false,
            underline: false,
            code: false,
            color: 'default',
          },
          plain_text: 'プライバシーポリシー',
          href: null,
        },
      ],
    },
  },
  url: 'https://www.notion.so/7715e691aaea4fbab2089e08f054c7bc',
}

// Retrieve a block response
export const mockBlock = {
  object: 'list',
  results: [
    {
      object: 'block',
      id: '4f4cb3de-d7b3-4e16-a8c1-5a911fe8678b',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                'イツモスマイル株式会社（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              'イツモスマイル株式会社（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '625e7a4f-b3d1-45ba-a359-c21d91c1ea55',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: { rich_text: [], color: 'default' },
    },
    {
      object: 'block',
      id: '78422565-c733-4c5f-881a-c8047655b21d',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_1',
      heading_1: {
        rich_text: [
          {
            type: 'text',
            text: { content: '第1条（個人情報）', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '第1条（個人情報）',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '610d639f-f032-42c5-8300-88a4c01210e5',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '5088da88-b0ec-43d5-b153-92f23ede3c55',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_1',
      heading_1: {
        rich_text: [
          {
            type: 'text',
            text: { content: '第2条（個人情報の収集方法）', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '第2条（個人情報の収集方法）',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '026dfe41-a0db-4134-ad0a-df752f0beb20',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'f5e2b546-2665-41c2-9c26-200b61fd602a',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_1',
      heading_1: {
        rich_text: [
          {
            type: 'text',
            text: {
              content: '第3条（個人情報を収集・利用する目的）',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '第3条（個人情報を収集・利用する目的）',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'd614d652-a2f6-4a1c-95d6-f1aa111282dd',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '当社が個人情報を収集・利用する目的は，以下のとおりです。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '当社が個人情報を収集・利用する目的は，以下のとおりです。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'a96962d0-c5bd-4024-b7da-d740a8a5652e',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content: '1. 当社サービスの提供・運営のため',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '1. 当社サービスの提供・運営のため',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'a5f4cb26-39c3-4c45-8a2f-621fb34f3baa',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '2. ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '2. ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '09007af9-f0d4-45ce-81c4-544458f0766d',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '3. ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '3. ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'f239953e-865b-4547-a05b-1580357eea47',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '4. メンテナンス，重要なお知らせなど必要に応じたご連絡のため',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '4. メンテナンス，重要なお知らせなど必要に応じたご連絡のため',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'a4029767-b844-432f-9973-0584d18317f0',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '5. 利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '5. 利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '5e15825f-90bf-4aaf-89be-2835b10e4921',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '6. ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '6. ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '05e107e4-1c00-48af-ae8c-d96cd1424954',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '7. 有料サービスにおいて，ユーザーに利用料金を請求するため',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '7. 有料サービスにおいて，ユーザーに利用料金を請求するため',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '3f7ec1be-68eb-43ce-88a4-e31bfa14e948',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content: '8. 上記の利用目的に付随する目的',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '8. 上記の利用目的に付随する目的',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '8348b535-c825-43ab-9285-460484ee63cc',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_1',
      heading_1: {
        rich_text: [
          {
            type: 'text',
            text: { content: '第4条（利用目的の変更）', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '第4条（利用目的の変更）',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '5d7da138-4f90-4722-b5b8-4be0a6059dcd',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '9. 当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '9. 当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '949f5e2c-20a2-4e3c-ae13-9d5bd8da00e5',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '10. 利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '10. 利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'cb023d8a-f577-42e2-ad30-f65ba47ff26c',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_1',
      heading_1: {
        rich_text: [
          {
            type: 'text',
            text: { content: '第5条（個人情報の第三者提供）', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '第5条（個人情報の第三者提供）',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'deafc3e5-d240-4a99-ac88-347380a64c21',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '11. 当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '11. 当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'c0ba1f42-13c9-427f-8082-e542e0c07ee7',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '1. 人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '1. 人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '6dbbccaf-b74a-4263-b531-8acab76446f8',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '2. 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '2. 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'f97c72c7-b50d-415d-b048-dbbf34b6f573',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '3. 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '3. 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '461f7356-c963-4497-91b5-7f357db16e7b',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '4. 予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '4. 予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '9f1d83c1-01b2-4a67-ae24-ba4444cef6f6',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content: '1. 利用目的に第三者への提供を含むこと',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '1. 利用目的に第三者への提供を含むこと',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'ec72d18a-c0cf-4ff8-a9eb-b6aa65e0cd44',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content: '2. 第三者に提供されるデータの項目',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '2. 第三者に提供されるデータの項目',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '2d85d5a7-63f9-42fe-a91f-252f18a53c16',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content: '3. 第三者への提供の手段または方法',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '3. 第三者への提供の手段または方法',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'fd386351-fb89-464b-bad1-73c5951db423',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '4. 本人の求めに応じて個人情報の第三者への提供を停止すること',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '4. 本人の求めに応じて個人情報の第三者への提供を停止すること',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '8628d185-1e3d-47c7-a906-15b9e69d2153',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: { content: '5. 本人の求めを受け付ける方法', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '5. 本人の求めを受け付ける方法',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'da4259ab-55b9-4e4f-97f4-1141a8389ee6',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '12. 前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '12. 前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '487833f6-cbc8-4d09-8698-6207fc313ee0',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '1. 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '1. 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'f3b054fc-e999-44e3-9d92-c15e15ab1256',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '2. 合併その他の事由による事業の承継に伴って個人情報が提供される場合',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '2. 合併その他の事由による事業の承継に伴って個人情報が提供される場合',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '6086b616-326b-4569-aeeb-e0c2d301a51e',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '3. 個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '3. 個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '4e7001c4-47c2-4387-86ae-c007e07c2fc1',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_1',
      heading_1: {
        rich_text: [
          {
            type: 'text',
            text: { content: '第6条（個人情報の開示）', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '第6条（個人情報の開示）',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '13488adf-e251-4533-8825-35e3e2b87a91',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '13. 当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1，000円の手数料を申し受けます。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '13. 当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1，000円の手数料を申し受けます。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '02ee33dc-7b1b-4296-8a22-b34545fb2742',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '1. 本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '1. 本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '3783450f-efde-47a5-b7b0-a7217c00c271',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '2. 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '2. 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '6bc2e49b-9b66-414e-94d6-f446417f6dfc',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content: '3. その他法令に違反することとなる場合',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '3. その他法令に違反することとなる場合',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '01469a39-b4ee-44b0-8ff6-b3c2e67a7207',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '14. 前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '14. 前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '608956d0-6adb-45be-99b7-1b7c499519e6',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_1',
      heading_1: {
        rich_text: [
          {
            type: 'text',
            text: {
              content: '第7条（個人情報の訂正および削除）',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '第7条（個人情報の訂正および削除）',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '327c40fc-c955-468c-a3f0-d67ba7706525',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '15. ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '15. ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '2b2b1b6b-612a-4c1b-9621-24ff60a47856',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '16. 当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '16. 当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '5e89de66-acee-4cc4-8b40-53d5feec6c19',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '17. 当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '17. 当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '3a5b16f3-2db9-400b-a164-d3c8e67ae434',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_1',
      heading_1: {
        rich_text: [
          {
            type: 'text',
            text: { content: '第8条（個人情報の利用停止等）', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '第8条（個人情報の利用停止等）',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '53ebbdd0-b13d-4006-81b3-5ac1f0328bb5',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '18. 当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '18. 当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '201c2485-1ae0-4787-b8e3-74692ac3193a',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '19. 前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '19. 前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'cc9b7f27-b248-4c25-9ec3-445ed68476d2',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '20. 当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '20. 当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'c8e1a9f3-0fe2-4bc2-ac16-5a0127df6e3e',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '21. 前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '21. 前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '717c18d9-dbbb-4af3-8ae2-77f320b19f75',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_1',
      heading_1: {
        rich_text: [
          {
            type: 'text',
            text: {
              content: '第9条（プライバシーポリシーの変更）',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '第9条（プライバシーポリシーの変更）',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '0ad06ba4-246b-4830-9943-0672ed51eb29',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '22. 本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '22. 本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '57dcb550-aa51-4d29-9f63-284b0e72666e',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '23. 当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '23. 当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '84e526e9-b656-40a5-a1ba-0693c954ad65',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_1',
      heading_1: {
        rich_text: [
          {
            type: 'text',
            text: { content: '第10条（お問い合わせ窓口）', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '第10条（お問い合わせ窓口）',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '33cc7fb8-1aa5-4846-bb87-ffeec2b66439',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'd2fe9c5b-aa72-4c7e-a71a-3ac22485a6ae',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content: '住所：〒770-0022 徳島県徳島市佐古二番町5番11号',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '住所：〒770-0022 徳島県徳島市佐古二番町5番11号',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '86e3ddf2-a7e4-4e5c-bef9-bddde004a9fa',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: { content: '社名：イツモスマイル株式会社', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '社名：イツモスマイル株式会社',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'b63c429a-17ab-4666-8f87-3a28e28c1636',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: { content: '担当部署：', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '担当部署：',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'd066c746-aad9-4f81-987c-54ae96e526ad',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: { content: 'Eメールアドレス：', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: 'Eメールアドレス：',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '1e6f67c4-c421-48bd-8308-b119fb8c6352',
      parent: {
        type: 'page_id',
        page_id: '7715e691-aaea-4fba-b208-9e08f054c7bc',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: { content: '無題', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '無題',
            href: null,
          },
        ],
        color: 'default',
      },
    },
  ],
  next_cursor: null,
  has_more: false,
  type: 'block',
  block: {},
}

export const mockTestBlock = {
  object: 'list',
  results: [
    {
      object: 'block',
      id: 'a7c67861-167e-4f2d-ad70-2da9c7636545',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_1',
      heading_1: {
        rich_text: [
          {
            type: 'text',
            text: { content: '見出し1', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '見出し1',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '78a55227-e517-4039-84c4-4e591aee9c6f',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_2',
      heading_2: {
        rich_text: [
          {
            type: 'text',
            text: { content: '見出し2', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '見出し2',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'f4e6f59a-9ab5-4f58-8b0f-80aa93927775',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'heading_3',
      heading_3: {
        rich_text: [
          {
            type: 'text',
            text: { content: '見出し3', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '見出し3',
            href: null,
          },
        ],
        is_toggleable: false,
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'bd0fcd43-d6ea-422b-8a2b-d6ab82edd6a0',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: { rich_text: [], color: 'default' },
    },
    {
      object: 'block',
      id: 'faf6ca36-3269-45de-a768-3c4a76d4efe2',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト通常のテキストダミーテキスト',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'e5782b10-6fdc-4a23-a864-9a68b9946293',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: { rich_text: [], color: 'default' },
    },
    {
      object: 'block',
      id: 'ab8f4357-3a4e-4dc9-98e1-37fc4f1d0fed',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: { content: '太字 ', link: null },
            annotations: {
              bold: true,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '太字 ',
            href: null,
          },
          {
            type: 'text',
            text: { content: '斜体下線 ', link: null },
            annotations: {
              bold: false,
              italic: true,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '斜体下線 ',
            href: null,
          },
          {
            type: 'text',
            text: { content: '打ち消し ', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: true,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '打ち消し ',
            href: null,
          },
          {
            type: 'text',
            text: { content: '色付き', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'red',
            },
            plain_text: '色付き',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'a0dd5c1d-e8e3-4712-9d2d-0f2f6db23300',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: { rich_text: [], color: 'default' },
    },
    {
      object: 'block',
      id: '2dc415ab-9eb1-4e41-87d9-6fb58cebbf6c',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'quote',
      quote: {
        rich_text: [
          {
            type: 'text',
            text: {
              content:
                '引用ダミーテキスト\n引用ダミーテキスト\n引用ダミーテキスト',
              link: null,
            },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text:
              '引用ダミーテキスト\n引用ダミーテキスト\n引用ダミーテキスト',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '46b52815-070d-4126-a196-8e04777fd81f',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: { rich_text: [], color: 'default' },
    },
    {
      object: 'block',
      id: '6fc93144-a6a7-4f1b-9c4a-ba5305e20a8f',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: { content: '画像', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '画像',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: 'faa5cd4e-ea94-4851-8187-cd8b0b02685e',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'image',
      image: {
        caption: [],
        type: 'file',
        file: {
          url: 'https://s3.us-west-2.amazonaws.com/secure.notion-static.com/fbad04fe-41d8-48dc-a4cf-597454a3f337/Untitled.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20221016%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20221016T113914Z&X-Amz-Expires=3600&X-Amz-Signature=5b2ac2be5f2d7766f2fa3777ce0f71c89b74f89898a671a7e9fd8fb693727072&X-Amz-SignedHeaders=host&x-id=GetObject',
          expiry_time: '2022-10-16T12:39:14.628Z',
        },
      },
    },
    {
      object: 'block',
      id: '26b896d6-af95-4445-b465-959e2d507b13',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: { rich_text: [], color: 'default' },
    },
    {
      object: 'block',
      id: '12847e54-c255-40c2-bc4b-6cdda046cc40',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: false,
      archived: false,
      type: 'paragraph',
      paragraph: {
        rich_text: [
          {
            type: 'text',
            text: { content: '画像×2', link: null },
            annotations: {
              bold: false,
              italic: false,
              strikethrough: false,
              underline: false,
              code: false,
              color: 'default',
            },
            plain_text: '画像×2',
            href: null,
          },
        ],
        color: 'default',
      },
    },
    {
      object: 'block',
      id: '7675c7c7-46bf-4b1b-a5e5-a4f71bb4663e',
      parent: {
        type: 'page_id',
        page_id: '749719dc-307d-497f-bfc3-f33ae53daa70',
      },
      created_time: '2022-10-05T09:24:00.000Z',
      last_edited_time: '2022-10-05T09:24:00.000Z',
      created_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      last_edited_by: {
        object: 'user',
        id: 'cdeb6646-527a-46fa-8f6a-376ab17bb479',
      },
      has_children: true,
      archived: false,
      type: 'column_list',
      column_list: {},
    },
  ],
  next_cursor: null,
  has_more: false,
  type: 'block',
  block: {},
}
