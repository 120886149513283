import { useToast } from '@chakra-ui/react'
import { useCallback } from 'react'
import {
  openVideoRoomNative,
  openVideoCallNative,
  isNativeApp,
  OpenVideoCallNativePayload,
  openMyNumberCardEntryNative,
  OpenMyNumberCardEntryNativePayload,
  didLoginNative,
  didLogoutNative,
} from '../utils/nativeApp'

const useNativeApp = () => {
  const toast = useToast()
  // ビデオ通話ルーム画面を開く
  const openVideoRoom = useCallback((roomId: number) => {
    if (isNativeApp()) {
      openVideoRoomNative(roomId)
    } else {
      const url = `${process.env.REACT_APP_CLIENT_VIDEO_BASE_URL}/room/${roomId}`
      window.location.href = url
    }
  }, [])

  // 個別通話コール画面を開く
  const openVideoCall = useCallback((payload: OpenVideoCallNativePayload) => {
    if (isNativeApp()) {
      openVideoCallNative(payload)
    } else {
      toast({
        title:
          'このデバイスは個別通話呼び出しに対応していません。iOSまたはAndroidアプリをご利用ください',
        status: 'error',
      })
    }
  }, [])

  // マイナンバーカード登録画面を開く
  const openMyNumberCardEntry = useCallback(
    (payload: OpenMyNumberCardEntryNativePayload) => {
      if (isNativeApp()) {
        openMyNumberCardEntryNative(payload)
      } else {
        toast({
          title:
            'このデバイスはマイナンバーカード登録に対応していません。iOSまたはAndroidアプリをご利用ください',
          status: 'error',
        })
      }
    },
    [],
  )

  // ネイティブアプリ側でtokenを削除
  const didLogin = useCallback(() => {
    if (isNativeApp()) {
      didLoginNative()
    }
  }, [])

  // ネイティブアプリ側でtokenを削除
  const didLogout = useCallback(() => {
    if (isNativeApp()) {
      didLogoutNative()
    }
  }, [])

  return {
    openVideoRoom,
    openVideoCall,
    openMyNumberCardEntry,
    didLogin,
    didLogout,
  }
}

export default useNativeApp
