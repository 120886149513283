import { Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { MdArrowForwardIos } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { MypageSectionTitle } from '../../components/mypage/MypageSectionTitle'
import {
  MypageSwitchList,
  MypageSwitchListItem,
} from '../../components/mypage/MypageSwitchList'
import { useUserPersistConfigContext } from '../../context/UserPersistConfigContext'
import useFormatMessage from '../../hooks/useFormatMessage'
import { isProd } from '../../utils/common'
import { p2r } from '../../utils/font'

type VideoLinkType = {
  path: string
  label: string
}
const VideoLink: React.FC<VideoLinkType> = (props) => {
  const { path, label } = props
  return (
    <Link to={path}>
      <Flex
        p="16px"
        alignItems="center"
        justifyContent="space-between"
        color="grand.black"
        backgroundColor="grand.white"
        borderWidth="1px"
        borderColor="grand.grayLight"
        borderRadius={{ base: '8px', md: '14px' }}
      >
        <Text fontSize={p2r(16)} lineHeight="150%" color="text.headline">
          {label}
        </Text>
        <Icon
          as={MdArrowForwardIos}
          color="rgba(60, 60, 67, 0.3)"
          boxSize="16px"
        />
      </Flex>
    </Link>
  )
}

const MypageVideo: React.FC = () => {
  const { f } = useFormatMessage()
  const { userPersistConfig, setConfigValue } = useUserPersistConfigContext()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfigValue(event.target.name, event.target.checked)
  }

  return (
    <Flex
      px={{ base: '0px', md: '32px' }}
      py={{ base: '0px', md: '40px' }}
      flexDirection="column"
      gap="40px"
    >
      <MypageSectionTitle label={f('ビデオ通話の詳細設定')} />
      <MypageSwitchList>
        {/* <MypageSwitchListItem
          label={f('ミーティング退出時に確認ダイアログを出す')}
          name="dialogue"
          value={setting.dialogue}
          handleChange={handleChange}
        /> */}
        <MypageSwitchListItem
          label={f('ビデオオンで通話を開始')}
          name="cnfEnableVideoAtStartOfMeeting"
          value={userPersistConfig.cnfEnableVideoAtStartOfMeeting}
          handleChange={handleChange}
        />
        <MypageSwitchListItem
          label={f('マイクオンで通話を開始')}
          name="cnfEnableMicAtStartOfMeeting"
          value={userPersistConfig.cnfEnableMicAtStartOfMeeting}
          handleChange={handleChange}
        />
        {/* <MypageSwitchListItem
          label={f('友達から招待を許可')}
          name="friendInvitation"
          value={setting.friendInvitation}
          handleChange={handleChange}
        /> */}
      </MypageSwitchList>

      {/* <VideoLink
        path={isMobile ? '/video/private' : '/video'}
        label={f('通話部屋を予約する')}
      /> */}

      <VideoLink path="/video/friend-block" label={f('ブロック中の友達')} />

      {isProd() === false && (
        <VideoLink path="/video/room-meeting" label={f('録画した一覧')} />
      )}
    </Flex>
  )
}

export default MypageVideo
