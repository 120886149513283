import { Box } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { AppErrorAlert } from '../../components/common/AppErrorAlert'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppLoading } from '../../components/common/AppLoading'
import {
  AppThumbnailList,
  AppThumbnailListItem,
} from '../../components/common/AppThumbnailList'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { getYoutubeThumbnailUrl } from '../../models/content'
import { getDateFormat } from '../../utils/date'

const BousaiMovie: React.FC = () => {
  useHeaderConfig({
    title: '防災対策動画',
  })

  const PAGE_SIZE = 100
  const { isLoading, isError, error, data } = useContentListQuery('dekigoto', {
    page: 1,
    pageSize: PAGE_SIZE,
    tags: ['防災対策動画'],
  })

  const bousaiMovieContents = useMemo(() => {
    if (!data?.pages?.[0]?.contents) {
      return []
    }

    return data.pages[0].contents
  }, [data])

  if (isLoading) {
    return <AppLoading />
  }

  if (isError) {
    return <AppErrorResult error={error} />
  }

  if (!bousaiMovieContents) {
    return <AppErrorAlert error="コンテンツがありません" />
  }

  return (
    <Box>
      <AppThumbnailList isMore={false} onClickMore={() => {}}>
        {bousaiMovieContents.map((content) => (
          <AppThumbnailListItem
            key={content.id}
            title={content.titleJa}
            date={getDateFormat(content.releaseAt)}
            image={getYoutubeThumbnailUrl(content?.dekigotoVideoUrl)}
            isShowLike
            isLiked={content?.isLiked}
            playCount={content?.likeCount}
            tags={content?.contentTags?.map((tag) => tag.label) || []}
            contentId={content.id}
            link={`/dekigoto/${content.id}`}
            isReaded={content.isRead}
          />
        ))}
      </AppThumbnailList>
    </Box>
  )
}

export default BousaiMovie
