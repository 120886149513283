import { Icon } from '@chakra-ui/react'
import React from 'react'

const SwipeVertical: React.FC = (props) => (
  <Icon viewBox="0 0 24 20" {...props}>
    <path
      d="M1 20V18.5H3.025C2.075 17.3333 1.33333 16.029 0.8 14.587C0.266667 13.1457 0 11.6167 0 10C0 8.38333 0.266667 6.854 0.8 5.412C1.33333 3.97067 2.075 2.66667 3.025 1.5H1V0H6V5H4.5V2.1C3.56667 3.15 2.83333 4.35 2.3 5.7C1.76667 7.05 1.5 8.48333 1.5 10C1.5 11.5167 1.76667 12.946 2.3 14.288C2.83333 15.6293 3.56667 16.8333 4.5 17.9V15H6V20H1ZM15.275 19.675L9.175 17.15L9.575 16.05C9.625 15.9 9.71667 15.7667 9.85 15.65C9.98333 15.5333 10.1417 15.4583 10.325 15.425L13.575 14.775L9.225 4.95C9.05833 4.56667 9.046 4.18333 9.188 3.8C9.32933 3.41667 9.59167 3.14167 9.975 2.975C10.3583 2.80833 10.7417 2.8 11.125 2.95C11.5083 3.1 11.7833 3.36667 11.95 3.75L14.4 9.225L15.225 8.85C15.3583 8.8 15.5083 8.754 15.675 8.712C15.8417 8.67067 15.9917 8.65833 16.125 8.675L20.7 8.875C21.1333 8.90833 21.5167 9.05833 21.85 9.325C22.1833 9.59167 22.4083 9.925 22.525 10.325L23.775 14.65C23.9083 15.1333 23.875 15.6 23.675 16.05C23.475 16.5 23.1417 16.825 22.675 17.025L17.025 19.55C16.7417 19.6833 16.4417 19.7627 16.125 19.788C15.8083 19.8127 15.525 19.775 15.275 19.675Z"
      fill="currentColor"
    />
  </Icon>
)

export default SwipeVertical
