import { Button, ButtonProps, Icon } from '@chakra-ui/react'
import { Howler } from 'howler'
import React, { useEffect, useState } from 'react'
import { MdOutlineStop, MdPlayArrow } from 'react-icons/md'
import useSound from 'use-sound'
import useFormatMessage from '../../../hooks/useFormatMessage'
import useUserTmpConfig from '../../../hooks/useUserTmpConfig'
import { p2r } from '../../../utils/font'

type AppSoundPlayButtonProps = {
  soundPath: string // 音声ファイル
} & ButtonProps

const AppSoundPlayButton: React.FC<AppSoundPlayButtonProps> = (props) => {
  const { f } = useFormatMessage()
  const { soundPath, ...rest } = props

  const { userConfig, setUserConfig } = useUserTmpConfig()

  // コンポーネント内の音声再生フラグ
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  // 音声再生
  const [play, { stop }] = useSound(soundPath || '', {
    interrupt: true,
    onend: () => {
      // 音声再生終了時に再生状況を初期値に更新
      setIsPlaying(false)
      setUserConfig({ playingAudioUrl: null })
    },
  })

  useEffect(
    // アンマウント時に強制的に音声停止
    () => () => {
      // Howlerからstop呼び出し（useSoundのstopでは動かなかったため）
      Howler.stop()
      setIsPlaying(false)
      setUserConfig({ playingAudioUrl: null })
    },
    [],
  )

  useEffect(() => {
    if (!userConfig.playingAudioUrl) {
      return
    }
    // 再生中の音声とコンポーネントの音声が一致していない場合（割り込まれた場合）、停止
    if (userConfig.playingAudioUrl !== soundPath) {
      stop()
      setIsPlaying(false)
      setUserConfig({ playingAudioUrl: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConfig.playingAudioUrl])

  // 再生ボタンクリック
  const playStart = () => {
    setIsPlaying(true)
    setUserConfig({
      playingAudioUrl: soundPath,
    })
    play()
  }

  // 停止ボタンクリック
  const playStop = () => {
    setIsPlaying(false)
    setUserConfig({
      playingAudioUrl: null,
    })
    stop()
  }

  return (
    <Button
      px={{ base: '8px', md: '16px' }}
      py="8px"
      leftIcon={
        <Icon
          as={isPlaying ? MdOutlineStop : MdPlayArrow}
          boxSize={{ base: p2r(24), md: p2r(32) }}
          color="theme.primary"
        />
      }
      height={{ base: '40px', md: '52px' }}
      variant="outline"
      border="2px solid"
      borderColor="theme.primary"
      borderRadius="10px"
      backgroundColor="grand.white"
      color="theme.primary"
      fontSize={{ base: p2r(14), md: p2r(16) }}
      fontWeight="700"
      onClick={isPlaying ? playStop : playStart}
      {...rest}
    >
      {isPlaying ? f('停止する') : f('再生する')}
    </Button>
  )
}

export default AppSoundPlayButton
