import React from 'react'
import weatherCloudy from "../../svg/WeatherCloudy"
import WeatherCloudyRain from '../../svg/WeatherCloudyRain'
import WeatherCloudySunny from '../../svg/WeatherCloudySunny'
import WeatherRain from "../../svg/WeatherRain"
import WeatherSunny from "../../svg/WeatherSunny"

const WeatherIconMap: { [key: string]: React.FC; } = {
    "1": WeatherSunny,
    "2": weatherCloudy,
    "3": WeatherRain,
    "4": WeatherRain,
    "5": WeatherRain,
    "6": WeatherRain,
    "11": WeatherSunny,
    "12": weatherCloudy,
    "13": WeatherRain,
    "16": WeatherRain,
    "100": WeatherSunny, // 晴れ
    "101": WeatherSunny, // 晴れ時々曇り
    "102": WeatherSunny, // 晴れ一時雨
    "103": WeatherSunny, // 晴れ時々雨
    "104": WeatherSunny, // 晴れ一時雪
    "105": WeatherSunny, // 晴れ時々雪
    "106": WeatherSunny, // 晴れ一時雨か雪
    "107": WeatherSunny, // 晴れ時々雨か雪
    "108": WeatherSunny, // 晴れ一時雨から雷雨
    "110": WeatherSunny, // 晴れ時々曇り
    "111": WeatherSunny, // 晴れ後曇り
    "112": WeatherSunny, // 晴れ後一時雨
    "113": WeatherSunny, // 晴れ時々雨
    "114": WeatherSunny, // 晴れ後雨
    "115": WeatherSunny, // 晴れ後一時雪
    "116": WeatherSunny, // 晴れ時々雪
    "117": WeatherSunny, // 晴れ後雪
    "118": WeatherSunny, // 晴れ後雨か雪
    "119": WeatherSunny, // 晴れ後雨か雷雨
    "120": WeatherSunny, // 晴れ朝夕一時雨
    "121": WeatherSunny, // 晴れ朝夕一時雨
    "122": WeatherSunny, // 晴れ夕方一時雨
    "123": WeatherSunny, // 晴れ山沿い雷雨
    "124": WeatherSunny, // 晴れ山沿い雪
    "125": WeatherSunny, // 晴れ午後は雷雨
    "126": WeatherSunny, // 晴れ昼頃から雨
    "127": WeatherSunny, // 晴れ夕方から雨
    "128": WeatherSunny, // 晴れ夜は雨
    "130": WeatherSunny, // 朝の内霧後晴れ
    "131": WeatherSunny, // 晴れ明け方霧
    "132": WeatherSunny, // 明朝夕曇
    "140": WeatherSunny, // 晴れ時々雨で雷を伴う
    "160": WeatherSunny, // 晴れ一時雪か雨
    "170": WeatherSunny, // 晴れ時々雪か雨
    "181": WeatherSunny, // 晴れ後雪か雨
    "200": weatherCloudy, // 曇り
    "201": WeatherCloudySunny, // 曇り時々晴れ
    "202": WeatherCloudyRain, // 曇り一時雨
    "203": WeatherCloudyRain, // 曇り時々雨
    "204": weatherCloudy, // 曇り一時雪
    "205": WeatherCloudyRain, // 曇り時々雨
    "206": WeatherCloudyRain, // 曇り一時雨か雪
    "207": WeatherCloudyRain, // 曇り時々雨か雪
    "208": WeatherCloudyRain, // 曇り一時雨か雷雨
    "209": weatherCloudy, // 霧
    "210": WeatherCloudySunny, // 曇り後時々晴れ
    "211": WeatherCloudySunny, // 曇り後晴れ
    "212": WeatherCloudyRain, // 曇り後一時雨
    "213": WeatherCloudyRain, // 曇り後時々雨
    "214": WeatherCloudyRain, // 曇り後雨
    "215": weatherCloudy, // 曇り後一時雪
    "216": weatherCloudy, // 曇り後時々雪
    "217": weatherCloudy, // 曇り後雪
    "218": WeatherCloudyRain, // 曇り後雨か雪
    "219": WeatherCloudyRain, // 曇り後雨から雷雨
    "220": weatherCloudy, // 曇り朝夕一時雨
    "221": weatherCloudy, // 曇り朝の内一時雨
    "222": weatherCloudy, // 曇り夕方一時雨
    "223": WeatherCloudySunny, // 曇り日中時々晴れ
    "224": WeatherCloudyRain, // 曇り昼頃から雨
    "225": WeatherCloudyRain, // 曇り夕方から雨
    "226": WeatherCloudyRain, // 曇り夜は雨
    "228": weatherCloudy, // 曇り昼頃から雪
    "229": weatherCloudy, // 曇り夕方から雪
    "230": weatherCloudy, // 曇り夜は雪
    "231": weatherCloudy, // 曇り海上海岸は霧か霧雨
    "240": weatherCloudy, // 曇り時々雨で雷を伴う
    "250": weatherCloudy, // 曇り時々雪で雷を伴う
    "260": WeatherCloudyRain, // 曇り一時雪か雨
    "270": WeatherCloudyRain, // 曇り曇り時々雪か雨
    "281": WeatherCloudyRain, // 曇り後雪か雨
    "300": WeatherRain, // 雨
    "301": WeatherRain, // 雨時々晴れ
    "302": WeatherRain, // 雨時々止む
    "303": WeatherRain, // 雨時々雪
    "304": WeatherRain, // 雨か雪
    "306": WeatherRain, // 大雨
    "308": WeatherRain, // 雨で暴風を伴う
    "309": WeatherRain, // 雨一時ゆき
    "311": WeatherRain, // 雨後晴れ
    "313": WeatherRain, // 雨後曇り
    "314": WeatherRain, // 雨後時々雪
    "315": WeatherRain, // 雨後ゆき
    "316": WeatherRain, // 雨か雪後晴れ
    "317": WeatherRain, // 雨か雪後曇り
    "320": WeatherRain, // 朝の内雨後晴れ
    "321": WeatherRain, // 朝の内雨後曇り
    "322": WeatherRain, // 雨朝晩一時雪
    "323": WeatherRain, // 雨昼頃から晴れ
    "324": WeatherRain, // 雨夕方から晴れ
    "325": WeatherRain, // 雨夜は晴れ
    "326": WeatherRain, // 雨夕方から雪
    "327": WeatherRain, // 雨夜は雪
    "328": WeatherRain, // 雨一時強く降る
    "329": WeatherRain, // 雨一時みぞれ
    "340": WeatherRain, // 雪か雨
    "350": WeatherRain, // 雨で雷を伴う
    "361": WeatherRain, // 雪か雨後晴れ
    "371": WeatherRain, // 雪か雨後曇り
    "400": WeatherRain, // 雪
    "401": WeatherRain, // 雪時々晴れ
    "402": WeatherRain, // 雪時々止む
    "403": WeatherRain, // 雪時々雨
    "405": WeatherRain, // 大雪
    "406": WeatherRain, // 風雪強い
    "407": WeatherRain, // 暴風雪
    "409": WeatherRain, // 雪一時雨
    "411": WeatherRain, // 雪後晴れ
    "413": WeatherRain, // 雪後曇り
    "414": WeatherRain, // 雪後雨
    "420": WeatherRain, // 朝の内雪後晴れ
    "421": WeatherRain, // 朝の内雪後曇り
    "422": WeatherRain, // 雪昼頃から雨
    "423": WeatherRain, // 雪夕方から雨
    "425": WeatherRain, // 雪一時強く降る
    "426": WeatherRain, // 雪後みぞれ
    "427": WeatherRain, // 雪一時みぞれ
    "450": WeatherRain, // 雪で雷を伴う
}

export default WeatherIconMap
