import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AppContentBasic } from '../../components/common/AppContentBasic'
import AppErrorResult from '../../components/common/AppErrorResult'
import { AppLoading } from '../../components/common/AppLoading'
import { useContentListQuery } from '../../hooks/coreApi/useContentListQuery'
import { useContentReadMutation } from '../../hooks/coreApi/useContentReadMutation'
import useHeaderConfig from '../../hooks/useHeaderConfig'
import { getDateFormat } from '../../utils/date'

const KinkyuDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const contentReadMutation = useContentReadMutation()

  useHeaderConfig({
    title: '緊急情報',
    showKinkyuBanner: false,
  })

  useEffect(() => {
    if (id) {
      contentReadMutation.mutate({
        contentReadRequest: {
          contentIds: [Number(id)],
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const contentQuery = useContentListQuery('kinkyu', {
    id: Number(id),
  })
  const content = contentQuery?.data?.pages[0].contents[0]

  if (contentQuery.isLoading) {
    return <AppLoading />
  }

  if (!content) {
    return <AppErrorResult message="コンテンツが見つかりませんでした" />
  }

  // 通常記事の場合
  return (
    <AppContentBasic
      date={getDateFormat(content.releaseAt)}
      title={content.titleJa || ''}
      body={content.bodyJa || ''}
      isShowLike={false}
      isLiked={content?.isLiked || false}
      likeCount={content?.likeCount || 0}
      contentId={content.id}
    />
  )
}

export default KinkyuDetail
