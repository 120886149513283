import { Box } from '@chakra-ui/react'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import 'react-big-calendar/lib/css/react-big-calendar.css'

// import dayjs plugins
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import minMax from 'dayjs/plugin/minMax'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import React, { useCallback, useMemo } from 'react'
import { Calendar, Views } from 'react-big-calendar'
import withDragAndDrop, {
  withDragAndDropProps,
} from 'react-big-calendar/lib/addons/dragAndDrop'
import { p2r } from '../../../utils/font'
import { AppCalendarCustomEvent } from './AppCalendar'
import style from './Style'
import dayjslocalizer from './dayjsLocalizer'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

const DragAndDropCalendar = withDragAndDrop(Calendar)

// load dayjs plugins
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(minMax)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.locale('ja')
const localizer = dayjslocalizer(dayjs)

const DEFAULT_HOUR = 6

export type ResourceMapProps = {
  resourceId: number
  resourceTitle: string
}

type AppResourceCalendarProps<TEvent extends object = AppCalendarCustomEvent> =
  {
    events: TEvent[]
    resourceMap: ResourceMapProps[]
    resizable?: boolean
    fullWidth?: boolean
    onSelectEvent?: (event: AppCalendarCustomEvent) => void
    onEventDrop?: (event: AppCalendarCustomEvent) => void
    onEventResize?: (event: AppCalendarCustomEvent) => void
  } & withDragAndDropProps<AppCalendarCustomEvent>

export const ResourceCalendarTitleElement = ({
  name,
  from,
  to,
  type,
}: {
  name: string
  from: string
  to: string
  type: string
}) => {
  const typeTextColor = (eventType: string) => {
    const typeCol = {
      title: '',
      text: '',
    }
    switch (eventType) {
      case 'type2':
        typeCol.title = '#fff'
        typeCol.text = '#ab5a6d'
        break
      case 'type3':
        typeCol.title = '#fff'
        typeCol.text = '#517b79'
        break
      default:
        typeCol.title = ''
        typeCol.text = '#517B79'
    }
    return typeCol
  }
  return (
    <>
      <Box
        mb="2"
        pos="absolute"
        top="6px"
        left="8px"
        color={typeTextColor(type).title}
      >
        {name}
      </Box>
      <Box color={typeTextColor(type).text} fontSize={p2r(12)}>
        <Box>{from}</Box>
        <Box my="1">↓</Box>
        <Box>{to}</Box>
      </Box>
    </>
  )
}

export const AppResourceCalendar: React.FC<AppResourceCalendarProps> = ({
  events,
  resourceMap,
  resizable,
  fullWidth = false,
  onSelectEvent,
  onEventDrop,
  onEventResize,
}) => {
  const lang = {
    ja: {
      week: '週',
      work_week: 'スケジュール',
      day: '日',
      month: '月',
      previous: '',
      next: '',
      today: '現在',
      agenda: 'スケジュール',
      showMore: (total: number) => `他${total}件`,
    },
  }

  const { defaultDate } = useMemo(
    () => ({
      defaultDate: new Date(),
    }),
    [],
  )

  const formats = {
    dateFormat: 'D',
    dayFormat: 'D(ddd)',
    monthHeaderFormat: 'YYYY年M月',
    dayHeaderFormat: 'M月D日(ddd)',
  }

  const eventPropGetter = useCallback(
    (event: any) => ({
      ...{
        className: event.type,
        color: '#fff',
      },
    }),
    [],
  )
  return (
    <div
      className={`${style} calendar-resource ${
        fullWidth ? 'calendar-resource--fullWidth' : ''
      }`}
    >
      <DragAndDropCalendar
        localizer={localizer}
        startAccessor={(event: any) => event.start}
        endAccessor={(event: any) => event.end}
        events={events}
        views={[Views.DAY]}
        formats={formats}
        defaultDate={defaultDate}
        defaultView={Views.DAY}
        messages={lang.ja}
        style={{ height: 500 }}
        eventPropGetter={eventPropGetter}
        resourceIdAccessor={(resource: any) => resource.resourceId}
        resources={resourceMap}
        resourceTitleAccessor={(resource: any) => resource.resourceTitle}
        step={15}
        scrollToTime={dayjs(defaultDate).hour(DEFAULT_HOUR).toDate()}
        resizable={resizable || false}
        longPressThreshold={0}
        onSelectEvent={onSelectEvent}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
      />
    </div>
  )
}
