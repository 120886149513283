import Cookies from 'js-cookie'
import { COOKIE_KEY } from '../lib/constants'
import { didLoginNative, didLogoutNative } from './nativeApp'

export function jwtDecode(token: string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export const getIdToken = (): string | undefined =>
  Cookies.get(COOKIE_KEY.idToken)

export const getRefreshToken = (): string | undefined =>
  Cookies.get(COOKIE_KEY.refreshToken)

export const getExpiredLeftTime = (): number => {
  const idToken = getIdToken()
  if (!idToken) {
    return 0
  }

  const decoded = jwtDecode(idToken)
  const currentTime = Date.now() / 1000
  const leftTime = decoded.exp - currentTime
  return leftTime * 1000
}

export const getCookieOptions = () => {
  const domain = process.env.REACT_APP_COOKIE_DOMAIN || window.location.hostname
  return {
    domain,
  }
}

export const setSession = (idToken: string, refreshToken: string) => {
  Cookies.set(COOKIE_KEY.idToken, idToken, { ...getCookieOptions() })
  Cookies.set(COOKIE_KEY.refreshToken, refreshToken, { ...getCookieOptions() })
  didLoginNative()
}

export const removeSession = () => {
  Cookies.remove(COOKIE_KEY.idToken, { ...getCookieOptions() })
  Cookies.remove(COOKIE_KEY.refreshToken, { ...getCookieOptions() })

  // 以前のドメイン属性なしのCookieも削除できるようにしておく
  // https://github.com/js-cookie/js-cookie?tab=readme-ov-file#basic-usage
  // The cookie with the name foo will only be available on .get() if it's visible from where the code is called; the domain and/or path attribute will not have an effect when reading.
  Cookies.remove(COOKIE_KEY.idToken)
  Cookies.remove(COOKIE_KEY.refreshToken)
  didLogoutNative()
}

export const getTenantId = (): number | undefined => {
  const tenantId = Cookies.get(COOKIE_KEY.tenantId)
  return tenantId ? Number(tenantId) : 1
}

export const isValidToken = (token: string | undefined) => {
  if (!token) {
    return false
  }

  const decoded = jwtDecode(token)

  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}
