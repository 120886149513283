import { Icon } from '@chakra-ui/react'
import React from 'react'

const Note: React.FC = (props) => (
  <Icon boxSize="20px" viewBox="0 0 20 20" {...props}>
    <mask
      id="mask0_2338_3782"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2338_3782)">
      <path
        d="M4.99992 18.3334C4.54159 18.3334 4.14936 18.1703 3.82325 17.8442C3.49659 17.5175 3.33325 17.125 3.33325 16.6667V3.33335C3.33325 2.87502 3.49659 2.48252 3.82325 2.15585C4.14936 1.82974 4.54159 1.66669 4.99992 1.66669H11.6666L16.6666 6.66669V16.6667C16.6666 17.125 16.5035 17.5175 16.1774 17.8442C15.8508 18.1703 15.4583 18.3334 14.9999 18.3334H4.99992ZM10.8333 7.50002H14.9999L10.8333 3.33335V7.50002Z"
        fill="currentColor"
      />
    </g>
  </Icon>
)

export default Note
