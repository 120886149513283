import { UserResponse } from '../openapi/api'
// フォントサイズ
export const UserConfigFontSize = {
  SMALL: 1,
  MEDIUM: 2,
  LARGE: 3,
} as const

export type UserConfigFontSizeType =
  typeof UserConfigFontSize[keyof typeof UserConfigFontSize]

export const UserConfigHomeTypeMobile = {
  TYPE_A: 1,
  TYPE_B: 2,
} as const

export type UserConfigHomeTypeMobileType =
  typeof UserConfigHomeTypeMobile[keyof typeof UserConfigHomeTypeMobile]

export type UserConfig = {
  fontSize: UserConfigFontSizeType
  tenantId: number | null
  homeTypeMobile: UserConfigHomeTypeMobileType
  playingAudioUrl: string | null
}

export const getTaxiHomeText = (user: UserResponse | null | undefined) => {
  let userHomeText = '自宅'
  if (user?.userInfo?.address) {
    userHomeText = `自宅(${user?.userInfo?.address || '住所未登録'})`
  }

  return userHomeText
}
