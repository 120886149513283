import {
  Flex,
  Text,
  Icon,
  HStack,
  Button,
  Box,
  useBreakpointValue,
  FlexProps,
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import useFormatMessage from '../../../hooks/useFormatMessage'
import { p2r } from '../../../utils/font'
import More from '../../svg/More'
import VideoStatusButton from '../VideoStatusButton'

export const VideoUserList: React.FC<{ children: ReactNode } & FlexProps> = (
  props,
) => {
  const { children, ...rest } = props
  return (
    <Flex direction="column" position="relative" {...rest}>
      {children}
    </Flex>
  )
}

export type VideoUserListItemProps = {
  name: string
  nameKn: string
  updatedAt?: string
  status: 'accepted' | 'blocked' | 'in_waiting' | 'in_request'
  isShowStatus?: boolean
  onClickStatus?: () => void
  onClickMore?: () => void
}
export const VideoUserListItem: React.FC<VideoUserListItemProps> = ({
  name,
  nameKn,
  updatedAt,
  status,
  isShowStatus = true,
  onClickStatus,
  onClickMore,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { f } = useFormatMessage()
  return (
    <Flex
      w="full"
      alignItems="center"
      justify="space-between"
      borderBottomWidth="1px"
      borderBottomColor="grand.grayLight"
      backgroundColor="grand.white"
      gap={isMobile ? '16px' : '40px'}
      pl={isMobile ? '16px' : '32px'}
      pr={isMobile ? '16px' : '120px'}
      py="16px"
      _last={{ borderBottomWidth: 0 }}
      id={nameKn}
    >
      <HStack
        spacing={isMobile ? '16px' : '24px'}
        flex="1"
        onClick={() => {
          if (isMobile) {
            if (onClickStatus) {
              onClickStatus()
            }
          }
        }}
      >
        {!isMobile && isShowStatus === true && (
          <VideoStatusButton onClick={onClickStatus} status={status} />
        )}
        <Box>
          {status === 'in_waiting' && updatedAt && (
            <Text>
              {f('依頼日：')}
              {updatedAt}
            </Text>
          )}
          {status === 'in_request' && updatedAt && (
            <Text>
              {f('申請日：')}
              {updatedAt}
            </Text>
          )}
          <Text
            color="text.body"
            fontSize={p2r(24)}
            fontWeight="700"
            noOfLines={isMobile ? 1 : 2}
          >
            {name}
          </Text>
        </Box>
      </HStack>
      {onClickMore && (
        <Box
          display="flex"
          alignItems="center"
          columnGap={isMobile ? '8px' : '16px'}
        >
          <Button variant="unstyled" h="fit-content" onClick={onClickMore}>
            <Icon
              as={More}
              boxSize={isMobile ? '40px' : '48px'}
              color="grand.gray"
            />
          </Button>
        </Box>
      )}
    </Flex>
  )
}
