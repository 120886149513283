import { HTMLAttributes } from 'react'
import { IntlProvider } from 'react-intl'
import useLocale from '../hooks/useLocale'
import * as locales from './locale'

const LocaleProvider = ({ children }: HTMLAttributes<HTMLElement>) => {
  const { locale } = useLocale()
  const messages = locales.en

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export default LocaleProvider
