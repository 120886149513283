import { Heading } from '@chakra-ui/react'
import React from 'react'
import { p2r } from '../../../utils/font'

export type MypageSectionTitleProps = {
  label: string
}
export const MypageSectionTitle: React.FC<MypageSectionTitleProps> = ({
  label,
}) => (
  <Heading
    as="h2"
    color="text.body"
    fontSize={p2r(24)}
    fontWeight="700"
    lineHeight="1"
  >
    {label}
  </Heading>
)
