import { Box, Text, Flex, FormLabel, Input, Button } from '@chakra-ui/react'
import React, { useCallback, useMemo } from 'react'
import { AppLoading } from '../../components/common/AppLoading'
import { MypageButton } from '../../components/mypage/MypageButton'
import { MypageSectionTitle } from '../../components/mypage/MypageSectionTitle'
import { useMeQuery } from '../../hooks/coreApi/useMeQuery'
import useFormatMessage from '../../hooks/useFormatMessage'
import useNativeApp from '../../hooks/useNativeApp'
import theme from '../../theme'
import { p2r } from '../../utils/font'

const MypageMyNumber: React.FC = () => {
  const { f } = useFormatMessage()
  const nativeApp = useNativeApp()
  const meQuery = useMeQuery(
    {},
    {
      // ネイティブアプリ側でマイナンバーカード登録が完了したあと表示を変更するためこの画面ではポーリングをしている
      refetchInterval: 1000,
    },
  )
  const me = meQuery?.data?.user

  const isRegisted = useMemo(() => {
    if (!me) {
      return false
    }
    return me.taxiDiscountFlag
  }, [me])

  const onClickRegist = useCallback((isUsePin: boolean) => {
    nativeApp.openMyNumberCardEntry({
      isUsePin,
    })
  }, [])

  if (meQuery.isLoading) {
    return <AppLoading />
  }

  return (
    <Box px={{ base: '0px', md: '32px' }} pt={{ base: '0px', md: '72px' }}>
      <MypageSectionTitle label={f('マイナンバーカード認証登録')} />
      <Flex
        mt={p2r(40)}
        py="16px"
        backgroundColor="#fff"
        flexDirection="column"
        w="100%"
        minW="242px"
        rowGap="8px"
      >
        <FormLabel
          fontSize={p2r(16)}
          lineHeight="150%"
          fontWeight="400"
          color="text.sub"
          mb="0"
        >
          {f('登録状況')}
        </FormLabel>
        <Input
          type="text"
          p="16px"
          lineHeight="150%"
          fontSize={p2r(24)}
          fontWeight="400"
          color={
            isRegisted ? theme.colors.theme.primary : theme.colors.grand.gray
          }
          variant="unstyled"
          border="1px solid"
          borderColor="grand.grayLight"
          borderRadius="10px"
          value={isRegisted ? '登録済み' : '未登録'}
          onChange={() => {}}
          opacity="1 !important"
          disabled
        />
        <Button
          mt="3"
          variant="link"
          onClick={() => {
            onClickRegist(true)
          }}
        >
          {f('再登録')}
        </Button>
      </Flex>
      {!isRegisted && (
        <>
          <MypageButton
            w="full"
            fontSize={p2r(24)}
            borderRadius="4px"
            height="52px"
            color={isRegisted ? theme.colors.theme.primary : '#fff'}
            backgroundColor={isRegisted ? '#fff' : theme.colors.theme.primary}
            onClick={() => {
              onClickRegist(true)
            }}
          >
            {isRegisted ? f('登録を削除する') : f('登録する')}
          </MypageButton>
          <Box textAlign="center" my={p2r(22)}>
            <Text
              as="a"
              href=""
              color={theme.colors.grand.gray}
              fontSize={p2r(16)}
              onClick={() => {
                onClickRegist(false)
              }}
            >
              {f('PINを忘れた方はこちら')}
            </Text>
          </Box>
        </>
      )}
    </Box>
  )
}

export default MypageMyNumber
