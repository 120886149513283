import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react'
import Axios, { AxiosError } from 'axios'
import React from 'react'
import { parseErrorMessages } from '../../../hooks/coreApi/appApiClient'

export type AppErrorAlertProps = {
  error: any
}

type ErrorResponse = {
  errors?: { [key: string]: string }
}

export const AppErrorAlert: React.FC<AppErrorAlertProps> = ({ error }) => {
  // Axiosエラー以外のエラーの場合
  if (!Axios.isAxiosError(error)) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>エラーが発生しました</AlertDescription>
      </Alert>
    )
  }

  const axiosError = error as AxiosError<ErrorResponse>

  // API側で想定しているバリデーションエラーの場合
  const errors = parseErrorMessages(axiosError)
  return (
    <Alert status="error">
      <AlertIcon />
      {errors.map((err) => (
        <AlertDescription>{err}</AlertDescription>
      ))}
    </Alert>
  )
}
