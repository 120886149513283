//
import { Box, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { useUserPersistConfigContext } from '../../../context/UserPersistConfigContext'
import { UserConfigFontSize } from '../../../models/user'
import { p2r } from '../../../utils/font'
import AppLikeButton from '../../common/AppLikeButton'
import { AppNewLabel } from '../../common/AppNewLabel'

export type HomePanelThumbnailListItemProps = {
  title: string
  date: string
  link?: string
  image: string
  isReaded?: boolean | null
  isLiked?: boolean | null
  contentId: number
}
export const HomePanelThumbnailListItem: React.FC<
  HomePanelThumbnailListItemProps
> = (props) => {
  const isMobile = useBreakpointValue({ base: true, md: false })

  const { userPersistConfig } = useUserPersistConfigContext()

  const link = props.link ? props.link : ''
  const textInner = (
    <Flex flex="1" direction="column" rowGap="8px">
      <Flex columnGap="8px" align="center">
        {!props.isReaded && <AppNewLabel />}
        <Text
          fontSize={p2r(14)}
          lineHeight="150%"
          color="text.sub"
          fontWeight="400"
        >
          {props.date}
        </Text>
      </Flex>
      <Text
        color="text.body"
        fontSize={{ base: p2r(20), md: p2r(16) }}
        lineHeight="150%"
        fontWeight={props.isReaded ? '400' : '700'}
        noOfLines={
          userPersistConfig.cnfFontSize === UserConfigFontSize.SMALL ? 3 : 2
        }
      >
        {props.title}
      </Text>
    </Flex>
  )

  const imageInner = (
    <Box
      position="relative"
      maxWidth="200px"
      w={p2r(200)}
      h={p2r(108)}
      minH="108px"
      overflow="hidden"
    >
      <Image
        display="block"
        w="100%"
        h="100%"
        src={props.image}
        fallbackSrc="/noimage.png"
        objectFit="cover"
        background="linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
      />
    </Box>
  )

  const inner = link ? (
    <Flex columnGap="16px">
      <Link to={link}>{imageInner}</Link>
      <Flex direction="column" rowGap="4px" flex="1">
        <Link to={link}>{textInner}</Link>
        {!isMobile && (
          <AppLikeButton
            mt="auto"
            isLiked={props.isLiked}
            contentId={props.contentId}
          />
        )}
      </Flex>
    </Flex>
  ) : (
    <Flex columnGap="16px">
      <Box>{imageInner}</Box>
      <Flex direction="column" rowGap="4px" flex="1">
        {textInner}
        {!isMobile && (
          <AppLikeButton
            mt="auto"
            isLiked={props.isLiked}
            contentId={props.contentId}
          />
        )}
      </Flex>
    </Flex>
  )

  return (
    <Box
      pt={p2r(12)}
      pb={p2r(4)}
      px="16px"
      bgColor={props.isReaded ? 'grand.graySuperLight' : 'transparent'}
    >
      {inner}
    </Box>
  )
}

export const HomePanelThumbnailList: React.FC<{ children: ReactNode }> = ({
  children,
}) => (
  <Flex direction="column" h="100%">
    {children}
  </Flex>
)
