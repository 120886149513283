import { atom, useRecoilState } from 'recoil'

type LoginStateType = {
  step: number
  callbackPath: string
  email: string
  password: string
  authCode: string
  idToken: string
}

const defaultLoginState = atom<LoginStateType>({
  key: 'login',
  default: {
    step: 1,
    callbackPath: '',
    email: '',
    password: '',
    authCode: '',
    idToken: '',
  },
})

export const useLoginState = () => {
  const [loginState, setOriginalLoginState] =
    useRecoilState<LoginStateType>(defaultLoginState)

  const setLoginState = (params: Partial<LoginStateType>) => {
    setOriginalLoginState({
      ...loginState,
      ...params,
    })
  }

  return {
    loginState,
    setLoginState,
  }
}
