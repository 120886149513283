import React from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { getIdToken } from '../../utils/auth'

const AuthGuard: React.FC = () => {
  const location = useLocation()
  const idToken = getIdToken()

  return !idToken ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : (
    <Outlet />
  )
}

export default AuthGuard
